import aditionalInformation from './en/aditionalInformation.json'
import authPages from './en/authPages.json'
import breadcrumb from './en/breadcrumb.json'
import cards from './en/cards.json'
import clientGroup from './en/clientGroup.json'
import common from './en/common.json'
import configurationInputErrors from './en/configurationInputErrors.json'
import customFilters from './en/customFilters.json'
import dashboard from './en/dashboard.json'
import inputErrors from './en/inputErrors.json'
import modalBlockchainHistory from './en/modalBlockchainHistory.json'
import modalDeleteConfirm from './en/modalDeleteConfirm.json'
import modalUpdatePassword from './en/modalUpdatePassword.json'
import pagination from './en/pagination.json'
import profile from './en/profile.json'
import tables from './en/tables.json'
import toasts from './en/toasts.json'
import users from './en/users.json'
import nutriTable from './en/nutriTable.json'
import partner from './en/partner.json'
import product from './en/product.json'
import productLine from './en/productLine.json'
import termOfUse from './en/termOfUse.json'
import months from './en/months.json'
import modalExportProperties from './en/modalExportProperties.json'
import modalExportOrders from './en/modalExportOrders.json'
import modalExportManageClientGroup from './en/modalExportManageClientGroup.json'

export default {
  aditionalInformation,
  authPages,
  breadcrumb,
  cards,
  clientGroup,
  common,
  configurationInputErrors,
  customFilters,
  dashboard,
  inputErrors,
  modalBlockchainHistory,
  modalDeleteConfirm,
  modalUpdatePassword,
  pagination,
  profile,
  tables,
  toasts,
  users,
  nutriTable,
  partner,
  product,
  productLine,
  termOfUse,
  months,
  modalExportProperties,
  modalExportOrders,
  modalExportManageClientGroup,
  filter: {
    slaughter: 'Filtrar abate'
  }
}

import { IndustryType } from 'types/Industry.types'

export const types = Object.freeze({
  CLEAN_INDUSTRY: '@industry/cleanIndustry',
  CREATE_INDUSTRY: '@industry/createIndustry',
  DELETE_INDUSTRY: '@industry/deleteIndustry',
  EDIT_INDUSTRY: '@industry/editIndustry',
  FETCH_ALL: '@industry/fetchAll',
  FETCH_ONE: '@industry/fetchOne',
  SET_INDUSTRIES: '@industry/setIndustries',
  SET_INDUSTRY_CREATED: '@industry/setIndustryCreated',
  SET_INDUSTRY_EDIT: '@industry/setIndustryEdit',
  SET_INDUSTRY: '@industry/setIndustry',
  SET_LOADING: '@industry/setLoading'
})

export function fetchAll(
  { params = {} }: { params?: Record<string, any> } = { params: {} }
) {
  return { type: types.FETCH_ALL, payload: { params } }
}

export function setIndustries({ industries = {} } = {}) {
  return { type: types.SET_INDUSTRIES, payload: { industries } }
}

export function createIndustry(
  industry: IndustryType,
  toUploadImages: Record<string, File>,
  imgIndustry: File[]
) {
  return {
    type: types.CREATE_INDUSTRY,
    payload: { industry, toUploadImages, imgIndustry }
  }
}

export function setIndustryCreated({ industryCreate = [] } = {}) {
  return {
    type: types.SET_INDUSTRY_CREATED,
    payload: { industryCreate, loading: false }
  }
}

export function fetchOne(id: string) {
  return { type: types.FETCH_ONE, payload: { id } }
}

export function setIndustry({ industry = {} } = {}) {
  return {
    type: types.SET_INDUSTRY,
    payload: { industry, loading: false }
  }
}

export function editIndustry(
  industry: IndustryType,
  toUploadImages: Record<string, File>,
  imgIndustry: File[],
  deletedImgIndustry: string[]
) {
  return {
    type: types.EDIT_INDUSTRY,
    payload: { industry, toUploadImages, imgIndustry, deletedImgIndustry }
  }
}

export function setIndustryEdit({ industryEdit = [] } = {}) {
  return {
    type: types.SET_INDUSTRY_EDIT,
    payload: { industryEdit, loading: false }
  }
}

export function deleteIndustry({ id }) {
  return {
    type: types.DELETE_INDUSTRY,
    payload: id
  }
}

export function setLoading({ loading = false } = {}) {
  return { type: types.SET_LOADING, payload: { loading } }
}

export function cleanIndustry() {
  return {
    type: types.CLEAN_INDUSTRY,
    payload: {
      industryCreate: {},
      industryEdit: {},
      industry: {}
    }
  }
}

import { HTTP } from 'providers'
import { ProductType } from 'types/Product.types'

export class ProductService extends HTTP {
  private endpoint = '/product'

  public fetchAll({ params = {} } = {}) {
    return this.get({ endpoint: this.endpoint, params })
  }

  public fetchOne(id) {
    return this.get({
      endpoint: `${this.endpoint}/${id}`
    })
  }

  public async create(product: ProductType) {
    return this.post({
      endpoint: this.endpoint,
      payload: product
    })
  }

  public uploadProductPhoto({ id, multipartForm }) {
    return this.post({
      endpoint: `${this.endpoint}/upload-product-photo/${id}?`,
      payload: multipartForm
    })
  }

  public async edit(product: ProductType) {
    const { id } = product
    return this.put({
      endpoint: `${this.endpoint}/${id}`,
      payload: product
    })
  }

  public async deleteProduct({ id }) {
    return this.delete({
      endpoint: `${this.endpoint}/${id}`
    })
  }
}

import styled from 'styled-components'
import media from 'styled-media-query'
import Drawer from '@material-ui/core/Drawer'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

export const MenuDrawer = styled(Drawer)`
  .MuiBackdrop-root {
    position: absolute;
  }
`

export const IconWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.labelGoBack};
  padding: 0.4rem;
  border-radius: 0.2rem;

  &.item-active {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`

export const LogoWrapper = styled.div`
  margin-left: 4rem;
  transform: scale(1);

  &.more-small-close {
    ${media.lessThan('small')`
      transform: scale(0);
    `}
  }
`

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-style: oblique;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.3rem;
`

export const ListItemI = styled(ListItemIcon).attrs(() => {})`
  &.MuiListItemIcon-root {
    min-width: 5.8rem;
    padding-left: 1rem;
  }
`

export const ListItemT = styled(ListItemText).attrs(() => {})`
  .MuiTypography-body1 {
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.darkGray};
  }
`

export const Line = styled.div`
  width: 21rem;
  height: 0.1rem;
  background-color: #70707052;
  opacity: 0.2;
  margin: 0 auto;
`

export const Menu = styled.div`
  display: flex;
  align-items: center;
`

export const MenuTitle = styled.p`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.4rem;
  margin-left: 0.2rem;
  cursor: pointer;
`

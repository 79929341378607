import { MouseEventHandler } from 'react'
import { Button } from 'components'

type PropTypes = {
  onSubmit: MouseEventHandler
  title: string
  variant: 'primary' | 'secondary' | 'alternative' | 'map'
}

export function SubmitButton({ onSubmit, title, variant }: PropTypes) {
  return (
    <Button variant={variant} onClick={onSubmit} size="medium">
      {title}
    </Button>
  )
}

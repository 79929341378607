import * as S from './styled'
import { useTranslation } from 'react-i18next'

export type props = {
  CAR: string
  propertyName: string
}

export function PropertyInfoModal({ CAR, propertyName }: props) {
  const { t } = useTranslation()
  return (
    <S.PropertyInfo>
      <S.InfoName>
        <S.InfoTitle>{t('common:property')}</S.InfoTitle>
        <S.InfoDescription>{propertyName}</S.InfoDescription>
      </S.InfoName>

      <S.InfoCAR>
        <S.InfoTitle>{t('common:CAR')}</S.InfoTitle>
        <S.InfoDescription>{CAR}</S.InfoDescription>
      </S.InfoCAR>
    </S.PropertyInfo>
  )
}

import { ProducerType } from 'types/Producer.types'
import { PropertyType } from 'types/Property.types'

export const types = Object.freeze({
  ADD_PRODUCER: '@property/addProducer',
  BLOCK_STATUS_PRODUCER: '@property/blockStatusProducer',
  BLOCK_STATUS_ALL_PRODUCER: '@property/blockStatusAllProducer',
  CLEAN_PROPERTY: '@property/cleanProperty',
  CREATE_PROPERTY: '@property/createProperty',
  DELETE_PROPERTY: '@property/deleteProperty',
  EDIT_PROPERTY: '@property/editProperty',
  FETCH_ALL_LOGS: '@property/fetchAllLogs',
  FETCH_ALL: '@property/fetchAll',
  FETCH_ONE: '@property/fetchOne',
  FETCH_BY_PROPERTY_ID: '@property/fetchByPropertyId',
  IMPORT_PROPERTIES: '@property/importProperties',
  SET_ADD_PRODUCER: '@property/setAddProducer',
  SET_BLOCK_STATUS_PRODUCER: '@property/setBlockStatusProducer',
  SET_BLOCK_STATUS_ALL_PRODUCER: '@property/setBlockStatusAllProducer',
  SET_LOADING: '@property/setLoading',
  SET_PROPERTIES_IMPORT: '@property/setPropertiesImport',
  SET_PROPERTIES_LOGS: '@property/setPropertiesLogs',
  SET_PROPERTIES: '@property/setProperties',
  SET_PROPERTIES_PRODUCERS: '@property/setPropertiesProducers',
  SET_PROPERTY_CREATED: '@property/setPropertyCreated',
  SET_PROPERTY_EDIT: '@property/setPropertyEdit',
  SET_PROPERTY: '@property/setProperty'
})

export function fetchAll(
  { params = {} }: { params?: Record<string, any> } = { params: {} }
) {
  return { type: types.FETCH_ALL, payload: { params } }
}

export function setProperties({ properties = {} } = {}) {
  return { type: types.SET_PROPERTIES, payload: { properties } }
}

export function createProperty(property: PropertyType) {
  return {
    type: types.CREATE_PROPERTY,
    payload: property
  }
}

export function setPropertyCreated({ propertyCreate = [] } = {}) {
  return {
    type: types.SET_PROPERTY_CREATED,
    payload: { propertyCreate, loading: false }
  }
}

export function fetchOne(id: string) {
  return { type: types.FETCH_ONE, payload: { id } }
}

export function setProperty({ property = [] } = {}) {
  return {
    type: types.SET_PROPERTY,
    payload: { property, loading: false }
  }
}

export function editProperty(property: PropertyType) {
  return {
    type: types.EDIT_PROPERTY,
    payload: property
  }
}

export function setPropertyEdit({ propertyEdit = [] } = {}) {
  return {
    type: types.SET_PROPERTY_EDIT,
    payload: { propertyEdit, loading: false }
  }
}

export function deleteProperty({ id }) {
  return {
    type: types.DELETE_PROPERTY,
    payload: id
  }
}

export function setLoading({ loading = false } = {}) {
  return { type: types.SET_LOADING, payload: { loading } }
}

export function cleanProperty() {
  return {
    type: types.CLEAN_PROPERTY,
    payload: {
      addProducer: {},
      blockStatusProducer: {},
      blockStatusAllProducer: {},
      propertyCreate: {},
      propertyEdit: {},
      propertiesImport: [],
      propertiesProducers: {
        items: []
      },
      property: {}
    }
  }
}

export function fetchAllLogs(
  { params = {} }: { params?: Record<string, any> } = { params: {} }
) {
  return { type: types.FETCH_ALL_LOGS, payload: { params } }
}

export function setPropertiesLogs({ logsProperties = {} } = {}) {
  return {
    type: types.SET_PROPERTIES_LOGS,
    payload: { logsProperties, loading: false }
  }
}

export function importProperties({ propertiesTmpIds }: Record<string, any>) {
  return { type: types.IMPORT_PROPERTIES, payload: { propertiesTmpIds } }
}

export function setPropertiesImport({ propertiesImport = [] } = {}) {
  return {
    type: types.SET_PROPERTIES_IMPORT,
    payload: { propertiesImport, loading: false }
  }
}

export function addProducer(producer: ProducerType[], id: string) {
  return {
    type: types.ADD_PRODUCER,
    payload: { producer, id }
  }
}

export function setAddProducer({ addProducer = [] } = {}) {
  return {
    type: types.SET_ADD_PRODUCER,
    payload: { addProducer, loading: false }
  }
}

export function blockStatusProducer(producer: ProducerType[], id: string) {
  return {
    type: types.BLOCK_STATUS_PRODUCER,
    payload: { producer, id }
  }
}

export function setBlockStatusProducer({ blockStatusProducer = [] } = {}) {
  return {
    type: types.SET_BLOCK_STATUS_PRODUCER,
    payload: { blockStatusProducer, loading: false }
  }
}

export function fetchByPropertyId(propertyId: string) {
  return { type: types.FETCH_BY_PROPERTY_ID, payload: { propertyId } }
}

export function setPropertiesProducers({ propertiesProducers = {} } = {}) {
  return {
    type: types.SET_PROPERTIES_PRODUCERS,
    payload: { propertiesProducers }
  }
}

export function blockStatusAllProducer(
  blockStatus: { blockStatus: string; reason: string },
  propertyId: string
) {
  return {
    type: types.BLOCK_STATUS_ALL_PRODUCER,
    payload: { blockStatus, propertyId }
  }
}

export function setBlockStatusAllProducer({
  blockStatusAllProducer = []
} = {}) {
  return {
    type: types.SET_BLOCK_STATUS_ALL_PRODUCER,
    payload: { blockStatusAllProducer, loading: false }
  }
}

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { IndustryType } from 'types/Industry.types'

import { Checkbox, Icon } from 'components'
import { IndustryGroupFilterDropDown } from './IndustryGroupFilterDropDown'
import { ModalDelete } from './ModalDelete/ModalDelete'
import { TableNotFoundHint } from 'components/TableNotFoundHint'

import { unlinkWithGroup } from 'store/IndustryGroup/IndustryGroup.actions'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type IndustryGroupFilterTableProps = {
  groupId: string
  groupName: string
  items: IndustryType[]
  onNewClick?: (_value: unknown) => void
}

type DeleteSelected = string[]

export function IndustryGroupFilterTable({
  groupId,
  groupName,
  items = [],
  onNewClick
}: IndustryGroupFilterTableProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [deleteIndustry, setDeleteIndustry] = useState({} as IndustryType)
  const [deleteSelected, setDeleteSelected] = useState([] as DeleteSelected)
  const [allCheckboxes, setAllCheckboxes] = useState(false)
  const [checkboxes, setCheckboxes] = useState([])

  useEffect(() => {
    if (items.length) {
      if (checkboxes.length === items.length) return setAllCheckboxes(true)
      return setAllCheckboxes(false)
    }
  }, [checkboxes, items])

  function cleanSelectedIndustries() {
    setCheckboxes([])
  }

  function changeAll() {
    setAllCheckboxes(!allCheckboxes)
    if (!allCheckboxes) return setCheckboxes(items.map(({ id }) => id))
    return cleanSelectedIndustries()
  }

  function handleCheckbox(id: string) {
    return function handle(val) {
      if (val) return setCheckboxes([...checkboxes, id])

      const newCheckboxes = checkboxes.filter(checkbox => checkbox !== id)
      return setCheckboxes(newCheckboxes)
    }
  }

  function onExit() {
    setDeleteIndustry({} as IndustryType)
    setDeleteSelected([] as DeleteSelected)
  }

  function onRemoveOne() {
    dispatch(unlinkWithGroup({ id: groupId, companyIds: [deleteIndustry.id] }))
    setDeleteIndustry({} as IndustryType)
    cleanSelectedIndustries()
    setAllCheckboxes(false)
  }

  function onRemoveSelected() {
    dispatch(unlinkWithGroup({ id: groupId, companyIds: deleteSelected }))
    setDeleteSelected([] as DeleteSelected)
    cleanSelectedIndustries()
    setAllCheckboxes(false)
  }

  function descriptionIndustrySelect() {
    return `${checkboxes.length} ${t('common:industrySelected')}`
  }

  function actionIndustrySelect() {
    return t('common:industryRemove')
  }

  return (
    <S.Wrapper data-testid="IndustryGroupFilterTable">
      {Object.keys(deleteIndustry).length ? (
        <ModalDelete
          cnpj={deleteIndustry.cnpj}
          groupName={deleteIndustry.name}
          onExit={onExit}
          onRemove={onRemoveOne}
        />
      ) : null}
      {deleteSelected.length ? (
        <ModalDelete
          cnpj={deleteSelected.map(
            id => items.find(({ id: eachId }) => eachId === id).cnpj
          )}
          groupName={groupName}
          onExit={onExit}
          onRemove={onRemoveSelected}
        />
      ) : null}
      {items && checkboxes.length ? (
        <S.CheckboxInfoGuide>
          <S.SelectedCheckboxInfo>
            <S.RemoveIndustryIcon
              title={t('common:clean')}
              onClick={cleanSelectedIndustries}
            >
              <Icon icon="input-exit" height={12} width={12} />
            </S.RemoveIndustryIcon>
            <span>{descriptionIndustrySelect()}</span>
            <S.RemoveIndustry onClick={() => setDeleteSelected(checkboxes)}>
              {actionIndustrySelect()}
            </S.RemoveIndustry>
          </S.SelectedCheckboxInfo>
        </S.CheckboxInfoGuide>
      ) : null}
      <S.TableGroup>
        <S.ScrollWrapper>
          <S.Table id="table-scrolled">
            <tbody>
              <S.Line id="header">
                <S.Header>
                  <S.CheckboxLine>
                    <Checkbox
                      labelFor=""
                      label=""
                      value="all"
                      checked={allCheckboxes}
                      onCheck={changeAll}
                    />
                    {t('tables:socialReason')}
                  </S.CheckboxLine>
                </S.Header>
                <S.Header>{t('tables:fantasyName')}</S.Header>
                <S.Header>{t('tables:CNPJ')}</S.Header>
              </S.Line>
              {items.map((each, key) => (
                <S.Line id="values" key={key}>
                  <S.Column>
                    <S.CheckboxLine>
                      <Checkbox
                        labelFor=""
                        label=""
                        value={each.id}
                        isChecked={
                          !!checkboxes.filter(f => f.includes(each.id)).length
                        }
                        onCheck={handleCheckbox(each.id)}
                      />
                      {each.name}
                    </S.CheckboxLine>
                  </S.Column>
                  <S.Column>{each.fancyName}</S.Column>
                  <S.Column>{each.cnpj}</S.Column>
                </S.Line>
              ))}
            </tbody>
          </S.Table>
        </S.ScrollWrapper>
        <S.Table id="table-fixed">
          <tbody>
            <S.Line id="header">
              <S.Header>{t('common:actions')}</S.Header>
            </S.Line>
            {items.map((each, key) => (
              <S.Line key={key}>
                <S.Column id="actions-column">
                  <IndustryGroupFilterDropDown
                    setDeleteIndustry={() => setDeleteIndustry(each)}
                  />
                </S.Column>
              </S.Line>
            ))}
          </tbody>
        </S.Table>
      </S.TableGroup>
      {!items.length && <TableNotFoundHint onClick={onNewClick} />}
    </S.Wrapper>
  )
}

export const types = Object.freeze({
  GET_BY_HASHPARTNER: '@orderHistory/getByHashPartner',
  SET_ORDERHISTORY: '@orderHistory/setOrderHistory',
  CLEAR_ORDERHISTORY: '@orderHistory/clearOrderHistory'
})

export function setOrderHistory({ orderHistories = [], success = true } = {}) {
  return { type: types.SET_ORDERHISTORY, payload: { orderHistories, success } }
}

export function clearOrderHistory({
  orderHistories = [],
  success = true
} = {}) {
  return {
    type: types.CLEAR_ORDERHISTORY,
    payload: { orderHistories, success }
  }
}

export function getByHashPartner(hashPartner: string) {
  return { type: types.GET_BY_HASHPARTNER, payload: { hashPartner } }
}

import { GeoJSONType } from '../../types/TraceabilityMap.types'

export const types = {
  setMarkerMap: '@traceabilityMap/SET_MARKER_MAP',
  setBiomes: '@traceabilityMap/SET_BIOMES',
  setCities: '@traceabilityMap/SET_CITIES',
  setStates: '@traceabilityMap/SET_STATES'
}

export function setMarkerMap(node) {
  const nodesSelectedChart: any[] = []

  if (!node || node.length === 0) {
    return { type: types.setMarkerMap, payload: { nodesSelectedChart } }
  }

  return { type: types.setMarkerMap, payload: { nodesSelectedChart: node } }
}

export function setBiomesGeoJSON(biomesGeoJSON: GeoJSONType) {
  return { type: types.setBiomes, payload: { biomesGeoJSON } }
}

export function setCitiesGeoJSON(citiesGeoJSON: GeoJSONType) {
  return { type: types.setCities, payload: { citiesGeoJSON } }
}

export function setStatesGeoJSON(statesGeoJSON: GeoJSONType) {
  return { type: types.setStates, payload: { statesGeoJSON } }
}

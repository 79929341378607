import { Link } from 'react-router-dom'
import { useState } from 'react'

import { Dropdown } from 'components/Dropdown'
import { Icon } from 'components/Icon'
import { useTranslation } from 'react-i18next'
import { translation } from 'utils/yup-translation'

import * as S from './styles'
import { setLocale } from 'yup'

import { UserService, UserStorageService } from 'services'

export const LangDropdown = ({ userLang }) => {
  const { i18n } = useTranslation()
  const langConfig = {
    'pt-BR': 'Português',
    'en-US': 'English',
    es: 'Español',
    ch: '中文 - ZH - CN'
  }
  const flagConfig = {
    'pt-BR': 'brazil',
    'en-US': 'england',
    es: 'spain',
    ch: 'china'
  }
  async function setChangeLang(lang: string, label: string) {
    i18n.changeLanguage(lang)
    setActiveLang(label)
    setLocale(translation())
    setFlag(flagConfig[lang])
    const $user = new UserService()
    const loggedInUser = UserStorageService.getUserDateForLangDropdown()

    if (loggedInUser) {
      await $user.updateProfile(loggedInUser.id, {
        lang: lang
      })

      UserStorageService.signIn(
        JSON.stringify({
          ...loggedInUser.user,
          lang: lang
        })
      )
    }
  }
  const [activeLang, setActiveLang] = useState(langConfig[userLang])
  const [flag, setFlag] = useState(flagConfig[userLang])

  return (
    <S.Wrapper>
      <Dropdown
        title={
          <>
            <Icon icon={`flag-${flag}`} height={31} width={31}></Icon>
            <S.Langname>{activeLang}</S.Langname>
            <Icon icon="user-profine-arrow-down" height={16} width={16} />
          </>
        }
      >
        <S.Nav>
          <Link to={'#'} onClick={() => setChangeLang('pt-BR', 'Português')}>
            <S.Link>
              <Icon icon="flag-brazil" height={16} width={16}></Icon>
              <span>Português</span>
            </S.Link>
          </Link>
          <Link to={'#'} onClick={() => setChangeLang('en-US', 'English')}>
            <S.Link>
              <Icon icon="flag-england" height={16} width={16}></Icon>
              <span>English</span>
            </S.Link>
          </Link>
          <Link to={'#'} onClick={() => setChangeLang('es', 'Español')}>
            <S.Link>
              <Icon icon="flag-spain" height={16} width={16}></Icon>
              <span>Español</span>
            </S.Link>
          </Link>
          <Link to={'#'} onClick={() => setChangeLang('ch', '中文 - ZH - CN')}>
            <S.Link>
              <Icon icon="flag-china" height={16} width={16}></Icon>
              <span>中文 - ZH - CN</span>
            </S.Link>
          </Link>
        </S.Nav>
      </Dropdown>
    </S.Wrapper>
  )
}

import { Input, InputAutocomplete, InputSelect } from 'components'
import { ERROR_INITIAL_STATE, INITIAL_STATE } from 'constants/product.constants'
import { useTranslation } from 'react-i18next'
import i18n from 'i18n'
import * as S from './styled'
import { ProductPhoto } from './ProductPhoto/ProductPhoto'

type ProductDetailsType = {
  payload: typeof INITIAL_STATE
  errors: typeof ERROR_INITIAL_STATE
  handleInput: (_value, _name) => void
  handleSelected: (_name: string) => (_value: any) => void
  handleAutocomplete: (_name, _value) => void
  onLoadImage: (_key) => void
  onRemoveImage: (_key) => void
}

const date = [
  { label: i18n.t('product:abateDate'), value: 'abate' },
  { label: i18n.t('product:dessocaDate'), value: 'desossa' },
  { label: i18n.t('product:packingDate'), value: 'embalagem' }
]

const prepare = [
  { label: i18n.t('product:cooked'), value: 'Cozido' },
  { label: i18n.t('product:grilled'), value: 'Grelhado' }
]

const language = [
  { label: i18n.t('product:langpt'), value: 'portugues' },
  { label: i18n.t('product:langing'), value: 'ingles' },
  { label: i18n.t('product:langchin'), value: 'chines' }
]

export function ProductDetails({
  payload,
  errors,
  handleInput,
  handleSelected,
  handleAutocomplete,
  onLoadImage,
  onRemoveImage
}: ProductDetailsType) {
  const { t } = useTranslation()
  return (
    <S.Wrapper container>
      <S.GridFields container item sm={12} md={8} spacing={2}>
        <S.GridInput item sm={12} md={4}>
          <InputSelect
            fullWidth
            label={t('product:language')}
            optionLabel="label"
            optionValue="value"
            options={language}
            error={Boolean(errors.language)}
            helperText={errors.language}
            value={payload.language}
            onSelected={handleSelected('language')}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <Input
            fullWidth
            label={t('product:prazoValidate')}
            value={payload.prazoValidate}
            error={Boolean(errors.prazoValidate)}
            helperText={errors.prazoValidate}
            onInput={value => handleInput(value, 'prazoValidate')}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <InputSelect
            fullWidth
            label={t('product:defaultData')}
            optionLabel="label"
            optionValue="value"
            options={date}
            error={Boolean(errors.padraoData)}
            helperText={errors.padraoData}
            value={payload.padraoData}
            onSelected={handleSelected('padraoData')}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={12}>
          <S.StyleAutoComplete8>
            <InputAutocomplete
              fullWidth
              label={t('product:prepare')}
              options={prepare}
              optionLabel="label"
              error={Boolean(errors.preparo)}
              helperText={errors.preparo}
              defaultValue={payload.preparo}
              onSelected={val => handleAutocomplete('preparo', val)}
            />
          </S.StyleAutoComplete8>
        </S.GridInput>
        <S.GridInput item sm={12} md={12}>
          <Input
            fullWidth
            label={t('product:observation')}
            value={payload.observation}
            multiline
            rows={3}
            error={Boolean(errors.observation)}
            helperText={errors.observation}
            onInput={value => handleInput(value, 'observation')}
          />
        </S.GridInput>
      </S.GridFields>
      <S.GridPhoto item sm={12} md={4}>
        {/* <S.DividerVertical orientation="vertical" /> */}
        <S.CardPhoto>
          <ProductPhoto
            name="photo"
            imgUrl={payload.photo ? payload.photo.src : ''}
            onLoadImage={onLoadImage}
            onRemoveImage={onRemoveImage}
          />
        </S.CardPhoto>
      </S.GridPhoto>
    </S.Wrapper>
  )
}

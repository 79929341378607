import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const GridTitle = styled(Grid)`
  display: block;
  padding: 0.5rem 0 1rem 0;
`

export const BoxTitle = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.4rem;
  font-weight: 600;
  padding-bottom: 1rem;
`

export const GridFilter = styled(Grid)`
  display: flex;
  margin: 0 -0.8rem;
  width: calc(100% + 16px);
  flex-direction: row;
  padding-bottom: 0.9rem;
  padding-top: 1.2rem;
`

export const FullGrid = styled(Grid)`
  padding: 2.4rem;
`

export const GridInputs = styled(Grid)`
  display: flex;
`

export const GridButtons = styled(Grid)`
  align-self: center;
  text-align: end;
  button + button {
    margin-left: 0.8rem;
  }
`
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 0.4rem;
  padding-top: 3.3rem;
  padding-bottom: 2rem;
`
export const GridSubtitle = styled.div``
export const BoxSubtitle = styled.h2`
  font-size: 1.4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary};
  padding-bottom: 1.4rem;
`
export const GridInput = styled(Grid)``

export const GridTable = styled(Grid)``

export const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ButtonText = styled.span`
  margin-left: 12px;
`

import { TraceabilityFilter } from 'types/Traceability.types'

export const types = Object.freeze({
  GET_RAW_MATERIAL: '@box/getRawMaterial',
  SET_FILTERS_RAW_MATERIAL: '@box/setFiltersRawMaterial',
  SET_RAW_MATERIAL: '@box/setRawMaterial',
  CLEAN_RAW_MATERIAL: '@box/cleanRawMaterial'
})

export function getRawMaterial(
  filterRawMaterial?: TraceabilityFilter,
  ordersKeys?: string[]
) {
  return {
    type: types.GET_RAW_MATERIAL,
    payload: { filterRawMaterial, ordersKeys }
  }
}

export function setFilterRawMaterial(filterRawMaterial: TraceabilityFilter) {
  return { type: types.SET_RAW_MATERIAL, payload: { filterRawMaterial } }
}

export function cleanRawMaterial() {
  return { type: types.CLEAN_RAW_MATERIAL }
}

export function setRawMaterial({ rawMaterial = {} } = {}) {
  return { type: types.SET_RAW_MATERIAL, payload: { rawMaterial } }
}

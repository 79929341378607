import { get } from 'lodash'
import { IndustryType } from 'types/Industry.types'

import { types } from './Industry.actions'

type Industry = {
  industries: {
    items: IndustryType[]
    pagination: {
      totalItems: number
      limit: number
      page: number
    }
  }
  industryCreate?: IndustryType
  industryEdit?: IndustryType
  industry?: IndustryType
  loading: boolean
}

const INITIAL_STATE = {
  industries: {
    items: [],
    pagination: null
  },
  industryCreate: {} as IndustryType,
  industryEdit: {} as IndustryType,
  industry: {} as IndustryType,
  loading: false
}

function setState(state: Industry) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.CLEAN_INDUSTRY]: setState(state),
    [types.SET_INDUSTRIES]: setState(state),
    [types.SET_INDUSTRY]: setState(state),
    [types.SET_INDUSTRY_CREATED]: setState(state),
    [types.SET_INDUSTRY_EDIT]: setState(state),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}

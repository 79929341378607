import { get } from 'lodash'

import { IndustryType } from 'types/Industry.types'
import {
  IndustryGroupOptionsType,
  IndustryGroupType
} from 'types/IndustryGroup.types'

import { types } from './IndustryGroup.actions'

type IndustryGroup = {
  industryGroups: {
    items: IndustryGroupType[]
    pagination: {
      totalItems: number
      limit: number
      page: number
    }
  }
  industryGroupCreate?: IndustryGroupType
  industryGroupEdit?: IndustryGroupType
  loading: boolean
  manageIndustryGroup?: IndustryGroupType
  options?: IndustryGroupOptionsType[]
  unrelatedIndustries?: {
    items: IndustryType[]
    pagination: {
      totalItems: number
      limit: number
      page: number
    }
  }
}

const INITIAL_STATE = {
  industryGroups: {
    items: [],
    pagination: null
  },
  industryGroupCreate: {} as IndustryGroupType,
  industryGroupEdit: {} as IndustryGroupType,
  loading: false,
  manageIndustryGroup: {} as IndustryGroupType,
  options: [],
  unrelatedIndustries: {
    items: [],
    pagination: null
  }
}

function setState(state: IndustryGroup) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

function removeDeletedIndustries(state: IndustryGroup) {
  return function _handle({ ids }: { ids: string[] }) {
    return {
      ...state,
      manageIndustryGroup: {
        ...state.manageIndustryGroup,
        companies: state.manageIndustryGroup.companies.filter(
          ({ id }) => !ids.includes(id)
        )
      }
    }
  }
}

function updateGroupIndustries(state: IndustryGroup) {
  return function _handle({ companies, companiesPagination }) {
    return {
      ...state,
      manageIndustryGroup: {
        ...state.manageIndustryGroup,
        companies,
        companiesPagination
      }
    }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.CLEAN_INDUSTRY_GROUPS]: setState(state),
    [types.REMOVE_DELETED_INDUSTRIES]: removeDeletedIndustries(state),
    [types.SET_INDUSTRY_GROUP_CREATED]: setState(state),
    [types.SET_INDUSTRY_GROUP_EDIT]: setState(state),
    [types.SET_INDUSTRY_GROUP_OPTIONS]: setState(state),
    [types.SET_INDUSTRY_GROUPS]: setState(state),
    [types.SET_LOADING]: setState(state),
    [types.SET_MANAGE_INDUSTRY_GROUP]: setState(state),
    [types.SET_UNRELATED_INDUSTRIES]: setState(state),
    [types.UPDATE_GROUP_INDUSTRIES]: updateGroupIndustries(state),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}

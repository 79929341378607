import { Icon } from '../Icon'
import * as S from './styled'
import { useTranslation } from 'react-i18next'
import { theme } from 'config'
import { THEMES_NAME } from 'config/theme.config'

export type GoBackProps = {
  goBack: () => void
}

export function GoBack({ goBack }: GoBackProps) {
  const { t } = useTranslation()
  return (
    <S.Wrapper onClick={goBack}>
      <Icon
        icon={
          theme.appTheme === THEMES_NAME.swift
            ? 'itens-interface-go-back-swift'
            : 'itens-interface-go-back'
        }
        width={22}
        height={22}
      />
      <S.Title>{t('common:goBack')}</S.Title>
    </S.Wrapper>
  )
}

import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const GridCard = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 5px 25px #0000000d;
  border-radius: 1.2rem;
  padding: 2.4rem 1.6rem;
`

export const GridHeader = styled(Grid)`
  display: flex;
  padding-bottom: 0.8rem;
  align-items: baseline;
`

export const GridTitle = styled(Grid)`
  display: block;
  padding-top: 0.5rem;
  padding-bottom: 1.6rem;
`

export const GridStatus = styled(Grid)`
  display: block;
  text-align: end;
`

export const BoxTitle = styled.p`
  color: ${({ theme }) => theme.colors.labelGoBack};
  font-size: 1.4rem;
`

export const Line = styled.div`
  width: auto;
  height: 0.1rem;
  background-color: #70707052;
  opacity: 0.2;
`

export const GroupName = styled.p`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.6rem;
  font-weight: 600;
  padding-top: 1.6rem;
`
export const GroupTitle = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.4rem;
  font-weight: 600;
  padding-top: 1.6rem;
`

export const GroupTitleName = styled.p`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.4rem;
  padding-top: 1rem;
  padding-bottom: 0.4rem;
`
export const GroupTitleEmail = styled.p`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.4rem;
  padding-bottom: 1.6rem;
`
export const GridLinks = styled.div`
  padding-top: 2.4rem;
`

export const Link = styled.li`
  align-items: center;
  background: white;
  color: ${({ theme }) => theme.colors.labelGoBack};
  display: flex;
  width: fit-content;
  margin-bottom: 1.6rem;
  cursor: pointer;

  > span {
    font-size: 1.4rem;
    margin: 0 0.8rem;
  }
`

import { filtersPeriod, filtersSpecific } from 'types/Traceability.types'

export const types = Object.freeze({
  GET_TRACEABILITY_PERIOD: '@traceability/getTraceabilityPeriod',
  GET_TRACEABILITY_SPECIFIC: '@traceability/getTraceabilitySpecific',
  SET_TRACEABILITY: '@traceability/setTraceability',
  SET_FILTERS_CHART: '@traceability/setFiltersChart',
  SET_INITIAL_FILTERS: '@traceability/setInitialFilters'
})

export function getTraceabilityPeriod({
  filterChart,
  filterPeriod
}: filtersPeriod) {
  return {
    type: types.GET_TRACEABILITY_PERIOD,
    payload: { filterChart, filterPeriod }
  }
}

export function getTraceabilityDateSpecific({
  filterChart,
  filterSpecific
}: filtersSpecific) {
  return {
    type: types.GET_TRACEABILITY_SPECIFIC,
    payload: { filterChart, filterSpecific }
  }
}

export function setTraceability({ traceability = [] } = {}) {
  return { type: types.SET_TRACEABILITY, payload: { ...traceability } }
}

export function setFiltersChart({ filters = {} } = {}) {
  return { type: types.SET_FILTERS_CHART, payload: { ...filters } }
}

export function setInitialFilters() {
  return {
    type: types.SET_INITIAL_FILTERS
  }
}

import { PartnerType } from 'types/Partner.types'

export const types = Object.freeze({
  CLEAN_PARTNERS: '@partners/cleanPartners',
  CREATE_PARTNER: '@partners/createPartner',
  DELETE_PARTNER: '@partners/deletePartner',
  EDIT_PARTNER: '@partners/editPartner',
  FETCH_ALL: '@partners/fetchAll',
  FETCH_ONE: '@partners/fetchOne',
  FILTER_PARTNERS: '@partners/filterPartners',
  GET_PARTNER_OPTIONS: '@partners/getPartnerOptions',
  SET_PARTNER_CREATED: '@partners/setPartnerCreated',
  SET_PARTNER_EDIT: '@partners/setPartnerEdit',
  SET_PARTNER_OPTIONS: '@partners/setPartnerOptions',
  SET_PARTNERS: '@partners/setPartners',
  SET_PARTNER: '@partners/setPartner',
  SET_LOADING: '@partners/setLoading'
})

export function fetchAll(filters = {}) {
  return { type: types.FETCH_ALL, payload: { filters } }
}

export function fetchOne(id: string) {
  return {
    type: types.FETCH_ONE,
    payload: { id }
  }
}

export function filterPartners({ params }) {
  return { type: types.FILTER_PARTNERS, payload: { params } }
}

export function setLoading({ loading = false } = {}) {
  return { type: types.SET_LOADING, payload: { loading } }
}

export function setPartners({ partners = {} } = {}) {
  return {
    type: types.SET_PARTNERS,
    payload: { partners, loading: false }
  }
}

export function setPartner({ partner = {} } = {}) {
  return {
    type: types.SET_PARTNER,
    payload: { partner, loading: false }
  }
}

export function createPartner(partner: PartnerType) {
  return {
    type: types.CREATE_PARTNER,
    payload: partner
  }
}

export function editPartner(partner: PartnerType) {
  return {
    type: types.EDIT_PARTNER,
    payload: partner
  }
}

export function deletePartner({ id }) {
  return {
    type: types.DELETE_PARTNER,
    payload: id
  }
}

export function setPartnerCreated({ partnerCreate = [] } = {}) {
  return {
    type: types.SET_PARTNER_CREATED,
    payload: { partnerCreate, loading: false }
  }
}

export function setPartnerEdit({ partnerEdit = [] } = {}) {
  return {
    type: types.SET_PARTNER_EDIT,
    payload: { partnerEdit, loading: false }
  }
}

export function getPartnerOptions() {
  return { type: types.GET_PARTNER_OPTIONS }
}

export function setPartnerOptions({ optionsPartner = [] } = {}) {
  return {
    type: types.SET_PARTNER_OPTIONS,
    payload: { optionsPartner }
  }
}

export function cleanPartners() {
  return {
    type: types.CLEAN_PARTNERS,
    payload: {
      partner: {},
      partnerCreate: {},
      partnerEdit: {}
    }
  }
}

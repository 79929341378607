import { Base } from 'layouts'
import { Details } from './Details'
import { GeneralFilters } from 'components/GeneralFilters'

export function SIFProduction() {
  return (
    <Base>
      <GeneralFilters />
      <Details />
    </Base>
  )
}

import { ReactNode } from 'react'

import * as S from './styled'

export type StatusBoxProps = {
  children: ReactNode
  variant?: 'active' | 'inactive'
  minWidth?: number
}

export function StatusBox({
  children,
  variant = 'active',
  minWidth = 6
}: StatusBoxProps) {
  return (
    <S.Button className={variant} minWidth={minWidth}>
      {children}
    </S.Button>
  )
}

import env from 'env-var'

const VIEW_MODE = {
  industry: 'INDUSTRY',
  retail: 'RETAIL'
}

export default Object.freeze({
  industry: env.get('REACT_APP_VIEW_MODE').asString() === VIEW_MODE.industry,
  retail: env.get('REACT_APP_VIEW_MODE').asString() === VIEW_MODE.retail
})

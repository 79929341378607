import * as S from './styled'

type PropTypes = {
  title: string
  boxLine?: boolean
}

export function HeaderBlock({ title, boxLine = false }: PropTypes) {
  return (
    <S.GridHeader>
      <S.GridTitle item container xs={12}>
        <S.BoxTitle>{title}</S.BoxTitle>
        {boxLine && <S.BoxLine />}
      </S.GridTitle>
    </S.GridHeader>
  )
}

import { Checkbox, Icon, Input, InputCpfCnpj } from 'components'
import { IconButton } from '@material-ui/core'
import { InputSelect } from 'components/InputSelect'
import { PROPERTY_BLOCK_STATUS } from 'constants/property.constants'
import { ProducerType } from 'types/Producer.types'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type FormProps = {
  disabledFields?: string[]
  errors: any
  index: number
  payload: ProducerType
  removeButton: boolean
  onFill: (_key, _val, _index) => void
  onRemove?: (_index) => void
  handleSelected: (_key, _val, _index) => void
}

export function ProducerForm({
  disabledFields,
  errors,
  index,
  payload,
  removeButton,
  onFill,
  onRemove,
  handleSelected
}: FormProps) {
  const { t } = useTranslation()
  function err(name: string) {
    const { producers } = errors
    if (producers) return producers[index][name]
    return errors[`producers[${index}].${name}`]
  }

  function disabled(name: string) {
    if (!disabledFields) return false
    return Boolean(disabledFields.find(e => e.includes(name)))
  }

  return (
    <S.Wrapper>
      <S.Remove>
        {removeButton && (
          <S.Removed onClick={onRemove}>
            <p>{t('common:delete')}</p>
            <IconButton
              style={{ backgroundColor: '#ffffff' }}
              aria-label="delete picture"
              component="span"
            >
              <Icon icon="delete-image" height={16} width={16} fill="#de4f4f" />
            </IconButton>
          </S.Removed>
        )}
      </S.Remove>
      <S.GridFields container spacing={2}>
        <S.GridInput item sm={12} md={3}>
          <InputSelect
            disabled={disabled('blockStatus')}
            error={Boolean(err('blockStatus'))}
            fullWidth
            helperText={err('blockStatus')}
            label={t('aditionalInformation:situationOwnerProducer')}
            onSelected={value => handleSelected('blockStatus', value, index)}
            optionLabel="label"
            options={PROPERTY_BLOCK_STATUS()}
            optionValue="value"
            value={payload.blockStatus}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={3}>
          <InputCpfCnpj
            disabled={disabled('CPFCNPJ')}
            fullWidth
            label={t('common:cpfCNPJ')}
            value={payload.CPFCNPJ}
            error={err('CPFCNPJ')}
            helperText={err('CPFCNPJ')}
            onInput={value => onFill('CPFCNPJ', value, index)}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <Input
            disabled={disabled('name')}
            fullWidth
            label={t('common:producerName')}
            value={payload.name}
            error={Boolean(err('name'))}
            helperText={err('name')}
            onInput={value => onFill('name', value, index)}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={2}>
          <Input
            disabled={disabled('IE')}
            fullWidth
            label={t('aditionalInformation:IEstateRegistration')}
            value={payload.IE}
            error={Boolean(err('IE'))}
            helperText={err('IE')}
            onInput={value => onFill('IE', value, index)}
          />
        </S.GridInput>
        {payload.blockStatus && (
          <S.GridInput item sm={9} md={6}>
            <Input
              disabled={disabled('reason')}
              fullWidth
              label={t('common:blockingReason')}
              value={payload.reason}
              error={Boolean(err('reason'))}
              helperText={err('reason')}
              onInput={value => onFill('reason', value, index)}
            />
          </S.GridInput>
        )}
        <S.GridInput
          item
          sm={payload.blockStatus ? 3 : 12}
          md={payload.blockStatus ? 6 : 12}
        >
          <S.CheckBoxBlock>
            <Checkbox
              disabled={disabled('free')}
              isChecked={payload.free}
              label={t('common:exempt')}
              labelFor="free"
              value={payload.free.toString()}
              onCheck={value => onFill('free', value, index)}
            />
          </S.CheckBoxBlock>
        </S.GridInput>
      </S.GridFields>
    </S.Wrapper>
  )
}

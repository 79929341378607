import { INITIAL_STATE as INITIAL_STATE_FILTER } from 'constants/traceability.constants'
import { get } from 'lodash'

import { types } from './Order.actions'

type payloadType = {
  orderKey: string | string[]
}

const INITIAL_STATE = {
  filterOrders: INITIAL_STATE_FILTER,
  ordersKeys: [],
  orders: {
    items: null,
    pagination: {}
  }
}

function setOrders(state) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

function setOrdersKeys(state) {
  return function _handle(payload: payloadType) {
    const isArray = Array.isArray(payload.orderKey)

    if (!isArray) {
      const keyIndex = state.ordersKeys.indexOf(payload.orderKey)
      if (keyIndex > -1) {
        state.ordersKeys.splice(keyIndex, 1)
        return { ...state }
      }
      return { ...state, ordersKeys: [...state.ordersKeys, payload.orderKey] }
    }

    const keys = (isArray ? payload.orderKey : []) as string[]
    const stateKeysLength = state.ordersKeys.length
    const newKeys = []

    keys.forEach((key: string) => {
      const keyExists = state.ordersKeys.includes(key)
      const isLimitKeys = stateKeysLength + newKeys.length === 50
      if (!keyExists && !isLimitKeys) newKeys.push(key)
    })

    if (!newKeys.length) {
      return {
        ...state,
        ordersKeys: [...state.ordersKeys.filter(key => !keys.includes(key))]
      }
    }

    return { ...state, ordersKeys: [...state.ordersKeys, ...newKeys] }
  }
}

function clearOrdersKeys(state) {
  return function _handle() {
    return { ...state, ordersKeys: [] }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.SET_ORDERS]: setOrders(state),
    [types.SET_FILTERS_ORDERS]: setOrders(state),
    [types.SET_ORDERS_KEYS]: setOrdersKeys(state),
    [types.CLEAN_ORDERS]: setOrders(INITIAL_STATE),
    [types.CLEAR_ORDERS_KEYS]: clearOrdersKeys(state),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}

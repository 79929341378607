import { call, put } from 'redux-saga/effects'

import { TraceabilityService } from 'services'
import { spinnerActions } from 'store/Spinner'

import * as actions from './TraceabilityCharts.actions'
import * as actionsMap from '../TraceabilityMap'
import { toasts } from 'utils/toasts'

export function* getTraceabilityPeriod(action) {
  yield put(spinnerActions.setVisibility(true))

  const filters = { ...action.payload }
  filters.filterSpecific = { days: [], months: [], years: [] }

  yield put(actions.setFiltersChart({ filters }))
  yield put(actionsMap.traceabilityMapActions.setMarkerMap(null))

  const { filterChart, filterPeriod } = action.payload
  const $traceability = new TraceabilityService()
  const traceability = yield call(
    $traceability.getPerPeriod.bind($traceability, {
      filterChart,
      filterPeriod
    })
  )

  if (traceability.success) {
    yield put(actions.setTraceability({ traceability: traceability.data }))
  } else toasts.generalFail()

  yield put(spinnerActions.setVisibility(false))
}

export function* getTraceabilitySpecific(action) {
  yield put(spinnerActions.setVisibility(true))

  const filters = { ...action.payload }
  filters.filterPeriod = {
    dtFinal: null,
    dtStart: null
  }

  yield put(actions.setFiltersChart({ filters }))
  yield put(actionsMap.traceabilityMapActions.setMarkerMap(null))

  const { filterChart, filterSpecific } = action.payload
  const $traceability = new TraceabilityService()
  const traceability = yield call(
    $traceability.getPerSpecific.bind($traceability, {
      filterChart,
      filterSpecific
    })
  )

  if (traceability.success) {
    yield put(actions.setTraceability({ traceability: traceability.data }))
  } else toasts.generalFail()

  yield put(spinnerActions.setVisibility(false))
}

import { useCallback, useEffect, useState, MouseEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Table, NewPagination } from 'components'
import { GridHeader } from '../GridHeader'

import { all } from 'store/TraceabilityTables/TraceabilityTables.selector'
import { all as allRawMaterial } from 'store/RawMaterial/RawMaterial.selector'
import { all as allOrders } from 'store/Order/Order.selector'
import { RawMaterialActions } from 'store/RawMaterial'

import { columns } from 'constants/rawMaterial.constants'
import { INITIAL_STATE } from 'constants/traceability.constants'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export function RawMaterialList() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [filter, setFilter] = useState({ ...INITIAL_STATE })
  const { filters, tableLoading } = useSelector(all)
  const { filterRawMaterial, rawMaterial = [] } = useSelector(allRawMaterial)
  const { ordersKeys } = useSelector(allOrders)
  const { pagination } = rawMaterial
  const [isNewPage, setNewPage] = useState({ ...pagination })
  const fetch = useCallback(() => {
    setFilter(filters)
    if (!rawMaterial.items) {
      dispatch(RawMaterialActions.getRawMaterial(filters, ordersKeys))
    }
  }, [dispatch, filters, ordersKeys, rawMaterial.items])

  useEffect(() => {
    if (ordersKeys.length) fetch()
  }, [fetch, ordersKeys])

  async function handleChangePage(
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    const newFilter = {
      ...filterRawMaterial,
      ...filters
    }
    newFilter.page = newPage
    dispatch(RawMaterialActions.setFilterRawMaterial(newFilter))
    dispatch(RawMaterialActions.getRawMaterial(newFilter, ordersKeys))
  }
  function handleNewPage() {
    const newPage = isNewPage.page
    if (newPage > pagination.totalPages) {
      const newFilter = {
        ...filterRawMaterial,
        ...filters
      }
      newFilter.page = pagination.totalPages
      dispatch(RawMaterialActions.setFilterRawMaterial(newFilter))
      dispatch(RawMaterialActions.getRawMaterial(newFilter, ordersKeys))
      setNewPage({ ...pagination, page: newPage })
    } else {
      const newFilter = {
        ...filterRawMaterial,
        ...filters
      }
      newFilter.page = newPage
      dispatch(RawMaterialActions.setFilterRawMaterial(newFilter))
      dispatch(RawMaterialActions.getRawMaterial(newFilter, ordersKeys))
      setNewPage({ ...pagination, page: newPage })
    }
  }
  function handleInputPagination(name: string, value: number) {
    setNewPage({ ...pagination, [name]: value })
  }

  function handleChangeRowsPerPage(e) {
    const newFilter = {
      ...filterRawMaterial,
      ...filters
    }
    newFilter.limit = e.target.value
    dispatch(RawMaterialActions.setFilterRawMaterial(newFilter))
    dispatch(RawMaterialActions.getRawMaterial(newFilter, ordersKeys))
  }

  return (
    <S.FullGrid item xs={12}>
      {rawMaterial.items?.length > 0 && (
        <GridHeader title={t('common:productList')} />
      )}
      <S.GridTable>
        <Table
          columns={columns()}
          rows={rawMaterial.items || []}
          sortColumn={{ column: filter.column, sort: filter.direction }}
          isLoading={tableLoading}
          isScroll={rawMaterial.items?.length > 10}
        />
        {pagination?.totalItems > 0 && (
          <NewPagination
            count={pagination?.totalPages}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            handleInput={handleInputPagination}
            handleNewPage={handleNewPage}
            page={pagination?.page}
            limit={pagination.limit}
          />
        )}
      </S.GridTable>
    </S.FullGrid>
  )
}

import styled from 'styled-components'

export const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.4rem;
  height: 4rem;
  transform-origin: center;
  outline: none;
  border-radius: 4rem;
  border: none;
  transition: background-color 400ms, color 400ms, transform 400ms;
  will-change: background-color, color, transform;
  padding: 1.1rem 3.2rem;
  width: max-content;
  :hover {
    background-color: ${({ theme }) => theme.colors.primary.darken()};
  }
`

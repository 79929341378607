import { Modal } from 'components/Modal'

import { ClientGroupListTable } from './ClientGroupListTable'
import { ClientGroupType } from 'types/ClientGroup.types'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type ModalClientGroupListProps = {
  groups: ClientGroupType[]
  onExit?: () => void
}

export function ModalClientGroupList({
  groups,
  onExit
}: ModalClientGroupListProps) {
  const { t } = useTranslation()
  const content = (
    <S.Wrapper>
      <S.GridHeader>
        <S.GridTitle item container xs={12}>
          <S.BoxTitle>{t('common:customersGroups')}</S.BoxTitle>
        </S.GridTitle>
      </S.GridHeader>

      <S.Line />

      <S.GridTable>
        {groups.length > 0 && <ClientGroupListTable payload={groups} />}
      </S.GridTable>
    </S.Wrapper>
  )

  return (
    <Modal
      content={content}
      isActionsEnabled={false}
      width={70}
      onExit={onExit}
    />
  )
}

import { StringSchema } from 'yup'
import i18n from 'i18n'

import { validate as validateCNPJ } from 'cnpj'
import { isValid as validateCPF } from 'cpf'

class Validator extends StringSchema {
  public verify() {
    return this.test(
      'CPFCNPJ',
      i18n.t('inputErrors:isInvalid'),
      function (val) {
        const { path, createError } = this
        const message = createError({
          path,
          message: i18n.t('inputErrors:isInvalid')
        })

        if (!val) return message

        const isCPFValid = validateCPF(val)
        if (isCPFValid) return true

        const isCNPJValid = validateCNPJ(val)
        if (isCNPJValid) return true

        return message
      }
    )
  }
}

export const cpfCnpj = new Validator()

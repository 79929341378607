import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { IndustryGroupFilter } from './IndustryGroupFilter'
import { IndustryGroupInfo } from './IndustryGroupInfo'
import { industryGroupActions } from 'store/IndustryGroup'
import { ModalAddIndustry } from './ModalAddIndustry/ModalAddIndustry'
import { AddButton } from 'components'

import * as S from './styled'

interface ParamTypes {
  id: string
}

export function ManageIndustryGroup() {
  const { id } = useParams<ParamTypes>()

  const [modalAdd, setModalAdd] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (id) dispatch(industryGroupActions.fetchOne(id))
  }, [dispatch, id])

  function onExit() {
    setModalAdd(false)
  }

  return (
    <S.Wrapper container spacing={3}>
      {modalAdd && <ModalAddIndustry id={id} onExit={onExit} />}
      <S.ButtonAdd onClick={() => setModalAdd(true)}>
        <AddButton />
      </S.ButtonAdd>
      <S.GridContainer item xs={12} md={4} lg={3}>
        <IndustryGroupInfo />
      </S.GridContainer>
      <S.GridContainer item xs={12} md={8} lg={9}>
        <IndustryGroupFilter onNewClick={() => setModalAdd(true)} />
      </S.GridContainer>
    </S.Wrapper>
  )
}

import { get } from 'lodash'
import { ChangePasswordType, SendEmailPwd, UserType } from 'types/User.types'

import { types } from './User.actions'

type User = {
  avatar: string
  changePassword?: ChangePasswordType
  loading: boolean
  pwdUpdate?: SendEmailPwd
  sendEmailPwd?: boolean | null
  user?: UserType
  userCreate?: UserType
  userEdit?: UserType
  users: {
    items: UserType[]
    pagination: {
      totalItems: number
      limit: number
      page: number
    }
  }
  filterOptions: {
    companies: Record<string, any>[]
    retails: Record<string, any>[]
  }
}

const INITIAL_STATE = {
  avatar: '',
  changePassword: {} as ChangePasswordType,
  pwdUpdate: null,
  sendEmail: {} as SendEmailPwd,
  loading: false,
  user: {} as UserType,
  userCreate: {} as UserType,
  userEdit: {} as UserType,
  users: {
    items: [],
    pagination: null
  },
  filterOptions: {
    companies: [],
    retails: []
  }
}

function setState(state: User) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.CHANGE_PASSWORD]: setState(state),
    [types.CLEAN_USER]: setState(state),
    [types.PWD_UPDATE]: setState(state),
    [types.SET_FILTER_OPTIONS]: setState(state),
    [types.SET_LOADING]: setState(state),
    [types.SET_USER_CREATED]: setState(state),
    [types.SET_USER_EDIT]: setState(state),
    [types.SET_USER]: setState(state),
    [types.SET_USERS]: setState(state),
    [types.SET_AVATAR]: setState(state),
    [types.SEND_EMAIL_PWD]: setState(state),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}

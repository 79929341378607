export const INITIAL_CUSTOM_FILTER_STATE = {
  customColumnFilter: null,
  customColumnValue: '',
  retailCnpj: null,
  isDelete: false
}

export const ERRORS_CUSTOM_FILTER_STATE = {
  customColumnFilter: '',
  customColumnValue: '',
  retailCnpj: '',
  isDelete: false
}

export const CUSTOM_RETAILS_FORM = [INITIAL_CUSTOM_FILTER_STATE]

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import { IconHeaderProps, IColumns, IRow } from './interface'
import * as S from './styled'

const renderIconHeader = ({ sortColumn, column = '' }: IconHeaderProps) => {
  return (
    <S.ButtonIcon aria-label="Chevron Right">
      {sortColumn.column !== column || sortColumn.sort === 'asc' ? (
        <ArrowDownwardIcon
          style={{
            color: sortColumn.column === column ? '#757575' : '#b1b1b1'
          }}
        />
      ) : (
        <ArrowUpwardIcon
          style={{
            color: sortColumn.column === column ? '#757575' : '#b1b1b1'
          }}
        />
      )}
    </S.ButtonIcon>
  )
}

export const renderRegularColumns = ({
  sortColumn,
  onSortColumn,
  columns
}: IColumns) =>
  columns.map(
    (column, index: number) =>
      column.fixed && (
        <S.Header
          key={index}
          buttonOpacity={sortColumn ? sortColumn.column === column.name : 0}
          className={column.align}
          onClick={() => (onSortColumn ? onSortColumn(column.name) : false)}
        >
          {onSortColumn &&
            column.align === 'right' &&
            renderIconHeader({ sortColumn, column: column.name })}
          {column.title || column.name}
          {onSortColumn &&
            column.align !== 'right' &&
            renderIconHeader({ sortColumn, column: column.name })}
        </S.Header>
      )
  )

export const renderScrollColumns = ({
  sortColumn,
  onSortColumn,
  columns
}: IColumns) =>
  columns.map(
    (column, index: number) =>
      !column.fixed && (
        <S.Header
          key={index}
          buttonOpacity={sortColumn ? sortColumn.column === column.name : 0}
          className={column.align}
        >
          {onSortColumn &&
            column.align === 'right' &&
            renderIconHeader({ sortColumn, column: column.name })}
          {column.title || column.name}
          {onSortColumn &&
            column.align !== 'right' &&
            renderIconHeader({ sortColumn, column: column.name })}
        </S.Header>
      )
  )

export const renderRegularRows = ({ changeColumn, columns, rows }: IRow) =>
  rows.map((row, index: number) => (
    <S.Line id="values" key={index}>
      {columns.map(
        (column, i) =>
          column.fixed && (
            <S.Column key={i} className={column.align}>
              {changeColumn ? changeColumn({ column, row }) : row[column.name]}
            </S.Column>
          )
      )}
    </S.Line>
  ))

export const renderScrollRows = ({ changeColumn, columns, rows }: IRow) =>
  rows.map((row, index: number) => (
    <S.Line id="values" key={index}>
      {columns.map(
        (column, i) =>
          !column.fixed && (
            <S.Column key={i} className={column.align}>
              {changeColumn ? changeColumn({ column, row }) : row[column.name]}
            </S.Column>
          )
      )}
    </S.Line>
  ))

import { KpiPropertiesDetailsType } from 'types/KpiPropertiesDetails.types'
import { KpiPropertiesSummaryType } from 'types/KpiPropertiesSummary.types'

export type KpiPropertiesDetailType = {
  kpiPropertiesDetails: {
    items: KpiPropertiesDetailsType[]
    pagination: any
  }
  kpiPropertiesSummary: {
    items: KpiPropertiesSummaryType[]
    pagination: any
  }
  showKpiPropertiesDetailsModal: boolean
}

export const INITIAL_STATE = {
  kpiPropertiesDetails: {
    items: [
      {
        citie: '',
        fazCarNumber: '',
        fazName: '',
        latitude: '',
        longitude: '',
        producerName: '',
        sifNumber: '',
        state: ''
      }
    ],
    summary: {
      items: null,
      pagination: { limit: null, page: null }
    }
  },
  kpiPropertiesSummary: {
    items: [
      {
        analyze: '',
        fazName: '',
        fazCode: '',
        fazCarNumber: '',
        bioma: '',
        state: '',
        city: '',
        producerName: '',
        producerDoc: '',
        qtyInvoice: ''
      }
    ],
    pagination: {
      items: null,
      pagination: { limit: null, page: null }
    }
  },
  showKpiPropertiesDetailsModal: false
}

import styled from 'styled-components'
import { Grid } from '@material-ui/core'
export const GridCardsCharts = styled(Grid)`
  margin-top: 3.2rem;
  text-transform: capitalize;
  margin-bottom: 1.4rem;
`
export const ChartsContainer = styled.div`
  border-radius: 1.2rem;
  background: white;
  box-shadow: 0px 5px 25px #0000000d;
  flex-wrap: wrap;
`

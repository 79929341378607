import { NutriTableType } from 'types/NutriTable.types'

export const types = Object.freeze({
  CLEAN_NUTRI_TABLES: '@nutriTable/cleanNutriTables',
  CREATE_NUTRI_TABLE: '@nutriTable/createNutriTable',
  DELETE_NUTRI_TABLE: '@nutriTable/deleteNutriTable',
  EDIT_NUTRI_TABLE: '@nutriTable/editNutriTable',
  FETCH_ALL: '@nutriTable/fetchAll',
  FETCH_ONE: '@nutriTable/fetchOne',
  FILTER_NUTRI_TABLE: '@nutriTable/filterNutriTables',
  GET_NUTRI_TABLE_OPTIONS: '@nutriTable/getNutriTableOptions',
  SET_NUTRI_TABLE_CREATED: '@nutriTable/setNutriTableCreated',
  SET_NUTRI_TABLE_EDIT: '@nutriTable/setNutriTableEdit',
  SET_NUTRI_TABLE_OPTIONS: '@nutriTable/setNutriTableOptions',
  SET_NUTRI_TABLES: '@nutriTable/setNutriTables',
  SET_NUTRI_TABLE: '@nutriTable/setNutriTable',
  SET_LOADING: '@nutriTable/setLoading'
})

export function fetchAll(
  { params = {} }: { params?: Record<string, any> } = { params: {} }
) {
  return { type: types.FETCH_ALL, payload: { params } }
}

export function fetchOne(id: string) {
  return {
    type: types.FETCH_ONE,
    payload: { id }
  }
}

export function filterNutriTables({ params }) {
  return { type: types.FILTER_NUTRI_TABLE, payload: { params } }
}

export function setLoading({ loading = false } = {}) {
  return { type: types.SET_LOADING, payload: { loading } }
}

export function setNutriTables({ nutriTables = {} } = {}) {
  return {
    type: types.SET_NUTRI_TABLES,
    payload: { nutriTables, loading: false }
  }
}

export function setNutriTable({ nutriTable = {} } = {}) {
  return {
    type: types.SET_NUTRI_TABLE,
    payload: { nutriTable, loading: false }
  }
}

export function createNutriTable(nutriTable: NutriTableType) {
  return {
    type: types.CREATE_NUTRI_TABLE,
    payload: nutriTable
  }
}

export function editNutriTable(nutriTable: NutriTableType) {
  return {
    type: types.EDIT_NUTRI_TABLE,
    payload: nutriTable
  }
}

export function deleteNutriTable({ id }) {
  return {
    type: types.DELETE_NUTRI_TABLE,
    payload: id
  }
}

export function setNutriTableCreated({ nutriTableCreate = [] } = {}) {
  return {
    type: types.SET_NUTRI_TABLE_CREATED,
    payload: { nutriTableCreate, loading: false }
  }
}

export function setNutriTableEdit({ nutriTableEdit = [] } = {}) {
  return {
    type: types.SET_NUTRI_TABLE_EDIT,
    payload: { nutriTableEdit, loading: false }
  }
}

export function getNutriTableOptions() {
  return { type: types.GET_NUTRI_TABLE_OPTIONS }
}

export function setNutriTableOptions({ options = [] } = {}) {
  return {
    type: types.SET_NUTRI_TABLE_OPTIONS,
    payload: { options }
  }
}

export function cleanNutriTables() {
  return {
    type: types.CLEAN_NUTRI_TABLES,
    payload: {
      nutriTable: {},
      nutriTableCreate: {},
      nutriTableEdit: {}
    }
  }
}

import { ClientGroupType } from 'types/ClientGroup.types'

export const types = Object.freeze({
  CLEAN_CLIENT_GROUP: '@clientGroup/cleanClientGroup',
  CREATE_CLIENT_GROUP: '@clientGroup/createClientGroup',
  DELETE_CLIENT_GROUP: '@clientGroup/deleteClientGroup',
  EDIT_CLIENT_GROUP: '@clientGroup/editClientGroup',
  FETCH_ALL: '@clientGroup/fetchAll',
  FETCH_ONE: '@clientGroup/fetchOne',
  FILTER_GROUP_CLIENTS: '@clientGroup/filterGroupClients',
  GET_CLIENT_GROUP_OPTIONS: '@clientGroup/getClientGroupOptions',
  GET_UNRELATED_CLIENTS: '@clientGroup/getUnrelatedClients',
  LINK_WITH_CLIENT: '@clientGroup/linkWithClient',
  REMOVE_DELETED_CLIENTS: '@clientGroup/removeDeletedClients',
  SET_CLIENT_GROUP_CREATED: '@clientGroup/setClientGroupCreated',
  SET_CLIENT_GROUP_EDIT: '@clientGroup/setClientGroupEdit',
  SET_CLIENT_GROUP_OPTIONS: '@clientGroup/setClientGroupOptions',
  SET_CLIENT_GROUP: '@clientGroup/setClientGroup',
  SET_LOADING: '@clientGroup/setLoading',
  SET_MANAGE_CLIENT_GROUP: '@clientGroup/setManageClientGroup',
  SET_UNRELATED_CLIENT: '@clientGroup/setUnrelatedGroup',
  UNLINK_WITH_CLIENT: '@clientGroup/unlinkWithClient',
  UPDATE_GROUP_CLIENTS: '@clientGroup/updateGroupClients',
  GET_CLIENT_GROUP_EXPORT: '@clientGroup/exportGroupClients'
})

export function setLoading({ loading = false } = {}) {
  return { type: types.SET_LOADING, payload: { loading } }
}

export function fetchAll(filters = {}) {
  return { type: types.FETCH_ALL, payload: { filters } }
}

export function setClientGroups({ clientGroups = {} } = {}) {
  return {
    type: types.SET_CLIENT_GROUP,
    payload: { clientGroups, loading: false }
  }
}

export function createClientGroup(clientGroup: ClientGroupType) {
  return {
    type: types.CREATE_CLIENT_GROUP,
    payload: clientGroup
  }
}

export function editClientGroup(clientGroup: ClientGroupType) {
  return {
    type: types.EDIT_CLIENT_GROUP,
    payload: clientGroup
  }
}

export function setClientGroupCreated({ clientGroupCreate = [] } = {}) {
  return {
    type: types.SET_CLIENT_GROUP_CREATED,
    payload: { clientGroupCreate, loading: false }
  }
}

export function setClientGroupEdit({ clientGroupEdit = [] } = {}) {
  return {
    type: types.SET_CLIENT_GROUP_EDIT,
    payload: { clientGroupEdit, loading: false }
  }
}

export function setUnrelatedClients({ unrelatedClients = {} } = {}) {
  return {
    type: types.SET_UNRELATED_CLIENT,
    payload: { unrelatedClients }
  }
}

export function fetchOne(id: string) {
  return { type: types.FETCH_ONE, payload: { id } }
}

export function setManageClientGroup({ manageClientGroup = [] } = {}) {
  return {
    type: types.SET_MANAGE_CLIENT_GROUP,
    payload: { manageClientGroup, loading: false }
  }
}

export function deleteClientGroup({ id }) {
  return {
    type: types.DELETE_CLIENT_GROUP,
    payload: id
  }
}

export function filterGroupClients({ groupId, params }) {
  return { type: types.FILTER_GROUP_CLIENTS, payload: { groupId, params } }
}

export function removeDeletedClients({ ids }) {
  return { type: types.REMOVE_DELETED_CLIENTS, payload: { ids } }
}

export function updateGroupClients({ retails, retailsPagination }) {
  return {
    type: types.UPDATE_GROUP_CLIENTS,
    payload: { retails, retailsPagination }
  }
}

export function linkWithClient({ id, clientIds }: Record<string, any>) {
  return { type: types.LINK_WITH_CLIENT, payload: { id, clientIds } }
}

export function unlinkWithGroup({ id, clientIds }) {
  return { type: types.UNLINK_WITH_CLIENT, payload: { id, clientIds } }
}

export function getUnrelatedClients(
  id: string,
  params: Record<string, any> = {}
) {
  return { type: types.GET_UNRELATED_CLIENTS, payload: { id, params } }
}

export function cleanClientGroup() {
  return {
    type: types.CLEAN_CLIENT_GROUP,
    payload: {
      clientGroupCreate: {},
      clientGroupEdit: {},
      manageClientGroup: {}
    }
  }
}

export function getClientGroupOptions() {
  return { type: types.GET_CLIENT_GROUP_OPTIONS }
}

export function setClientGroupOptions({ options = [] } = {}) {
  return {
    type: types.SET_CLIENT_GROUP_OPTIONS,
    payload: { options }
  }
}

export function getClientGroupExported({ groupId, params }) {
  return { type: types.GET_CLIENT_GROUP_EXPORT, payload: { groupId, params } }
}

import { Grid } from '@material-ui/core'

import styled from 'styled-components'

export const Container = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* margin-top: 24px; */
`

import { get } from 'lodash'

import { ClientType } from 'types/Client.types'
import {
  ClientGroupOptionsType,
  ClientGroupType
} from 'types/ClientGroup.types'

import { types } from './ClientGroup.actions'

type ClientGroup = {
  clientGroups: {
    items: ClientGroupType[]
    pagination: {
      totalItems: number
      limit: number
      page: number
    }
  }
  clientGroupCreate?: ClientGroupType
  clientGroupEdit?: ClientGroupType
  deleteClientFromGroup?: ClientType
  loading: boolean
  manageClientGroup?: ClientGroupType
  options?: ClientGroupOptionsType[]
  unrelatedClients?: {
    items: ClientType[]
    pagination: {
      totalItems: number
      limit: number
      page: number
    }
  }
}

const INITIAL_STATE = {
  clientGroups: {
    items: [],
    pagination: null
  },
  clientGroupCreate: {} as ClientGroupType,
  clientGroupEdit: {} as ClientGroupType,
  deleteClientFromGroup: {} as ClientType,
  loading: false,
  manageClientGroup: {} as ClientGroupType,
  options: [],
  unrelatedClients: {
    items: [],
    pagination: null
  }
}

function setState(state: ClientGroup) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

function removeDeletedClients(state: ClientGroup) {
  return function _handle({ ids }: { ids: string[] }) {
    return {
      ...state,
      manageClientGroup: {
        ...state.manageClientGroup,
        clients: state.manageClientGroup.retails.filter(
          ({ id }) => !ids.includes(id)
        )
      }
    }
  }
}

function updateGroupClients(state: ClientGroup) {
  return function _handle({ retails, retailsPagination }) {
    return {
      ...state,
      manageClientGroup: {
        ...state.manageClientGroup,
        retails,
        retailsPagination
      }
    }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.CLEAN_CLIENT_GROUP]: setState(state),
    [types.REMOVE_DELETED_CLIENTS]: removeDeletedClients(state),
    [types.SET_CLIENT_GROUP_CREATED]: setState(state),
    [types.SET_CLIENT_GROUP_EDIT]: setState(state),
    [types.SET_CLIENT_GROUP_OPTIONS]: setState(state),
    [types.SET_CLIENT_GROUP]: setState(state),
    [types.SET_LOADING]: setState(state),
    [types.SET_MANAGE_CLIENT_GROUP]: setState(state),
    [types.SET_UNRELATED_CLIENT]: setState(state),
    [types.UPDATE_GROUP_CLIENTS]: updateGroupClients(state),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}

import { TableNotFoundHint } from 'components/TableNotFoundHint'
import { useTranslation } from 'react-i18next'
import { TableProps } from './interface'
import * as S from './styled'
import {
  renderRegularColumns,
  renderScrollColumns,
  renderRegularRows,
  renderScrollRows
} from './tableHelpers'

export function Table({
  columns = [],
  linkTableNotFound = '',
  rows = [],
  sortColumn,
  actionTable,
  changeColumn,
  onSortColumn,
  isLoading,
  isScroll
}: TableProps) {
  const { t } = useTranslation()
  const columnFixed = columns.findIndex(f => f.fixed) > -1
  const columnScroll = columns.findIndex(f => !f.fixed) > -1

  const loadingTable = () => {
    if (!rows.length && isLoading) {
      return (
        <S.TableLoadingFistTime>
          {t('common:loadingData')}
        </S.TableLoadingFistTime>
      )
    }
    if (rows.length && isLoading) {
      return <S.TableLoading>{t('common:loadingData')}</S.TableLoading>
    }
    if (!rows.length && !isLoading) {
      return <TableNotFoundHint to={linkTableNotFound} />
    }
  }

  return (
    <>
      {isScroll ? (
        <S.WrapperScroll
          data-testid="Tab"
          columnFixed={columnFixed}
          columnScroll={columnScroll}
          actionTable={actionTable}
        >
          <S.Container isLoading={isLoading}>
            <S.Table id="table-fixed">
              <tbody>
                <S.Line id="header">
                  {renderRegularColumns({ sortColumn, onSortColumn, columns })}
                </S.Line>
                {renderRegularRows({ changeColumn, columns, rows })}
              </tbody>
            </S.Table>
            {columnScroll && (
              <S.Table id="table-scrolled">
                <tbody>
                  <S.Line id="header">
                    {renderScrollColumns({
                      sortColumn,
                      onSortColumn,
                      columns
                    })}
                  </S.Line>
                  {renderScrollRows({ changeColumn, columns, rows })}
                </tbody>
              </S.Table>
            )}
          </S.Container>
          {loadingTable()}
        </S.WrapperScroll>
      ) : (
        <S.Wrapper
          data-testid="Tab"
          columnFixed={columnFixed}
          columnScroll={columnScroll}
          actionTable={actionTable}
        >
          <S.Container isLoading={isLoading}>
            <S.Table id="table-fixed">
              <tbody>
                <S.Line id="header">
                  {renderRegularColumns({ sortColumn, onSortColumn, columns })}
                </S.Line>
                {renderRegularRows({ changeColumn, columns, rows })}
              </tbody>
            </S.Table>
            {columnScroll && (
              <S.ScrollWrapper>
                <S.Table id="table-scrolled">
                  <tbody>
                    <S.Line id="header">
                      {renderScrollColumns({
                        sortColumn,
                        onSortColumn,
                        columns
                      })}
                    </S.Line>
                    {renderScrollRows({ changeColumn, columns, rows })}
                  </tbody>
                </S.Table>
              </S.ScrollWrapper>
            )}
          </S.Container>
          {loadingTable()}
        </S.Wrapper>
      )}
    </>
  )
}

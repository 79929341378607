import { INITIAL_STATE as INITIAL_STATE_FILTER } from 'constants/traceability.constants'
import { get } from 'lodash'

import { types } from './RawMaterial.actions'

const INITIAL_STATE = {
  filterRawMaterial: INITIAL_STATE_FILTER,
  rawMaterial: {
    items: null,
    pagination: {}
  }
}

function setRawMaterial(state) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.SET_RAW_MATERIAL]: setRawMaterial(state),
    [types.SET_FILTERS_RAW_MATERIAL]: setRawMaterial(state),
    [types.CLEAN_RAW_MATERIAL]: setRawMaterial(INITIAL_STATE),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}

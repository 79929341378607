import { Icon, Input, InputPhone } from 'components'
import { IconButton } from '@material-ui/core'
import * as S from './styled'
import { ContactType } from 'types/Contact.types'
import { useTranslation } from 'react-i18next'

export type ContactFormProps = {
  errors: any
  index: number
  payload: ContactType
  removeButton: boolean
  onFill: (_key, _val, _index) => void
  onRemove: (_index) => void
}

export function ContactForm({
  index,
  errors,
  payload,
  removeButton,
  onFill,
  onRemove
}: ContactFormProps) {
  const { t } = useTranslation()
  function err(name: string) {
    const { contacts } = errors

    if (contacts && contacts[index] && contacts[index][name]) {
      return contacts[index][name]
    } else {
      return errors[`contacts[${index}].${name}`]
    }
  }

  return (
    <S.Wrapper>
      <S.Remove>
        {removeButton && (
          <S.Removed onClick={onRemove}>
            <p>{t('common:delete')}</p>
            <IconButton
              style={{ backgroundColor: '#ffffff' }}
              aria-label="delete picture"
              component="span"
            >
              <Icon icon="delete-image" height={16} width={16} fill="#de4f4f" />
            </IconButton>
          </S.Removed>
        )}
      </S.Remove>
      <S.GridFields container spacing={2}>
        <S.GridInput item sm={12} md={4}>
          <Input
            fullWidth
            label={t('profile:contactName')}
            value={payload.name}
            error={Boolean(err('name'))}
            helperText={err('name')}
            onInput={value => onFill('name', value, index)}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <InputPhone
            fullWidth
            label={t('profile:contactPhone')}
            value={payload.phone}
            error={Boolean(err('phone'))}
            helperText={err('phone')}
            onInput={value => onFill('phone', value, index)}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <Input
            fullWidth
            label={t('profile:contactEmail')}
            value={payload.email}
            error={Boolean(err('email'))}
            helperText={err('email')}
            onInput={value => onFill('email', value, index)}
          />
        </S.GridInput>
      </S.GridFields>
    </S.Wrapper>
  )
}

import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { viewMode } from 'config'
import { AddButton } from 'components/AddButton'
import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { InputCpfCnpj } from 'components/InputCpfCnpj'
import { InputPhone } from 'components/InputPhone'
import { InputSelect } from 'components/InputSelect'
import { Pagination } from 'components/Pagination'
import { Table } from 'components/Table/Table'
import { GroupType } from 'types/User.types'
import { ClientGroupType } from 'types/ClientGroup.types'
import { IndustryGroupType } from 'types/IndustryGroup.types'
import { ModalClientGroupList } from '../ModalClientGroupList/ModalClientGroupList'
import { ModalIndustryGroupList } from '../ModalIndustryGroupList/ModalIndustryGroupList'
import { ModalDeleteUser } from '../ModalDeleteUser/ModalDeleteUser'
import { UserDropdown } from './UserDropdown'
import { filterUserSchema } from 'store/User/User.validator'
import { all } from 'store/User/User.selector'
import { userActions } from 'store/User'
import { submitOnEnter } from 'utils/submitOnEnter'
import { COLUMNS, INITIAL_STATE_FILTER } from 'constants/user.constants'
import i18n from 'i18n'
import * as S from './styled'
import { ButtonPwd } from './ButtonPwd'
import { UserService } from 'services'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const ADMIN = 'Administrador'
const GROUPS = 'groups'

export function UserList() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const columns = viewMode.industry ? COLUMNS.INDUSTRY : COLUMNS.RETAIL
  const [errors, setErrors] = useState({ ...INITIAL_STATE_FILTER })
  const [filter, setFilter] = useState({ ...INITIAL_STATE_FILTER })
  const [_clientGroups, _setClientGroups] = useState([])
  const [_industryGroups, _setIndustryGroups] = useState([])
  const [_deleteUser, _setDeleteUser] = useState({
    id: null,
    name: null
  })
  const { users, filterOptions } = useSelector(all)
  useEffect(() => {
    dispatch(userActions.fetchAll())
  }, [dispatch])
  function changeColumn({ column, row }) {
    if (column.name === GROUPS) {
      if (row.role === ADMIN) {
        return (
          <Button
            size="xsmall"
            variant="fixed"
            onClick={() => handleRetailGroup(row.groups)}
          >
            {i18n.t('common:fullAccess')}
          </Button>
        )
      } else {
        return (
          <>
            <Button
              size="xsmall"
              variant="permission" // verificar como vai ficar nos outros temas
              onClick={() => handleRetailGroup(row.groups)}
            >
              {countGroups(row.groups)} {i18n.t('common:customers')}
            </Button>
            <Button
              size="xsmall"
              onClick={() => handleCompanyGroup(row.groupsIndustry)}
            >
              {countGroups(row.groupsIndustry)} {i18n.t('common:customers')}
            </Button>
          </>
        )
      }
    } else return row[column.name]
  }
  function onSortColumn(column) {
    const { column: columnOld, sort } = filter
    const newSort =
      column !== columnOld ? 'asc' : sort === 'asc' ? 'desc' : 'asc'
    setFilter({ ...filter, column, sort: newSort })

    const params = { ...filter, column, sort: newSort }
    dispatch(userActions.fetchAll({ params }))
  }
  function actionsOnTable({ id, name }) {
    return (
      <UserDropdown
        id={id}
        setDeleteUser={() => _setDeleteUser({ id, name })}
      />
    )
  }
  async function newPassword(payload) {
    const user = await new UserService().sendEmailNewPwd(payload)
    if (await user) {
      toast.success(t('toasts:sendEmailSuccess'))
    }
    return user
  }
  function sendEmail({ id, email, recoveryToken, lastSignIn }) {
    const payload = { id, email, recoveryToken, lastSignIn }
    return (
      <ButtonPwd
        variant={payload.lastSignIn ? 'send' : 'confirm'}
        lastSignIn={payload.lastSignIn}
        sendEmail={() => newPassword(payload)}
      />
    )
  }
  function onExit() {
    _setClientGroups([] as ClientGroupType[])
    _setDeleteUser({ id: null, name: null })
    _setIndustryGroups([] as IndustryGroupType[])
  }
  function handleRetailGroup(groups: GroupType[]) {
    _setClientGroups(groups)
  }
  function handleCompanyGroup(groups: GroupType[]) {
    _setIndustryGroups(groups)
  }
  function onRemoveUser() {
    dispatch(
      userActions.deleteUser({
        id: _deleteUser.id
      })
    )
    _setDeleteUser({ id: null, name: null })
  }
  function countGroups(groups: GroupType[]) {
    return groups.length < 10 ? '0' + groups.length : groups.length
  }
  function handleInput(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setFilter({ ...filter, [name]: value })
  }
  async function onFilter() {
    try {
      const params = await filterUserSchema().validate(filter, {
        abortEarly: false,
        stripUnknown: true
      })
      dispatch(userActions.fetchAll({ params }))
    } catch (ex) {
      const pairs = ex.inner.map(({ path, message }) => [
        path,
        message.replace(`${path} `, '')
      ])
      setErrors(Object.fromEntries(pairs) as typeof INITIAL_STATE_FILTER)
    }
  }
  function onFilterPaginate(params = {}) {
    dispatch(userActions.fetchAll({ params }))
  }
  function clearFilter() {
    const params = {
      ...INITIAL_STATE_FILTER,
      limit: users.pagination.limit,
      page: users.pagination.page,
      column: filter.column,
      sort: filter.sort
    }
    setFilter(params)
    setErrors({ ...INITIAL_STATE_FILTER })
    dispatch(userActions.fetchAll({ params }))
  }

  return (
    <S.Wrapper container>
      {Object.keys(_clientGroups).length > 0 && (
        <ModalClientGroupList groups={_clientGroups} onExit={onExit} />
      )}
      {Object.keys(_industryGroups).length > 0 && (
        <ModalIndustryGroupList groups={_industryGroups} onExit={onExit} />
      )}
      {_deleteUser.id && (
        <ModalDeleteUser
          name={_deleteUser.name}
          onExit={onExit}
          onRemove={onRemoveUser}
        />
      )}
      <S.ButtonAdd>
        <Link to="/usuario/novo">
          <AddButton />
        </Link>
      </S.ButtonAdd>
      <S.FullGrid item xs={12}>
        <S.GridHeader>
          <S.GridTitle item container xs={12}>
            <S.BoxTitle>{i18n.t('users:filterLabel')}</S.BoxTitle>
            <S.BoxLine />
          </S.GridTitle>
        </S.GridHeader>
        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              error={Boolean(errors.name)}
              helperText={errors.name}
              fullWidth
              label={i18n.t('common:name')}
              value={filter.name}
              onKeyPress={submitOnEnter(onFilter)}
              onInput={value => handleInput(value, 'name')}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <InputPhone
              error={Boolean(errors.phone)}
              helperText={errors.phone}
              fullWidth
              label={i18n.t('profile:completePhoneNumber')}
              value={filter.phone}
              onInput={value => handleInput(value, 'phone')}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              error={Boolean(errors.email)}
              helperText={errors.email}
              fullWidth
              label={i18n.t('common:email')}
              value={filter.email}
              onKeyPress={submitOnEnter(onFilter)}
              onInput={value => handleInput(value, 'email')}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <InputCpfCnpj
              error={Boolean(errors.CPF)}
              helperText={errors.CPF}
              fullWidth
              label={i18n.t('profile:cpfNumber')}
              type="cpf"
              value={filter.CPF}
              onKeyPress={submitOnEnter(onFilter)}
              onInput={value => handleInput(value, 'CPF')}
            />
          </S.GridInput>
        </S.GridFilter>
        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={4} lg={3}>
            <Input
              error={Boolean(errors.department)}
              helperText={errors.department}
              fullWidth
              label={i18n.t('profile:department')}
              value={filter.department}
              onKeyPress={submitOnEnter(onFilter)}
              onInput={value => handleInput(value, 'department')}
            />
          </S.GridInput>
          {viewMode.industry && (
            <>
              <S.GridInput item xs={12} sm={6} md={4} lg={3}>
                <InputSelect
                  disabled={filterOptions.retails.length < 1}
                  error={Boolean(errors.retail)}
                  fullWidth
                  helperText={errors.retail}
                  label={i18n.t('cards:customersGroup')}
                  onSelected={({ value }) => handleInput(value, 'retail')}
                  optionLabel="label"
                  options={filterOptions.retails}
                  optionValue="value"
                  value={filter.retail}
                />
              </S.GridInput>
              <S.GridInput item xs={12} sm={6} md={4} lg={3}>
                <InputSelect
                  disabled={filterOptions.companies.length < 1}
                  error={Boolean(errors.company)}
                  fullWidth
                  helperText={errors.company}
                  label={i18n.t('cards:industryGroup')}
                  onSelected={({ value }) => handleInput(value, 'company')}
                  optionLabel="label"
                  options={filterOptions.companies}
                  optionValue="value"
                  value={filter.company}
                />
              </S.GridInput>
            </>
          )}
          <S.GridButtons
            item
            xs={12}
            sm={12}
            md={12}
            lg={viewMode.industry ? 3 : 9}
          >
            <Button variant="default" onClick={clearFilter} size="medium">
              <p>{i18n.t('common:clean')}</p>
            </Button>
            <Button variant="primary" onClick={onFilter} size="medium">
              <p>{i18n.t('common:search')}</p>
            </Button>
          </S.GridButtons>
        </S.GridFilter>
        <S.GridTable>
          <Table
            columns={columns()}
            linkTableNotFound="/usuario/novo"
            rows={users.items}
            sortColumn={{ column: filter.column, sort: filter.sort }}
            actionTable={actionsOnTable}
            sendEmail={sendEmail}
            onSortColumn={onSortColumn}
            changeColumn={changeColumn}
          />
          {users.pagination?.totalItems > 0 && (
            <Pagination
              filters={{
                ...filter,
                limit: users.pagination.limit,
                page: users.pagination.page
              }}
              limit={users.pagination.limit}
              page={users.pagination.page}
              totalItems={users.pagination.totalItems}
              setFilter={onFilterPaginate}
            />
          )}
        </S.GridTable>
      </S.FullGrid>
    </S.Wrapper>
  )
}

import { INITIAL_CONTACT_STATE } from './contact.constants'
import { INITIAL_CUSTOM_FILTER_STATE } from './retails.constants'

export const QUALIFICATIONS = [
  { qualificationsName: 'LATAM' },
  { qualificationsName: 'NA' },
  { qualificationsName: 'EUROPA' },
  { qualificationsName: 'ÁSIA' },
  { qualificationsName: 'OCEANIA' }
]

export const INITIAL_STATE = {
  address: '',
  cep: '',
  city: '',
  cnpj: '',
  fancyName: '',
  groups: [],
  industryGroups: [],
  documentType: 'nacional',
  ie: '',
  inspectionNum: '',
  inspectionType: '',
  lat: null,
  lng: null,
  name: '',
  qualifications: [],
  accessGroupsFilter: [],
  industryGroupsFilter: [],
  state: '',
  status: true,
  customRetailsFilters: [{ ...INITIAL_CUSTOM_FILTER_STATE }],
  contacts: [{ ...INITIAL_CONTACT_STATE }]
}

export const ERRORS_INITIAL_STATE = {
  address: '',
  cep: '',
  city: '',
  cnpj: '',
  fancyName: '',
  ie: '',
  inspectionNum: '',
  inspectionType: '',
  lat: '',
  lng: '',
  name: '',
  qualifications: '',
  accessGroupsFilter: '',
  industryGroupsFilter: '',
  state: '',
  status: null,
  customRetailsFilters: [{ ...INITIAL_CUSTOM_FILTER_STATE }],
  contacts: [{ ...INITIAL_CONTACT_STATE }]
}

export const GROUP_FILTER_INITIAL_STATE = {
  name: '',
  fancyName: '',
  cnpj: ''
}

import { Modal } from 'components'
import { Button } from 'components/Button'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type ModalSendEmailProps = {
  title: string
  description: string
  onExit?: () => void
}
export function ModalSendEmail({
  onExit,
  title,
  description
}: ModalSendEmailProps) {
  const { t } = useTranslation()
  const button = (
    <Button onClick={onExit} size="medium">
      <p>{t('OK')}</p>
    </Button>
  )

  const content = (
    <S.Wrapper>
      <S.Title>{title}</S.Title>
      <S.Description>{description} </S.Description>
    </S.Wrapper>
  )

  return (
    <Modal
      button={button}
      cancelButton={false}
      content={content}
      isActionsEnabled={true}
      width={60}
      onExit={onExit}
    />
  )
}

import aditionalInformation from './pt-br/aditionalInformation.json'
import authPages from './pt-br/authPages.json'
import breadcrumb from './pt-br/breadcrumb.json'
import cards from './pt-br/cards.json'
import clientGroup from './pt-br/clientGroup.json'
import common from './pt-br/common.json'
import configurationInputErrors from './pt-br/configurationInputErrors.json'
import customFilters from './pt-br/customFilters.json'
import dashboard from './pt-br/dashboard.json'
import inputErrors from './pt-br/inputErrors.json'
import modalBlockchainHistory from './pt-br/modalBlockchainHistory.json'
import modalDeleteConfirm from './pt-br/modalDeleteConfirm.json'
import modalUpdatePassword from './pt-br/modalUpdatePassword.json'
import pagination from './pt-br/pagination.json'
import profile from './pt-br/profile.json'
import tables from './pt-br/tables.json'
import toasts from './pt-br/toasts.json'
import users from './pt-br/users.json'
import nutriTable from './pt-br/nutriTable.json'
import partner from './pt-br/partner.json'
import product from './pt-br/product.json'
import productLine from './pt-br/productLine.json'
import termOfUse from './pt-br/termOfUse.json'
import months from './pt-br/months.json'
import modalExportProperties from './pt-br/modalExportProperties.json'
import modalExportOrders from './pt-br/modalExportOrders.json'
import modalExportManageClientGroup from './pt-br/modalExportManageClientGroup.json'

export default {
  aditionalInformation,
  authPages,
  breadcrumb,
  cards,
  clientGroup,
  common,
  configurationInputErrors,
  customFilters,
  dashboard,
  inputErrors,
  modalBlockchainHistory,
  modalDeleteConfirm,
  modalUpdatePassword,
  pagination,
  profile,
  tables,
  toasts,
  users,
  nutriTable,
  partner,
  product,
  productLine,
  termOfUse,
  months,
  modalExportProperties,
  modalExportOrders,
  modalExportManageClientGroup,
  filter: {
    slaughter: 'Filtrar abate'
  }
}

import { Link } from 'react-router-dom'

import { IconCard } from 'components/IconCard'
import { viewMode } from 'config'
import { UserStorageService } from 'services'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

const links = {
  client: '/cliente',
  clientGroup: '/grupo-cliente',
  industry: '/industria',
  industryGroup: '/grupo-industria',
  none: '#',
  property: '/propriedade',
  user: '/usuario',
  product: '/painel-produtos'
}

export function Cards() {
  const { t } = useTranslation()
  const { user } = UserStorageService.getUserData()

  return (
    <S.GridContainer data-testid="cards-general" container spacing={4}>
      {!UserStorageService.isViewer(user) && (
        <S.GridIconCard item xs={12} md={4}>
          <Link to={links.user}>
            <IconCard
              title={t('common:users')}
              description={t('cards:addOrManageUsers')}
              icon="card-profile"
            />
          </Link>
        </S.GridIconCard>
      )}
      {viewMode.industry && (
        <>
          {!UserStorageService.isViewer(user) && (
            <S.GridIconCard item xs={12} md={4}>
              <Link to={links.client}>
                <IconCard
                  title={t('common:customers')}
                  description={t('cards:createOrManageCustomers')}
                  icon="card-clients"
                />
              </Link>
            </S.GridIconCard>
          )}
          <S.GridIconCard item xs={12} md={4}>
            <Link to={links.clientGroup}>
              <IconCard
                title={t('cards:customersGroups')}
                description={
                  UserStorageService.isViewer(user)
                    ? t('cards:viewCustomersGroups')
                    : t('cards:createOrManageCustomersGroups')
                }
                icon="card-group"
              />
            </Link>
          </S.GridIconCard>
          {!UserStorageService.isViewer(user) && (
            <>
              <S.GridIconCard item xs={12} md={4}>
                <Link to={links.industry}>
                  <IconCard
                    title={t('common:industries')}
                    description={t('cards:addOrManageIndustries')}
                    icon="card-industry"
                  />
                </Link>
              </S.GridIconCard>
              <S.GridIconCard item xs={12} md={4}>
                <Link to={links.industryGroup}>
                  <IconCard
                    title={t('cards:industryGroups')}
                    description={t('cards:createOrManageIndustryGroups')}
                    icon="card-industry"
                  />
                </Link>
              </S.GridIconCard>
              <S.GridIconCard item xs={12} md={4}>
                <Link to={links.product}>
                  <IconCard
                    title={t('product:productCard')}
                    description={t('product:productCardDesc')}
                    icon="card-product"
                  />
                </Link>
              </S.GridIconCard>
            </>
          )}
        </>
      )}
      {viewMode.retail && (
        <S.GridIconCard item xs={12} md={4}>
          <Link to={links.property}>
            <IconCard
              title={t('common:properties')}
              description={t('cards:addOrManageProperties')}
              icon="card-property"
            />
          </Link>
        </S.GridIconCard>
      )}
    </S.GridContainer>
  )
}

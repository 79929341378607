import { call, put } from 'redux-saga/effects'

import { toasts } from 'utils/toasts'

import { ClientService } from '../../services'

import * as actions from './Client.actions'
import * as actionsClientGroup from '../ClientGroup/ClientGroup.actions'
import { INITIAL_CUSTOM_FILTER_STATE } from 'constants/retails.constants'

export function* fetchAll(action) {
  const { params } = action.payload

  yield put(actions.setClient({ client: {} }))

  const $client = new ClientService()
  const {
    success,
    status,
    data: clients
  } = yield call($client.fetchAll.bind($client), { params })

  if (status === 404) {
    return yield put(
      actions.setClients({
        clients: { items: [], pagination: { totalItems: 0 } }
      })
    )
  } else if (success) yield put(actions.setClients({ clients }))
  else toasts.generalFail()

  yield put(actionsClientGroup.getClientGroupOptions())
}

export function* create(action) {
  const { payload } = action

  yield put(actions.setLoading({ loading: true }))

  const $client = new ClientService()
  const client = yield call($client.create.bind($client), { payload })

  if (client.success) {
    toasts.created()
    return yield put(
      actions.setClientCreated({
        clientCreate: client.data
      })
    )
  } else if (client.data.code === 'DUPLICATED') {
    toasts.duplicatedCNPJ()
    return yield put(actions.setLoading({ loading: false }))
  } else if (client.data.code === 'DUPLICATED_WITH_RETAIL_FILTERS') {
    toasts.duplicatedRetailWithCustomFilters()
    return yield put(actions.setLoading({ loading: false }))
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* fetchOne(action) {
  const { id } = action.payload

  yield put(actions.setLoading({ loading: true }))

  const $client = new ClientService()
  const client = yield call($client.fetchOne.bind($client), id)

  if (client.success) {
    return yield put(
      actions.setClient({
        client: {
          ...client.data,
          customRetailsFilters: client.data.customRetailsFilters.length
            ? client.data.customRetailsFilters
            : [INITIAL_CUSTOM_FILTER_STATE]
        }
      })
    )
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* edit(action) {
  const { payload } = action

  yield put(actions.setLoading({ loading: true }))

  const $client = new ClientService()
  const { success, data } = yield call($client.edit.bind($client), payload)

  if (success) {
    toasts.edited()
    return yield put(actions.setClientEdit({ clientEdit: data }))
  } else if (data.code === 'DUPLICATED') {
    toasts.duplicatedCNPJ()
    return yield put(actions.setLoading({ loading: false }))
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* deleteClient(action) {
  const id = action.payload

  const $client = new ClientService()
  const client = yield call($client.deleteClient.bind($client), {
    id
  })

  if (client.success) {
    toasts.deleted()
    return yield put(actions.fetchAll())
  } else toasts.generalFail()
}

import { UserType, ChangePasswordType } from '../../types/User.types'

export const types = Object.freeze({
  CHANGE_PASSWORD: '@user/changePassword',
  CLEAN_USER: '@user/cleanUser',
  CREATE_USER: '@user/createUser',
  DELETE_USER: '@user/deleteUser',
  EDIT_USER: '@user/editUser',
  FETCH_ALL: '@user/fetchAll',
  FETCH_ONE: '@user/fetchOne',
  PWD_UPDATE: '@user/pwdUpdate',
  SET_LOADING: '@user/setLoading',
  SET_USER: '@user/setUser',
  SET_USERS: '@user/setUsers',
  SET_USER_CREATED: '@user/setUserCreated',
  SET_USER_EDIT: '@user/setUserEdit',
  SET_FILTER_OPTIONS: '@user/setFilterOptions',
  SET_AVATAR: '@user/setAvatar',
  SEND_EMAIL_PWD: '@user/sendEmailPwd'
})

export function changePassword(payload: ChangePasswordType) {
  return { type: types.CHANGE_PASSWORD, payload }
}

export function setAvatar(payload: { avatar: string }) {
  return { type: types.SET_AVATAR, payload }
}

export function fetchAll(
  { params = {} }: { params?: Record<string, any> } = { params: {} }
) {
  return { type: types.FETCH_ALL, payload: { params } }
}

export function setUsers({ users = {} } = {}) {
  return { type: types.SET_USERS, payload: { users } }
}

export function setFilterOptions({ companies = [], retails = [] } = {}) {
  return {
    type: types.SET_FILTER_OPTIONS,
    payload: { filterOptions: { companies, retails } }
  }
}

export function createUser(user: UserType) {
  return {
    type: types.CREATE_USER,
    payload: user
  }
}

export function setUserCreated({ userCreate = [] } = {}) {
  return {
    type: types.SET_USER_CREATED,
    payload: { userCreate, loading: false }
  }
}

export function fetchOne(id: string) {
  return {
    type: types.FETCH_ONE,
    payload: { id }
  }
}

export function setUser({ user = {} } = {}) {
  return { type: types.SET_USER, payload: { user, loading: false } }
}

export function editUser(user: UserType) {
  return {
    type: types.EDIT_USER,
    payload: user
  }
}

export function setUserEdit({ userEdit = [] } = {}) {
  return {
    type: types.SET_USER_EDIT,
    payload: { userEdit, loading: false }
  }
}

export function deleteUser({ id }) {
  return {
    type: types.DELETE_USER,
    payload: id
  }
}

export function setLoading({ loading = false } = {}) {
  return { type: types.SET_LOADING, payload: { loading } }
}

export function pwdUpdate({ pwdUpdate }) {
  return { type: types.PWD_UPDATE, payload: { pwdUpdate } }
}

export function sendEmailPwd({ sendEmailPwd }) {
  return { type: types.SEND_EMAIL_PWD, payload: { sendEmailPwd } }
}

export function cleanUser() {
  return {
    type: types.CLEAN_USER,
    payload: {
      pwdUpdate: null,
      userCreate: {},
      userEdit: {},
      user: {}
    }
  }
}

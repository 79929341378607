import styled from 'styled-components'

export const Modal = styled.div`
  margin-top: 2.5rem;
  max-height: 15rem;
  overflow-y: auto;

  ::-webkit-scrollbar {
    height: 0.8rem;
    width: 0.4rem;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray.luminance(0.5)};
    border-radius: 2rem;
  }
`
export const Section = styled.section`
  text-align: justify;
  margin-bottom: 2rem;
`
export const Title = styled.h1`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.secondary};
  text-align: center;
`

export const Line = styled.hr`
  width: 50%;
`
export const Info = styled.p`
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin-top: 2rem;
`
export const Link = styled.a`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.primary2};
  text-align: center;
`
export const Accept = styled.div`
  margin-top: 2rem;
`

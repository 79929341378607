import styled from 'styled-components'
import { Grid, IconButton } from '@material-ui/core'
import media from 'styled-media-query'

export const Wrapper = styled(Grid)`
  margin-bottom: 2.4rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 5px 25px #0000000d;
  border-radius: 1.2rem;

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const GridMap = styled.div.attrs(() => {})`
  width: ${({ width }) => width}%;
  z-index: 0;
  opacity: ${({ width }) => (width < 29 ? 0 : 1)};
  transition: 0.2s ease-in-out;
`

export const GridChart = styled.div.attrs(() => {})`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0 1.2rem 1.2rem 0;
  width: ${({ width }) => width}%;
  opacity: ${({ width }) => (width < 70 ? 0 : 1)};
  transition: 0.2s ease-in-out;
`

export const ButtonIcon = styled(IconButton).attrs(() => {})`
  background-color: #4e4e4e;
  position: absolute;
  z-index: 1;
  left: -15px;
  top: ${({ top }) => top};
  display: ${({ display }) => display};

  &:hover {
    background-color: #686868;
  }
`

export const GridFilter = styled(Grid)`
  padding: 1rem 1.6rem 0 3.2rem;
`

export const GridGraph = styled.div`
  padding: 0 1.6rem 0 3.2rem;
`

export const GridFirstColumn = styled(Grid)``

export const GridSecondColumn = styled(Grid)`
  display: flex;
  justify-content: center;
`

export const GridThirdColumn = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`

import { HTTP } from 'providers'
import { TraceabilityFilter } from '../types/Traceability.types'

export class KpisService extends HTTP {
  private endpointCards = '/kpis'
  private endpointCharts = '/charts'

  public async getKpisCards(params: TraceabilityFilter) {
    const kpis = await this.get({
      endpoint: this.endpointCards,
      params
    })
    return kpis
  }

  public async getKpisCharts(params: TraceabilityFilter) {
    const kpis = await this.get({
      endpoint: this.endpointCharts,
      params
    })
    return kpis
  }

  public async getPropertiesLocation(params: TraceabilityFilter) {
    const kpis = await this.get({
      endpoint: `${this.endpointCards}/properties-location`,
      params
    })
    return kpis
  }

  public async getKpisPropertiesDetails(params: TraceabilityFilter) {
    const items = await this.get({
      endpoint: `${this.endpointCards}/details`,
      params
    })
    return items
  }

  public async getKpisPropertiesSummarized(params: TraceabilityFilter) {
    const items = await this.get({
      endpoint: `${this.endpointCards}/summary`,
      params
    })
    return items
  }

  public async getKpisPropertiesExported(params: TraceabilityFilter) {
    const { data } = await this.download({
      endpoint: `${this.endpointCards}/export`,
      params
    })
    return data
  }
}

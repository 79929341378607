import { HTTP } from 'providers'
import { ClientType } from 'types/Client.types'
import { downloadFile } from 'utils/DownloadFile'

export class ClientService extends HTTP {
  private endpoint = '/retail'
  private config: Record<string, any> = {
    showLoading: true
  }

  public fetchAll({ params = {} } = {}) {
    return this.get({ endpoint: this.endpoint, params })
  }

  public searchByGroup({ groupId, params }) {
    return this.get({
      endpoint: `${this.endpoint}/by-group/${groupId}`,
      params
    })
  }

  public async exportByGroup({ groupId, params }) {
    const { data } = await this.download({
      endpoint: `${this.endpoint}/export/by-group/${groupId}`,
      params
    })
    const date = new Date().toISOString().split('T')[0]
    const name = `customer-group-export-${date}.xlsx`
    downloadFile(data, name)
  }

  public removeMany({ ids }: { ids: string[] }) {
    return this.delete({ endpoint: `${this.endpoint}/many`, data: { ids } })
  }

  public create({ payload }: { payload: ClientType }) {
    return this.post({
      endpoint: this.endpoint,
      payload,
      config: this.config
    })
  }

  public fetchOne(id) {
    return this.get({
      endpoint: `${this.endpoint}/${id}`
    })
  }

  public fetchUnrelatedWithGroup({ groupId, params = {} }) {
    return this.get({
      endpoint: `${this.endpoint}/unrelated-with-group/${groupId}`,
      params
    })
  }

  public edit(client: ClientType) {
    const { id } = client
    return this.put({
      endpoint: `${this.endpoint}/${id}`,
      payload: client,
      config: this.config
    })
  }

  public deleteClient({ id }) {
    return this.delete({
      endpoint: `${this.endpoint}/${id}`
    })
  }
}

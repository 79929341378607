import { all, call, put } from 'redux-saga/effects'

import { toasts } from 'utils/toasts'

import { IndustryService } from '../../services'

import * as actions from './Industry.actions'
import * as actionsIndustryGroup from '../IndustryGroup/IndustryGroup.actions'

export function* fetchAll(action) {
  const { params } = action.payload

  yield put(actions.setIndustry({ industry: {} }))

  const $industry = new IndustryService()
  const {
    success,
    status,
    data: industries
  } = yield call($industry.fetchAll.bind($industry), { params })

  if (status === 404) {
    return yield put(
      actions.setIndustries({
        industries: { items: [], pagination: { totalItems: 0 } }
      })
    )
  } else if (success) {
    yield put(actions.setIndustries({ industries }))
  } else toasts.generalFail()

  yield put(actionsIndustryGroup.getIdustryGroupOptions())
}

export function* create(action) {
  const { payload } = action
  const { industry, toUploadImages, imgIndustry } = payload

  yield put(actions.setLoading({ loading: true }))

  const $industry = new IndustryService()
  const { success, data } = yield call($industry.create.bind($industry), {
    payload: industry
  })

  const { id } = data

  if (success) {
    if (id && toUploadImages.sifPhoto) {
      const multipartForm = new FormData()
      multipartForm.set('file', toUploadImages.sifPhoto)

      yield call($industry.uploadSifPhoto.bind($industry), {
        id,
        multipartForm
      })
    }

    if (id && toUploadImages.technician) {
      const multipartForm = new FormData()
      multipartForm.set('file', toUploadImages.technician)

      yield call($industry.uploadEmployeePhoto.bind($industry), {
        id,
        multipartForm,
        name: 'technician'
      })
    }

    if (id && toUploadImages.manager) {
      const multipartForm = new FormData()
      multipartForm.set('file', toUploadImages.manager)

      yield call($industry.uploadEmployeePhoto.bind($industry), {
        id,
        multipartForm,
        name: 'manager'
      })
    }

    if (id && imgIndustry) {
      const imgPost = []
      Promise.all(
        imgIndustry.map(async img => {
          const multipartForm = new FormData()

          multipartForm.set('file', img)
          const { photoId } = img as any

          if (!photoId) {
            imgPost.push(
              call($industry.uploadCompanyPhoto.bind($industry), {
                id,
                multipartForm
              })
            )
          }
        })
      )

      yield all(imgPost)
    }

    toasts.created()
    return yield put(
      actions.setIndustryCreated({
        industryCreate: data
      })
    )
  } else if (data.code === 'DUPLICATED') {
    toasts.duplicatedCNPJ()
    return yield put(actions.setLoading({ loading: false }))
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* fetchOne(action) {
  const { id } = action.payload

  yield put(actions.setLoading({ loading: true }))

  const $industry = new IndustryService()
  const industry = yield call($industry.fetchOne.bind($industry), id)

  if (industry.success) {
    return yield put(
      actions.setIndustry({
        industry: industry.data
      })
    )
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* edit(action) {
  const { payload } = action
  const { industry, toUploadImages, imgIndustry, deletedImgIndustry } = payload

  yield put(actions.setLoading({ loading: true }))

  const $industry = new IndustryService()
  const { success, data } = yield call($industry.edit.bind($industry), industry)

  const { id } = data

  if (success) {
    if (id && toUploadImages.sifPhoto) {
      const multipartForm = new FormData()
      multipartForm.set('file', toUploadImages.sifPhoto)

      yield call($industry.uploadSifPhoto.bind($industry), {
        id,
        multipartForm
      })
    }

    if (id && toUploadImages.technician) {
      const multipartForm = new FormData()
      multipartForm.set('file', toUploadImages.technician)

      yield call($industry.uploadEmployeePhoto.bind($industry), {
        id,
        multipartForm,
        name: 'technician'
      })
    }

    if (id && toUploadImages.manager) {
      const multipartForm = new FormData()
      multipartForm.set('file', toUploadImages.manager)

      yield call($industry.uploadEmployeePhoto.bind($industry), {
        id,
        multipartForm,
        name: 'manager'
      })
    }

    if (id && deletedImgIndustry) {
      const imgDelete = []
      Promise.all(
        deletedImgIndustry.map(async del => {
          imgDelete.push(
            call($industry.removeCompanyPhoto.bind($industry), {
              id,
              del
            })
          )
        })
      )

      yield all(imgDelete)
    }

    if (id && imgIndustry) {
      const imgPost = []
      Promise.all(
        imgIndustry.map(async img => {
          const multipartForm = new FormData()

          multipartForm.set('file', img)
          const { photoId } = img as any

          if (!photoId) {
            imgPost.push(
              call($industry.uploadCompanyPhoto.bind($industry), {
                id,
                multipartForm
              })
            )
          }
        })
      )

      yield all(imgPost)
    }

    toasts.edited()
    return yield put(actions.setIndustryEdit({ industryEdit: data }))
  } else if (data.code === 'DUPLICATED') {
    toasts.duplicatedCNPJ()
    return yield put(actions.setLoading({ loading: false }))
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* deleteIndustry(action) {
  const id = action.payload

  const $industry = new IndustryService()
  const industry = yield call($industry.deleteIndustry.bind($industry), {
    id
  })

  if (industry.success) {
    toasts.deleted()
    return yield put(actions.fetchAll())
  } else toasts.generalFail()
}

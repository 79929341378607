import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import { Checkbox } from 'components/Checkbox'
import { TableNotFoundHint } from 'components/TableNotFoundHint'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type ColumnsProps = {
  align?: 'right' | 'left' | 'center'
  fixed?: boolean
  name: string
  title?: string
}

export type TableProps = {
  columns: ColumnsProps[]
  linkTableNotFound?: string
  rows: any[]
  sortColumn?: {
    column: string
    sort: string
  }
  actionTable?: (_row) => JSX.Element
  sendEmail?: (_row) => JSX.Element
  changeColumn?: (_props) => JSX.Element
  onSortColumn?: (_column) => void
  actionColumnLabel?: string
  sendEmailColumnLabel?: string
  isLoading?: boolean
  checkOrders?: boolean
  selectOrdersKeys?: (_keys: string[] | string) => void
  checkedKeys?: string[]
  selectedAll?: boolean
  disableSelectOrder?: boolean
  disableSelectAllOrder?: boolean
  isScroll?: boolean
}

export function Table({
  columns = [],
  linkTableNotFound = '',
  rows = [],
  sortColumn,
  actionTable,
  sendEmail,
  changeColumn,
  onSortColumn,
  actionColumnLabel,
  sendEmailColumnLabel,
  isLoading,
  checkOrders,
  selectOrdersKeys,
  checkedKeys = [],
  selectedAll,
  disableSelectOrder,
  disableSelectAllOrder,
  isScroll
}: TableProps) {
  const { t } = useTranslation()
  const columnFixed = columns.findIndex(f => f.fixed) > -1
  const columnScroll = columns.findIndex(f => !f.fixed) > -1

  const loadingTable = () => {
    if (!rows.length && isLoading) {
      return (
        <S.TableLoadingFistTime>
          {t('common:loadingData')}
        </S.TableLoadingFistTime>
      )
    }
    if (rows.length && isLoading) {
      return <S.TableLoading>{t('common:loadingData')}</S.TableLoading>
    }
    if (!rows.length && !isLoading) {
      return <TableNotFoundHint to={linkTableNotFound} />
    }
  }

  function IconHeader({ column = '' }) {
    return (
      <S.ButtonIcon aria-label="Chevron Right">
        {sortColumn.column !== column || sortColumn.sort === 'asc' ? (
          <ArrowDownwardIcon
            style={{
              color: sortColumn.column === column ? '#757575' : '#b1b1b1'
            }}
          />
        ) : (
          <ArrowUpwardIcon
            style={{
              color: sortColumn.column === column ? '#757575' : '#b1b1b1'
            }}
          />
        )}
      </S.ButtonIcon>
    )
  }

  function getAllOrdersKeys() {
    selectOrdersKeys(rows.map(each => each.key))
  }

  return (
    <>
      {isScroll ? (
        <>
          <S.WrapperScroll
            data-testid="Table"
            columnFixed={columnFixed}
            columnScroll={columnScroll}
            actionTable={actionTable}
            sendEmail={sendEmail}
            isScroll={isScroll}
          >
            <S.ContainerScroll isLoading={isLoading}>
              {checkOrders && (
                <S.Table id="table-actions">
                  <tbody>
                    <S.Line id="header">
                      <S.Header
                        style={{ padding: '0rem 1.1rem', borderRight: 0 }}
                      >
                        <S.SelectAllHeaderContainer>
                          {t('common:select')} &nbsp;
                          {checkedKeys.length}/50
                        </S.SelectAllHeaderContainer>
                        <S.SelectAllHeaderContainer>
                          <Checkbox
                            style={{
                              height: '14px',
                              width: '14px'
                            }}
                            label=""
                            labelFor="order-key"
                            disabled={!selectedAll && disableSelectAllOrder}
                            isChecked={selectedAll}
                            onClick={getAllOrdersKeys}
                          />
                        </S.SelectAllHeaderContainer>
                      </S.Header>
                    </S.Line>
                    {rows.map((row, index) => (
                      <S.Line key={index}>
                        <S.Column style={{ borderRight: 0 }}>
                          <S.CheckboxColumnContainer>
                            <Checkbox
                              label=""
                              disabled={
                                !checkedKeys.includes(row.key) &&
                                disableSelectOrder
                              }
                              labelFor="order-key"
                              isChecked={checkedKeys.includes(row.key)}
                              onClick={() => selectOrdersKeys(row.key)}
                            />
                          </S.CheckboxColumnContainer>
                        </S.Column>
                      </S.Line>
                    ))}
                  </tbody>
                </S.Table>
              )}
              <S.Table id="table-fixed">
                <tbody>
                  <S.Line id="header">
                    {columns.map(
                      (column, index) =>
                        column.fixed && (
                          <S.Header
                            key={index}
                            buttonOpacity={
                              sortColumn ? sortColumn.column === column.name : 0
                            }
                            className={column.align}
                            onClick={() =>
                              onSortColumn ? onSortColumn(column.name) : false
                            }
                          >
                            {onSortColumn && column.align === 'right' && (
                              <IconHeader column={column.name} />
                            )}
                            {column.title || column.name}
                            {onSortColumn && column.align !== 'right' && (
                              <IconHeader column={column.name} />
                            )}
                          </S.Header>
                        )
                    )}
                  </S.Line>
                  {rows.map((row, index) => (
                    <S.Line id="values" key={index}>
                      {columns.map(
                        (column, i) =>
                          column.fixed && (
                            <S.Column key={i} className={column.align}>
                              {changeColumn
                                ? changeColumn({ column, row })
                                : row[column.name]}
                            </S.Column>
                          )
                      )}
                    </S.Line>
                  ))}
                </tbody>
              </S.Table>
              {columnScroll && (
                <S.ScrollWrapper>
                  <S.Table id="table-scrolled">
                    <tbody>
                      <S.Line id="header">
                        {columns.map(
                          (column, index) =>
                            !column.fixed && (
                              <S.Header
                                key={index}
                                buttonOpacity={
                                  sortColumn
                                    ? sortColumn.column === column.name
                                    : 0
                                }
                                className={column.align}
                                onClick={() =>
                                  onSortColumn
                                    ? onSortColumn(column.name)
                                    : false
                                }
                              >
                                {onSortColumn && column.align === 'right' && (
                                  <IconHeader column={column.name} />
                                )}
                                {column.title || column.name}
                                {onSortColumn && column.align !== 'right' && (
                                  <IconHeader column={column.name} />
                                )}
                              </S.Header>
                            )
                        )}
                      </S.Line>
                      {rows.map((row, index) => (
                        <S.Line id="values" key={index}>
                          {columns.map(
                            (column, i) =>
                              !column.fixed && (
                                <S.Column key={i} className={column.align}>
                                  {changeColumn
                                    ? changeColumn({ column, row })
                                    : row[column.name]}
                                </S.Column>
                              )
                          )}
                        </S.Line>
                      ))}
                    </tbody>
                  </S.Table>
                </S.ScrollWrapper>
              )}
              {sendEmail && (
                <S.Table id="table-actions">
                  <tbody>
                    <S.Line id="header">
                      <S.Header>
                        {sendEmailColumnLabel || t('common:password')}
                      </S.Header>
                    </S.Line>
                    {rows.map((row, index) => (
                      <S.Line key={index}>
                        <S.Column id="actions-column">
                          {sendEmail && sendEmail(row)}
                        </S.Column>
                      </S.Line>
                    ))}
                  </tbody>
                </S.Table>
              )}
              {actionTable && (
                <S.Table id="table-actions">
                  <tbody>
                    <S.Line id="header">
                      <S.Header>
                        {actionColumnLabel || t('common:actions')}
                      </S.Header>
                    </S.Line>
                    {rows.map((row, index) => (
                      <S.Line key={index}>
                        <S.Column id="actions-column">
                          {actionTable && actionTable(row)}
                        </S.Column>
                      </S.Line>
                    ))}
                  </tbody>
                </S.Table>
              )}
            </S.ContainerScroll>
          </S.WrapperScroll>
        </>
      ) : (
        <>
          <S.Wrapper
            data-testid="Table"
            columnFixed={columnFixed}
            columnScroll={columnScroll}
            actionTable={actionTable}
            sendEmail={sendEmail}
            isScroll={isScroll}
          >
            <S.Container isLoading={isLoading}>
              {checkOrders && (
                <S.Table id="table-actions">
                  <tbody>
                    <S.Line id="header">
                      <S.Header
                        style={{ padding: '0rem 1.1rem', borderRight: 0 }}
                      >
                        <S.SelectAllHeaderContainer>
                          {t('common:select')} &nbsp;
                          {checkedKeys.length}/50
                        </S.SelectAllHeaderContainer>
                        <S.SelectAllHeaderContainer>
                          <Checkbox
                            style={{
                              height: '14px',
                              width: '14px'
                            }}
                            label=""
                            labelFor="order-key"
                            disabled={!selectedAll && disableSelectAllOrder}
                            isChecked={selectedAll}
                            onClick={getAllOrdersKeys}
                          />
                        </S.SelectAllHeaderContainer>
                      </S.Header>
                    </S.Line>
                    {rows.map((row, index) => (
                      <S.Line key={index}>
                        <S.Column style={{ borderRight: 0 }}>
                          <S.CheckboxColumnContainer>
                            <Checkbox
                              label=""
                              disabled={
                                !checkedKeys.includes(row.key) &&
                                disableSelectOrder
                              }
                              labelFor="order-key"
                              isChecked={checkedKeys.includes(row.key)}
                              onClick={() => selectOrdersKeys(row.key)}
                            />
                          </S.CheckboxColumnContainer>
                        </S.Column>
                      </S.Line>
                    ))}
                  </tbody>
                </S.Table>
              )}
              <S.Table id="table-fixed">
                <tbody>
                  <S.Line id="header">
                    {columns.map(
                      (column, index) =>
                        column.fixed && (
                          <S.Header
                            key={index}
                            buttonOpacity={
                              sortColumn ? sortColumn.column === column.name : 0
                            }
                            className={column.align}
                            onClick={() =>
                              onSortColumn ? onSortColumn(column.name) : false
                            }
                          >
                            {onSortColumn && column.align === 'right' && (
                              <IconHeader column={column.name} />
                            )}
                            {column.title || column.name}
                            {onSortColumn && column.align !== 'right' && (
                              <IconHeader column={column.name} />
                            )}
                          </S.Header>
                        )
                    )}
                  </S.Line>
                  {rows.map((row, index) => (
                    <S.Line id="values" key={index}>
                      {columns.map(
                        (column, i) =>
                          column.fixed && (
                            <S.Column key={i} className={column.align}>
                              {changeColumn
                                ? changeColumn({ column, row })
                                : row[column.name]}
                            </S.Column>
                          )
                      )}
                    </S.Line>
                  ))}
                </tbody>
              </S.Table>
              {columnScroll && (
                <S.ScrollWrapper>
                  <S.Table id="table-scrolled">
                    <tbody>
                      <S.Line id="header">
                        {columns.map(
                          (column, index) =>
                            !column.fixed && (
                              <S.Header
                                key={index}
                                buttonOpacity={
                                  sortColumn
                                    ? sortColumn.column === column.name
                                    : 0
                                }
                                className={column.align}
                                onClick={() =>
                                  onSortColumn
                                    ? onSortColumn(column.name)
                                    : false
                                }
                              >
                                {onSortColumn && column.align === 'right' && (
                                  <IconHeader column={column.name} />
                                )}
                                {column.title || column.name}
                                {onSortColumn && column.align !== 'right' && (
                                  <IconHeader column={column.name} />
                                )}
                              </S.Header>
                            )
                        )}
                      </S.Line>
                      {rows.map((row, index) => (
                        <S.Line id="values" key={index}>
                          {columns.map(
                            (column, i) =>
                              !column.fixed && (
                                <S.Column key={i} className={column.align}>
                                  {changeColumn
                                    ? changeColumn({ column, row })
                                    : row[column.name]}
                                </S.Column>
                              )
                          )}
                        </S.Line>
                      ))}
                    </tbody>
                  </S.Table>
                </S.ScrollWrapper>
              )}
              {sendEmail && (
                <S.Table id="table-actions">
                  <tbody>
                    <S.Line id="header">
                      <S.Header>
                        {sendEmailColumnLabel || t('common:password')}
                      </S.Header>
                    </S.Line>
                    {rows.map((row, index) => (
                      <S.Line key={index}>
                        <S.Column id="actions-column">
                          {sendEmail && sendEmail(row)}
                        </S.Column>
                      </S.Line>
                    ))}
                  </tbody>
                </S.Table>
              )}
              {actionTable && (
                <S.Table id="table-actions">
                  <tbody>
                    <S.Line id="header">
                      <S.Header>
                        {actionColumnLabel || t('common:actions')}
                      </S.Header>
                    </S.Line>
                    {rows.map((row, index) => (
                      <S.Line key={index}>
                        <S.Column id="actions-column">
                          {actionTable && actionTable(row)}
                        </S.Column>
                      </S.Line>
                    ))}
                  </tbody>
                </S.Table>
              )}
            </S.Container>

            {loadingTable()}
          </S.Wrapper>
        </>
      )}
    </>
  )
}

import { HTTP } from 'providers'

export class CityService extends HTTP {
  private endpoint = '/city'

  public async fetchByUF({ uf }) {
    const boxes = await this.get({
      endpoint: `${this.endpoint}/uf/${uf}`
    })
    return boxes
  }
}

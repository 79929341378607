import { IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import * as S from './styles'

import { Dropdown, Icon } from '../'
import { theme } from 'config'
import { THEMES_NAME } from 'config/theme.config'

export type OrderDropdownProps = {
  setHashPartner: () => void
}

export const OrderDropdown = ({ setHashPartner }: OrderDropdownProps) => {
  const { t } = useTranslation()
  return (
    <S.Wrapper>
      <Dropdown
        title={
          <IconButton
            id="action"
            color="inherit"
            aria-label="open drawer"
            onClick={() => {}}
            size="medium"
            edge="start"
          >
            <Icon
              icon={
                theme.appTheme === THEMES_NAME.swift
                  ? 'itens-interface-actions-swift'
                  : 'icon-blockchain'
              }
              width={14}
              height={14}
              aria-label="action"
            />
          </IconButton>
        }
      >
        <S.Nav>
          <S.Link onClick={setHashPartner}>
            <span>{t('aditionalInformation:historyBlockchain')}</span>
          </S.Link>
        </S.Nav>
      </Dropdown>
    </S.Wrapper>
  )
}

import styled from 'styled-components'

import { Icon as I } from '../Icon'

export const IconCard = styled.div`
  align-items: center;
  background: white;
  border-radius: 1.2rem;
  border: none;
  box-shadow: 0px 11px 29px #00000021;
  color: ${({ theme }) => theme.colors.darkGray};
  display: grid;
  height: 12rem;
  padding: 2.4rem 2.4rem 1.6px 2.4rem;
  transform-origin: left;
  transition: box-shadow 400ms, color 400ms, transform 400ms;
  will-change: background-color, color, transform;
  &:hover {
    box-shadow: 0px 11px 29px #00000041;
    transform: translate(0, -1rem);
  }
`
export const Title = styled.p`
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1.8rem;
  width: 100%;
`

export const Subtitle = styled.div`
  display: flex;
  margin-bottom: 1.8rem;
  width: 100%;
`

export const Description = styled.p`
  align-self: center;
  font-size: 1.4rem;
  font-weight: 400;
  margin-left: 0.8rem;
`

export const Icon = styled(I)`
  & path {
    fill: ${({ theme }) => theme.colors.fillIcon};
  }
`

import { HTTP } from 'providers'

import { filtersPeriod, filtersSpecific } from '../types/Traceability.types'

export class TraceabilityService extends HTTP {
  private endpoint = '/traceability'

  public async getPerPeriod({ filterChart, filterPeriod }: filtersPeriod) {
    const traceability = await this.get({
      endpoint: this.endpoint,
      params: { ...filterChart, ...filterPeriod }
    })
    return traceability
  }

  public async getPerSpecific({
    filterChart,
    filterSpecific
  }: filtersSpecific) {
    const traceability = await this.get({
      endpoint: this.endpoint,
      params: { ...filterChart, ...filterSpecific }
    })
    return traceability
  }
}

/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import { Base } from 'layouts'
import TabContext from '@material-ui/lab/TabContext'
import { useDispatch, useSelector } from 'react-redux'
import { all as allFilter } from 'store/TraceabilityTables/TraceabilityTables.selector'
import { propertiesDetailsActions } from 'store/PropertiesDetails'
import { ProprietyTab } from './ProprietyTab'
import * as S from './styled'
import { all } from 'store/PropertiesDetails/PropertiesDetails.selector'
import { useTranslation } from 'react-i18next'
import { GeneralFilters } from 'components/GeneralFilters'

export function Properties() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { filters } = useSelector(allFilter)

  const [filter] = useState({ ...filters, trackable: 'true', limit: 50 })
  const { kpiPropertiesSummary } = useSelector(all)

  useEffect(() => {
    if (filters.begin && filters.end) {
      dispatch(
        propertiesDetailsActions.getKpisPropertiesSummary({
          ...filters,
          limit: filter.limit,
          years: filters.years.length
            ? filters.years
            : [new Date().getFullYear()]
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])
  return (
    <Base>
      <GeneralFilters isPropertyPage={true} />
      <TabContext value="properties" aria-label="simple tabs example">
        <S.TabList>
          <S.Tab
            data-tip="tooltip_loading"
            label={t('common:properties')}
            value="properties"
          />
        </S.TabList>
        <S.Panel value="properties">
          <ProprietyTab kpiPropertiesSummary={kpiPropertiesSummary} />
        </S.Panel>
      </TabContext>
    </Base>
  )
}

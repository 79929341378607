import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Button } from 'components'
import { Modal } from 'components/Modal'
import { ProducerForm } from 'pages/Property/Form/ProducerForm'
import { PropertyInfoModal } from 'pages/Property/List/PropertyInfoModal/PropertyInfoModal'

import { YupValidator } from 'services/yupValidator.service'

import { AddProducerFormSchema } from 'store/Property/Property.validator'
import { propertyActions } from 'store/Property'
import { all } from 'store/Property/Property.selector'

import { ERRORS_PRODUCER_STATE } from 'constants/producer.constants'
import { ERRORS_INITIAL_STATE_MODAL } from 'constants/property.constants'

import * as S from './styled'

type Property = {
  blockStatus: boolean
  CAR: string
  id: string
  propertyName: string
  producerId: string
  producerName: string
  CPFCNPJ: string
  IE: string
  reason: string
}

export type ModalLogsListProps = {
  property: Property
  onExit?: () => void
}

export function ModalBlockProducer({ property, onExit }: ModalLogsListProps) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [form, setForm] = useState({
    producers: [
      {
        CPFCNPJ: property.CPFCNPJ,
        name: property.producerName,
        IE: property.IE,
        free: Boolean(!property.IE),
        blockStatus: property.blockStatus,
        reason: property.reason
      }
    ]
  })
  const [errors, setErrors] = useState({ ...ERRORS_INITIAL_STATE_MODAL })

  const { blockStatusProducer } = useSelector(all)

  useEffect(() => {
    if (blockStatusProducer.id) {
      dispatch(propertyActions.cleanProperty())
      dispatch(propertyActions.fetchAll())
      onExit()
    }
  }, [blockStatusProducer, dispatch, onExit])

  function handleFill(name: string) {
    return function handle(key, val, index) {
      const updated = [...form?.[name]]

      updated[index] = {
        ...updated[index],
        [key]: val
      }

      setForm({ ...form, [name]: updated })
    }
  }

  function handleSelectedIndex(name: string) {
    return function handle(key, val, index) {
      const updated = [...form?.[name]]
      updated[index] = {
        ...updated[index],
        [key]: val.value
      }
      setForm({ ...form, [name]: updated })
    }
  }

  async function onSubmit() {
    setErrors({
      producers: form.producers.map(_producer => ERRORS_PRODUCER_STATE)
    })

    const [isValid, validationError] = await new YupValidator(
      AddProducerFormSchema()
    ).validate(form)

    if (!isValid) {
      return setErrors(validationError as typeof ERRORS_INITIAL_STATE_MODAL)
    }

    const _producers = []
    form.producers.forEach(producer =>
      _producers.push({
        id: property.producerId,
        blockStatus: producer.blockStatus,
        reason: producer.blockStatus ? producer.reason : ''
      })
    )

    dispatch(propertyActions.blockStatusProducer(_producers, property.id))
  }

  const button = (
    <Button
      variant={
        property.blockStatus !== form.producers[0].blockStatus
          ? 'secondary'
          : 'default'
      }
      onClick={
        property.blockStatus !== form.producers[0].blockStatus
          ? onSubmit
          : () => false
      }
      size="medium"
    >
      <p>{t('common:save')}</p>
    </Button>
  )

  const content = (
    <S.Wrapper>
      <S.GridHeader>
        <S.GridTitle item container xs={12}>
          <S.BoxTitle>
            {property.blockStatus ? t('common:unlock') : t('common:block')}
            {' ' + t('common:productor')}
          </S.BoxTitle>
        </S.GridTitle>
      </S.GridHeader>

      <S.Line />

      <PropertyInfoModal
        CAR={property.CAR}
        propertyName={property.propertyName}
      />

      {form.producers.map((producer, i) => (
        <ProducerForm
          disabledFields={['name', 'CPFCNPJ', 'IE', 'free']}
          errors={errors}
          index={i}
          key={i}
          payload={producer}
          onFill={handleFill('producers')}
          removeButton={false}
          handleSelected={handleSelectedIndex('producers')}
        />
      ))}
    </S.Wrapper>
  )

  return (
    <Modal
      button={button}
      content={content}
      isActionsEnabled={true}
      width={120}
      onExit={onExit}
    />
  )
}

import { ColumnsProps } from 'components/Table/Table'
import i18n from 'i18n'

export const columns = (): ColumnsProps[] => [
  {
    align: 'left',
    fixed: true,
    name: 'sifproducao',
    title: i18n.t('common:SIF')
  },
  {
    align: 'left',
    fixed: true,
    name: 'cnpj',
    title: i18n.t('common:cnpj')
  },
  {
    align: 'left',
    fixed: true,
    name: 'name',
    title: i18n.t('common:fancyname')
  },
  {
    align: 'left',
    fixed: true,
    name: 'city',
    title: i18n.t('common:city')
  },
  {
    align: 'left',
    fixed: true,
    name: 'state',
    title: i18n.t('common:state')
  },

  {
    align: 'right',
    fixed: true,
    name: 'pesoliquido',
    title: i18n.t('common:netWeightDelivered')
  },
  {
    align: 'right',
    fixed: true,
    name: 'totalvolumecaixa',
    title: i18n.t('common:totalBoxVolume')
  }
]

import { Input } from 'components/Input'
import { InputCpfCnpj } from 'components/InputCpfCnpj'
import { InputSelect } from 'components/InputSelect'

import { ERRORS_INITIAL_STATE, INITIAL_STATE } from 'constants/client.constants'
import { useTranslation } from 'react-i18next'
import i18n from 'i18n'

import * as S from './styled'

const status = () => [
  { label: i18n.t('common:active'), value: true },
  { label: i18n.t('common:inactive'), value: false }
]

const inspectionType = () => [
  { title: '', value: '' },
  { title: i18n.t('profile:federalInspectionSeal'), value: 'SIF' },
  { title: i18n.t('profile:stateInspectionSeal'), value: 'SIE' },
  { title: i18n.t('profile:municipalInspectionSeal'), value: 'SIM' }
]

type BasicInformationType = {
  errors: typeof ERRORS_INITIAL_STATE
  payload: typeof INITIAL_STATE
  isEdit: boolean
  handleInput: (_value, _name) => void
  handleSelected: (_name: string) => (_value: any) => void
}

export function BasicInformation({
  errors,
  payload,
  isEdit,
  handleInput,
  handleSelected
}: BasicInformationType) {
  const { t } = useTranslation()

  const changeRadionButton = (value: string) =>
    handleInput(value, 'documentType')

  return (
    <>
      <S.GridInput item sm={12} md={4}>
        <S.RadionContainer marginBottom={35}>
          <S.RadionButton
            type="radio"
            id="nacional"
            name="drone"
            value="nacional"
            checked={payload.documentType === 'nacional'}
            onClick={() => changeRadionButton('nacional')}
          />
          <label htmlFor="nacional">{t('common:national')}</label>
          <S.RadionButton
            type="radio"
            id="internacional"
            name="drone"
            value="internacional"
            checked={payload.documentType === 'internacional'}
            onClick={() => changeRadionButton('internacional')}
          />
          <label htmlFor="internacional">{t('common:international')}</label>
        </S.RadionContainer>
      </S.GridInput>
      <S.GridFields container spacing={2}>
        <S.GridInput item sm={12} md={4}>
          <Input
            fullWidth
            label={t('common:socialReason')}
            value={payload.name}
            error={Boolean(errors.name)}
            helperText={errors.name}
            onInput={value => handleInput(value, 'name')}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <Input
            fullWidth
            label={t('common:fantasyName')}
            value={payload.fancyName}
            error={Boolean(errors.fancyName)}
            helperText={errors.fancyName}
            onInput={value => handleInput(value, 'fancyName')}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          {/* Visualmente está sendo mostrado como "Identificação", mas na API e banco continua sendo cnpj por motivos de compatibilidade.
        Quando for nacional deve-ser um cnpj válido, quando não, pode ser uma string simples.
      Entenda-se que "Identificador" é o campo CNPJ no backend. */}
          {payload.documentType === 'nacional' ? (
            <InputCpfCnpj
              disabled={isEdit}
              fullWidth
              label={t('common:identificationCode')}
              value={payload.cnpj}
              error={Boolean(errors.cnpj)}
              helperText={errors.cnpj}
              type="cnpj"
              onInput={value => handleInput(value, 'cnpj')}
            />
          ) : (
            <Input
              disabled={isEdit}
              fullWidth
              label={t('common:identificationCode')}
              value={payload.cnpj}
              error={Boolean(errors.cnpj)}
              helperText={errors.cnpj}
              onInput={value => handleInput(value, 'cnpj')}
            />
          )}
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <Input
            fullWidth
            disabled={payload.documentType === 'internacional'}
            label={t('common:stateRegistration')}
            value={payload.ie}
            error={Boolean(errors.ie)}
            helperText={errors.ie}
            onInput={value => handleInput(value, 'ie')}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <InputSelect
            error={Boolean(errors.inspectionType)}
            fullWidth
            helperText={errors.inspectionType}
            label={t('common:typeInspection')}
            onSelected={handleSelected('inspectionType')}
            optionLabel="title"
            optionValue="value"
            options={inspectionType()}
            value={payload.inspectionType}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <Input
            fullWidth
            label={t('common:inspectionNumber')}
            value={payload.inspectionNum.replace(/\D/g, '')}
            error={Boolean(errors.inspectionNum)}
            helperText={errors.inspectionNum}
            onInput={value => handleInput(value, 'inspectionNum')}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <InputSelect
            error={Boolean(errors.status)}
            fullWidth
            helperText={errors.status}
            label={t('common:status')}
            onSelected={handleSelected('status')}
            optionLabel="label"
            optionValue="value"
            options={status()}
            value={payload.status}
          />
        </S.GridInput>
      </S.GridFields>
    </>
  )
}

import * as Yup from 'yup'
import validaCNPJ from 'utils/validaCNPJ'
import i18n from 'i18n'

export const ClientGroupFormSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required().label(i18n.t('clientGroup:groupName')),
    adminName: Yup.string().required().label(i18n.t('clientGroup:adminName')),
    adminEmail: Yup.string()
      .required()
      .email()
      .label(i18n.t('clientGroup:adminEmail')),
    visible: Yup.boolean()
      .nullable(true)
      .required()
      .label(i18n.t('clientGroup:view'))
  })
}

export const FilterClientSchema = () => {
  return Yup.object().shape({
    name: Yup.string(),
    fancyName: Yup.string(),
    cnpj: Yup.string()
      // .test('cnpj', i18n.t('inputErrors:invalidCNPJ'), value => {
      //   if (value) {
      //     const isValid = validaCNPJ(value) // remover obrigatoriedade da validação
      //     return isValid
      //   }
      //   return true
      // })
      .label(i18n.t('common:CNPJ'))
  })
}

export const FilterAddClientSchema = () =>
  Yup.object().shape({
    name: Yup.string(),
    cnpj: Yup.string()
      .test('cnpj', i18n.t('inputErrors:invalidCNPJ'), value => {
        if (value) {
          const isValid = validaCNPJ(value)
          return isValid
        }
        return true
      })
      .label(i18n.t('common:CNPJ'))
  })

import { useState } from 'react'
import { Base } from 'layouts'
import { Tabs } from '.'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Cards,
  ClusterMaps,
  HeaderBlock,
  ModalPropertiesKpi
} from 'components'
import { InvoicesAndPropertiesCharts, TonsAndVolumeCharts } from './CardCharts'
import { PropertiesActions } from 'store/Properties'
import MapIcon from '@material-ui/icons/Map'

import * as S from './styled'
import { all as allProperties } from 'store/Properties/Properties.selector'
import { all as allTraceabilityTables } from 'store/TraceabilityTables/TraceabilityTables.selector'

import { all as allPropertiesDetails } from 'store/PropertiesDetails/PropertiesDetails.selector'

export function TraceabilityTables() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { propertiesLocation } = useSelector(allProperties)
  const { filters } = useSelector(allTraceabilityTables)
  const { showKpiPropertiesDetailsModal } = useSelector(allPropertiesDetails)

  const [showPropertiesMap, setShowPropertiesMap] = useState(false)

  async function fetchPropertiesLocationsForMap() {
    dispatch(PropertiesActions.getPropertiesLocation(filters))
    setShowPropertiesMap(true)
  }

  return (
    <>
      {showKpiPropertiesDetailsModal && <ModalPropertiesKpi />}
      <Base>
        {showPropertiesMap && <ClusterMaps data={propertiesLocation} />}

        {/* <CardFilters /> */}

        <S.ChartsContainer>
          <S.HeaderContainer>
            <HeaderBlock title={t('breadcrumb:graphicViewInvoices')} />
            <S.MapButtonContainer>
              <Button
                variant="map"
                onClick={() => fetchPropertiesLocationsForMap()}
              >
                <S.ButtonContent>
                  <MapIcon />
                  <S.ButtonText>Qualqer coisa</S.ButtonText>
                </S.ButtonContent>
              </Button>
            </S.MapButtonContainer>
          </S.HeaderContainer>

          <S.GridCardsCharts container spacing={3}>
            <Cards />
            <InvoicesAndPropertiesCharts />
            <TonsAndVolumeCharts />
          </S.GridCardsCharts>
        </S.ChartsContainer>
        <Tabs />
      </Base>
    </>
  )
}

import { get } from 'lodash'
import { NutriTableOptionsType, NutriTableType } from 'types/NutriTable.types'
import { types } from './nutriTable.action'

type NutriTable = {
  nutriTables: {
    items: NutriTableType[]
    pagination: {
      totalItems: number
      limit: number
      page: number
    }
  }
  options?: NutriTableOptionsType[]
  nutriTableCreate?: NutriTableType
  nutriTableEdit?: NutriTableType
  nutriTable?: NutriTableType
  loading: boolean
}

const INITIAL_STATE = {
  nutriTables: {
    items: [],
    pagination: null
  },
  nutriTableCreate: {} as NutriTableType,
  nutriTableEdit: {} as NutriTableType,
  nutriTable: {} as NutriTableType,
  options: [],
  loading: false
}

function setState(state: NutriTable) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.CLEAN_NUTRI_TABLES]: setState(state),
    [types.SET_NUTRI_TABLE_CREATED]: setState(state),
    [types.SET_NUTRI_TABLE_EDIT]: setState(state),
    [types.SET_NUTRI_TABLE_OPTIONS]: setState(state),
    [types.SET_NUTRI_TABLES]: setState(state),
    [types.SET_NUTRI_TABLE]: setState(state),
    [types.SET_LOADING]: setState(state),
    _default: () => state
  }
  return get(_handlers, type, _handlers._default)(payload)
}

import { Input } from 'components/Input'
import { submitOnEnter } from 'utils/submitOnEnter'
import { Pagination } from '@mui/material'
import { useTranslation } from 'react-i18next'
import * as S from './styled'

type PaginationProps = {
  rowsPerPageOptions: number[]
  count: number
  onChangePage: (_e: any, _newPage: number) => void
  onChangeRowsPerPage: (_e: any) => void
  handleNewPage?: (_e: any, _newPage: number) => void
  handleInput: (_name: string, _value: number) => void
  page: number
  limit?: number
}

export function NewPagination({
  rowsPerPageOptions,
  count,
  onChangePage,
  onChangeRowsPerPage,
  handleNewPage,
  handleInput,
  page,
  limit
}: PaginationProps) {
  const { t } = useTranslation()
  return (
    <S.Pagination>
      <S.LinesPerPages>
        <S.Label>{t('common:itemsPerPage')}</S.Label>
        <S.Select onChange={e => onChangeRowsPerPage(e)}>
          <S.Option hidden={true}>{limit}</S.Option>
          {rowsPerPageOptions.map((index, value) => {
            return <S.Option key={value}>{index}</S.Option>
          })}
        </S.Select>
      </S.LinesPerPages>
      <Pagination
        count={count}
        shape="rounded"
        onChange={onChangePage}
        page={page}
      />
      <S.Input>
        <Input
          focused={true}
          label={t('common:goToPage')}
          onKeyPress={submitOnEnter(handleNewPage)}
          onInput={value => handleInput('page', parseInt(value))}
        />
      </S.Input>
    </S.Pagination>
  )
}

import { Button } from 'components'
import { Modal } from 'components/Modal'
import { useState } from 'react'
import { PropertyTmpType } from 'types/PropertyTmp.types'
import { ModalConfirm } from './ModalConfirm/ModalConfirm'
import i18n from 'i18n'

import * as S from './styled'

const data = () => [
  { field: i18n.t('common:property'), value: 'nameProperty' },
  { field: i18n.t('common:CAR'), value: 'CAR' },
  { field: i18n.t('common:producerName'), value: 'nameProducer' },
  { field: i18n.t('common:cpfCNPJ'), value: 'CPFCNPJ' },
  { field: i18n.t('common:description'), value: 'description' },
  { field: i18n.t('common:IE'), value: 'IE' },
  { field: i18n.t('common:CCIR'), value: 'CCIR' },
  { field: i18n.t('common:INCRA'), value: 'INCRA' },
  { field: i18n.t('common:LARLAU'), value: 'LARLAU' },
  { field: i18n.t('common:NIRF'), value: 'NIRF' },
  { field: i18n.t('common:address'), value: 'address' },
  { field: i18n.t('common:number'), value: 'streetNumber' },
  { field: i18n.t('common:CEP'), value: 'zipCode' },
  { field: i18n.t('common:city'), value: 'cityName' },
  { field: i18n.t('common:state'), value: 'stateName' },
  { field: i18n.t('common:status'), value: 'flagStatus' },
  { field: i18n.t('common:latitude'), value: 'lat' },
  { field: i18n.t('common:longitude'), value: 'lng' }
]

export type ModalLogsListProps = {
  propertyTmp: PropertyTmpType
  discard?: (_property) => void
  save?: (_property) => void
  onExit?: () => void
}

export function ModalCompare({
  propertyTmp,
  discard,
  save,
  onExit
}: ModalLogsListProps) {
  const [confirmDiscard, setConfirmDiscard] = useState(false)

  function onDiscard() {
    discard(propertyTmp.id)
    setConfirmDiscard(false)
    onExit()
  }

  function onSave(propertyTmp) {
    save(propertyTmp)
    onExit()
  }

  const buttons = (
    <>
      <Button
        variant="primary"
        onClick={() => setConfirmDiscard(true)}
        size="medium"
      >
        <p>{i18n.t('common:discard')}</p>
      </Button>

      {propertyTmp.property && (
        <Button
          variant="secondary"
          onClick={() => onSave(propertyTmp)}
          size="medium"
        >
          <p>{i18n.t('common:save')}</p>
        </Button>
      )}
    </>
  )

  const content = (
    <S.Wrapper>
      {confirmDiscard ? (
        <ModalConfirm
          property={propertyTmp.nameProperty}
          producer={propertyTmp.nameProducer}
          onExit={() => setConfirmDiscard(false)}
          onDiscard={onDiscard}
        />
      ) : null}
      <S.GridHeader>
        <S.GridTitle item container xs={12}>
          <S.BoxTitle>{i18n.t('common:importInformation')}</S.BoxTitle>
        </S.GridTitle>
      </S.GridHeader>

      <S.Line />

      <S.Container>
        <S.Header>
          <S.Title>{i18n.t('common:importInformation')}</S.Title>
          {propertyTmp.property && (
            <S.Title>{i18n.t('common:importInformation')}</S.Title>
          )}
          <S.Title>{i18n.t('common:importInformation')}</S.Title>
        </S.Header>
        {data().map((each, i) => {
          return (
            <S.Row key={i} index={i}>
              <S.Field>{each.field}</S.Field>
              {propertyTmp.property && (
                <S.Value>{propertyTmp.property[`${each.value}`]}</S.Value>
              )}
              <S.Value className="import">
                {propertyTmp[`${each.value}`]}
              </S.Value>
            </S.Row>
          )
        })}
      </S.Container>
    </S.Wrapper>
  )

  return (
    <Modal
      cancelTitle={i18n.t('common:close')}
      button={buttons}
      content={content}
      isActionsEnabled={true}
      width={120}
      onExit={onExit}
    />
  )
}

import styled from 'styled-components'

export const GridTable = styled.div`
  margin-top: 2.5rem;
  max-height: 55rem;
  padding-left: 2rem;
  padding-bottom: 2rem;
  padding-top: 1rem;
  overflow-y: auto;

  ::-webkit-scrollbar {
    height: 1.2rem;
    width: 0.4rem;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray.luminance(0.5)};
    border-radius: 2rem;
  }
`

export const TableWrapper = styled.div`
  padding-right: 20px;
  width: 100%;
`
export const Wrapper = styled.div`
  align-items: center;
  display: flex;
`

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  min-width: 18rem;
`

export const Link = styled.li`
  align-items: center;
  background: white;
  color: ${({ theme }) => theme.colors.gray};
  display: flex;
  cursor: pointer;

  :first-child {
    margin-top: 0.8rem;
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
  }

  :last-child {
    margin-bottom: 0.8rem;
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 0;
  }

  > div {
    margin: 0.8rem -0.4rem 0.8rem 1.6rem !important;
  }

  > span {
    font-size: 1.6rem;
    margin: 0.8rem 1.6rem !important;
  }
`
export const Table = styled.table`
  border-collapse: collapse;
  font-family: Arial, Helvetica, sans-serif;
  table-layout: auto;
  margin-bottom: auto;
  width: 100%;
`

export const Line = styled.tr`
  height: 4rem;
  :nth-child(even) {
    background-color: ${({ theme }) => theme.colors.rowTable};
  }
`
export const Header = styled.th.attrs(() => {})`
  border: 1px solid #ddd;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1.1rem 1.6rem;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  line-height: 1.6rem;

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  button {
    transition: 0.3s all ease-in-out;
    opacity: ${({ buttonOpacity }) => (buttonOpacity ? 1 : 0)};
  }

  &:hover {
    button {
      opacity: 1;
    }
  }
`
export const Column = styled.td<{ position?: string }>`
  border: 1px solid #ddd;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.4rem;
  padding: 0 1.6rem;
  white-space: nowrap;
  text-align: ${props => props.position || 'left'};
  vertical-align: middle;
  line-height: 1.6rem;

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &#actions-column {
    padding: 0.6rem 0 0.6rem 0;
    div {
      margin: auto;
    }
    button {
      display: block;
      margin: auto;
    }
  }
`
export const Date = styled.div`
  padding-top: 4rem;
  padding-left: 0.8rem;
  margin-bottom: 0;
  p {
    color: #233762;
    font-size: 1.6rem;
    font-weight: 600;
    padding: 0 1.2rem;
  }
`

import { MouseEventHandler } from 'react'
import { Button } from 'components'

type PropTypes = {
  onExit: MouseEventHandler
  title: string
}

export function CancelButton({ onExit, title }: PropTypes) {
  return (
    <Button variant="default" onClick={onExit} size="medium">
      {title}
    </Button>
  )
}

import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  white-space: normal;
`

export const Line = styled.div`
  width: auto;
  height: 0.2rem;
  background-color: #f2f3f7;
  margin-bottom: 12px;
`

export const NoContent = styled.div`
  font-size: 1.8rem;
  display: flex;
  justify-content: center;
`

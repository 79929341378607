import { HTTP } from 'providers'

export class CustomFields extends HTTP {
  private endpoint = '/custom-access-filters'

  public fetchFilters() {
    return this.get({
      endpoint: this.endpoint
    })
  }
}

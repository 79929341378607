import { useState } from 'react'

import { CityType } from 'types/City.types'
import { LatLngType } from 'types/Location.types'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Input,
  InputCep,
  InputSelect,
  ModalMapMarker
} from 'components'

import {
  locationType,
  ERRORS_LOCATION_STATE,
  STATES
} from 'constants/location.constants'

import * as S from './styled'

type PropTypes = {
  cities?: CityType[]
  errors: typeof ERRORS_LOCATION_STATE
  optionValueCity?: string
  payload: locationType
  handleInput: (_value, _name) => void
  handleSelected: (_name: string) => (_value: any) => void
}

export function Location({
  cities,
  errors,
  optionValueCity = 'value',
  payload,
  handleInput,
  handleSelected
}: PropTypes) {
  const { t } = useTranslation()
  const [hasError, setError] = useState(false)
  const [latLng, setLatLng] = useState<LatLngType>(null)

  function viewInMap() {
    const lat = Number(payload.lat.replace(/,/, '.'))
    const lng = Number(payload.lng.replace(/,/, '.'))

    if (!lat || !lng) return setError(true)

    if (isNaN(parseFloat(lat.toString()))) return setError(true)
    if (isNaN(parseFloat(lng.toString()))) return setError(true)

    setError(false)
    setLatLng({ lat, lng })
  }

  function onExit() {
    setLatLng(null)
  }

  return (
    <>
      {latLng && <ModalMapMarker latLng={latLng} onExit={onExit} />}
      <S.GridFields container spacing={2}>
        <S.GridInput item sm={12} md={4}>
          <Input
            error={Boolean(errors.address)}
            fullWidth
            disabled={payload.documentType === 'internacional'}
            helperText={errors.address}
            label={t('common:address')}
            onInput={value => handleInput(value, 'address')}
            value={payload.address}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <InputSelect
            error={Boolean(errors.state)}
            fullWidth
            helperText={errors.state}
            label={t('common:state')}
            disabled={payload.documentType === 'internacional'}
            onSelected={handleSelected('state')}
            optionLabel="title"
            options={STATES}
            optionValue="value"
            value={payload.state}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <InputSelect
            disabled={
              payload.state === '' || payload.documentType === 'internacional'
            }
            error={Boolean(errors.city)}
            fullWidth
            helperText={errors.city}
            label={t('common:city')}
            onSelected={handleSelected('city')}
            optionLabel="title"
            options={cities.filter(city => city.state === payload.state)}
            optionValue={optionValueCity}
            value={payload.city}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <InputCep
            error={Boolean(errors.cep)}
            fullWidth
            helperText={errors.cep}
            disabled={payload.documentType === 'internacional'}
            label={t('common:CEP')}
            onInput={value => handleInput(value, 'cep')}
            value={payload.cep}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <S.InputCoordinates
            error={Boolean(errors.lat)}
            fullWidth
            disabled={payload.documentType === 'internacional'}
            helperText={errors.lat}
            label={t('common:latitude')}
            onInput={value => handleInput(value, 'lat')}
            placeholder="-23.60916"
            value={payload.lat || ''}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <S.InputCoordinates
            error={Boolean(errors.lng)}
            disabled={payload.documentType === 'internacional'}
            fullWidth
            helperText={errors.lng}
            label={t('common:longitude')}
            value={payload.lng || ''}
            placeholder="-51.66763"
            onInput={value => handleInput(value, 'lng')}
          />
        </S.GridInput>

        <S.GridButtonsMap item sm={12}>
          <Button variant="primary" onClick={viewInMap}>
            <p>{t('common:viewMap')}</p>
          </Button>
          {hasError && (
            <S.ErrorMessage>
              {t('toasts:needFillLatitudeLongitude')}
            </S.ErrorMessage>
          )}
        </S.GridButtonsMap>
      </S.GridFields>
    </>
  )
}

import styled, { keyframes, css } from 'styled-components'
import { IconButton } from '@material-ui/core'

export const Wrapper = styled.div.attrs(() => {})`
  background-color: ${({ theme }) => theme.colors.white};
  padding-bottom: 0.05rem;
  display: flow-root;
  width: 100%;

  table {
    width: ${({ columnScroll }) => (columnScroll ? 'auto' : '100%')};
  }

  th {
    padding: 0.2rem 1.1rem;
    cursor: pointer;
  }

  #table-fixed {
    td:last-child,
    th:last-child {
      border-right: ${({ actionTable, columnScroll }) =>
        columnScroll ? 1 : actionTable ? 0 : 1};
    }
  }

  #table-scrolled {
    td,
    tr,
    td:first-child,
    th:first-child {
      border-left: ${({ columnFixed }) => (columnFixed ? 0 : 1)};
    }

    td:last-child,
    th:last-child {
      border-right: ${({ actionTable, columnFixed }) =>
        columnFixed && !actionTable ? 1 : !columnFixed && !actionTable ? 1 : 1};
    }
  }

  #table-actions {
    width: auto;
  }
`
export const WrapperScroll = styled.div.attrs(() => {})`
  background-color: ${({ theme }) => theme.colors.white};
  padding-bottom: 0.05rem;
  display: flow-root;
  width: 100%;
  @media (max-height: 766px) {
    height: 450px;
  }
  height: 750px;
  overflow-x: scroll;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    height: 1rem;
    width: 0.8rem;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray.luminance(0.5)};
    border-radius: 2rem;
    &:hover {
      background-color: ${({ theme }) => theme.colors.gray};
    }
  }

  table {
    width: ${({ columnScroll }) => (columnScroll ? '100%' : '100%')};
  }

  th {
    padding: 0.2rem 1.1rem;
    cursor: pointer;
  }

  #table-fixed {
    td:last-child,
    th:last-child {
      border-right: ${({ actionTable, columnScroll }) =>
        columnScroll ? 1 : actionTable ? 0 : 1};
    }
  }

  #table-actions {
    width: auto;
  }
`

export const Container = styled.div<{ isLoading?: boolean }>`
  display: flex;
  width: 100%;

  ${props =>
    props.isLoading &&
    css`
      & #values {
        background: #fff;
        animation: 1.5s ${fade} alternate infinite;
      }

      #table-actions {
        cursor: none;
        pointer-events: none;
      }
    `}
`

export const ScrollWrapper = styled.div`
  display: grid;
  overflow-x: auto;
  width: 100%;

  ::-webkit-scrollbar {
    height: 1rem;
    width: 1.2rem;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray.luminance(0.5)};
    border-radius: 2rem;
    &:hover {
      background-color: ${({ theme }) => theme.colors.gray};
    }
  }
`

export const Table = styled.table`
  border-collapse: collapse;
  font-family: Arial, Helvetica, sans-serif;
  table-layout: auto;
  margin-bottom: auto;
`

export const Header = styled.th.attrs(() => {})`
  border: 1px solid #ddd;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1.1rem 1.6rem;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  line-height: 1.6rem;
  position: sticky;
  position: -webkit-sticky;
  top: -0.1rem;
  background-color: #d8f2d6;
  z-index: 10;

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  button {
    transition: 0.3s all ease-in-out;
    opacity: ${({ buttonOpacity }) => (buttonOpacity ? 1 : 0)};
  }

  &:hover {
    button {
      opacity: 1;
    }
  }
`

export const SelectAllHeaderContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 18px;
  justify-content: center;
`

export const Line = styled.tr`
  height: 4rem;
  :nth-child(even) {
    background-color: ${({ theme }) => theme.colors.rowTable};
  }
`

export const Column = styled.td<{ position?: string }>`
  border: 1px solid #ddd;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.4rem;
  padding: 0 1.6rem;
  white-space: nowrap;
  text-align: ${props => props.position || 'left'};
  vertical-align: middle;
  line-height: 1.6rem;

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  /* &#actions-column {
    padding: 0.6rem 0 0.6rem 0;
    div {
      margin: auto;
    }
    button {
      display: block;
      margin: auto;
    }
  } */
`

export const CheckboxColumnContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const ButtonIcon = styled(IconButton)`
  background-color: #ffffff;
  margin: 0 0.8rem;
  &:hover {
    background-color: #efefef;
  }
`

const fade = keyframes`
  from {
    margin-top: 5px;
    background: #fff;
    opacity: 0.5;
  }

  to {
    margin-top: 5px;
    background: #fff;
    opacity: 0.1;
  }
`

export const TableLoadingFistTime = styled.div`
  width: 100%;
  height: 120px;
  font-size: 2rem;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: -57px;
  padding-bottom: 25px;
  animation: 1.5s ${fade} alternate infinite;
`

export const TableLoading = styled.div`
  width: 100%;
  font-size: 2rem;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: -32px;
  padding-bottom: 25px;
`
export const Button = styled.button`
  border: none;
  background-color: transparent;
`

import { HTTP } from '../providers'

export class OrderHistoryService extends HTTP {
  private endpoint = '/orderHistory'

  public getByHashPartner({ hashPartner }) {
    return this.get({
      endpoint: `${this.endpoint}?hashPartner=${hashPartner}`
    })
  }
}

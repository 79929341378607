import { HTTP } from 'providers'
import { TraceabilityFilter } from 'types/Traceability.types'

export class PropertiesService extends HTTP {
  private endpoint = '/properties'

  public async getProperties(params: TraceabilityFilter) {
    const properties = await this.get({
      endpoint: this.endpoint,
      params
    })
    return properties
  }

  public async getPropertiesLocation(params: TraceabilityFilter): Promise<any> {
    const properties = await this.get({
      endpoint: this.endpoint + '/locations',
      params
    })
    return properties
  }
}

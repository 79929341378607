import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

import { AddButton } from 'components/AddButton'
import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { Pagination } from 'components/Pagination'

import { all } from 'store/ClientGroup/ClientGroup.selector'
import { clientGroupActions } from 'store/ClientGroup'

import { submitOnEnter } from 'utils/submitOnEnter'

import * as S from './styled'
import { ClientGroupTable } from './ClientGroupTable'
import { useTranslation } from 'react-i18next'
import { UserStorageService } from 'services'

const FILTERS_INITIAL_STATE = {
  name: '',
  adminEmail: '',
  limit: 10,
  page: 0
}

const ERRORS_INITIAL_STATE = {
  name: '',
  adminEmail: ''
}

export function ClientGroupList() {
  const { t } = useTranslation()
  const { user } = UserStorageService.getUserData()
  const [filter, setFilter] = useState({ ...FILTERS_INITIAL_STATE })
  const [errors, setErrors] = useState({ ...ERRORS_INITIAL_STATE })

  const { clientGroups } = useSelector(all)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clientGroupActions.fetchAll())
  }, [dispatch])

  function handleInputData(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setFilter({ ...filter, [name]: value })
  }

  async function onFilter() {
    try {
      const filterPayload = await Yup.object()
        .shape({
          adminEmail: Yup.string().email().label('Email'),
          name: Yup.string()
        })
        .validate(filter, {
          abortEarly: false,
          stripUnknown: true
        })

      dispatch(clientGroupActions.fetchAll(filterPayload))
    } catch (ex) {
      const pairs = ex.inner.map(({ path, message }) => [
        path,
        message.replace(`${path} `, '')
      ])
      setErrors(Object.fromEntries(pairs) as typeof FILTERS_INITIAL_STATE)
    }
  }

  function onFilterPaginate(params = {}) {
    dispatch(clientGroupActions.fetchAll(params))
  }

  function clearFilter() {
    const params = {
      ...FILTERS_INITIAL_STATE,
      limit: clientGroups.pagination.limit,
      page: clientGroups.pagination.page
    }
    setFilter(params)
    setErrors({ ...ERRORS_INITIAL_STATE })
    dispatch(clientGroupActions.fetchAll(params))
  }

  return (
    <S.Wrapper container>
      {!UserStorageService.isViewer(user) && (
        <S.ButtonAdd>
          <Link to="/grupo-cliente/novo">
            <AddButton />
          </Link>
        </S.ButtonAdd>
      )}
      <S.FullGrid item xs={12}>
        <S.GridHeader>
          <S.GridTitle item container xs={12}>
            <S.BoxTitle>{t('common:filterGroups')}</S.BoxTitle>
            <S.BoxLine />
          </S.GridTitle>
        </S.GridHeader>
        <S.GridFilter container>
          <S.GridInput
            item
            sm={12}
            md={UserStorageService.isViewer(user) ? 12 : 6}
            lg={UserStorageService.isViewer(user) ? 8 : 4}
          >
            <Input
              error={Boolean(errors.name)}
              fullWidth
              helperText={errors.name}
              label={t('clientGroup:groupName')}
              onInput={value => handleInputData(value, 'name')}
              onKeyPress={submitOnEnter(onFilter)}
              value={filter.name}
            />
          </S.GridInput>
          <S.GridInput
            item
            sm={12}
            md={6}
            lg={4}
            hidden={UserStorageService.isViewer(user)}
          >
            <Input
              error={Boolean(errors.adminEmail)}
              fullWidth
              helperText={errors.adminEmail}
              label={t('profile:adminEmail')}
              onKeyPress={submitOnEnter(onFilter)}
              onInput={value => handleInputData(value, 'adminEmail')}
              type="email"
              value={filter.adminEmail}
            />
          </S.GridInput>
          <S.GridButtons item sm={12} md={12} lg={4}>
            <Button variant="default" onClick={clearFilter} size="medium">
              <p>{t('common:clean')}</p>
            </Button>
            <Button variant="primary" onClick={onFilter} size="medium">
              <p>{t('common:search')}</p>
            </Button>
          </S.GridButtons>
        </S.GridFilter>
        <S.GridTable>
          <ClientGroupTable
            rows={clientGroups.items}
            isUserViewer={UserStorageService.isViewer(user)}
          />
          {clientGroups.pagination?.totalItems > 0 && (
            <Pagination
              filters={{
                ...filter,
                limit: clientGroups.pagination.limit,
                page: clientGroups.pagination.page
              }}
              limit={clientGroups.pagination.limit}
              page={clientGroups.pagination.page}
              totalItems={clientGroups.pagination.totalItems}
              setFilter={onFilterPaginate}
            />
          )}
        </S.GridTable>
      </S.FullGrid>
    </S.Wrapper>
  )
}

import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const ChartsContainer = styled.div`
  border-radius: 1.2rem;
  background: #fff;
  margin: 20px 0px;
  box-shadow: 0px 5px 25px #0000000d;
  width: 100%;
  flex-wrap: wrap;
  padding: 20px 20px 0 20px;
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const GridCardsCharts = styled(Grid)`
  margin-bottom: 0;
`

export const Charts = styled(Grid)`
  height: 100%;
`

export const MapButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  padding-bottom: 20px;
`

export const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ButtonText = styled.span`
  margin-left: 12px;
`

import { call, put } from 'redux-saga/effects'

import { KpisService } from 'services'

import * as actions from './PropertiesDetails.actions'
import FilteringTraceability from 'utils/FilteringTraceability'
import * as actionsKpis from '../TraceabilityTables/TraceabilityTables.actions'

export function* getKpisPropertiesDetails(action) {
  yield put(actionsKpis.setTableLoading(true))
  const _filters = FilteringTraceability(action.payload.filterProperties)

  const $kpis = new KpisService()

  const items = yield call($kpis.getKpisPropertiesDetails.bind($kpis), _filters)

  if (items.success) {
    yield put(
      actions.setKpisPropertiesDetails({ kpiPropertiesDetails: items.data })
    )
  }
  yield put(actionsKpis.setTableLoading(false))
}

export function* getKpisPropertiesSummary(action) {
  yield put(actionsKpis.setTableLoading(true))
  const _filters = FilteringTraceability(action.payload.filterProperties)
  const $kpis = new KpisService()

  const items = yield call(
    $kpis.getKpisPropertiesSummarized.bind($kpis),
    _filters
  )

  if (items.success) {
    yield put(
      actions.setKpisPropertiesSummary({
        kpiPropertiesSummary: items.data
      })
    )
  }
  yield put(actionsKpis.setTableLoading(false))
}

export function* getKpisPropertiesExported(action) {
  const _filters = FilteringTraceability(action.payload.filterProperties)

  const $kpis = new KpisService()

  yield call($kpis.getKpisPropertiesExported.bind($kpis), _filters)
}

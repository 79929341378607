import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const ButtonAdd = styled.div`
  position: absolute;
  right: 2.4rem;
  top: 14.6rem;
`

export const Wrapper = styled(Grid)`
  margin-bottom: 7.4rem;
`
export const GridContainer = styled(Grid)``

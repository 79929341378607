import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import { Icon } from 'components/Icon'
import { StatusBox } from 'components/StatusBox'
import { ModalDeleteGroupIndustry } from 'components/IndustryGroup/ModalDeleteGroupIndustry/ModalDeleteGroupIndustry'
import { useTranslation } from 'react-i18next'

import {
  labels,
  statusBadgeClassnames
} from 'constants/industryGroup.constants'

import { deleteIndustryGroup } from 'store/IndustryGroup/IndustryGroup.actions'
import { all } from 'store/IndustryGroup/IndustryGroup.selector'

import * as S from './styled'
import { theme } from 'config'
import { THEMES_NAME } from 'config/theme.config'

export function IndustryGroupInfo() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { goBack } = useHistory()
  const [_deleteIndustryGroup, _setDeleteIndustryGroup] = useState(false)
  const { manageIndustryGroup, loading } = useSelector(all)

  const { adminEmail, adminName, companies, id, name, visible } =
    manageIndustryGroup

  const history = useHistory()

  useEffect(() => {
    history.replace({
      state: { nameBreadcrumbs: `${t('common:group')} ${name}` }
    })
  }, [history, name, id, t])

  function onExit() {
    _setDeleteIndustryGroup(false)
  }

  function onRemoveIndustryGroup() {
    dispatch(
      deleteIndustryGroup({
        id
      })
    )
    _setDeleteIndustryGroup(false)
    goBack()
  }

  function onDeleteIndustryGroup() {
    _setDeleteIndustryGroup(true)
  }

  return (
    <S.GridCard>
      {!loading && (
        <>
          <S.GridHeader>
            <S.GridTitle item container xs={12} md={6}>
              <S.BoxTitle>{t('common:industryGroup')}</S.BoxTitle>
            </S.GridTitle>
            <S.GridStatus item container xs={12} md={6}>
              <StatusBox variant={statusBadgeClassnames[String(visible)]}>
                {labels()[String(visible)]}
              </StatusBox>
            </S.GridStatus>
          </S.GridHeader>

          <S.Line />

          <S.GroupName>
            {t('common:group')} {name}
          </S.GroupName>
          <S.GroupTitle>{t('common:responsible')}</S.GroupTitle>
          <S.GroupTitleName>{adminName}</S.GroupTitleName>
          <S.GroupTitleEmail>{adminEmail}</S.GroupTitleEmail>

          <S.Line />

          <S.GridLinks>
            <Link to={`/grupo-industria/editar/${id}`}>
              <S.Link>
                <Icon
                  icon={
                    theme.appTheme === THEMES_NAME.swift
                      ? 'group-profile-rename-swift'
                      : 'group-profile-rename'
                  }
                  height={16}
                  width={16}
                ></Icon>
                <span>{t('breadcrumb:editGroup')}</span>
              </S.Link>
            </Link>
            <S.Link onClick={onDeleteIndustryGroup}>
              <Icon
                icon={
                  theme.appTheme === THEMES_NAME.swift
                    ? 'group-profile-delete-swift'
                    : 'group-profile-delete'
                }
                height={16}
                width={16}
              ></Icon>
              <span>{t('breadcrumb:deleteGroup')}</span>
            </S.Link>
          </S.GridLinks>

          {_deleteIndustryGroup && (
            <ModalDeleteGroupIndustry
              groupName={name}
              companies={companies.length}
              onExit={onExit}
              onRemove={onRemoveIndustryGroup}
            />
          )}
        </>
      )}
    </S.GridCard>
  )
}

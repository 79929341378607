import { OrderHistoryType } from 'types/OrderHistory.types'
import { HeaderBlock, Table } from 'components'
import IconButton from '@material-ui/core/IconButton'
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined'
import exportFromJSON from 'export-from-json'

import { allColumns, columnDownload } from './GridColumns'

import { USER_ROLES } from 'constants/user.constants'
import { UserStorageService } from 'services'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

type PropTypes = {
  orderHistories: OrderHistoryType[]
}

export function OrderHistoryGrid({ orderHistories }: PropTypes) {
  const { t } = useTranslation()
  const { user } = UserStorageService.getUserData()
  const allColumnsReturn = allColumns()
  const columnDownloadReturn = columnDownload()
  const url = document.URL
  const splitUrl = url.split('//')
  const result = splitUrl[1].split('.')

  const columns =
    user.tag === USER_ROLES.viewer
      ? [...allColumnsReturn]
      : [...columnDownloadReturn, ...allColumnsReturn]

  function changeColumn({ column, row }) {
    if (result[0] === 'tracebeef-demo') {
      if (column.name === 'lastTouchBy') {
        return <p>INDÚSTRIA S/A</p>
      }
      if (column.name === 'payload') {
        return (
          <IconButton
            color="primary"
            aria-label="download json file"
            component="span"
            disabled={true}
          >
            <CloudDownloadOutlinedIcon />
          </IconButton>
        )
      } else return row[column.name]
    } else {
      if (column.name === 'payload') {
        return (
          <IconButton
            color="primary"
            aria-label="download json file"
            component="span"
            onClick={() => downloadJSON(row)}
          >
            <CloudDownloadOutlinedIcon />
          </IconButton>
        )
      } else return row[column.name]
    }
  }

  async function downloadJSON({ hashPartner, originalData, payload }) {
    const data = {
      ...originalData,
      payload: { ...JSON.parse(payload) }
    }
    const fileName = hashPartner
    const exportType = exportFromJSON.types.json
    exportFromJSON({ data, fileName, exportType })
  }

  return (
    <>
      <S.GridTable>
        <HeaderBlock title={t('common:completeHistory')} />
        <S.TableWrapper>
          <Table
            rows={orderHistories}
            columns={columns}
            changeColumn={changeColumn}
          />
        </S.TableWrapper>
      </S.GridTable>
    </>
  )
}

import { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { all } from 'store/PropertiesDetails/PropertiesDetails.selector'
import { all as allFilter } from 'store/TraceabilityTables/TraceabilityTables.selector'
import { propertiesDetailsActions } from 'store/PropertiesDetails'
import { ModalContent } from './ModalContent'
import { Modal } from 'components'

export function ModalPropertiesKpi() {
  const dispatch = useDispatch()
  const { filters } = useSelector(allFilter)
  const { kpiPropertiesDetails } = useSelector(all)

  const fetch = useCallback(() => {
    dispatch(propertiesDetailsActions.getKpisPropertiesDetails(filters))
  }, [dispatch, filters])

  const onExitModalLogs = () => {
    dispatch(propertiesDetailsActions.cleanPropertiesDetails())
    dispatch(propertiesDetailsActions.setShowModal(false))
  }

  useEffect(() => {
    fetch()
  }, [fetch])

  const content = <ModalContent kpiPropertiesDetails={kpiPropertiesDetails} />

  return (
    <Modal
      content={content}
      isActionsEnabled={false}
      width={180}
      onExit={onExitModalLogs}
    />
  )
}

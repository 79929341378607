export const types = Object.freeze({
  FETCH_ALL: '@userRole/fetchAll',
  SET_USER_ROLE: '@userRole/setUserRole',
  SET_LOADING: '@userRole/setLoading'
})

export function fetchAll(filters = {}) {
  return { type: types.FETCH_ALL, payload: { filters } }
}

export function setUserRole({ userRole = [] } = {}) {
  return {
    type: types.SET_USER_ROLE,
    payload: { userRole, loading: false }
  }
}

export function setLoading({ loading = false } = {}) {
  return { type: types.SET_LOADING, payload: { loading } }
}

import { useTranslation } from 'react-i18next'
import * as S from './styled'
type ButtonPwdProps = {
  sendEmail: () => void
  lastSignIn: Date
  variant?: 'send' | 'confirm'
  minWidth?: number
}
export function ButtonPwd({
  sendEmail,
  lastSignIn,
  variant = 'confirm',
  minWidth = 6
}: ButtonPwdProps) {
  const { t } = useTranslation()
  return (
    <>
      <S.Button
        className={`${variant}`}
        onClick={sendEmail}
        minWidth={minWidth}
      >
        {lastSignIn ? t('users:send') : t('users:confirm')}
      </S.Button>
    </>
  )
}

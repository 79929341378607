import styled from 'styled-components'

interface IClusterMarker {
  pointCount?: number
  points?: object[]
}

export const MapContainer = styled.div`
  height: 70vh;
  margin: 0 auto;
  width: 90%;
  padding-bottom: 5rem;
`

export const ClusterMarker = styled.div<IClusterMarker>`
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 13px;
  margin-top: -10px;
  margin-left: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => `
    width: ${10 + (props.pointCount / props.points.length) * 15}px;
    height: ${10 + (props.pointCount / props.points.length) * 15}px;
  `}
`

export const PropertieMarker = styled.button`
  background: none;
  border: none;

  & img {
    margin-top: -25px;
    margin-left: -25px;
    width: 25px;
  }
`

import * as Yup from 'yup'
import validaCNPJ from 'utils/validaCNPJ'
import i18n from 'i18n'

const contactsSchema = () => {
  return Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required().label(i18n.t('common:name')),
      email: Yup.string().required().email().label(i18n.t('common:email')),
      phone: Yup.string()
        .nullable(false)
        .min(13, i18n.t('inputErrors:invalidPhone'))
        .max(14, i18n.t('inputErrors:invalidPhone'))
        .label(i18n.t('common:phone'))
    })
  )
}

const customRetailsFiltersSchema = () => {
  return Yup.array().of(
    Yup.object().shape({
      customColumnFilter: Yup.string()
        .test(
          'customColumnFilter',
          i18n.t('customFilters:errorColumn'),
          (value, props) => {
            if (props.parent.customColumnValue && !value) return false
            if (!props.path.includes('[0]') && !value) return false
            return true
          }
        )
        .nullable(),
      customColumnValue: Yup.string()
        .test(
          'customColumnValue',
          i18n.t('customFilters:errorValue'),
          (value, props) => {
            if (!props.parent.customColumnFilter) return true
            if (props.parent.customColumnFilter && value) return true
            if (!props.path.includes('[0]') && !value) return false
            return false
          }
        )
        .nullable(),
      retailCnpj: Yup.string().nullable()
    })
  )
}

export const ClientFormSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required().label(i18n.t('common:socialReason')),
    fancyName: Yup.string().required().label(i18n.t('common:fantasyName')),
    inspectionType: Yup.mixed().test(
      'inspection_type',
      i18n.t('inputErrors:typeInspectionRequired'),
      (value, props) => {
        if (props.parent.inspectionNum !== '') {
          const sc = Yup.string().oneOf([
            i18n.t('common:SIF'),
            i18n.t('common:SIE'),
            i18n.t('common:SIM')
          ])
          return sc.isValidSync(value)
        }
        return true
      }
    ),
    inspectionNum: Yup.mixed().test(
      'inspection_num',
      i18n.t('inputErrors:typeInspectionRequired'),
      (value, props) => {
        if (props.parent.inspectionType !== '') {
          const sc = Yup.string().required()
          return sc.isValidSync(value)
        }
        return true
      }
    ),
    cnpj: Yup.string()
      .required()
      .test('cnpj', i18n.t('inputErrors:invalidCNPJ'), (value, props) => {
        if (value && props.parent.documentType === 'nacional') {
          return validaCNPJ(value)
        } else return value.length > 0
      })
      .label(i18n.t('common:CNPJ')),
    ie: Yup.string()
      .nullable(true)
      .test('state', i18n.t('inputErrors:isRequired'), (value, props) => {
        if (props.parent.documentType === 'nacional') return value.length >= 1
        return true
      })
      .label(i18n.t('common:stateRegistration')),
    status: Yup.boolean()
      .nullable(true)
      .required()
      .label(i18n.t('common:status')),
    address: Yup.string().nullable(true).label(i18n.t('common:address')),
    state: Yup.string().nullable(true).label(i18n.t('common:state')),
    city: Yup.string().nullable(true).label(i18n.t('common:city')),
    cep: Yup.string().nullable(true).label(i18n.t('common:CEP')),
    lat: Yup.number()
      .nullable(true)
      .label(i18n.t('common:latitude'))
      .typeError(i18n.t('inputErrors:invalidLatitude')),
    lng: Yup.number()
      .nullable(true)
      .label(i18n.t('common:longitude'))
      .typeError(i18n.t('inputErrors:invalidLongitude')),
    qualifications: Yup.array().label(i18n.t('common:license')),
    contacts: contactsSchema(),
    customRetailsFilters: customRetailsFiltersSchema()
  })
}

import * as Yup from 'yup'

// const partnerFormSchema = () => {
//   return Yup.object().shape({
//     id: Yup.string().nullable(true),
//     partnerName: Yup.string().nullable(true)
//   })
// }

export const LineProductFormSchema = Yup.object().shape({
  productLineName: Yup.string().required(),
  partner: Yup.string().nullable(true).required()
})

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { omit } from 'lodash'

import {
  Button,
  ContactForm,
  FormActionRow,
  HeaderBlock,
  Location
} from 'components'
import { AditionalInformation } from './AditionalInformation/AditionalInformation'
import { BasicInformation } from './BasicInformation/BasicInformation'
import { ProducerForm } from './ProducerForm'

import {
  ERRORS_INITIAL_STATE,
  INITIAL_STATE
} from 'constants/property.constants'
import {
  ERRORS_CONTACT_STATE,
  INITIAL_CONTACT_STATE
} from 'constants/contact.constants'
import {
  ERRORS_PRODUCER_STATE,
  INITIAL_PRODUCER_STATE
} from 'constants/producer.constants'

import { YupValidator } from 'services/yupValidator.service'

import { propertyActions } from 'store/Property'
import { PropertyFormSchema } from 'store/Property/Property.validator'
import { CityActions } from 'store/City'
import { all } from 'store/Property/Property.selector'
import { all as allCity } from 'store/City/City.selector'

import { mapErrors } from 'utils/MapErrors'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

interface ParamTypes {
  id: string
}

export function Form() {
  const { t } = useTranslation()
  const { id } = useParams<ParamTypes>()

  const { goBack } = useHistory()
  const dispatch = useDispatch()

  const [form, setForm] = useState({ ...INITIAL_STATE })
  const [errors, setErrors] = useState({ ...ERRORS_INITIAL_STATE })

  const { property, propertyCreate, propertyEdit } = useSelector(all)
  const { cities } = useSelector(allCity)

  useEffect(() => {
    if (id) {
      dispatch(propertyActions.fetchOne(id))
    }
  }, [dispatch, id])

  useEffect(() => {
    if (propertyCreate.id || propertyEdit.id) {
      goBack()
      dispatch(propertyActions.cleanProperty())
    }
  }, [propertyCreate, propertyEdit, dispatch, goBack])

  useEffect(() => {
    if (form.state) {
      dispatch(CityActions.fetchByUf({ uf: form.state }))
    }
  }, [form.state, dispatch])

  useEffect(() => {
    if (Object.keys(property).length) {
      const _form = property as typeof INITIAL_STATE
      const contacts = []
      if (property.contacts && property.contacts.length) {
        property.contacts.forEach(contact => contacts.push({ ...contact }))
      } else {
        contacts.push(INITIAL_CONTACT_STATE)
      }
      _form.contacts = contacts

      const producers = []
      if (property.producers && property.producers.length) {
        property.producers.forEach(producer => producers.push({ ...producer }))
      } else {
        contacts.push(INITIAL_PRODUCER_STATE)
      }
      _form.producers = producers

      if (_form.lat === null) {
        _form.lat = ''
      }

      if (_form.lng === null) {
        _form.lng = ''
      }

      setErrors({
        ...ERRORS_INITIAL_STATE,
        contacts: _form.contacts.map(_contact => ERRORS_CONTACT_STATE),
        producers: _form.producers.map(_producer => ERRORS_PRODUCER_STATE)
      })

      setForm({ ..._form })
    }
  }, [property])

  function handleInput(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setForm({ ...form, [name]: value })
  }

  function handleSelected(name: string) {
    return function handle(value) {
      setErrors({ ...errors, [name]: '' })
      if (name === 'state') {
        setForm({ ...form, [name]: value.value, city: '' })
      } else {
        setForm({ ...form, [name]: value.value })
      }
    }
  }

  function handleSelectedIndex(name: string) {
    return function handle(key, val, index) {
      const updated = [...form?.[name]]
      updated[index] = {
        ...updated[index],
        [key]: val.value
      }
      setForm({ ...form, [name]: updated })
    }
  }

  function handleAddItem(name: string) {
    return function handle() {
      const dataErrors =
        errors?.[name]?.length > 0 ? errors?.[name] : mapErrors(errors, name)

      const initialErrors =
        name === 'contacts' ? ERRORS_CONTACT_STATE : ERRORS_PRODUCER_STATE

      const initialState =
        name === 'contacts' ? INITIAL_CONTACT_STATE : INITIAL_PRODUCER_STATE

      setErrors({
        ...errors,
        [name]: [...dataErrors, { ...initialErrors }]
      })

      setForm({
        ...form,
        [name]: [...form?.[name], { ...initialState }]
      })
    }
  }

  function handleFill(name: string) {
    return function handle(key, val, index) {
      const updated = [...form?.[name]]

      updated[index] = {
        ...updated[index],
        [key]: val
      }

      setForm({ ...form, [name]: updated })
    }
  }

  function handleRemove(name: string, index: number) {
    const data =
      errors?.[name]?.length > 0 ? errors?.[name] : mapErrors(errors, name)
    const itemError = data

    const item = form?.[name]

    if (item.length === 1) {
      setForm({ ...form, [name]: [] })
      setErrors({ ...errors, [name]: [] })
      return
    }

    const updated = [...item]
    const updatedErrors = [...itemError]

    updated.splice(index, 1)
    updatedErrors.splice(index, 1)

    setErrors({ ...errors, [name]: updatedErrors })
    setForm({ ...form, [name]: updated })
  }

  function onExit() {
    goBack()
    dispatch(propertyActions.cleanProperty())
  }

  async function onSubmit() {
    setErrors({
      ...ERRORS_INITIAL_STATE,
      contacts: form.contacts.map(_contact => ERRORS_CONTACT_STATE),
      producers: form.producers.map(_producer => ERRORS_PRODUCER_STATE)
    })

    const _form = {
      ...form,
      lat: form.lat || null,
      lng: form.lng || null
    }

    const [isValid, validationError] = await new YupValidator(
      PropertyFormSchema()
    ).validate(_form)

    if (!isValid) {
      return setErrors(validationError as typeof ERRORS_INITIAL_STATE)
    }

    const _producers = []
    _form.producers.forEach(producer =>
      _producers.push({
        ...producer,
        reason: producer.blockStatus ? producer.reason : ''
      })
    )

    const _contacts = []
    _form.contacts.forEach(contact => _contacts.push({ ...contact }))

    const payload = {
      ...omit(_form, ['state', 'city']),
      cityId: _form.city,
      contacts: _contacts,
      producers: _producers
    }

    dispatch(
      id
        ? propertyActions.editProperty(payload)
        : propertyActions.createProperty(payload)
    )
  }

  return (
    <S.Wrapper container>
      <S.FullGrid item xs={12}>
        <S.Fieldset>
          <HeaderBlock title={t('breadcrumb:propertyInformation')} />

          <BasicInformation
            payload={form}
            errors={errors}
            handleInput={handleInput}
          />
        </S.Fieldset>

        <S.Fieldset>
          <HeaderBlock title={t('breadcrumb:InformationOrgans')} />

          <AditionalInformation
            payload={form}
            errors={errors}
            handleInput={handleInput}
          />
        </S.Fieldset>

        <S.Fieldset>
          <HeaderBlock title={t('common:localization')} />

          <Location
            cities={cities}
            errors={errors}
            payload={form}
            handleInput={handleInput}
            handleSelected={handleSelected}
          />
        </S.Fieldset>

        <S.Fieldset>
          <HeaderBlock title={t('breadcrumb:producers')} />

          {form.producers.map((producer, i) => (
            <ProducerForm
              errors={errors}
              index={i}
              key={i}
              payload={producer}
              onFill={handleFill('producers')}
              removeButton={Boolean(form.producers.length > 1)}
              onRemove={() => handleRemove('producers', i)}
              handleSelected={handleSelectedIndex('producers')}
            />
          ))}

          {!id && (
            <S.GridFields container spacing={2}>
              <S.GridButtonsAdd item sm={12}>
                <Button variant="primary" onClick={handleAddItem('producers')}>
                  {t('common:addProducer')}
                </Button>
              </S.GridButtonsAdd>
            </S.GridFields>
          )}
        </S.Fieldset>

        <S.Fieldset>
          <HeaderBlock title={t('breadcrumb:contactDetails')} />

          {form.contacts.map((contact, i) => (
            <ContactForm
              errors={errors}
              index={i}
              key={i}
              payload={contact}
              onFill={handleFill('contacts')}
              removeButton={Boolean(form.contacts.length > 1)}
              onRemove={() => handleRemove('contacts', i)}
            />
          ))}

          <S.GridFields container spacing={2}>
            <S.GridButtonsAdd item sm={12}>
              <Button variant="primary" onClick={handleAddItem('contacts')}>
                {t('users:addContact')}
              </Button>
            </S.GridButtonsAdd>
          </S.GridFields>
        </S.Fieldset>
      </S.FullGrid>

      <FormActionRow onExit={onExit} onSubmit={onSubmit} />
    </S.Wrapper>
  )
}

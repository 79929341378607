import { HTTP } from 'providers'

export class PropertyLogService extends HTTP {
  private endpoint = '/propertyLog'

  public fetchAllLogs({ params = {} } = {}) {
    return this.get({
      endpoint: this.endpoint,
      params
    })
  }
}

import * as S from './styled'
import { useTranslation } from 'react-i18next'

export function GroupTable({ rows }) {
  const { t } = useTranslation()
  return (
    <S.Wrapper>
      <S.Table>
        <tbody>
          <S.Line id="header">
            <S.Header>{t('clientGroup:groupName')}</S.Header>
            <S.Header>{t('clientGroup:groupAdmin')}</S.Header>
            <S.Header>{t('clientGroup:adminEmail')}</S.Header>
            <S.Header>{t('clientGroup:view')}</S.Header>
          </S.Line>
          {rows.map(row => (
            <S.Line id="values" key={row.id}>
              <S.Column>{row.name}</S.Column>
              <S.Column>{row.admin.name}</S.Column>
              <S.Column>{row.admin.email}</S.Column>
              <S.Column>{row.visualization}</S.Column>
            </S.Line>
          ))}
        </tbody>
      </S.Table>
    </S.Wrapper>
  )
}

import { Input } from 'components/Input'
import { useTranslation } from 'react-i18next'

import {
  ERRORS_INITIAL_STATE,
  INITIAL_STATE
} from 'constants/property.constants'

import * as S from '../styled'

type BasicInformationType = {
  errors: typeof ERRORS_INITIAL_STATE
  payload: typeof INITIAL_STATE
  handleInput: (_value, _name) => void
}

export function BasicInformation({
  errors,
  payload,
  handleInput
}: BasicInformationType) {
  const { t } = useTranslation()
  return (
    <S.GridFields container spacing={2}>
      <S.GridInput item sm={12} md={6}>
        <Input
          fullWidth
          label={t('common:socialReason')}
          value={payload.name}
          error={Boolean(errors.name)}
          helperText={errors.name}
          onInput={value => handleInput(value, 'name')}
        />
      </S.GridInput>

      <S.GridInput item sm={12} md={6}>
        <Input
          fullWidth
          label={t('common:fantasyName')}
          value={payload.fancyName}
          error={Boolean(errors.fancyName)}
          helperText={errors.fancyName}
          onInput={value => handleInput(value, 'fancyName')}
        />
      </S.GridInput>

      <S.GridInput item sm={12} md={12}>
        <Input
          fullWidth
          label={t('common:about')}
          value={payload.description}
          error={Boolean(errors.description)}
          helperText={errors.description}
          multiline
          rows={3}
          onInput={value => handleInput(value, 'description')}
        />
      </S.GridInput>
    </S.GridFields>
  )
}

import { theme } from 'config'
import { THEMES_NAME } from 'config/theme.config'
import { Base } from 'layouts'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { UserStorageService } from 'services'
import { USER_ROLES } from 'constants/user.constants'
import * as S from './styled'

export function Help() {
  const { t } = useTranslation()
  const { user } = UserStorageService.getUserData()

  const urlDemo = 'https://ecotrace7509.zendesk.com/hc/pt-br'

  // Link URL Zendesk JBS
  // const baseUrlJBS = 'https://suporte-ecotrace-jbs.zendesk.com/hc/'
  // const finalUrlJBS = '/categories/6158401397915-B2B-Friboi'
  // const baseUrlStandard = 'https://suporte-ecotrace.zendesk.com/hc/'
  // const finalUrlStandard = '/categories/4520365383323-Plataforma-ECO-B2B'
  // const finalUrlRetail =
  //   '/categories/6634271928347-Garantia-de-Origem-Friboi-Varejo'

  const lang = localStorage.getItem('i18nextLng')
  useEffect(() => {
    if (theme.appTheme === THEMES_NAME.jbsFriboi) {
      if (user.tag === USER_ROLES.viewer) {
        if (lang === 'ch') {
          const urlHelpDeskRetailCN = `${urlDemo}`
          window.open(urlHelpDeskRetailCN, '_blank')
          window.focus()
          return window.history.back()
        } else if (lang === 'es') {
          const urlHelpDeskRetailAR = `${urlDemo}`
          window.open(urlHelpDeskRetailAR, '_blank')
          window.focus()
          return window.history.back()
        } else if (lang === 'en-US') {
          const urlHelpDeskRetailEN = `${urlDemo}`
          window.open(urlHelpDeskRetailEN, '_blank')
          window.focus()
          return window.history.back()
        } else {
          const urlHelpDeskRetailBR = `${urlDemo}`
          window.open(urlHelpDeskRetailBR, '_blank')
          window.focus()
          return window.history.back()
        }
      } else {
        if (lang === 'ch') {
          const urlHelpDeskJSBAdmCH = `${urlDemo}`
          window.open(urlHelpDeskJSBAdmCH, '_blank')
          window.focus()
          return window.history.back()
        } else if (lang === 'es') {
          const urlHelpDeskJSBAdmAR = `${urlDemo}`
          window.open(urlHelpDeskJSBAdmAR, '_blank')
          window.focus()
          return window.history.back()
        } else if (lang === 'en-US') {
          const urlHelpDeskJSBAdmEN = `${urlDemo}`
          window.open(urlHelpDeskJSBAdmEN, '_blank')
          window.focus()
          return window.history.back()
        } else {
          const urlHelpDeskJSBAdmBR = `${urlDemo}`
          window.open(urlHelpDeskJSBAdmBR, '_blank')
          window.focus()
          return window.history.back()
        }
      }
    } else {
      if (lang === 'ch') {
        const urlHelpDeskStandardCH = `${urlDemo}`
        window.open(urlHelpDeskStandardCH, '_blank')
        window.focus()
        return window.history.back()
      } else if (lang === 'es') {
        const urlHelpDeskStandardAR = `${urlDemo}`
        window.open(urlHelpDeskStandardAR, '_blank')
        window.focus()
        return window.history.back()
      } else if (lang === 'en-US') {
        const urlHelpDeskStandardEN = `${urlDemo}`
        window.open(urlHelpDeskStandardEN, '_blank')
        window.focus()
        return window.history.back()
      } else {
        const urlHelpDeskStandardBR = `${urlDemo}`
        window.open(urlHelpDeskStandardBR, '_blank')
        window.focus()
        return window.history.back()
      }
    }
  }, [lang, user.tag])
  return (
    <Base>
      <S.GridContainer container>
        <h1>{t('breadcrumb:helpDesk')}</h1>
      </S.GridContainer>
    </Base>
  )
}

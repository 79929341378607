/* eslint-disable no-unused-vars */
import jwt from 'jwt-decode'
import { GoTo } from 'utils/goto'

type UserData = {
  id: string
  user: Record<string, any>
  profile: { avatar: string; name: string }
  tag: string
}

const getDataFromLocalStorage = () => {
  const userFromLocalStorage = localStorage.getItem('@user')
  if (userFromLocalStorage) {
    const user = JSON.parse(userFromLocalStorage)
    const userJWT: Object = jwt(user.token)
    const profile = { name: user.profile.name, avatar: user.avatar }
    return { ...userJWT, user, profile } as UserData
  }
  return null
}

export const UserStorageService = {
  isAuthenticated: () => !!localStorage.getItem('@user'),
  isViewer: user => user.tag === 'viewer',
  signIn: user => localStorage.setItem('@user', user),
  signOut: () => localStorage.removeItem('@user'),
  getToken: () => localStorage.getItem('@user'),
  getUserDateForLangDropdown: () => getDataFromLocalStorage(),
  getUserData: (): UserData => {
    const isHaveUserDate = getDataFromLocalStorage()
    if (isHaveUserDate) return isHaveUserDate
    GoTo.login()
  }
}

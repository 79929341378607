import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const Wrapper = styled(Grid)`
  margin-bottom: 7.4rem;
`

export const FullGrid = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 5px 25px #0000000d;
  border-radius: 1.2rem;
  padding: 2.4rem;
`
export const GridHeader = styled(Grid)`
  display: inline-flexbox;
  padding: 0 0 2.4rem 0.7rem;
`
export const GridTitle = styled(Grid)`
  display: block;
  padding: 1rem 0 0 0;
`
export const BoxTitle = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.4rem;
  font-weight: 600;
  padding: 0.7rem 0 0 0.3rem;
`
export const Fieldset = styled.div`
  margin-bottom: 1.6rem;
`
export const GridFields = styled(Grid)`
  display: flex;
  margin: -16px;
  width: calc(100% + 24px);
  flex-direction: row;
`
export const GridInput = styled(Grid)``

export const GridButtons = styled(Grid)`
  align-self: center;
  text-align: end;
  margin: 3.2rem 0;

  button {
    margin: 0;
  }

  button + button {
    margin-left: 2.4rem;
  }
`

export const StyleAutoComplete = styled(Grid)`
  margin-left: 12px;
  margin-right: -8px;
`
export const StyleDiv = styled(Grid)`
  padding-right: 10px;
`

import { useSelector } from 'react-redux'
import { all } from 'store/TraceabilityTables/TraceabilityTables.selector'
import { useTranslation } from 'react-i18next'

import { BarChart } from 'components'
import * as S from './styled'
import { theme } from 'config'
import { THEMES_NAME } from 'config/theme.config'

export function TonsAndVolumeCharts() {
  const { t } = useTranslation()
  const monthsTranslate = t('common:months')
  const amountTranslate = t('common:amount')

  const { trackedTonsByMonth, volumeDeliveredByMonth, chartsLoading } =
    useSelector(all)

  const trackedTonsSeries = {
    name: monthsTranslate,
    data: trackedTonsByMonth
  }

  const volumeDeliveredSeries = {
    name: monthsTranslate,
    data: volumeDeliveredByMonth
  }

  return (
    <S.Wrapper item xs={12} md={5} container>
      <S.GridHorizontalDate item xs={12}>
        <BarChart
          color={theme.appTheme === THEMES_NAME.swift ? '#F39C12' : '#7aaae0'}
          type="column"
          title={t('common:tonsProduced')}
          subTitle=""
          series={trackedTonsSeries}
          tooltipSuffix={t('common:tonne')}
          xAxisTitle={monthsTranslate}
          yAxisTitle={amountTranslate}
          height={275}
          isLoading={chartsLoading}
        />
      </S.GridHorizontalDate>
      <S.GridHorizontalDate item xs={12}>
        <BarChart
          color={theme.appTheme === THEMES_NAME.swift ? '#3D3935' : '#30bbc5'}
          type="column"
          title={t('common:volumesDelivered')}
          subTitle=""
          series={volumeDeliveredSeries}
          tooltipSuffix={t('common:volumeDelivered')}
          xAxisTitle={monthsTranslate}
          yAxisTitle={amountTranslate}
          height={275}
          isLoading={chartsLoading}
        />
      </S.GridHorizontalDate>
    </S.Wrapper>
  )
}

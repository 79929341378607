import { get } from 'lodash'

import { types } from './OrderHistory.actions'
import {
  INITIAL_STATE,
  OrderHistoriesType
} from 'constants/orderHistory.constants'

function setOrderHistory(state: OrderHistoriesType) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.SET_ORDERHISTORY]: setOrderHistory(state),
    [types.CLEAR_ORDERHISTORY]: setOrderHistory(state),
    _default: () => state
  }
  return get(_handlers, type, _handlers._default)(payload)
}

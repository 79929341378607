import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const Wrapper = styled(Grid)`
  margin-bottom: 7.4rem;
`

export const GridHeader = styled(Grid)`
  display: inline-flexbox;
  padding: 0.5rem 0 0.7rem 0;
`

export const GridTitle = styled(Grid)`
  display: block;
  padding: 0.5rem 0 1rem 0;
`

export const BoxTitle = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.4rem;
  font-weight: 600;
  padding-bottom: 1rem;
`

export const GridFilter = styled(Grid)`
  display: flex;
  margin: -16px;
  width: calc(100% + 24px);
  flex-direction: row;
`

export const GridNotification = styled(Grid)`
  display: flex;
  margin: -16px;
  width: calc(100% + 24px);
`

export const FullGrid = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 5px 25px #0000000d;
  border-radius: 1.2rem;
  padding: 1.2rem 2rem 2.4rem 2.8rem;
  .MuiGrid-spacing-xs-2 > .MuiGrid-item {
    padding: 4px;
  }
`

export const GridInputs = styled(Grid)`
  display: flex;
`

export const GridButtons = styled(Grid)`
  align-self: center;
  text-align: end;
  margin: 3.2rem 0;

  button {
    margin: 0;
  }

  button + button {
    margin-left: 2.4rem;
  }
`
export const GridCheck = styled(Grid)``

export const GridInput = styled(Grid)``

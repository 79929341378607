import * as Yup from 'yup'
import { cpfCnpj } from 'utils/cpfCnpjValidator'
import i18n from 'i18n'

const contactsSchema = () => {
  return Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required().label(i18n.t('common:name')),
      email: Yup.string().required().email().label(i18n.t('common:email')),
      phone: Yup.string()
        .nullable(false)
        .min(13, i18n.t('inputErrors:invalidPhone'))
        .max(14, i18n.t('inputErrors:invalidPhone'))
        .label(i18n.t('common:phone'))
    })
  )
}
const producersSchema = () => {
  return Yup.array().of(
    Yup.object().shape({
      CPFCNPJ: cpfCnpj.verify().required().label(i18n.t('common:cpfCNPJ')),
      name: Yup.string().required().label(i18n.t('common:name')),
      IE: Yup.mixed().test(
        'IE',
        i18n.t('inputErrors:IERequired'),
        (value, props) => {
          if (!props.parent.free) {
            const fieldValidation = Yup.string()
              .required()
              .oneOf([props.parent.IE])
            return fieldValidation.isValidSync(value)
          }
          return true
        }
      ),
      blockStatus: Yup.boolean()
        .nullable(true)
        .required()
        .label(i18n.t('common:situation')),
      reason: Yup.mixed().test(
        'reason',
        i18n.t('inputErrors:reasonRequiredToBlock'),
        (value, props) => {
          if (props.parent.blockStatus === true) {
            const sc = Yup.string().required().oneOf([props.parent.reason])
            return sc.isValidSync(value)
          }
          return true
        }
      )
    })
  )
}

export const PropertyFormSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required().label(i18n.t('common:socialReason')),
    fancyName: Yup.string().required().label(i18n.t('common:fantasyName')),

    CAR: Yup.string().required().label(i18n.t('common:CAR')),

    address: Yup.string().required().label(i18n.t('common:address')),
    state: Yup.string().required().label(i18n.t('common:state')),
    city: Yup.string().required().label('common:city'),
    cep: Yup.string()
      .required()
      .min(10, i18n.t('inputErrors:invalidZIPCode'))
      .label(i18n.t('common:CEP')),

    contacts: contactsSchema(),
    producers: producersSchema()
  })
}

export const AddProducerFormSchema = () => {
  return Yup.object().shape({
    producers: producersSchema()
  })
}

import styled from 'styled-components'

export const GridInfoBlock = styled.div`
  font-size: 1.4rem;
  line-height: 3.2rem;
`

export const GridInfoLabel = styled.label`
  font-weight: bold;
`

export const GridInfoValue = styled.span``

import { GeneralFilters } from 'components/GeneralFilters'
import { Base } from 'layouts'
import { ConsolidationCards } from './ConsolidationCards'

export function ConsolidationHomePage() {
  return (
    <Base>
      <GeneralFilters />
      <ConsolidationCards />
    </Base>
  )
}

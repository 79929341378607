import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  StoreEnhancer,
  Reducer
} from 'redux'
import createSagaMiddleware from 'redux-saga'
import { all, takeLatest } from 'redux-saga/effects'

import { tron } from 'config'

import { box, BoxActions, BoxSagas } from './Box'
import {
  rawMaterial,
  RawMaterialActions,
  RawMaterialSagas
} from './RawMaterial'
import { breadcrumbs } from './Breadcrumbs'
import { city, CityActions, CitySagas } from './City'
import { client, clientActions, clientSagas } from './Client'
import { partnerActions, partners, partnerSaga } from './Partners'
import { productActions, product, productSaga } from './Product'
import { productLineActions, productLine, productLineSaga } from './ProductLine'
import { nutriTableActions, nutriTable, nutriTableSaga } from './NutriTable'
import {
  clientGroup,
  clientGroupActions,
  clientGroupSagas
} from './ClientGroup'
import { industry, industryActions, industrySagas } from './Industry'
import {
  industryGroup,
  industryGroupActions,
  industryGroupSagas
} from './IndustryGroup'
import { order, OrderActions, OrderSagas } from './Order'
import { tableSIF, TableSIFActions, TableSIFSagas } from './TableSIF'
import {
  orderHistory,
  OrderHistoryActions,
  OrderHistorySagas
} from './OrderHistory'
import { properties, PropertiesActions, PropertiesSagas } from './Properties'
import { property, propertyActions, propertySagas } from './Property'
import {
  propertyTmp,
  propertyTmpActions,
  propertyTmpSagas
} from './PropertyTmp'
import { foodSafety, FoodSafetyActions, FoodSafetySagas } from './FoodSafety'
import { sidebar } from './Sidebar'
import { traceabilityMap } from './TraceabilityMap'
import {
  traceabilityCharts,
  traceabilityChartsActions,
  traceabilityChartsSagas
} from './TraceabilityCharts'
import {
  traceabilityTables,
  traceabilityTablesActions,
  traceabilityTablesSagas
} from './TraceabilityTables'
import { user, userActions, userSagas } from './User'
import { userRole, userRoleActions, userRoleSagas } from './UserRole'
import { spinner } from './Spinner'
import { versionApi } from './Version'
import {
  propertiesDetails,
  propertiesDetailsActions,
  propertiesDetailsSagas
} from './PropertiesDetails'
import { clearReduxStateAction } from './ClearReduxState'

export type RootState = {
  box: ReturnType<typeof box>
  breadcrumbs: ReturnType<typeof breadcrumbs>
  city: ReturnType<typeof city>
  client: ReturnType<typeof client>
  partners: ReturnType<typeof partners>
  product: ReturnType<typeof product>
  productLine: ReturnType<typeof productLine>
  nutriTable: ReturnType<typeof nutriTable>
  clientGroup: ReturnType<typeof clientGroup>
  industry: ReturnType<typeof industry>
  industryGroup: ReturnType<typeof industryGroup>
  order: ReturnType<typeof order>
  orderHistory: ReturnType<typeof orderHistory>
  properties: ReturnType<typeof properties>
  property: ReturnType<typeof property>
  propertyTmp: ReturnType<typeof propertyTmp>
  foodSafety: ReturnType<typeof foodSafety>
  sidebar: ReturnType<typeof sidebar>
  traceabilityMap: ReturnType<typeof traceabilityMap>
  traceabilityCharts: ReturnType<typeof traceabilityCharts>
  traceabilityTables: ReturnType<typeof traceabilityTables>
  propertiesDetails: ReturnType<typeof propertiesDetails>
  rawMaterial: ReturnType<typeof rawMaterial>
  user: ReturnType<typeof user>
  userRole: ReturnType<typeof userRole>
  spinner: ReturnType<typeof spinner>
  versionApi: ReturnType<typeof versionApi>
  tableSIF: ReturnType<typeof tableSIF>
}

const saga = createSagaMiddleware()

function* rootSaga() {
  yield all([
    takeLatest(
      clearReduxStateAction.types.CLEAR_REDUX_STATE,
      clearReduxStateAction.clearRedux
    ),

    takeLatest(BoxActions.types.GET_BOXES, BoxSagas.getBoxes),

    takeLatest(
      RawMaterialActions.types.GET_RAW_MATERIAL,
      RawMaterialSagas.getRawMaterial
    ),

    takeLatest(CityActions.types.FETCH_BY_UF, CitySagas.fetchByUF),

    takeLatest(clientActions.types.FETCH_ALL, clientSagas.fetchAll),
    takeLatest(clientActions.types.CREATE_CLIENT, clientSagas.create),
    takeLatest(clientActions.types.FETCH_ONE, clientSagas.fetchOne),
    takeLatest(clientActions.types.EDIT_CLIENT, clientSagas.edit),
    takeLatest(clientActions.types.DELETE_CLIENT, clientSagas.deleteClient),

    takeLatest(partnerActions.types.FETCH_ALL, partnerSaga.fetchAll),
    takeLatest(
      partnerActions.types.FILTER_PARTNERS,
      partnerSaga.filterPartners
    ),
    takeLatest(partnerActions.types.CREATE_PARTNER, partnerSaga.create),
    takeLatest(partnerActions.types.EDIT_PARTNER, partnerSaga.edit),
    takeLatest(partnerActions.types.FETCH_ONE, partnerSaga.fetchOne),
    takeLatest(partnerActions.types.DELETE_PARTNER, partnerSaga.deletePartner),
    takeLatest(
      partnerActions.types.GET_PARTNER_OPTIONS,
      partnerSaga.getPartnerOptions
    ),

    takeLatest(productActions.types.FETCH_ALL, productSaga.fetchAll),
    takeLatest(
      productActions.types.FILTER_PRODUCTS,
      productSaga.filterProducts
    ),
    takeLatest(productActions.types.CREATE_PRODUCT, productSaga.create),
    takeLatest(productActions.types.EDIT_PRODUCT, productSaga.edit),
    takeLatest(productActions.types.FETCH_ONE, productSaga.fetchOne),
    takeLatest(productActions.types.DELETE_PRODUCT, productSaga.deleteProduct),

    takeLatest(productLineActions.types.FETCH_ALL, productLineSaga.fetchAll),
    takeLatest(
      productLineActions.types.FILTER_PRODUCT_LINES,
      productLineSaga.filterProductLines
    ),
    takeLatest(
      productLineActions.types.CREATE_PRODUCT_LINE,
      productLineSaga.create
    ),
    takeLatest(
      productLineActions.types.EDIT_PRODUCT_LINE,
      productLineSaga.edit
    ),
    takeLatest(productLineActions.types.FETCH_ONE, productLineSaga.fetchOne),
    takeLatest(
      productLineActions.types.DELETE_PRODUCT_LINE,
      productLineSaga.deleteProductLine
    ),
    takeLatest(
      productLineActions.types.GET_PRODUCT_LINE_OPTIONS,
      productLineSaga.getProductLineOptions
    ),

    takeLatest(nutriTableActions.types.FETCH_ALL, nutriTableSaga.fetchAll),
    takeLatest(
      nutriTableActions.types.FILTER_NUTRI_TABLE,
      nutriTableSaga.filterNutriTables
    ),
    takeLatest(
      nutriTableActions.types.CREATE_NUTRI_TABLE,
      nutriTableSaga.create
    ),
    takeLatest(nutriTableActions.types.EDIT_NUTRI_TABLE, nutriTableSaga.edit),
    takeLatest(nutriTableActions.types.FETCH_ONE, nutriTableSaga.fetchOne),
    takeLatest(
      nutriTableActions.types.DELETE_NUTRI_TABLE,
      nutriTableSaga.deleteNutriTable
    ),
    takeLatest(
      nutriTableActions.types.GET_NUTRI_TABLE_OPTIONS,
      nutriTableSaga.getNutriTableOptions
    ),

    takeLatest(clientGroupActions.types.FETCH_ALL, clientGroupSagas.fetchAll),
    takeLatest(
      clientGroupActions.types.FILTER_GROUP_CLIENTS,
      clientGroupSagas.filterGroupClients
    ),
    takeLatest(
      clientGroupActions.types.CREATE_CLIENT_GROUP,
      clientGroupSagas.create
    ),
    takeLatest(
      clientGroupActions.types.EDIT_CLIENT_GROUP,
      clientGroupSagas.edit
    ),
    takeLatest(clientGroupActions.types.FETCH_ONE, clientGroupSagas.fetchOne),
    takeLatest(
      clientGroupActions.types.DELETE_CLIENT_GROUP,
      clientGroupSagas.deleteClientGroup
    ),
    takeLatest(
      clientGroupActions.types.UNLINK_WITH_CLIENT,
      clientGroupSagas.unlinkWithClient
    ),
    takeLatest(
      clientGroupActions.types.GET_CLIENT_GROUP_OPTIONS,
      clientGroupSagas.getClientGroupOptions
    ),
    takeLatest(
      clientGroupActions.types.GET_UNRELATED_CLIENTS,
      clientGroupSagas.getUnrelatedClients
    ),
    takeLatest(
      clientGroupActions.types.LINK_WITH_CLIENT,
      clientGroupSagas.linkWithClient
    ),
    takeLatest(
      clientGroupActions.types.GET_CLIENT_GROUP_EXPORT,
      clientGroupSagas.getClientGroupExported
    ),

    takeLatest(industryActions.types.FETCH_ALL, industrySagas.fetchAll),
    takeLatest(industryActions.types.CREATE_INDUSTRY, industrySagas.create),
    takeLatest(industryActions.types.FETCH_ONE, industrySagas.fetchOne),
    takeLatest(industryActions.types.EDIT_INDUSTRY, industrySagas.edit),
    takeLatest(
      industryActions.types.DELETE_INDUSTRY,
      industrySagas.deleteIndustry
    ),

    takeLatest(
      industryGroupActions.types.FETCH_ALL,
      industryGroupSagas.fetchAll
    ),
    takeLatest(
      industryGroupActions.types.FILTER_GROUP_INDUSTRIES,
      industryGroupSagas.filterGroupCompanies
    ),
    takeLatest(
      industryGroupActions.types.CREATE_INDUSTRY_GROUP,
      industryGroupSagas.create
    ),
    takeLatest(
      industryGroupActions.types.EDIT_INDUSTRY_GROUP,
      industryGroupSagas.edit
    ),
    takeLatest(
      industryGroupActions.types.FETCH_ONE,
      industryGroupSagas.fetchOne
    ),
    takeLatest(
      industryGroupActions.types.GET_UNRELATED_INDUSTRIES,
      industryGroupSagas.fetchUnrelatedWithGroup
    ),
    takeLatest(
      industryGroupActions.types.DELETE_INDUSTRY_GROUP,
      industryGroupSagas.deleteIndustryGroup
    ),
    takeLatest(
      industryGroupActions.types.GET_INDUSTRY_GROUP_OPTIONS,
      industryGroupSagas.getIndustryGroupOptions
    ),
    takeLatest(
      industryGroupActions.types.LINK_INDUSTRY_WITH_GROUP,
      industryGroupSagas.linkIndustryWithGroup
    ),
    takeLatest(
      industryGroupActions.types.UNLINK_WITH_GROUP,
      industryGroupSagas.unlinkIndustryWithGroup
    ),

    takeLatest(OrderActions.types.GET_ORDERS, OrderSagas.getOrders),
    takeLatest(
      OrderActions.types.GET_ORDERS_EXPORT,
      OrderSagas.getOrdersExported
    ),
    takeLatest(TableSIFActions.types.GET_TABLE_SIF, TableSIFSagas.getTableSIF),
    takeLatest(
      traceabilityTablesActions.types.GET_KPIS_CARDS,
      traceabilityTablesSagas.getKpisCards
    ),
    takeLatest(
      traceabilityTablesActions.types.GET_KPIS_CHARTS,
      traceabilityTablesSagas.getKpisCharts
    ),
    takeLatest(
      propertiesDetailsActions.types.GET_KPIS_PROPERTIES_DETAILS,
      propertiesDetailsSagas.getKpisPropertiesDetails
    ),
    takeLatest(
      propertiesDetailsActions.types.GET_KPIS_PROPERTIES_SUMMARY,
      propertiesDetailsSagas.getKpisPropertiesSummary
    ),
    takeLatest(
      propertiesDetailsActions.types.GET_KPIS_PROPERTIES_EXPORT,
      propertiesDetailsSagas.getKpisPropertiesExported
    ),
    takeLatest(
      OrderHistoryActions.types.GET_BY_HASHPARTNER,
      OrderHistorySagas.getByHashPartner
    ),

    takeLatest(
      PropertiesActions.types.GET_PROPERTIES,
      PropertiesSagas.getProperties
    ),
    takeLatest(
      PropertiesActions.types.GET_PROPERTIES_LOCATION,
      PropertiesSagas.getPropertiesLocation
    ),

    takeLatest(propertyActions.types.FETCH_ALL, propertySagas.fetchAll),
    takeLatest(propertyActions.types.CREATE_PROPERTY, propertySagas.create),
    takeLatest(propertyActions.types.FETCH_ONE, propertySagas.fetchOne),
    takeLatest(propertyActions.types.EDIT_PROPERTY, propertySagas.edit),
    takeLatest(
      propertyActions.types.DELETE_PROPERTY,
      propertySagas.deleteProperty
    ),
    takeLatest(
      propertyActions.types.FETCH_ALL_LOGS,
      propertySagas.fetchAllLogs
    ),
    takeLatest(
      propertyActions.types.IMPORT_PROPERTIES,
      propertySagas.importProperties
    ),
    takeLatest(propertyActions.types.ADD_PRODUCER, propertySagas.addProducer),
    takeLatest(
      propertyActions.types.BLOCK_STATUS_PRODUCER,
      propertySagas.blockStatusProducer
    ),
    takeLatest(
      propertyActions.types.FETCH_BY_PROPERTY_ID,
      propertySagas.fetchByPropertyId
    ),
    takeLatest(
      propertyActions.types.BLOCK_STATUS_ALL_PRODUCER,
      propertySagas.blockStatusAllProducer
    ),

    takeLatest(propertyTmpActions.types.FETCH_ALL, propertyTmpSagas.fetchAll),
    takeLatest(
      propertyTmpActions.types.DELETE_PROPERTY_TMP,
      propertyTmpSagas.deletePropertyTmp
    ),

    takeLatest(
      FoodSafetyActions.types.GET_FOODSAFETY,
      FoodSafetySagas.getFoodSafety
    ),

    takeLatest(
      traceabilityChartsActions.types.GET_TRACEABILITY_PERIOD,
      traceabilityChartsSagas.getTraceabilityPeriod
    ),
    takeLatest(
      traceabilityChartsActions.types.GET_TRACEABILITY_SPECIFIC,
      traceabilityChartsSagas.getTraceabilitySpecific
    ),
    takeLatest(userActions.types.SEND_EMAIL_PWD, userSagas.sendEmailPwd),
    takeLatest(userActions.types.CHANGE_PASSWORD, userSagas.changePassword),
    takeLatest(userActions.types.CREATE_USER, userSagas.create),
    takeLatest(userActions.types.DELETE_USER, userSagas.deleteUser),
    takeLatest(userActions.types.EDIT_USER, userSagas.edit),
    takeLatest(userActions.types.FETCH_ALL, userSagas.fetchAll),
    takeLatest(userActions.types.FETCH_ONE, userSagas.fetchOne),

    takeLatest(userRoleActions.types.FETCH_ALL, userRoleSagas.fetchAll)
  ])
}

export const appReducer = combineReducers({
  box,
  breadcrumbs,
  city,
  client,
  clientGroup,
  industry,
  industryGroup,
  order,
  orderHistory,
  partners,
  product,
  productLine,
  nutriTable,
  properties,
  property,
  propertyTmp,
  foodSafety,
  sidebar,
  spinner,
  traceabilityCharts,
  traceabilityMap,
  traceabilityTables,
  propertiesDetails,
  rawMaterial,
  user,
  userRole,
  tableSIF,
  versionApi
})

const rootEnhancer: StoreEnhancer = compose(
  ...[applyMiddleware(saga), tron.createEnhancer()].filter(e => e)
)

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === clearReduxStateAction.types.CLEAR_REDUX_STATE) {
    localStorage.removeItem('persist:root')
    state = {} as RootState
  }
  return appReducer(state, action)
}

export const store = createStore(rootReducer, rootEnhancer)
saga.run(rootSaga)

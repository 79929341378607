import { Grid } from '@material-ui/core'

import styled from 'styled-components'
export const Wrapper = styled(Grid)`
  display: flex;
  background: white;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.8rem;
  box-shadow: 0px 5px 25px #0000000d;
  padding: 0.9rem;
  margin-top: 8rem;
`
export const Group = styled.div`
  padding: 0rem 1rem;

  button {
    display: inline-block;
    text-transform: capitalize;
    background: #f2f3f7;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.primary};
    border: 0 !important;
    border-radius: 0.8rem;
    margin: 0.8rem 0 0.8rem 0.4rem;
    & :hover {
      background-color: ${({ theme }) => theme.colors.secondary} !important;
      color: white;
    }
  }
`
export const Title = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.darkGray};
  font-weight: 600;
`
export const WrapperMonth = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
export const WrapperYear = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const GridButtons = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

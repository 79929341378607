import { HeaderBlock } from 'components/HeaderBlock'
import { Header } from '../Header'
import { GeneralBlock } from '../GeneralBlock'
import { OrderHistoryGrid } from '../OrderHistoryGrid'

import * as S from './styled'
import { OrderHistoryType } from 'types/OrderHistory.types'
import { useTranslation } from 'react-i18next'

type PropTypes = {
  orderHistories: OrderHistoryType[]
  spinnerIsVisible?: boolean
}

export function ModalContent({ orderHistories, spinnerIsVisible }: PropTypes) {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <Header title={t('breadcrumb:orderHistoryBlockchain')} />

      <S.Line />

      {orderHistories.length > 0 ? (
        <>
          <HeaderBlock title={t('common:generalData')} />
          <GeneralBlock orderHistory={orderHistories[0]} />
          <OrderHistoryGrid orderHistories={orderHistories} />
        </>
      ) : (
        <>
          {!spinnerIsVisible && (
            <S.NoContent>{t('modalBlockchainHistory:noData')}</S.NoContent>
          )}
        </>
      )}
    </S.Wrapper>
  )
}

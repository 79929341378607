import { format } from 'date-fns'

export default function (filters) {
  const _filters = { ...filters }

  if (_filters.typeDate === 'slaughteredat') {
    if (_filters.begin !== null && _filters.end !== null) {
      _filters.slaughteredat = [
        format(new Date(_filters.begin), 'yyyy-MM-dd'),
        format(new Date(_filters.end), 'yyyy-MM-dd')
      ]
    }
  }
  if (_filters.typeDate === 'boningoutat') {
    if (_filters.begin !== null && _filters.end !== null) {
      _filters.boningoutat = [
        format(new Date(_filters.begin), 'yyyy-MM-dd'),
        format(new Date(_filters.end), 'yyyy-MM-dd')
      ]
    }
  }
  if (_filters.typeDate === 'expiresat') {
    if (_filters.begin !== null && _filters.end !== null) {
      _filters.expiresat = [
        format(new Date(_filters.begin), 'yyyy-MM-dd'),
        format(new Date(_filters.end), 'yyyy-MM-dd')
      ]
    }
  }
  if (_filters.typeDate === 'orderedat') {
    if (_filters.begin !== null && _filters.end !== null) {
      _filters.orderedat = [
        format(new Date(_filters.begin), 'yyyy-MM-dd'),
        format(new Date(_filters.end), 'yyyy-MM-dd')
      ]
    }
  }
  if (_filters.typeDate === 'invoiceat') {
    if (_filters.begin !== null && _filters.end !== null) {
      _filters.invoiceat = [
        format(new Date(_filters.begin), 'yyyy-MM-dd'),
        format(new Date(_filters.end), 'yyyy-MM-dd')
      ]
    }
  }

  if (_filters.producerdoc) {
    _filters.producerdoc = _filters.producerdoc.replace(/\D/g, '')
  }
  if (_filters.destinationcnpj) {
    _filters.destinationcnpj = _filters.destinationcnpj.replace(/\D/g, '')
  }

  delete _filters.typeDate
  delete _filters.begin
  delete _filters.end

  return _filters
}

import { Base } from 'layouts'
import { Form } from '../Form'

import * as S from './styled'

export function PropertyRegister() {
  return (
    <Base>
      <S.GridContainer container>
        <Form />
      </S.GridContainer>
    </Base>
  )
}

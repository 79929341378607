import { call, put } from 'redux-saga/effects'

import { OrderService } from 'services'
import FilteringTraceability from 'utils/FilteringTraceability'

import * as actions from './Order.actions'
import * as actionsKpis from '../TraceabilityTables/TraceabilityTables.actions'

export function* getOrders(action) {
  yield put(actionsKpis.setTableLoading(true))

  const _filters = FilteringTraceability(action.payload.filterOrders)

  const $order = new OrderService()

  const orders = yield call($order.getOrders.bind($order), _filters)

  if (orders.success) {
    yield put(actions.setOrders({ orders: orders.data }))
  } else {
    yield put(
      actions.setOrders({
        orders: { items: [], pagination: { totalItems: 0 } }
      })
    )
    if (orders.status !== 404) {
      yield put(actionsKpis.cleanKpis())
    }
  }

  yield put(actionsKpis.setTableLoading(false))
}

export function* getOrdersExported(action) {
  const _filters = FilteringTraceability(action.payload.filterOrders)
  const $order = new OrderService()

  yield call($order.getOrdersExported.bind($order), _filters)
}

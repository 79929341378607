import { HTTP } from 'providers'
import { NutriTableType } from 'types/NutriTable.types'

export class NutriTableService extends HTTP {
  private endpoint = '/nutri-table'

  public fetchAll({ params = {} } = {}) {
    return this.get({ endpoint: this.endpoint, params })
  }

  public fetchOne(id) {
    return this.get({
      endpoint: `${this.endpoint}/${id}`
    })
  }

  public async create(nutriTable: NutriTableType) {
    return this.post({
      endpoint: this.endpoint,
      payload: nutriTable
    })
  }

  public async edit(nutriTable: NutriTableType) {
    const { id } = nutriTable
    return this.put({
      endpoint: `${this.endpoint}/${id}`,
      payload: nutriTable
    })
  }

  public async deleteNutriTable({ id }) {
    return this.delete({
      endpoint: `${this.endpoint}/${id}`
    })
  }

  public async options() {
    return this.get({ endpoint: `${this.endpoint}/options` })
  }
}

import { HTTP } from 'providers'
import { TraceabilityFilter } from 'types/Traceability.types'

export class RawMaterialService extends HTTP {
  private endpoint = '/packing'

  public async getRawMaterial(params: TraceabilityFilter) {
    const rawMaterial = await this.get({
      endpoint: this.endpoint,
      params
    })
    return rawMaterial
  }
}

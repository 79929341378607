import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { InputCpfCnpj } from 'components/InputCpfCnpj'
import { Pagination } from 'components/Pagination'

import { GROUP_FILTER_INITIAL_STATE } from 'constants/industry.constants'
import { submitOnEnter } from 'utils/submitOnEnter'

import { IndustryGroupFilterTable } from './IndustryGroupFilterTable'

import { all } from 'store/IndustryGroup/IndustryGroup.selector'
import { filterGroupIndustries } from 'store/IndustryGroup/IndustryGroup.actions'
import { FilterClientSchema } from 'store/ClientGroup/ClientGroup.validator'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type IndustryGroupFilterProps = {
  onNewClick?: (_value: unknown) => void
}

export function IndustryGroupFilter({ onNewClick }: IndustryGroupFilterProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { manageIndustryGroup, loading } = useSelector(all)
  const {
    companiesPagination,
    companies: industries,
    id,
    name
  } = manageIndustryGroup

  const [filter, setFilter] = useState({
    ...GROUP_FILTER_INITIAL_STATE,
    limit: 10,
    page: 0
  })
  const [errors, setErrors] = useState({ ...GROUP_FILTER_INITIAL_STATE })

  function onInput(name: string) {
    return function _handle(val) {
      setErrors({ ...errors, [name]: '' })
      setFilter({ ...filter, [name]: val })
    }
  }

  async function submitFilter() {
    try {
      await FilterClientSchema().validate(filter, {
        abortEarly: false,
        stripUnknown: true
      })
      const formatedFilter = {
        name: filter.name,
        fancyName: filter.fancyName,
        cnpj: filter.cnpj.replace(/[^\w\s]/gi, '') || ''
      }
      dispatch(filterGroupIndustries({ groupId: id, params: formatedFilter }))
    } catch (ex) {
      const _errors = Object.fromEntries(
        ex.inner.map(({ path, message }) => {
          return [path, message.replace(`${message} `, '')]
        })
      )
      setErrors({ ...GROUP_FILTER_INITIAL_STATE, ..._errors })
    }
  }

  function onFilterPaginate(params: any = {}) {
    const formatedFilter = {
      ...params,
      name: params.name,
      fancyName: params.fancyName,
      cnpj: params.cnpj.replace(/[^\w\s]/gi, '') || ''
    }
    dispatch(filterGroupIndustries({ groupId: id, params: formatedFilter }))
  }

  function clearFilters() {
    const params = {
      ...GROUP_FILTER_INITIAL_STATE,
      limit: companiesPagination.limit,
      page: companiesPagination.page
    }

    setFilter(params)
    setErrors({ ...GROUP_FILTER_INITIAL_STATE })
    dispatch(
      filterGroupIndustries({
        groupId: id,
        params
      })
    )
  }

  return (
    <S.GridCard>
      <S.GridHeader>
        <S.GridTitle item container xs={12}>
          <S.BoxTitle>{t('common:filterIndustries')}</S.BoxTitle>
          <S.BoxLine />
        </S.GridTitle>
      </S.GridHeader>

      <S.GridFilter container>
        <S.GridInput item md={12} lg={4}>
          <Input
            fullWidth
            label={t('common:socialReason')}
            value={filter.name}
            error={Boolean(errors.name)}
            helperText={errors.name}
            onKeyPress={submitOnEnter(submitFilter)}
            onInput={onInput('name')}
          />
        </S.GridInput>
        <S.GridInput item md={12} lg={4}>
          <Input
            fullWidth
            label={t('common:fantasyName')}
            value={filter.fancyName}
            error={Boolean(errors.fancyName)}
            helperText={errors.fancyName}
            onKeyPress={submitOnEnter(submitFilter)}
            onInput={onInput('fancyName')}
          />
        </S.GridInput>
        <S.GridInput item md={12} lg={4}>
          <InputCpfCnpj
            fullWidth
            type={t('common:CNPJ')}
            value={filter.cnpj}
            error={Boolean(errors.cnpj)}
            helperText={errors.cnpj}
            onKeyPress={submitOnEnter(submitFilter)}
            onInput={onInput('cnpj')}
          />
        </S.GridInput>
        <S.GridButtons item xs={12}>
          <Button variant="default" onClick={clearFilters} size="medium">
            <p>{t('common:clean')}</p>
          </Button>
          <Button variant="primary" onClick={submitFilter} size="medium">
            <p>{t('common:filter')}</p>
          </Button>
        </S.GridButtons>
      </S.GridFilter>

      {!loading && (
        <S.GridTable>
          <IndustryGroupFilterTable
            groupId={id}
            groupName={name}
            items={industries}
            onNewClick={onNewClick}
          />
          {companiesPagination?.totalItems > 0 && (
            <Pagination
              filters={{
                ...filter,
                limit: companiesPagination.limit,
                page: companiesPagination.page
              }}
              limit={companiesPagination.limit}
              page={companiesPagination.page}
              totalItems={companiesPagination.totalItems}
              setFilter={onFilterPaginate}
            />
          )}
        </S.GridTable>
      )}
    </S.GridCard>
  )
}

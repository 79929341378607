import { Divider, Grid } from '@material-ui/core'
import styled from 'styled-components'

export const Wrapper = styled(Grid)`
  display: flex;
  margin: 2.4rem 0 2.4rem 0;
  width: calc(100% + 24px);
  flex-direction: row;

  span {
    font-size: 1.4rem;
    color: #464a53;
    opacity: 1;
    font-weight: 800;
  }
`

export const GridLeft = styled(Grid)`
  display: flex;
  width: calc(100% + 24px);
  padding: 0 1.4rem 0 0.3rem;
`

export const GridRight = styled(Grid)`
  display: flex;
  width: calc(100% + 24px);
  padding: 0 1.4rem;
`

export const GridInput = styled(Grid)``

export const GridHeader = styled(Grid)`
  display: inline-flexbox;
  padding: 0 0 2.4rem 0;
`

export const GridTitle = styled(Grid)`
  display: block;
  padding: 1rem 0 0 0;
`

export const BoxTitle = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.4rem;
  font-weight: 600;
  padding: 0.7rem 0 0 1rem;
`

export const DividerVertical = styled(Divider)`
  background-color: #f2f3f7;
`

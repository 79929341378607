import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const Wrapper = styled(Grid)``

export const GridHorizontalDate = styled(Grid)`
  text-transform: capitalize;
  width: 100%;
  height: 25rem;
`

export const GridHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 2.4rem;
  padding-right: 2.4rem;
  padding-left: 2.4rem;
  padding-bottom: 3.1rem;
`
export const Title = styled.div`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.colorCardGray};
  font-weight: 600;
`
export const BoxTitle = styled.h2``
export const NotFit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    height: 1.5rem;
    width: 1.5rem;
    background: red;
    margin-right: 0.5rem;
    border-radius: 50%;
  }
  p {
    font-size: 1.4rem;
  }
`
export const BoxFit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    height: 1.5rem;
    width: 1.5rem;
    background: green;
    margin-right: 0.5rem;
    border-radius: 50%;
  }
  p {
    font-size: 1.4rem;
  }
`
export const BoxLabels = styled(Grid)`
  display: flex;
  justify-content: space-around;
`

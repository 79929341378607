export const QUALIFICATIONS = [
  { qualificationsName: 'LATAM' },
  { qualificationsName: 'NA' },
  { qualificationsName: 'EUROPA' },
  { qualificationsName: 'ÁSIA' },
  { qualificationsName: 'OCEANIA' }
]

export const CONTACT_INITIAL_STATE = { email: '', name: '', phone: '' }

export const INITIAL_STATE = {
  address: '',
  cep: '',
  city: '',
  cnpj: '',
  contacts: [{ ...CONTACT_INITIAL_STATE }],
  fancyName: '',
  groups: [],
  ie: '',
  inspectionNum: '',
  inspectionType: '',
  lat: null,
  lng: null,
  name: '',
  manager: {
    bio: '',
    doc: '',
    name: '',
    photo: null
  },
  photos: [],
  qualifications: [],
  sifPhoto: null,
  state: '',
  status: true,
  technician: {
    bio: '',
    doc: '',
    name: '',
    photo: null
  }
}

export const ERRORS_INITIAL_STATE = {
  address: '',
  cep: '',
  city: '',
  cnpj: '',
  contacts: [{ ...CONTACT_INITIAL_STATE }],
  fancyName: '',
  ie: '',
  inspectionNum: '',
  inspectionType: '',
  lat: '',
  lng: '',
  name: '',
  manager: {
    bio: '',
    doc: '',
    name: ''
  },
  qualifications: '',
  sifPhoto: null,
  state: '',
  status: null,
  technician: {
    bio: '',
    doc: '',
    name: ''
  }
}

export const GROUP_FILTER_INITIAL_STATE = {
  name: '',
  fancyName: '',
  cnpj: ''
}

import { ChartSIF } from 'components/Charts'
import { AnalisysSIFTable } from 'pages/SIFProduction/Details/AnalisysSIFTable'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { all as allTableSIF } from 'store/TableSIF/TableSIF.selector'
import * as S from './styled'
export function Details() {
  const { t } = useTranslation()
  const { tableSIF } = useSelector(allTableSIF)
  const { pagination } = tableSIF
  return (
    <S.Wrapper>
      <S.Graphic item md={12}>
        <S.GridHeader>
          <S.BoxTitle>
            <S.Title>{t('sifProduction')}</S.Title>
          </S.BoxTitle>
        </S.GridHeader>
        <ChartSIF />
      </S.Graphic>
      <S.Details item md={12}>
        <S.GridHeader>
          <S.BoxTitle>
            <S.Title>{t('analyzeBySIF')}</S.Title>
          </S.BoxTitle>
          <S.Register>
            {pagination.totalItems} {t('register')}
          </S.Register>
        </S.GridHeader>
        <S.Table>
          <AnalisysSIFTable />
        </S.Table>
      </S.Details>
    </S.Wrapper>
  )
}

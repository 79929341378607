import { CardsGeneral } from 'components'
import { Base } from 'layouts'

export function General() {
  return (
    <Base>
      <CardsGeneral />
    </Base>
  )
}

import { useEffect, useRef, useState } from 'react'
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  FeatureGroup,
  Marker,
  Polygon,
  Popup,
  useMap,
  LayersControl
} from 'react-leaflet'
import L, { MapOptions } from 'leaflet'

import icon2x from '../../assets/map/images/marker-icon-2x.png'
import icon from '../../assets/map/images/marker-icon.png'

import * as S from './styled'

const DefaultIcon = L.icon({
  iconRetinaUrl: icon2x,
  iconUrl: icon,
  iconAnchor: [10, 41],
  popupAnchor: [2, -40]
})

L.Marker.prototype.options.icon = DefaultIcon

export type MapProps = {
  data: any
} & MapOptions

const DEFAULT_CENTER = { lat: -17.8, lng: -55 }
const DEFAULT_ZOOM = 4
const DEFAULT_COLOR = '#2b9fd2'

export function Map({ data, ...props }: MapProps) {
  const [center, setCenter] = useState(props.center || DEFAULT_CENTER)
  const [zoom, setZoom] = useState(props.zoom || DEFAULT_ZOOM)

  useEffect(() => {
    setZoom(props.zoom)
  }, [props.zoom])

  useEffect(() => {
    setCenter(props.center)
  }, [props.center])

  function DefaultCenterZoom() {
    const map = useMap()

    useEffect(() => {
      Object.keys(data).length === 0 &&
        map.setView(DEFAULT_CENTER, DEFAULT_ZOOM)

      setTimeout(function () {
        map.invalidateSize()
      }, 400)
    }, [map])
    return null
  }

  function GeoMaps({ data }) {
    const mapRef = useRef(null)
    const groupRef = useRef(null)

    return (
      <>
        {data.geo?.length &&
          data.geo.map(geo => {
            const color = geo.color || DEFAULT_COLOR
            return (
              <FeatureGroup key={geo.id} ref={groupRef}>
                <GeoJSON
                  key={geo.id}
                  ref={mapRef}
                  data={geo}
                  style={{ color }}
                />
              </FeatureGroup>
            )
          })}

        {(data.latlng?.length || data.polygon?.length) && (
          <FeatureGroup ref={groupRef}>
            {data.latlng?.length &&
              data.latlng.map((latlng, i) => {
                return (
                  <Marker key={i} ref={mapRef} position={latlng.position}>
                    <Popup>{latlng.id}</Popup>
                  </Marker>
                )
              })}
            {data.polygon?.length &&
              data.polygon.map((polygon, i) => {
                return <Polygon key={i} ref={mapRef} positions={polygon} />
              })}
          </FeatureGroup>
        )}
      </>
    )
  }

  return (
    <S.Wrapper data-testid="map">
      <S.LeafletContainerMap>
        <MapContainer
          style={{ width: '100%', height: '100%' }}
          zoomControl={true}
          center={center}
          zoom={zoom}
          preferCanvas
          {...props}
        >
          <LayersControl position="bottomright">
            <LayersControl.BaseLayer checked name="Mapa">
              <TileLayer
                maxZoom={18}
                url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_TOKEN_MAPBOX}`}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Satélite">
              <TileLayer
                maxZoom={18}
                url={`https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_TOKEN_MAPBOX}`}
              />
            </LayersControl.BaseLayer>
          </LayersControl>
          {Object.keys(data).length && <GeoMaps data={data} />}
          <DefaultCenterZoom />
        </MapContainer>
      </S.LeafletContainerMap>
    </S.Wrapper>
  )
}

import { FormPwd, SvgSignin } from 'components'
import { useEffect, useState } from 'react'
import { UserService } from 'services'
import { ResetForm } from './ResetForm'
import * as S from './styled'
import { useLocation } from 'react-router-dom'
import { theme } from 'config'
import { useTranslation } from 'react-i18next'
import { ErrorMessageToken } from 'pages/RecoveryPwd/ErrorMessageToken'
import { SuccessResetMessage } from './SuccessResetMessage'
const VISIBLE_SECTION = {
  requestForm: 0,
  errorMessage: 1,
  resetForm: 2,
  resetMessage: 3
}
export function ConfirmRegistration() {
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const [visibleSec, setVisibleSection] = useState(VISIBLE_SECTION.requestForm)
  const [query, setQuery] = useState({})
  const lang = new URLSearchParams(window.location.search.substring(1)).get(
    'language'
  )

  useEffect(() => {
    const raw = new URLSearchParams(window.location.search.substring(1))
    const email = raw.get('email')
    const token = raw.get('token')
    const _query = {}
    if (lang) {
      Object.assign(_query, { lang })
      i18n.changeLanguage(lang)
    }
    if (email) Object.assign(_query, { email })
    if (token) Object.assign(_query, { token })

    if (!Object.keys(query).length && Object.keys(_query).length) {
      setQuery(_query)
      setVisibleSection(VISIBLE_SECTION.resetForm)
    }
  }, [query, location.search, lang, i18n])
  async function onSubmit(payload) {
    const success = await new UserService().confirmRegister({
      ...payload,
      ...query
    })

    if (success.data.code === 'NOT_FOUND') {
      setVisibleSection(VISIBLE_SECTION.errorMessage)
    } else {
      setVisibleSection(VISIBLE_SECTION.resetMessage)
    }
  }
  function GetThemeMode() {
    const THEMES_NAME = {
      standard: (
        <>
          <S.LogoEco />
          <S.Title>{t('authPages:ecoB2BEco')}</S.Title>
        </>
      )
    }

    return THEMES_NAME[theme.appTheme]
  }
  return (
    <FormPwd>
      <S.SideBarCorner>
        <SvgSignin />
      </S.SideBarCorner>
      <S.FormContainer>
        <GetThemeMode />
        {visibleSec === VISIBLE_SECTION.resetForm && (
          <>
            <S.Title>{t('authPages:confirmRegister')}</S.Title>
            <ResetForm onSubmit={onSubmit} />
          </>
        )}
        {visibleSec === VISIBLE_SECTION.resetMessage && <SuccessResetMessage />}
        {visibleSec === VISIBLE_SECTION.errorMessage && <ErrorMessageToken />}
      </S.FormContainer>
    </FormPwd>
  )
}

import { ColumnsProps } from 'components/Table/Table'
import i18n from 'i18n'

export const USER_ROLES = { admin: 'admin', viewer: 'viewer' }

export const YEAR_OFFSET = { admin: 4, viewer: 1, base: 2021 }

export const INITIAL_STATE = {
  companies: [],
  confirmPassword: '',
  CPF: '',
  department: '',
  email: '',
  emailRecovery: '',
  firstName: '',
  lang: '',
  lastName: '',
  password: '',
  phone: '',
  phoneRecovery: '',
  retails: [],
  roleId: '',
  typeNotification: '',
  termOfUse: false,
  lastSignIn: ''
}

export const ERRORS_INITIAL_STATE = {
  companies: '',
  CPF: '',
  confirmPassword: '',
  department: '',
  email: '',
  emailRecovery: '',
  firstName: '',
  lang: '',
  lastName: '',
  password: '',
  phone: '',
  phoneRecovery: '',
  retails: '',
  roleId: '',
  typeNotification: '',
  termOfUse: '',
  lastSignIn: ''
}

export const PASSWORD_INITIAL_STATE = {
  currentPassword: '',
  password: '',
  confirmPassword: ''
}

export const INITIAL_STATE_FILTER = {
  company: '',
  CPF: '',
  department: '',
  email: '',
  limit: 10,
  name: '',
  page: 0,
  phone: '',
  retail: '',
  column: 'name',
  sort: 'asc'
}

const INDUSTRY = (): ColumnsProps[] => [
  { fixed: true, name: 'name', title: i18n.t('tables:name') },
  { align: 'right', name: 'phone', title: i18n.t('tables:mobilePhone') },
  { align: 'right', name: 'CPF', title: i18n.t('tables:documentNumber') },
  { name: 'email', title: i18n.t('tables:email') },
  { name: 'role', title: i18n.t('tables:userType') },
  { name: 'groups', title: i18n.t('tables:groupPermissions') },
  { name: 'department', title: i18n.t('tables:department') }
]

const RETAIL = (): ColumnsProps[] => [
  { fixed: true, name: 'name', title: i18n.t('tables:name') },
  { align: 'right', name: 'phone', title: i18n.t('tables:mobilePhone') },
  { align: 'right', name: 'CPF', title: i18n.t('tables:documentNumber') },
  { name: 'email', title: i18n.t('tables:email') },
  { name: 'role', title: i18n.t('tables:userType') },
  { name: 'department', title: i18n.t('tables:department') }
]

export const COLUMNS = {
  INDUSTRY,
  RETAIL
}

import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  white-space: normal;
`
export const GridHeader = styled.div`
  display: flex;
`
export const BoxTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 0 2.4rem;
`
export const Title = styled.p`
  color: #233762;
  font-size: 1.8rem;
  font-weight: 600;
  padding: 0 1.2rem;
`
export const BoxFit = styled.div`
  display: flex;
`
export const Box = styled.div`
  display: flex;
  align-items: center;
  .iconFit {
    margin-bottom: -5px;
  }
`
export const SubTitle = styled.p`
  color: #464a53;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 0 1.2rem;
`

import { Grid } from '@material-ui/core'
import styled from 'styled-components'

interface IRadioProps {
  marginBottom?: number
}

export const GridFields = styled(Grid)`
  display: flex;
  margin: -16px;
  width: calc(100% + 24px);
  flex-direction: row;
`

export const GridInput = styled(Grid)``

export const RadionContainer = styled.div<IRadioProps>`
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;

  label {
    padding-top: 1px;
    margin-right: 4px;
  }
`

export const RadionButton = styled.input``

import { useCallback, useEffect, useState, MouseEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  Button,
  ModalOrderHistory,
  NewPagination,
  OrderDropdown,
  Table
} from 'components'
import { all } from 'store/TraceabilityTables/TraceabilityTables.selector'
import { all as allOrders } from 'store/Order/Order.selector'
import { OrderActions } from 'store/Order'
import { PropertiesActions } from 'store/Properties'
import { FoodSafetyActions } from 'store/FoodSafety'
import { BoxActions } from 'store/Box'
import { INITIAL_STATE } from 'constants/traceability.constants'

import { USER_ROLES } from 'constants/user.constants'
import { UserStorageService } from 'services'

import { allColumns, columnTrackable } from 'constants/order.constants'
import { useTranslation } from 'react-i18next'

import * as S from './styled'
import { GridHeader } from '../GridHeader'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { ModalSendEmail } from 'components/ModalSendMail'

export function OrderTable() {
  const { t } = useTranslation()
  const { user } = UserStorageService.getUserData()

  const columns =
    user.tag === USER_ROLES.viewer
      ? [...allColumns()]
      : [...columnTrackable(), ...allColumns()]
  const dispatch = useDispatch()

  const { filters, tableLoading } = useSelector(all)
  const { filterOrders, orders, ordersKeys } = useSelector(allOrders)
  const [selectedAll, setSelectedAll] = useState(false)
  const [modalExport, setModalExport] = useState(false)

  const [filter, setFilter] = useState({ ...INITIAL_STATE })
  const [hashPartner, setHashPartner] = useState(null)
  const { pagination } = orders
  const [isNewPage, setNewPage] = useState({ ...pagination })
  const isAllChecked = useCallback(() => {
    const ordersSelectedOnThisPage = orders.items.filter(
      (each: Record<string, any>) => ordersKeys.includes(each.key)
    )

    const isSameSelected =
      ordersSelectedOnThisPage.length === orders.items.length

    if (isSameSelected && orders.items.length > 0) setSelectedAll(true)
    else if (ordersSelectedOnThisPage.length && ordersKeys.length === 50) {
      setSelectedAll(true)
    } else setSelectedAll(false)
  }, [ordersKeys, orders.items])

  useEffect(() => {
    if (!ordersKeys.length) setSelectedAll(false)
  }, [ordersKeys])

  const fetch = useCallback(() => {
    // Trecho comentado por que essa tabela está sendo utilizada em outros locais e que está sendo disparado a action
    // if (!orders.items) dispatch(OrderActions.getOrders(filters))
    if (ordersKeys?.length) isAllChecked()
  }, [isAllChecked, ordersKeys])

  useEffect(() => {
    fetch()
  }, [fetch])

  function onSortColumn(column) {
    const { direction, column: oldColumn } = filterOrders
    let newSort = 'asc'

    if (column === oldColumn && direction === 'asc') newSort = 'desc'

    const newFilter = {
      ...filterOrders,
      ...filters,
      column,
      direction: newSort
    }

    setFilter(newFilter)
    dispatch(OrderActions.setFilterOrder(newFilter))
    dispatch(OrderActions.getOrders(newFilter))
  }

  function actionsOnTable({ hashpartner }) {
    return <OrderDropdown setHashPartner={() => setHashPartner(hashpartner)} />
  }

  function onExitModalLogs() {
    setHashPartner(null)
  }

  function changeColumn({ column, row }) {
    if (column.name === 'percentStatus') {
      return <p>{row.percentStatus}</p>
    } else return row[column.name]
  }

  const setOrdersKeysList = (key: string | string[]) => {
    dispatch(OrderActions.setOrdersKeys(key))
    dispatch(PropertiesActions.cleanProperties())
    dispatch(FoodSafetyActions.cleanFoodSafety())
    dispatch(BoxActions.cleanBoxes())
    isAllChecked()
  }

  function onExport() {
    setModalExport(true)
    dispatch(OrderActions.getOrdersExported(filters))
  }

  async function handleChangePage(
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    const limit = orders.pagination.limit
    const newFilter = {
      ...filterOrders,
      ...filters
    }
    newFilter.page = newPage
    newFilter.limit = limit
    dispatch(OrderActions.setFilterOrder(newFilter))
    dispatch(OrderActions.getOrders(newFilter))
  }
  function handleNewPage() {
    const newPage = isNewPage.page
    if (newPage > pagination.totalPages) {
      const newFilter = {
        ...filterOrders,
        ...filters
      }
      const limit = orders.pagination.limit
      newFilter.limit = limit
      newFilter.page = pagination.totalPages
      dispatch(OrderActions.setFilterOrder(newFilter))
      dispatch(OrderActions.getOrders(newFilter))
      setNewPage({ ...pagination, page: newPage })
    } else {
      const newFilter = {
        ...filterOrders,
        ...filters
      }
      const limit = orders.pagination.limit
      newFilter.page = newPage
      newFilter.limit = limit
      dispatch(OrderActions.setFilterOrder(newFilter))
      dispatch(OrderActions.getOrders(newFilter))
      setNewPage({ ...pagination, page: newPage })
    }
  }
  function handleInputPagination(name: string, value: number) {
    setNewPage({ ...pagination, [name]: value })
  }
  function handleChangeRowsPerPage(e) {
    const newFilter = {
      ...filterOrders,
      ...filters
    }
    newFilter.limit = Number(e.target.value)
    dispatch(OrderActions.setFilterOrder(newFilter))
    dispatch(OrderActions.getOrders(newFilter))
  }
  return (
    <>
      {hashPartner && (
        <ModalOrderHistory hashPartner={hashPartner} onExit={onExitModalLogs} />
      )}
      <S.FullGrid item xs={12}>
        <S.HeaderContainer>
          <S.GridSubtitle>
            <GridHeader title={t('common:invoiceList')} />
          </S.GridSubtitle>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontSize: '1.4rem',
                color: 'gray',
                marginRight: '1.5rem'
              }}
            >
              {orders.pagination.totalItems} {t('common:register')}
            </p>
            <Button variant="secondary" size="medium" onClick={onExport}>
              <S.ButtonContent>
                <CloudDownloadIcon />
                <S.ButtonText>{t('common:export')}</S.ButtonText>
              </S.ButtonContent>
            </Button>
          </div>
        </S.HeaderContainer>

        <S.GridTable>
          <Table
            disableSelectAllOrder={
              ordersKeys.length === 50 &&
              (orders.pagination.page + 1) * orders.items.length > 50
            }
            disableSelectOrder={ordersKeys.length === 50}
            checkOrders
            columns={columns}
            rows={orders.items || []}
            sortColumn={{ column: filter.column, sort: filter.direction }}
            actionTable={actionsOnTable}
            onSortColumn={onSortColumn}
            changeColumn={changeColumn}
            actionColumnLabel={t('common:blockchain')}
            isLoading={tableLoading}
            selectOrdersKeys={setOrdersKeysList}
            checkedKeys={ordersKeys}
            selectedAll={selectedAll}
            isScroll={orders.items?.length > 10}
          />
          {modalExport && (
            <ModalSendEmail
              title={t('modalExportOrders:title')}
              description={t('modalExportOrders:message')}
              onExit={() => setModalExport(false)}
            />
          )}
          {pagination?.totalItems > 0 && (
            <NewPagination
              count={pagination?.totalPages}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 25, 50, 100]}
              handleInput={handleInputPagination}
              handleNewPage={handleNewPage}
              page={pagination?.page}
              limit={pagination.limit}
            />
          )}
        </S.GridTable>
      </S.FullGrid>
    </>
  )
}

import { useState, useRef } from 'react'
import GoogleMapReact from 'google-map-react'
import useSupercluster from 'use-supercluster'
import * as S from './styled'

const Marker = ({ children }: any) => children

export const ClusterMaps = ({ data }) => {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY

  const mapRef = useRef() as any
  const [bounds, setBounds] = useState(null)
  const [zoom, setZoom] = useState(10)

  const points = data.map(property => {
    return {
      type: 'Feature',
      properties: { cluster: false, propertLocationId: property.locationId },
      geometry: {
        type: 'Point',
        coordinates: [property.location.longitude, property.location.latitude]
      }
    }
  })

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 }
  })
  return (
    <S.MapContainer>
      <GoogleMapReact
        bootstrapURLKeys={{ key: apiKey }}
        defaultCenter={{ lat: -15.7801, lng: -47.9292 }}
        defaultZoom={5}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom)
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat
          ])
        }}
      >
        {clusters.map(cluster => {
          const [longitude, latitude] = cluster.geometry.coordinates
          const { cluster: isCluster, point_count: pointCount } =
            cluster.properties

          if (isCluster) {
            return (
              <Marker
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
              >
                <S.ClusterMarker
                  points={points}
                  pointCount={pointCount}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      20
                    )
                    mapRef.current.setZoom(expansionZoom)
                    mapRef.current.panTo({ lat: latitude, lng: longitude })
                  }}
                >
                  {pointCount}
                </S.ClusterMarker>
              </Marker>
            )
          }

          return (
            <Marker
              key={`property-${cluster.properties.propertLocationId}`}
              lat={latitude}
              lng={longitude}
            >
              <S.PropertieMarker>
                <img src="/images/pin-8-24.png" alt="ping_map" />
              </S.PropertieMarker>
            </Marker>
          )
        })}
      </GoogleMapReact>
    </S.MapContainer>
  )
}

import { HTTP } from 'providers'

export class AuthService extends HTTP {
  private endpoint = '/sign-in'
  private config: Record<string, any> = {
    showLoading: true
  }

  public login(payload) {
    return this.post({
      endpoint: this.endpoint,
      payload,
      config: this.config
    })
  }
}

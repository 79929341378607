import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { ClientType } from 'types/Client.types'

import { Checkbox, Icon } from 'components'
import { ClientGroupFilterDropDown } from './ClientGroupFilterDropDown'
import { ModalDelete } from './ModalDelete/ModalDelete'
import { TableNotFoundHint } from 'components/TableNotFoundHint'

import { unlinkWithGroup } from 'store/ClientGroup/ClientGroup.actions'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type ClientGroupFilterTableProps = {
  groupId: string
  groupName: string
  items: ClientType[]
  isUserViewer: boolean
  onNewClick?: (_value: unknown) => void
}

type DeleteSelected = string[]

export function ClientGroupFilterTable({
  groupId,
  groupName,
  items = [],
  isUserViewer,
  onNewClick
}: ClientGroupFilterTableProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [deleteClient, setDeleteClient] = useState({} as ClientType)
  const [deleteSelected, setDeleteSelected] = useState([] as DeleteSelected)
  const [allCheckboxes, setAllCheckboxes] = useState(false)
  const [checkboxes, setCheckboxes] = useState([])

  useEffect(() => {
    if (items.length) {
      if (checkboxes.length === items.length) return setAllCheckboxes(true)
      return setAllCheckboxes(false)
    }
  }, [checkboxes, items])

  function cleanSelectedClients() {
    setCheckboxes([])
  }

  function changeAll() {
    setAllCheckboxes(!allCheckboxes)
    if (!allCheckboxes) return setCheckboxes(items.map(({ id }) => id))
    return cleanSelectedClients()
  }

  function handleCheckbox(id: string) {
    return function handle(val) {
      if (val) return setCheckboxes([...checkboxes, id])

      const newCheckboxes = checkboxes.filter(checkbox => checkbox !== id)
      return setCheckboxes(newCheckboxes)
    }
  }

  function onExit() {
    setDeleteClient({} as ClientType)
    setDeleteSelected([] as DeleteSelected)
  }

  function onRemoveOne() {
    dispatch(unlinkWithGroup({ id: groupId, clientIds: [deleteClient.id] }))
    setDeleteClient({} as ClientType)
    cleanSelectedClients()
    setAllCheckboxes(false)
  }

  function onRemoveMany() {
    dispatch(unlinkWithGroup({ id: groupId, clientIds: deleteSelected }))
    setDeleteSelected([] as DeleteSelected)
    cleanSelectedClients()
    setAllCheckboxes(false)
  }

  function descriptionClientSelect() {
    return `${checkboxes.length} ${t('common:customerSelected')}`
  }

  function actionClientSelect() {
    return t('common:customerRemove')
  }

  return (
    <S.Wrapper data-testid="ClientGroupFilterTable" isUserViewer={isUserViewer}>
      {Object.keys(deleteClient).length ? (
        <ModalDelete
          cnpj={deleteClient.cnpj}
          groupName={deleteClient.name}
          onExit={onExit}
          onRemove={onRemoveOne}
        />
      ) : null}
      {deleteSelected.length ? (
        <ModalDelete
          cnpj={deleteSelected.map(
            id => items.find(({ id: eachId }) => eachId === id).cnpj
          )}
          groupName={groupName}
          onExit={onExit}
          onRemove={onRemoveMany}
        />
      ) : null}
      {items && checkboxes.length ? (
        <S.CheckboxInfoGuide>
          <S.SelectedCheckboxInfo>
            <S.RemoveClientIcon
              title={t('common:clean')}
              onClick={cleanSelectedClients}
            >
              <Icon icon="input-exit" height={12} width={12} />
            </S.RemoveClientIcon>
            <span>{descriptionClientSelect()}</span>
            <S.RemoveClient onClick={() => setDeleteSelected(checkboxes)}>
              {actionClientSelect()}
            </S.RemoveClient>
          </S.SelectedCheckboxInfo>
        </S.CheckboxInfoGuide>
      ) : null}
      <S.TableGroup>
        <S.ScrollWrapper>
          <S.Table id="table-scrolled">
            <tbody>
              <S.Line id="header">
                <S.Header>
                  {!isUserViewer && (
                    <S.CheckboxLine>
                      <Checkbox
                        labelFor=""
                        label=""
                        value="all"
                        checked={allCheckboxes}
                        onCheck={changeAll}
                      />
                      {t('tables:socialReason')}
                    </S.CheckboxLine>
                  )}
                  {isUserViewer && t('tables:socialReason')}
                </S.Header>

                <S.Header>{t('tables:fantasyName')}</S.Header>
                <S.Header>{t('tables:identifier')}</S.Header>
              </S.Line>
              {items.map((each, key) => (
                <S.Line id="values" key={key}>
                  <S.Column>
                    {!isUserViewer && (
                      <S.CheckboxLine>
                        <Checkbox
                          labelFor=""
                          label=""
                          value={each.id}
                          isChecked={
                            !!checkboxes.filter(f => f.includes(each.id)).length
                          }
                          onCheck={handleCheckbox(each.id)}
                        />
                        {each.name}
                      </S.CheckboxLine>
                    )}
                    {isUserViewer && each.name}
                  </S.Column>

                  <S.Column>{each.fancyName}</S.Column>
                  <S.Column>{each.cnpj}</S.Column>
                </S.Line>
              ))}
            </tbody>
          </S.Table>
        </S.ScrollWrapper>
        {!isUserViewer && (
          <S.Table id="table-fixed">
            <tbody>
              <S.Line id="header">
                <S.Header>{t('common:actions')}</S.Header>
              </S.Line>
              {items.map((each, key) => (
                <S.Line key={key}>
                  <S.Column id="actions-column">
                    <ClientGroupFilterDropDown
                      setDeleteClient={() => setDeleteClient(each)}
                    />
                  </S.Column>
                </S.Line>
              ))}
            </tbody>
          </S.Table>
        )}
      </S.TableGroup>
      {!items.length && (
        <TableNotFoundHint onClick={!isUserViewer ? onNewClick : null} />
      )}
    </S.Wrapper>
  )
}

import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const Wrapper = styled(Grid)``

export const GridHorizontalDate = styled(Grid)`
  padding: 1.6rem 2.4rem 0 2.4rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 20px #0000000d;
  border-radius: 1.2rem;
  margin-top: 0.8rem;
  margin-bottom: 3rem;
`

import { get } from 'lodash'
import { TraceabilityMap } from 'types/TraceabilityMap.types'

import { types } from './TraceabilityMap.actions'

const INITIAL_STATE = {
  nodesSelectedChart: [],
  biomesGeoJSON: {
    type: '',
    features: []
  },
  citiesGeoJSON: {
    type: '',
    features: []
  },
  statesGeoJSON: {
    type: '',
    features: []
  }
}

function setStates(state: TraceabilityMap) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.setMarkerMap]: setStates(state),
    [types.setBiomes]: setStates(state),
    [types.setCities]: setStates(state),
    [types.setStates]: setStates(state),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}

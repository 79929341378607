import { TraceabilityFilter } from 'types/Traceability.types'

export const types = Object.freeze({
  GET_FOODSAFETY: '@FOODSAFETY/getFoodSafety',
  SET_FILTERS_FOODSAFETY: '@FOODSAFETY/setFilterFoodSafety',
  SET_FOODSAFETY: '@FOODSAFETY/SetFoodSafety',
  CLEAN_FOODSAFETY: '@FOODSAFETY/cleanFoodSafety'
})

export function getFoodSafety(
  filterFoodSafety?: TraceabilityFilter,
  ordersKeys?: string[]
) {
  return {
    type: types.GET_FOODSAFETY,
    payload: { filterFoodSafety, ordersKeys }
  }
}

export function setFilters(filterFoodSafety: TraceabilityFilter) {
  return { type: types.SET_FILTERS_FOODSAFETY, payload: { filterFoodSafety } }
}

export function cleanFoodSafety() {
  return { type: types.CLEAN_FOODSAFETY }
}

export function setFoodSafety({ foodSafety = {} } = {}) {
  return { type: types.SET_FOODSAFETY, payload: { foodSafety } }
}

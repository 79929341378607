import Autocomplete from '@material-ui/lab/Autocomplete'
import styled from 'styled-components'

export const InputAutocompletePro = styled(Autocomplete).attrs(() => {})`
  padding: 0.8rem;
  .MuiChip-root {
    background-color: #f2f3f7;
    border: 1px solid #f2f3f7;
    border-radius: 2rem;
    color: #464a53;
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;
    width: fit-content;
  }

  .MuiChip-deleteIcon {
    display: none;
  }

  .MuiInputLabel-root {
    font-size: 1.4rem;
    transform: translate(${({ theme }) => theme.spacings.xsmall}, 1.9rem)
      scale(1);
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.71428);
  }

  .MuiAutocomplete-tag {
    font-size: 1.5rem;
    font-weight: 800;
    color: ${({ theme }) => theme.colors.focusForm};
  }
  .MuiAutocomplete-tag > span {
    color: #464a53;
    font-size: 1.4rem;
  }

  .MuiOutlinedInput-input {
    font-size: 1.4rem;
    padding: ${({ padding }) => (padding === 'large' ? '1.57rem' : '1.17rem')} 0;
    height: 0.4rem;

    &.MuiOutlinedInput-inputMultiline {
      padding: ${({ padding }) => (padding === 'large' ? '1.57rem' : '1.17rem')}
        0;
    }
  }
  .MuiFormLabel-root {
    top: -0.4rem;
  }
  label.Mui-focused {
    color: ${({ theme }) => theme.colors.focusForm};
  }

  .MuiOutlinedInput-root {
    fieldset {
      legend {
        font-size: 1.05rem;
      }
      border-color: ${({ border, theme }) =>
        border === 'dark' ? theme.colors.darkGray : theme.colors.Gray};
      opacity: 1;
    }

    &:hover fieldset {
      border-color: ${({ theme }) => theme.colors.hoverForm};
    }

    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.colors.focusForm};
    }
  }

  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.error};
  }

  .MuiFormLabel-root.Mui-error {
    color: ${({ theme }) => theme.colors.error};
  }

  .MuiFormHelperText-root.Mui-error {
    color: ${({ theme }) => theme.colors.error};
    font-size: 1rem;
    font-weight: 600;
    padding-left: ${({ theme }) => theme.spacings.xxxsmall};
  }
`

export const AlertIcon = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.error};

  position: relative;
  bottom: 1.4rem;
  height: 0;
  img {
    height: 1rem;
  }
`

import { PropertiesDetailsGrid } from '../PropertiesDetailsGrid'

import * as S from './styled'
import { KpiPropertiesDetailsType } from 'types/KpiPropertiesDetails.types'
import { Modal } from 'components/Modal'
import { Icon } from 'components/Icon'
import { useTranslation } from 'react-i18next'

type PropTypes = {
  kpiPropertiesDetails?: {
    items: KpiPropertiesDetailsType[]
    pagination: any
  }
  onExit?: () => void
}

export function ModalContent({ kpiPropertiesDetails, onExit }: PropTypes) {
  const { t } = useTranslation()

  const content = (
    <S.Wrapper>
      <S.GridHeader>
        <S.BoxTitle>
          <Icon icon="icon-leaf" width={24} height={24} />
          <S.Title>{t('conformityChartTitle')}</S.Title>
        </S.BoxTitle>
        <S.BoxFit>
          <S.Box>
            <Icon icon="icon-fit" width={22} height={22} />
            <S.SubTitle>{t('fit')}</S.SubTitle>
          </S.Box>
          <S.Box>
            <Icon
              className="iconFit"
              icon="icon-not-fit"
              width={22}
              height={22}
            />
            <S.SubTitle>{t('notFit')}</S.SubTitle>
          </S.Box>
        </S.BoxFit>
      </S.GridHeader>

      {kpiPropertiesDetails != null && (
        <>
          <PropertiesDetailsGrid kpiPropertiesDetails={kpiPropertiesDetails} />
        </>
      )}
    </S.Wrapper>
  )
  return (
    <Modal content={content} width={160} cancelButton={false} onExit={onExit} />
  )
}

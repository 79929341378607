import { TraceabilityFilter } from 'types/Traceability.types'

export const types = Object.freeze({
  GET_TABLE_SIF: '@order/getTableSIF',
  SET_FILTERS_TABLE_SIF: '@order/setFiltersTableSIF',
  SET_TABLE_SIF: '@order/setTableSIF',
  CLEAN_TABLE_SIF: '@order/cleanTableSIF'
})

export function getTableSIF(filterTableSIF?: TraceabilityFilter) {
  return { type: types.GET_TABLE_SIF, payload: { filterTableSIF } }
}

export function setFiltersTableSIF(filterTableSIF: TraceabilityFilter) {
  return { type: types.SET_FILTERS_TABLE_SIF, payload: { filterTableSIF } }
}

export function setTableSIF({ tableSIF = {} } = {}) {
  return { type: types.SET_TABLE_SIF, payload: { tableSIF } }
}

export function cleanTableSIF() {
  return { type: types.CLEAN_TABLE_SIF }
}

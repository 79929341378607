import { HTTP } from 'providers'
import { TraceabilityFilter } from 'types/Traceability.types'

export class FoodSafetyService extends HTTP {
  private endpoint = '/rdc24'

  public async getFoodSafety(params: TraceabilityFilter) {
    const foodSafety = await this.get({
      endpoint: `${this.endpoint}`,
      params
    })
    return foodSafety
  }
}

import aditionalInformation from './es/aditionalInformation.json'
import authPages from './es/authPages.json'
import breadcrumb from './es/breadcrumb.json'
import cards from './es/cards.json'
import clientGroup from './es/clientGroup.json'
import common from './es/common.json'
import configurationInputErrors from './es/configurationInputErrors.json'
import customFilters from './es/customFilters.json'
import dashboard from './es/dashboard.json'
import inputErrors from './pt-br/inputErrors.json'
import modalBlockchainHistory from './es/modalBlockchainHistory.json'
import modalDeleteConfirm from './es/modalDeleteConfirm.json'
import modalUpdatePassword from './es/modalUpdatePassword.json'
import pagination from './es/pagination.json'
import profile from './es/profile.json'
import tables from './es/tables.json'
import toasts from './es/toasts.json'
import users from './es/users.json'
import nutriTable from './es/nutriTable.json'
import partner from './es/partner.json'
import product from './es/product.json'
import productLine from './es/productLine.json'
import termOfUse from './es/termOfUse.json'
import months from './es/months.json'
import modalExportProperties from './es/modalExportProperties.json'
import modalExportOrders from './es/modalExportOrders.json'
import modalExportManageClientGroup from './es/modalExportManageClientGroup.json'

export default {
  aditionalInformation,
  authPages,
  breadcrumb,
  cards,
  clientGroup,
  common,
  configurationInputErrors,
  customFilters,
  dashboard,
  inputErrors,
  modalBlockchainHistory,
  modalDeleteConfirm,
  modalUpdatePassword,
  pagination,
  profile,
  tables,
  toasts,
  users,
  nutriTable,
  partner,
  product,
  productLine,
  termOfUse,
  months,
  modalExportProperties,
  modalExportOrders,
  modalExportManageClientGroup,
  filter: {
    slaughter: 'Filtrar abate'
  }
}

import { Base } from 'layouts'
import { ClientGroupForm } from 'components'

import * as S from './styled'

export function ClientGroupEdit() {
  return (
    <Base>
      <S.GridContainer container>
        <ClientGroupForm />
      </S.GridContainer>
    </Base>
  )
}

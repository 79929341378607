import { MouseEvent } from 'react'
import TablePagination from '@material-ui/core/TablePagination'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

const useStyles = makeStyles({
  caption: {
    fontSize: '1.3rem'
  },
  select: {
    fontSize: '1.3rem'
  },
  input: {
    marginTop: '8px'
  }
})

type PaginationType = {
  totalItems: number
  page: number
  limit: number
  filters: any
  setFilter: (_args: any) => void
  rowsPerPageOptions?: number[]
}

export function Pagination({
  rowsPerPageOptions,
  ...pagination
}: PaginationType) {
  const { t } = useTranslation()
  const classes = useStyles()

  async function handleChangePage(
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    pagination.setFilter({
      ...pagination.filters,
      page: newPage
    })
  }

  function handleChangeRowsPerPage(e) {
    pagination.setFilter({
      ...pagination.filters,
      limit: e.target.value
    })
  }

  return (
    <S.Wrapper>
      <TablePagination
        backIconButtonText={t('pagination:previus_page')}
        classes={classes}
        component="ul"
        count={pagination.totalItems}
        labelRowsPerPage={t('pagination:rows_per_page')}
        nextIconButtonText={t('pagination:next_page')}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={pagination.page}
        rowsPerPage={pagination.limit}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </S.Wrapper>
  )
}

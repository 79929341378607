import { HTTP } from 'providers'

import { TraceabilityFilter } from '../types/Traceability.types'

export class OrderService extends HTTP {
  private endpoint = '/order'

  public async getOrders(params: TraceabilityFilter) {
    const order = await this.get({
      endpoint: this.endpoint,
      params
    })
    return order
  }

  public async getOrdersExported(params: TraceabilityFilter) {
    const { data } = await this.download({
      endpoint: `${this.endpoint}/export`,
      params
    })

    return data
  }
}

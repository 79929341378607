import { Button } from 'components/Button'
import { Icon } from 'components/Icon'
import { Modal } from 'components/Modal'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type ModalCreateProps = {
  cnpj: string | string[]
  groupName: string
  onExit?: () => void
  onRemove?: () => void
}

function formatCNPJ(cnpj: string | string[]) {
  if (Array.isArray(cnpj)) return cnpj.join(', ')
  return cnpj
}

export function ModalDelete({
  cnpj,
  groupName,
  onExit,
  onRemove
}: ModalCreateProps) {
  const { t } = useTranslation()
  const button = (
    <Button variant="secondary" onClick={onRemove} size="medium">
      <p>{t('common:yes')}</p>
    </Button>
  )

  const content = (
    <S.Wrapper>
      <S.Icon>
        <Icon icon="dropdown-delete" width={16} height={16} />
      </S.Icon>
      <S.Title>{t('common:oneCustomerRemove')}</S.Title>
      <S.Description>
        {t('modalDeleteConfirm:wantToDelete')}
        <span>
          {t('common:CNPJ')} {formatCNPJ(cnpj)}
        </span>
        {t('modalDeleteConfirm:fromGroup')}
        <span> {groupName}</span> ?
      </S.Description>
    </S.Wrapper>
  )

  return (
    <Modal
      button={button}
      content={content}
      isActionsEnabled={true}
      width={60}
      onExit={onExit}
    />
  )
}

import { ClientType } from 'types/Client.types'

export const types = Object.freeze({
  CLEAN_CLIENT: '@client/cleanClient',
  CREATE_CLIENT: '@client/createClient',
  DELETE_CLIENT: '@client/deleteClient',
  EDIT_CLIENT: '@client/editClient',
  FETCH_ALL: '@client/fetchAll',
  FETCH_ONE: '@client/fetchOne',
  SET_CLIENT_CREATED: '@client/setClientCreated',
  SET_CLIENT_EDIT: '@client/setClientEdit',
  SET_CLIENT: '@client/setClient',
  SET_CLIENTS: '@client/setClients',
  SET_LOADING: '@client/setLoading'
})

export function fetchAll(
  { params = {} }: { params?: Record<string, any> } = { params: {} }
) {
  return { type: types.FETCH_ALL, payload: { params } }
}

export function setClients({ clients = {} } = {}) {
  return { type: types.SET_CLIENTS, payload: { clients } }
}

export function createClient(client: ClientType) {
  return {
    type: types.CREATE_CLIENT,
    payload: client
  }
}

export function setClientCreated({ clientCreate = [] } = {}) {
  return {
    type: types.SET_CLIENT_CREATED,
    payload: { clientCreate, loading: false }
  }
}

export function fetchOne(id: string) {
  return { type: types.FETCH_ONE, payload: { id } }
}

export function setClient({ client = {} } = {}) {
  return {
    type: types.SET_CLIENT,
    payload: { client, loading: false }
  }
}

export function editClient(client: ClientType) {
  return {
    type: types.EDIT_CLIENT,
    payload: client
  }
}

export function setClientEdit({ clientEdit = [] } = {}) {
  return {
    type: types.SET_CLIENT_EDIT,
    payload: { clientEdit, loading: false }
  }
}

export function deleteClient({ id }) {
  return {
    type: types.DELETE_CLIENT,
    payload: id
  }
}

export function setLoading({ loading = false } = {}) {
  return { type: types.SET_LOADING, payload: { loading } }
}

export function cleanClient() {
  return {
    type: types.CLEAN_CLIENT,
    payload: {
      clientCreate: {},
      clientEdit: {},
      client: {}
    }
  }
}

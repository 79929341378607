const urlAtual = window.location.href

const URL_JBS = 'b2b-jbs.foodsafe.info'
const URL_SWIFT_PROD = 'b2b-swift.foodsafe.info'
const URL_SWIFT_TSI = 'b2b-swift.ecotrace.technology'

const urlList = {
  [URL_JBS]: 'jbsFriboi',
  [URL_SWIFT_TSI]: 'swift',
  [URL_SWIFT_PROD]: 'swift'
}

export const THEMES_NAME = {
  jbsFriboi: 'jbsFriboi',
  swift: 'swift',
  standard: 'standard'
}

const checkUrlForTheme = () => {
  for (const url in urlList) {
    if (urlAtual.includes(url)) return urlList[url]
  }
  return 'standard'
}

export default {
  appTheme: checkUrlForTheme()
}

import { ProductLineType } from 'types/ProductLine.types'

export const types = Object.freeze({
  CLEAN_PRODUCT_LINES: '@productLine/cleanProductLines',
  CREATE_PRODUCT_LINE: '@productLine/createProductLine',
  DELETE_PRODUCT_LINE: '@productLine/deleteProductLine',
  EDIT_PRODUCT_LINE: '@productLine/editProductLine',
  FETCH_ALL: '@productLine/fetchAll',
  FETCH_ONE: '@productLine/fetchOne',
  FILTER_PRODUCT_LINES: '@productLine/filterProductLines',
  GET_PRODUCT_LINE_OPTIONS: '@productLine/getProductLineOptions',
  SET_PRODUCT_LINE_CREATED: '@productLine/setProductLineCreated',
  SET_PRODUCT_LINE_EDIT: '@productLine/setProductLineEdit',
  SET_PRODUCT_LINE_OPTIONS: '@productLine/setProductLineOptions',
  SET_PRODUCT_LINES: '@productLine/setProductLines',
  SET_PRODUCT_LINE: '@productLine/setProductLine',
  SET_LOADING: '@productLine/setLoading'
})

export function fetchAll(filters = {}) {
  return { type: types.FETCH_ALL, payload: { filters } }
}

export function setLoading({ loading = false } = {}) {
  return { type: types.SET_LOADING, payload: { loading } }
}

export function fetchOne(id: string) {
  return {
    type: types.FETCH_ONE,
    payload: { id }
  }
}

export function filterProductLines({ params }) {
  return { type: types.FILTER_PRODUCT_LINES, payload: { params } }
}

export function createProductLine(productLine: ProductLineType) {
  return {
    type: types.CREATE_PRODUCT_LINE,
    payload: productLine
  }
}

export function editProductLine(productLine: ProductLineType) {
  return {
    type: types.EDIT_PRODUCT_LINE,
    payload: productLine
  }
}

export function deleteProductLine({ id }) {
  return {
    type: types.DELETE_PRODUCT_LINE,
    payload: id
  }
}

export function cleanProductLines() {
  return {
    type: types.CLEAN_PRODUCT_LINES,
    payload: {
      editProduct: {},
      createProduct: {},
      productLine: {}
    }
  }
}

export function setProductLines({ productLines = {} } = {}) {
  return { type: types.SET_PRODUCT_LINES, payload: { productLines } }
}

export function setProductLine({ productLine = {} } = {}) {
  return {
    type: types.SET_PRODUCT_LINE,
    payload: { productLine, loading: false }
  }
}

export function setProductLineCreated({ productLineCreate = [] } = {}) {
  return {
    type: types.SET_PRODUCT_LINE_CREATED,
    payload: { productLineCreate, loading: false }
  }
}

export function setProductLineEdit({ productLineEdit = [] } = {}) {
  return {
    type: types.SET_PRODUCT_LINE_EDIT,
    payload: { productLineEdit, loading: false }
  }
}

export function getProductLineOptions() {
  return { type: types.GET_PRODUCT_LINE_OPTIONS }
}

export function setProductLineOptions({ options = [] } = {}) {
  return {
    type: types.SET_PRODUCT_LINE_OPTIONS,
    payload: { options }
  }
}

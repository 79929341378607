import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { barChartType } from 'types/Traceability.types'
import * as S from './styled'
import { ptBR, enUS, es, zhCN } from 'date-fns/locale'
import { format } from 'date-fns'
import { convertToTimeZone } from 'date-fns-timezone'
import { useEffect, useState } from 'react'
import { CircularProgress } from '@material-ui/core'
window.Highcharts = Highcharts

export type Props = {
  color?: string
  series: {
    name: string
    data: barChartType
  }
  subTitle?: string
  title: string
  tooltipSuffix?: string
  type?: 'column' | 'bar'
  xAxisTitle?: string
  yAxisTitle?: string
  height?: number
  width?: number
  isLoading?: boolean
}

export function BarChart({
  color = '#4ba8df',
  height = 400,
  series,
  width,
  subTitle,
  title = '',
  tooltipSuffix,
  type = 'bar',
  xAxisTitle = '',
  yAxisTitle = '',
  isLoading = false
}: Props) {
  const [chartLang, setChartLang] = useState(ptBR)
  const [options, setOptions] = useState({
    chart: { type, height, width },
    title: { text: title },
    subtitle: { text: subTitle },
    xAxis: {
      type: 'category',
      title: { text: xAxisTitle }
    },
    yAxis: {
      title: { text: yAxisTitle }
    },
    tooltip: {
      headerFormat: `<span style="font-size:13px">${series.name}</span><br>`,
      pointFormat: `<span style="color:{point.color}">{point.name}</span>: <b>{point.y} ${tooltipSuffix}</b><br/>`
    },
    plotOptions: {
      series: {
        colorByPoint: false,
        color,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: '{point.y:,.0f}'
        }
      }
    },
    legend: {
      enabled: false
    },
    series: { data: [], name: 'Meses' },
    credits: { enabled: false },
    with: '100%',
    height: '100%'
  })

  useEffect(() => {
    const lang = window.localStorage.getItem('i18nextLng')
    if (lang === 'pt-BR') setChartLang(ptBR)
    if (lang === 'en-US') setChartLang(enUS)
    if (lang === 'es') setChartLang(es)
    if (lang === 'ch') setChartLang(zhCN)
  }, [series])

  useEffect(() => {
    const _seriesData = isLoading
      ? {
          data: [],
          name: 'Meses'
        }
      : {
          ...series,
          data: series.data.map(each => ({
            name: format(
              convertToTimeZone(new Date(each.name), { timeZone: 'UTC' }),
              'MMM yyyy',
              { locale: chartLang }
            ),
            y: each.y
          }))
        }

    setOptions(prev => ({ ...prev, series: _seriesData }))
  }, [
    chartLang,
    color,
    height,
    isLoading,
    series,
    subTitle,
    title,
    tooltipSuffix,
    type,
    width,
    xAxisTitle,
    yAxisTitle
  ])

  return (
    <>
      {isLoading ? (
        <S.BarChartLoadingContainer>
          <S.LoadingTextContainer>
            <CircularProgress size={75} color="primary" />
          </S.LoadingTextContainer>
        </S.BarChartLoadingContainer>
      ) : (
        <div
          style={{
            textTransform: 'capitalize',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      )}
    </>
  )
}

import { get } from 'lodash'
import { PartnerOptionsType, PartnerType } from 'types/Partner.types'
import { types } from './partners.action'

type Partner = {
  partners: {
    items: PartnerType[]
    pagination: {
      totalItems: number
      limit: number
      page: number
    }
  }
  optionsPartner?: PartnerOptionsType[]
  partnerCreate?: PartnerType
  partnerEdit?: PartnerType
  partner?: PartnerType
  loading: boolean
}

const INITIAL_STATE = {
  partners: {
    items: [],
    pagination: null
  },
  partnerCreate: {} as PartnerType,
  partnerEdit: {} as PartnerType,
  partner: {} as PartnerType,
  optionsPartner: [],
  loading: false
}

function setState(state: Partner) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.CLEAN_PARTNERS]: setState(state),
    [types.SET_PARTNER_CREATED]: setState(state),
    [types.SET_PARTNER_EDIT]: setState(state),
    [types.SET_PARTNER_OPTIONS]: setState(state),
    [types.SET_PARTNERS]: setState(state),
    [types.SET_PARTNER]: setState(state),
    [types.SET_LOADING]: setState(state),
    _default: () => state
  }
  return get(_handlers, type, _handlers._default)(payload)
}

import { ColumnsProps } from 'components/Table/Table'
import i18n from 'i18n'

export const columns = (): ColumnsProps[] => [
  {
    align: 'right',
    fixed: true,
    name: 'order',
    title: i18n.t('tables:orderNumber')
  },
  {
    align: 'right',
    fixed: true,
    name: 'cargo',
    title: i18n.t('tables:charge')
  },
  {
    align: 'right',
    fixed: true,
    name: 'industrydoc',
    title: i18n.t('tables:CNPJIndustrySlaughter')
  },
  {
    align: 'right',
    fixed: true,
    name: 'corporatename',
    title: i18n.t('tables:socialReasonSlaughter')
  },
  {
    align: 'right',
    name: 'ufcnpj',
    title: i18n.t('tables:ufCNPJSlaughter')
  },
  {
    align: 'right',
    name: 'city',
    title: i18n.t('tables:municipalitySlaughter')
  },
  {
    align: 'right',
    name: 'slaughterinspectionnum',
    title: i18n.t('tables:slaughterSIF')
  },
  {
    align: 'right',
    name: 'slaughteredat',
    title: i18n.t('tables:slaughterDate')
  },
  {
    align: 'right',
    name: 'productcode',
    title: i18n.t('common:productCode')
  },
  {
    name: 'productdescription',
    title: i18n.t('common:productDescription')
  },
  {
    align: 'right',
    name: 'slaughterbatch',
    title: i18n.t('tables:slaughterBatch')
  },
  {
    align: 'right',
    name: 'sequential',
    title: i18n.t('tables:slaughterSequence')
  },
  {
    align: 'right',
    name: 'band',
    title: i18n.t('common:side')
  },
  {
    align: 'right',
    name: 'weight',
    title: i18n.t('tables:hotWeightSlaughter')
  },
  {
    align: 'right',
    name: 'boningoutinspectionnum',
    title: i18n.t('tables:boningSIF')
  },
  {
    align: 'right',
    name: 'boningoutat',
    title: i18n.t('tables:packingDate')
  },
  {
    align: 'right',
    name: 'productiondate',
    title: i18n.t('tables:deboningBatch')
  }
]

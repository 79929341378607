import { useCallback, useEffect, useState, MouseEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Table, NewPagination } from 'components'
import { GridHeader } from '../GridHeader'

import { all } from 'store/TraceabilityTables/TraceabilityTables.selector'
import { all as allBox } from 'store/Box/Box.selector'
import { all as allOrders } from 'store/Order/Order.selector'
import { BoxActions } from 'store/Box'

// import { columns as allColumns } from 'constants/box.constants'
import { columns as allBoxColumns } from 'constants/box-retail.constants'
import { INITIAL_STATE } from 'constants/traceability.constants'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

// import { USER_ROLES } from 'constants/user.constants'
// import { UserStorageService } from 'services'

export function BoxesList() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [filter, setFilter] = useState({ ...INITIAL_STATE })
  const { filters, tableLoading } = useSelector(all)
  const { filterBoxes, boxes = [] } = useSelector(allBox)
  const { ordersKeys } = useSelector(allOrders)
  const columns = [...allBoxColumns()]
  const { pagination } = boxes
  const [isNewPage, setNewPage] = useState({ ...pagination })
  const fetch = useCallback(() => {
    setFilter(filters)
    if (!boxes.items) dispatch(BoxActions.getBoxes(filters, ordersKeys))
  }, [dispatch, filters, ordersKeys, boxes.items])

  useEffect(() => {
    if (ordersKeys.length) fetch()
  }, [fetch, ordersKeys])
  function changeColumn({ column, row }) {
    if (column.name === 'status') {
      return <p>{row.status ? t('common:yes') : t('common:no')}</p>
    } else return row[column.name]
  }
  async function handleChangePage(
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    const newFilter = {
      ...filterBoxes,
      ...filters
    }
    newFilter.page = newPage
    dispatch(BoxActions.setFilterBoxes(newFilter))
    dispatch(BoxActions.getBoxes(newFilter, ordersKeys))
  }
  function handleNewPage() {
    const newPage = isNewPage.page
    if (newPage > pagination.totalPages) {
      const newFilter = {
        ...filterBoxes,
        ...filters
      }
      newFilter.page = pagination.totalPages
      dispatch(BoxActions.setFilterBoxes(newFilter))
      dispatch(BoxActions.getBoxes(newFilter, ordersKeys))
      setNewPage({ ...pagination, page: newPage })
    } else {
      const newFilter = {
        ...filterBoxes,
        ...filters
      }
      newFilter.page = newPage
      dispatch(BoxActions.setFilterBoxes(newFilter))
      dispatch(BoxActions.getBoxes(newFilter, ordersKeys))
      setNewPage({ ...pagination, page: newPage })
    }
  }
  function handleInputPagination(name: string, value: number) {
    setNewPage({ ...pagination, [name]: value })
  }

  function handleChangeRowsPerPage(e) {
    const newFilter = {
      ...filterBoxes,
      ...filters
    }
    newFilter.limit = e.target.value
    dispatch(BoxActions.setFilterBoxes(newFilter))
    dispatch(BoxActions.getBoxes(newFilter, ordersKeys))
  }

  return (
    <S.FullGrid item xs={12}>
      {boxes.items?.length > 0 && (
        <GridHeader title={t('common:productList')} />
      )}
      <S.GridTable>
        <Table
          columns={columns}
          rows={boxes.items || []}
          sortColumn={{ column: filter.column, sort: filter.direction }}
          isLoading={tableLoading}
          changeColumn={changeColumn}
          isScroll={boxes.items?.length > 10}
        />
        {pagination?.totalItems > 0 && (
          <NewPagination
            count={pagination?.totalPages}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            handleInput={handleInputPagination}
            handleNewPage={handleNewPage}
            page={pagination?.page}
            limit={pagination.limit}
          />
        )}
      </S.GridTable>
    </S.FullGrid>
  )
}

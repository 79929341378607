import { Base } from 'layouts'
import { List } from './List'

import * as S from './styled'

export function Property() {
  return (
    <Base>
      <S.GridContainer container>
        <List />
      </S.GridContainer>
    </Base>
  )
}

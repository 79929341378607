import { get } from 'lodash'
import { PropertyType } from 'types/Property.types'

import { types } from './Property.actions'

type Property = {
  logsProperties: {
    items: any[]
    pagination: {
      totalItems: number
      limit: number
      page: number
    }
  }
  properties: {
    items: PropertyType[]
    pagination: {
      totalItems: number
      limit: number
      page: number
    }
  }
  propertiesProducers: {
    items: PropertyType[]
  }
  addProducer?: PropertyType
  blockStatusProducer?: PropertyType
  blockStatusAllProducer?: PropertyType
  propertyCreate?: PropertyType
  propertyEdit?: PropertyType
  propertiesImport?: PropertyType[]
  property?: PropertyType
  loading: boolean
}

const INITIAL_STATE = {
  logsProperties: {
    items: [],
    pagination: null
  },
  properties: {
    items: [],
    pagination: null
  },
  propertiesProducers: {
    items: []
  },
  addProducer: {} as PropertyType,
  blockStatusProducer: {} as PropertyType,
  blockStatusAllProducer: {} as PropertyType,
  propertyCreate: {} as PropertyType,
  propertyEdit: {} as PropertyType,
  propertiesImport: [] as PropertyType[],
  property: {} as PropertyType,
  loading: false
}

function setState(state: Property) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.CLEAN_PROPERTY]: setState(state),
    [types.SET_ADD_PRODUCER]: setState(state),
    [types.SET_BLOCK_STATUS_PRODUCER]: setState(state),
    [types.SET_BLOCK_STATUS_ALL_PRODUCER]: setState(state),
    [types.SET_LOADING]: setState(state),
    [types.SET_PROPERTIES_IMPORT]: setState(state),
    [types.SET_PROPERTIES_LOGS]: setState(state),
    [types.SET_PROPERTIES]: setState(state),
    [types.SET_PROPERTIES_PRODUCERS]: setState(state),
    [types.SET_PROPERTY_CREATED]: setState(state),
    [types.SET_PROPERTY_EDIT]: setState(state),
    [types.SET_PROPERTY]: setState(state),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}

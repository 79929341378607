import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const Wrapper = styled(Grid)`
  margin-bottom: 3rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 5px 25px #0000000d;
  border-radius: 1.2rem;
  padding: 2.4rem;
  margin: 12px 0px;
  width: 100%;
`

export const GridHorizontalDate = styled(Grid)``

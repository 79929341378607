import { Input } from 'components/Input'
import { useTranslation } from 'react-i18next'

import {
  ERRORS_INITIAL_STATE,
  INITIAL_STATE
} from 'constants/property.constants'

import * as S from '../styled'

type ComponentType = {
  errors: typeof ERRORS_INITIAL_STATE
  payload: typeof INITIAL_STATE
  handleInput: (_value, _name) => void
}

export function AditionalInformation({
  errors,
  payload,
  handleInput
}: ComponentType) {
  const { t } = useTranslation()
  return (
    <S.GridFields container spacing={2}>
      <S.GridInput item sm={12} md={4}>
        <Input
          fullWidth
          label={t('aditionalInformation:CARRuralRegistry')}
          value={payload.CAR}
          error={Boolean(errors.CAR)}
          helperText={errors.CAR}
          onInput={value => handleInput(value, 'CAR')}
        />
      </S.GridInput>

      <S.GridInput item sm={12} md={4}>
        <Input
          fullWidth
          label={t('aditionalInformation:INCRARuralPropertyCode')}
          value={payload.INCRA}
          error={Boolean(errors.INCRA)}
          helperText={errors.INCRA}
          onInput={value => handleInput(value, 'INCRA')}
        />
      </S.GridInput>

      <S.GridInput item sm={12} md={4}>
        <Input
          fullWidth
          label={t('aditionalInformation:NIRFInscriptionFederalRevenue')}
          value={payload.NIRF}
          error={Boolean(errors.NIRF)}
          helperText={errors.NIRF}
          onInput={value => handleInput(value, 'NIRF')}
        />
      </S.GridInput>

      <S.GridInput item sm={12} md={4}>
        <Input
          fullWidth
          label={t(
            'aditionalInformation:CCIRCertificateRuralPropertyRegistration'
          )}
          value={payload.CCIR}
          error={Boolean(errors.CCIR)}
          helperText={errors.CCIR}
          onInput={value => handleInput(value, 'CCIR')}
        />
      </S.GridInput>

      <S.GridInput item sm={12} md={4}>
        <Input
          fullWidth
          label={t('aditionalInformation:LARPRFNumber')}
          value={payload.LARLAU || ''}
          error={Boolean(errors.LARLAU)}
          helperText={errors.LARLAU}
          onInput={value => handleInput(value, 'LARLAU')}
        />
      </S.GridInput>

      <S.GridInput item sm={12} md={4}>
        <Input
          fullWidth
          label={t('aditionalInformation:originDocsPerimeter')}
          value={payload.perimeterDocsOrigin || ''}
          error={Boolean(errors.perimeterDocsOrigin)}
          helperText={errors.perimeterDocsOrigin}
          onInput={value => handleInput(value, 'perimeterDocsOrigin')}
        />
      </S.GridInput>

      <S.GridInput item sm={12} md={4}>
        <Input
          fullWidth
          label={t('aditionalInformation:livestockExploitationCode')}
          value={payload.livestockExplorationCode}
          error={Boolean(errors.livestockExplorationCode)}
          helperText={errors.livestockExplorationCode}
          onInput={value => handleInput(value, 'livestockExplorationCode')}
        />
      </S.GridInput>

      <S.GridInput item sm={12} md={4}>
        <Input
          fullWidth
          label={t('aditionalInformation:establishmentCode')}
          value={payload.establishmentCode}
          error={Boolean(errors.establishmentCode)}
          helperText={errors.establishmentCode}
          onInput={value => handleInput(value, 'establishmentCode')}
        />
      </S.GridInput>
    </S.GridFields>
  )
}

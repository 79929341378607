import { useState, useEffect } from 'react'
import ptBR from 'date-fns/locale/pt-BR'
import DateFnsUtils from '@date-io/date-fns'
import { Icon } from '../Icon'
import { THEMES_NAME } from 'config/theme.config'
import { theme } from 'config'

import * as S from './styled'

export type InputDateProps = {
  error?: boolean
  fullWidth?: boolean
  helperText?: string
  id?: string
  label: string
  maxDate?: Date
  minDate?: Date
  name?: string
  value?: Date
  width?: number
  onInput?: (_value: string | Date) => void
  disabled?: boolean
}

export function InputDate({
  error = false,
  fullWidth = false,
  helperText = '',
  id = 'date',
  label,
  name = 'date',
  value = null,
  minDate = null,
  width = 200,
  onInput,
  disabled = false
}: InputDateProps) {
  const [selectedDate, setSelectedDate] = useState(value)

  useEffect(() => {
    if (value !== selectedDate) setSelectedDate(value)
  }, [value, selectedDate])

  const onChange = date => {
    if (!date) {
      onInput(null)
      return
    }
    if (date < minDate) {
      value = minDate
      setSelectedDate(minDate)
      !!onInput && onInput(minDate)
    } else {
      const dt = date
      dt.setHours(0, 0, 0)
      setSelectedDate(dt)
      !!onInput && onInput(date)
    }
  }

  return (
    <S.Wrapper style={{ width: !fullWidth ? width : 'none' }}>
      <S.Provider locale={ptBR} utils={DateFnsUtils}>
        <S.InputDate
          disabled={disabled}
          data-testid="input"
          autoOk
          disableToolbar
          error={error}
          format="dd/MM/yyyy"
          fullWidth={fullWidth}
          helperText={helperText}
          inputVariant="outlined"
          id={id}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          keyboardIcon={
            <Icon
              icon={
                theme.appTheme === THEMES_NAME.swift
                  ? 'input-calendar-swift'
                  : 'input-calendar'
              }
              width={16}
              height={16}
            />
          }
          label={label}
          name={name}
          style={{ width: !fullWidth ? width : '100%' }}
          variant="inline"
          value={selectedDate}
          onChange={onChange}
        />
      </S.Provider>
      {error && helperText && (
        <S.AlertIcon>
          <Icon icon="itens-interface-alert" height={10} width={10} />
        </S.AlertIcon>
      )}
    </S.Wrapper>
  )
}

import { TraceabilityFilter } from 'types/Traceability.types'

export const types = Object.freeze({
  GET_ORDERS: '@order/getOrders',
  GET_ORDERS_EXPORT: '@order/getOrdersExport',
  SET_FILTERS_ORDERS: '@order/setFiltersOrders',
  SET_ORDERS: '@order/setOrders',
  CLEAN_ORDERS: '@order/cleanOrders',
  SET_ORDERS_KEYS: '@order/setOrdersKeys',
  CLEAR_ORDERS_KEYS: '@order/clearOrdersKeys'
})

export function getOrders(filterOrders?: TraceabilityFilter) {
  return { type: types.GET_ORDERS, payload: { filterOrders } }
}

export function getOrdersExported(filterOrders?: TraceabilityFilter) {
  return { type: types.GET_ORDERS_EXPORT, payload: { filterOrders } }
}

export function setOrdersKeys(orderKey: string | string[]) {
  return {
    type: types.SET_ORDERS_KEYS,
    payload: { orderKey }
  }
}

export function clearOrdersKeys() {
  return { type: types.CLEAR_ORDERS_KEYS }
}

export function setFilterOrder(filterOrders: TraceabilityFilter) {
  return { type: types.SET_FILTERS_ORDERS, payload: { filterOrders } }
}

export function setOrders({ orders = {} } = {}) {
  return { type: types.SET_ORDERS, payload: { orders } }
}

export function cleanOrders() {
  return { type: types.CLEAN_ORDERS }
}

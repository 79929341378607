import { get } from 'lodash'

import { types } from './Properties.actions'
import { INITIAL_STATE as INITIAL_STATE_FILTER } from 'constants/traceability.constants'

const INITIAL_STATE = {
  filterProperties: INITIAL_STATE_FILTER,
  propertiesLocation: [
    {
      location: { longitude: '', latitude: '' },
      locationId: ''
    }
  ],
  properties: {
    items: null,
    pagination: {}
  }
}

function setProperties(state) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.SET_PROPERTIES_LIST]: setProperties(state),
    [types.SET_FILTERS]: setProperties(state),
    [types.CLEAN_PROPERTIES_LIST]: setProperties(INITIAL_STATE),
    [types.SET_PROPERTIES_LOCATION]: setProperties(state),
    [types.CLEAN_PROPERTIES_LOCATION]: setProperties({
      ...state,
      propertiesLocation: [
        {
          location: { longitude: '', latitude: '' },
          locationId: ''
        }
      ]
    }),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}

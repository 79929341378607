import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { Pagination } from 'components/Pagination'
import { GROUP_FILTER_INITIAL_STATE } from 'constants/client.constants'
import { submitOnEnter } from 'utils/submitOnEnter'
import { ClientGroupFilterTable } from './ClientGroupFilterTable'
import { all } from 'store/ClientGroup/ClientGroup.selector'
import {
  filterGroupClients,
  getClientGroupExported
} from 'store/ClientGroup/ClientGroup.actions'
import { FilterClientSchema } from 'store/ClientGroup/ClientGroup.validator'
import { useTranslation } from 'react-i18next'
import { UserStorageService } from 'services'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { ModalSendEmail } from 'components/ModalSendMail'

import * as S from './styled'

export type ClientGroupFilterProps = {
  onNewClick?: (_value: unknown) => void
}

export function ClientGroupFilter({ onNewClick }: ClientGroupFilterProps) {
  const { t } = useTranslation()
  const { user } = UserStorageService.getUserData()
  const dispatch = useDispatch()
  const { manageClientGroup } = useSelector(all)
  const { retailsPagination, retails, id, name } = manageClientGroup
  const [modalExport, setModalExport] = useState(false)
  const [filter, setFilter] = useState({
    ...GROUP_FILTER_INITIAL_STATE,
    limit: 10,
    page: 0
  })
  const [errors, setErrors] = useState({ ...GROUP_FILTER_INITIAL_STATE })

  function onInput(name: string) {
    return function _handle(val) {
      setErrors({ ...errors, [name]: '' })
      setFilter({ ...filter, [name]: val })
    }
  }

  async function submitFilter() {
    try {
      await FilterClientSchema().validate(filter, {
        abortEarly: false,
        stripUnknown: true
      })
      const formatedFilter = {
        name: filter.name,
        fancyName: filter.fancyName,
        cnpj: filter.cnpj.replace(/[^\w\s]/gi, '') || ''
      }
      dispatch(filterGroupClients({ groupId: id, params: formatedFilter }))
    } catch (ex) {
      const _errors = Object.fromEntries(
        ex.inner.map(({ path, message }) => {
          return [path, message.replace(`${message} `, '')]
        })
      )
      setErrors({ ...GROUP_FILTER_INITIAL_STATE, ..._errors })
    }
  }

  function onFilterPaginate(params: any = {}) {
    const formatedFilter = {
      ...params,
      name: params.name,
      fancyName: params.fancyName,
      cnpj: params.cnpj.replace(/[^\w\s]/gi, '') || ''
    }
    dispatch(filterGroupClients({ groupId: id, params: formatedFilter }))
  }

  async function clearFilters() {
    const params = {
      ...GROUP_FILTER_INITIAL_STATE,
      limit: retailsPagination.limit,
      page: retailsPagination.page
    }

    setFilter(params)
    setErrors({ ...GROUP_FILTER_INITIAL_STATE })
    dispatch(
      filterGroupClients({
        groupId: id,
        params
      })
    )
  }

  function onExport() {
    const formatedFilter = {
      name: filter.name,
      fancyName: filter.fancyName,
      cnpj: filter.cnpj.replace(/[^\w\s]/gi, '') || ''
    }
    setModalExport(true)
    dispatch(getClientGroupExported({ groupId: id, params: formatedFilter }))
  }

  return (
    <S.GridCard>
      <S.GridHeader>
        <S.GridTitle item container xs={12}>
          <S.BoxTitle>{t('common:filterCustomers')}</S.BoxTitle>
          <S.BoxLine />
        </S.GridTitle>
      </S.GridHeader>

      <S.GridFilter container>
        <S.GridInput item md={12} lg={4}>
          <Input
            fullWidth
            label={t('common:socialReason')}
            value={filter.name}
            error={Boolean(errors.name)}
            helperText={errors.name}
            onKeyPress={submitOnEnter(submitFilter)}
            onInput={onInput('name')}
          />
        </S.GridInput>
        <S.GridInput item md={12} lg={4}>
          <Input
            fullWidth
            label={t('common:fantasyName')}
            value={filter.fancyName}
            error={Boolean(errors.fancyName)}
            helperText={errors.fancyName}
            onKeyPress={submitOnEnter(submitFilter)}
            onInput={onInput('fancyName')}
          />
        </S.GridInput>
        <S.GridInput item md={12} lg={4}>
          <Input
            fullWidth
            label={t('common:identifier')}
            value={filter.cnpj}
            error={Boolean(errors.cnpj)}
            helperText={errors.cnpj}
            onKeyPress={submitOnEnter(submitFilter)}
            onInput={onInput('cnpj')}
          />
        </S.GridInput>
        <S.GridButtons item xs={12}>
          <Button variant="secondary" onClick={onExport} size="medium">
            <S.ButtonContent>
              <CloudDownloadIcon />
              <S.ButtonText>{t('common:export')}</S.ButtonText>
            </S.ButtonContent>
          </Button>
          <Button variant="default" onClick={clearFilters} size="medium">
            <p>{t('common:clean')}</p>
          </Button>
          <Button variant="primary" onClick={submitFilter} size="medium">
            <p>{t('common:filter')}</p>
          </Button>
        </S.GridButtons>
      </S.GridFilter>

      <S.GridTable>
        <ClientGroupFilterTable
          groupId={id}
          groupName={name}
          items={retails}
          isUserViewer={UserStorageService.isViewer(user)}
          onNewClick={onNewClick}
        />
        {modalExport && (
          <ModalSendEmail
            onExit={() => setModalExport(false)}
            title={t('modalExportManageClientGroup:title')}
            description={t('modalExportManageClientGroup:message')}
          />
        )}
        {retailsPagination?.totalItems > 0 && (
          <Pagination
            filters={{
              ...filter,
              limit: retailsPagination.limit,
              page: retailsPagination.page
            }}
            limit={retailsPagination.limit}
            page={retailsPagination.page}
            totalItems={retailsPagination.totalItems}
            setFilter={onFilterPaginate}
          />
        )}
      </S.GridTable>
    </S.GridCard>
  )
}

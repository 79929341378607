import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { omit } from 'lodash'

import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { InputCpfCnpj } from 'components/InputCpfCnpj'
import { Modal } from 'components/Modal'
import { IndustryTableAdd } from './IndustryTableAdd'
import { Pagination } from 'components/Pagination'
import { useTranslation } from 'react-i18next'

import {
  getUnrelatedIndustries,
  linkWithGroup
} from 'store/IndustryGroup/IndustryGroup.actions'
import { all } from 'store/IndustryGroup/IndustryGroup.selector'

import * as S from './styled'
import { FilterCnpjIndustrySchema } from 'store/IndustryGroup/IndustryGroup.validator'

export type ModalAddIndustryProps = {
  id: string
  onExit?: () => void
}

interface Filter {
  cnpj: string
  name: string
  limit: number
  page: number
}

const INITIAL_FILTER: Filter = { cnpj: '', name: '', limit: 10, page: 0 }

export function ModalAddIndustry({ id, onExit }: ModalAddIndustryProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [filter, setFilter] = useState(INITIAL_FILTER)
  const [industryIds, setIndustryIds] = useState([])
  const [errors, setErrors] = useState({ ...INITIAL_FILTER })

  const {
    loading,
    manageIndustryGroup,
    unrelatedIndustries: industries
  } = useSelector(all)
  const { name } = manageIndustryGroup

  useEffect(() => {
    dispatch(getUnrelatedIndustries({ id }))
  }, [dispatch, id])

  function handleFilter(value: string, name: string) {
    setFilter({ ...filter, [name]: value })
  }

  function onAdd() {
    if (industryIds.length) {
      dispatch(linkWithGroup({ id, industryIds }))
      onExit()
    }
  }

  async function onFilter() {
    try {
      await FilterCnpjIndustrySchema().validate(filter, {
        abortEarly: false,
        stripUnknown: true
      })
      dispatch(
        getUnrelatedIndustries({
          id,
          params: {
            ...filter,
            limit: industries.pagination.limit,
            page: industries.pagination.page
          }
        })
      )
      setErrors({ ...INITIAL_FILTER })
    } catch (ex) {
      const _errors = Object.fromEntries(
        ex.inner.map(({ path, message }) => {
          return [path, message.replace(`${message} `, '')]
        })
      )
      setErrors({ ...INITIAL_FILTER, ..._errors })
    }
  }

  function onFilterPaginate(params: any = {}) {
    dispatch(
      getUnrelatedIndustries({ id: params.id, params: omit(params, 'id') })
    )
  }

  function clearFilter() {
    const params = {
      ...INITIAL_FILTER,
      limit: industries.pagination.limit,
      page: industries.pagination.page
    }
    setFilter(INITIAL_FILTER)
    dispatch(getUnrelatedIndustries({ id, params }))
  }

  const button = (
    <Button variant="alternative" onClick={onAdd} size="medium">
      <p>{t('common:add')}</p>
    </Button>
  )

  const content = (
    <S.Wrapper>
      <S.GridHeader>
        <S.GridTitle item container xs={12}>
          <S.BoxTitle>
            {t('common:addIndustryToGroup')} {name}
          </S.BoxTitle>
        </S.GridTitle>
      </S.GridHeader>

      <S.Line />

      <S.Title>{t('breadcrumb:industryList')}</S.Title>

      <S.GridFilter container>
        <S.GridInput item md={12} lg={6}>
          <Input
            fullWidth
            label={t('common:socialReason')}
            value={filter.name}
            onInput={value => handleFilter(value, 'name')}
          />
        </S.GridInput>
        <S.GridInput item md={12} lg={6}>
          <InputCpfCnpj
            fullWidth
            type="cnpj"
            value={filter.cnpj}
            error={Boolean(errors.cnpj)}
            helperText={errors.cnpj}
            onInput={value => handleFilter(value, 'cnpj')}
          />
        </S.GridInput>
        <S.GridButtons item xs={12}>
          <Button variant="default" onClick={clearFilter} size="medium">
            <p>{t('common:clean')}</p>
          </Button>
          <Button variant="primary" onClick={onFilter} size="medium">
            <p>{t('common:search')}</p>
          </Button>
        </S.GridButtons>
      </S.GridFilter>

      <S.GridTable>
        <IndustryTableAdd
          industries={industries.items}
          loading={loading}
          setIndustries={setIndustryIds}
        />
        {industries.pagination?.totalItems > 0 && (
          <Pagination
            filters={{
              ...filter,
              id,
              limit: industries.pagination.limit,
              page: industries.pagination.page
            }}
            limit={industries.pagination.limit}
            page={industries.pagination.page}
            totalItems={industries.pagination.totalItems}
            setFilter={onFilterPaginate}
          />
        )}
      </S.GridTable>
    </S.Wrapper>
  )

  return (
    <Modal
      button={button}
      content={content}
      isActionsEnabled={true}
      width={72}
      onExit={onExit}
    />
  )
}

import * as Yup from 'yup'
import validaCNPJ from 'utils/validaCNPJ'
import { isValid as validateCPF } from 'cpf'
import i18n from 'i18n'

const contactsSchema = () => {
  return Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required().label(i18n.t('common:name')),
      email: Yup.string().required().email().label(i18n.t('common:email')),
      phone: Yup.string()
        .nullable(false)
        .min(13, i18n.t('inputErrors:invalidPhone'))
        .max(14, i18n.t('inputErrors:invalidPhone'))
        .label(i18n.t('common:phone'))
    })
  )
}

const technicianSchema = () => {
  return Yup.object().shape({
    bio: Yup.string().required().label(i18n.t('profile:miniCurriculum')),
    doc: Yup.string().required().label(i18n.t('profile:docNumber')),
    name: Yup.string().required().label(i18n.t('common:name'))
  })
}

const managerSchema = () => {
  return Yup.object().shape({
    bio: Yup.string().required().label(i18n.t('profile:miniCurriculum')),
    doc: Yup.string()
      .required()
      .test('cpf', i18n.t('inputErrors:invalidCPF'), value => {
        if (value) {
          const valido = validateCPF(value)
          return valido
        }
        return false
      })
      .label(i18n.t('profile:docNumber')),
    name: Yup.string().required().label(i18n.t('common:name'))
  })
}

export const IndustryFormSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required().label(i18n.t('common:socialReason')),
    fancyName: Yup.string().required().label(i18n.t('common:fantasyName')),
    inspectionType: Yup.string()
      .oneOf([i18n.t('common:SIF'), i18n.t('common:SIE'), i18n.t('common:SIM')])
      .required()
      .label(i18n.t('common:typeInspection')),
    inspectionNum: Yup.string()
      .required()
      .label(i18n.t('common:inspectionNumber')),
    cnpj: Yup.string()
      .required()
      .test('cnpj', i18n.t('inputErrors:invalidCNPJ'), value => {
        if (value) {
          const valido = validaCNPJ(value)
          return valido
        }
        return false
      })
      .label(i18n.t('common:CNPJ')),
    ie: Yup.string().required().label(i18n.t('common:stateRegistration')),
    status: Yup.boolean()
      .nullable(true)
      .required()
      .label(i18n.t('common:status')),
    address: Yup.string().required().label(i18n.t('common:address')),
    state: Yup.string().required().label(i18n.t('common:state')),
    city: Yup.string().required().label(i18n.t('common:city')),
    cep: Yup.string()
      .required()
      .min(10, i18n.t('inputErrors:invalidZIPCode'))
      .label(i18n.t('common:CEP')),
    lat: Yup.number()
      .nullable(true)
      .label(i18n.t('common:latitude'))
      .typeError(i18n.t('inputErrors:invalidLatitude')),
    lng: Yup.number()
      .nullable(true)
      .label(i18n.t('common:longitude'))
      .typeError(i18n.t('inputErrors:invalidLongitude')),
    qualifications: Yup.array().min(1).label(i18n.t('common:license')),
    sifPhoto: Yup.mixed().required().label(i18n.t('profile:SIFPhoto')),
    contacts: contactsSchema(),
    technician: technicianSchema(),
    manager: managerSchema()
  })
}

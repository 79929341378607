import { get } from 'lodash'
import { INITIAL_STATE } from 'constants/kpiPropertiesDetails.constants'

import { types } from './PropertiesDetails.actions'

function setState(state: any) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.SET_KPIS_PROPERTIES_DETAILS]: setState(state),
    [types.CLEAN_KPIS_PROPERTIES_DETAILS]: setState(INITIAL_STATE),
    [types.SET_SHOW_MODAL]: setState(state),
    [types.SET_KPIS_PROPERTIES_SUMMARY]: setState({
      ...state,
      kpiPropertiesDetails: {
        ...state.kpiPropertiesDetails,
        summary: {
          ...state.kpiPropertiesDetails.summary,
          pagination: {
            limit: state.kpiPropertiesDetails.summary.pagination.limit || 50,
            page: state.kpiPropertiesDetails.summary.pagination.page || 0
          }
        }
      }
    }),
    [types.CLEAN_KPIS_PROPERTIES_SUMMARY]: setState(INITIAL_STATE),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}

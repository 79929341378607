import { Input } from 'components/Input'
import { InputCpfCnpj } from 'components/InputCpfCnpj'
import { Picture } from 'components/Picture'
import { useTranslation } from 'react-i18next'

import {
  ERRORS_INITIAL_STATE,
  INITIAL_STATE
} from 'constants/industry.constants'

import * as S from './styled'

type ManagerType = {
  errors: typeof ERRORS_INITIAL_STATE
  payload: typeof INITIAL_STATE
  handleInput: (_value, _name, _field) => void
  onLoadImage: (_key) => void
  onRemoveImage: (_key) => void
}

export function Manager({
  errors,
  payload,
  handleInput,
  onLoadImage,
  onRemoveImage
}: ManagerType) {
  const { t } = useTranslation()
  function err(name: string) {
    const { manager } = errors
    return manager ? manager[name] : errors[`manager.${name}`]
  }

  return (
    <S.GridFieldsPhoto container>
      <S.GridFields container item sm={12} md={8} spacing={2}>
        <S.GridInput item sm={12} md={6}>
          <Input
            fullWidth
            label={t('profile:managerName')}
            value={payload.manager.name}
            error={Boolean(err('name'))}
            helperText={err('name')}
            onInput={value => handleInput(value, 'manager', 'name')}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={6}>
          <InputCpfCnpj
            fullWidth
            label={t('profile:cpfNumberManager')}
            value={payload.manager.doc}
            error={Boolean(err('doc'))}
            helperText={err('doc')}
            type="cpf"
            onInput={value => handleInput(value, 'manager', 'doc')}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={12}>
          <Input
            fullWidth
            label={t('common:about')}
            value={payload.manager.bio}
            error={Boolean(err('bio'))}
            helperText={err('bio')}
            multiline
            rows={3}
            onInput={value => handleInput(value, 'manager', 'bio')}
          />
        </S.GridInput>
      </S.GridFields>
      <S.GridPhoto item sm={12} md={4}>
        <S.DividerVertical orientation="vertical" />
        <S.CardPhoto>
          <Picture
            name="manager.photo"
            imgUrl={payload.manager.photo ? payload.manager.photo.src : ''}
            onLoadImage={onLoadImage}
            onRemoveImage={onRemoveImage}
          />
        </S.CardPhoto>
      </S.GridPhoto>
    </S.GridFieldsPhoto>
  )
}

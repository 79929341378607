export const INITIAL_CONTACT_STATE = {
  email: '',
  name: '',
  phone: ''
}
export const ERRORS_CONTACT_STATE = {
  email: '',
  name: '',
  phone: ''
}

export const CONTACTS_FORM = [INITIAL_CONTACT_STATE]

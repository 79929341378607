import { ClientGroupType } from 'types/ClientGroup.types'
import { TableNotFoundHint } from 'components/TableNotFoundHint'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type ClientGroupProps = {
  payload: ClientGroupType[]
}

export function ClientGroup({ payload }: ClientGroupProps) {
  const { t } = useTranslation()
  return (
    <S.Wrapper data-testid="ClientGroup">
      <S.TableGroup>
        <S.ScrollWrapper>
          <S.Table id="table-scrolled">
            <tbody>
              <S.Line id="header">
                <S.Header>{t('clientGroup:groupName')}</S.Header>
                <S.Header>{t('clientGroup:groupAdmin')}</S.Header>
                <S.Header>{t('clientGroup:adminEmail')}</S.Header>
                <S.Header>{t('clientGroup:view')}</S.Header>
              </S.Line>
              {payload.map((each, key) => (
                <S.Line id="values" key={key}>
                  <S.Column>{each.name}</S.Column>
                  <S.Column>{each.adminName}</S.Column>
                  <S.Column>{each.adminEmail}</S.Column>
                  <S.Column>
                    {each.visible
                      ? t('clientGroup:released')
                      : t('clientGroup:notReleased')}
                  </S.Column>
                </S.Line>
              ))}
            </tbody>
          </S.Table>
        </S.ScrollWrapper>
      </S.TableGroup>
      {!payload.length && <TableNotFoundHint to="/grupo-cliente" />}
    </S.Wrapper>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux'
import { all } from 'store/TraceabilityTables/TraceabilityTables.selector'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { traceabilityTablesActions } from 'store/TraceabilityTables'
import * as S from './styled'
import { NewCard } from 'components/Cards/NewCards'
import { theme } from 'config'
import { THEMES_NAME } from 'config/theme.config'
import { BarChart } from 'components/Charts'
import { all as allDrawer } from 'store/Sidebar/Sidebar.selector'

const links = {
  invoices: '/notas-fiscais',
  sifProd: '/sif-producao',
  netWeight: '/peso-liquido',
  properties: '/properties'
}

export function ConsolidationCards() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    invoicesByMonth,
    chartsLoading,
    trackedTonsByMonth,
    properties,
    invoices,
    sifProduction,
    trackedTons,
    cardsLoading,
    filters
  } = useSelector(all)
  const { persistent } = useSelector(allDrawer)
  const i18nLang = window.localStorage.getItem('i18nextLng')
  const monthsTranslation = t('common:months')
  const invoicesTranslation = t('common:invoices')
  const amountTranslation = t('common:amount')

  const invoiceSeries = {
    name: monthsTranslation,
    data: invoicesByMonth
  }
  const trackedTonsSeries = {
    name: monthsTranslation,
    data: trackedTonsByMonth
  }

  useEffect(() => {
    if (filters.begin && filters.end && !cardsLoading) {
      dispatch(traceabilityTablesActions.getKpisCards(filters))
    }
  }, [filters])
  useEffect(() => {
    if (filters.begin && filters.end && !chartsLoading) {
      dispatch(
        traceabilityTablesActions.getKpisCharts({
          ...filters,
          years: filters.years.length
            ? filters.years
            : [new Date().getFullYear()]
        })
      )
    }
  }, [dispatch, filters])

  return (
    <S.GridContainer data-testid="cards">
      <S.GridIconCard item xs={12} md={4}>
        <S.GridCard item xs={12} md={11}>
          <NewCard
            variant="darkGreen"
            cursor="pointer"
            quantity={(invoices && invoices.toLocaleString(i18nLang)) || 0}
            redirect={links.invoices}
            service={t('invoices')}
            description={t('accessInvoices')}
            icon={
              theme.appTheme === THEMES_NAME.jbsFriboi
                ? 'calendar-right-jbs'
                : 'calendar-right'
            }
            isLoading={cardsLoading}
          />
        </S.GridCard>
        <S.GridCard item xs={12} md={11}>
          <NewCard
            variant="boldGreen"
            cursor="pointer"
            quantity={
              (sifProduction && sifProduction.toLocaleString(i18nLang)) || 0
            }
            redirect={links.sifProd}
            service={t('sifProduction')}
            description={t('accessSifProduction')}
            icon={
              theme.appTheme === THEMES_NAME.jbsFriboi
                ? 'calendar-right-jbs'
                : 'calendar-right'
            }
            isLoading={cardsLoading}
          />
        </S.GridCard>
        <S.GridCard item xs={12} md={11}>
          <NewCard
            variant="green"
            cursor="pointer"
            quantity={(properties && properties.toLocaleString(i18nLang)) || 0}
            redirect={links.properties}
            service={t('properties')}
            description={t('accessProperties')}
            icon={
              theme.appTheme === THEMES_NAME.jbsFriboi
                ? 'calendar-right-jbs'
                : 'calendar-right'
            }
            isLoading={cardsLoading}
          />
        </S.GridCard>
        <S.GridCard item xs={12} md={11}>
          <NewCard
            variant="lightGreen"
            quantity={Number(trackedTons).toLocaleString(i18nLang) || 0}
            cursor="default"
            service={t('netWeight')}
            description={t('accessNetWeightTitle')}
            icon={
              theme.appTheme === THEMES_NAME.jbsFriboi
                ? 'calendar-right-jbs'
                : 'calendar-right'
            }
            isLoading={cardsLoading}
          />
        </S.GridCard>
      </S.GridIconCard>
      <S.GridChart item xs={12} md={8}>
        <S.InvoiceChart>
          <S.GridHeader>
            <S.BoxTitle>
              <S.Title>{invoicesTranslation}</S.Title>
            </S.BoxTitle>
          </S.GridHeader>
          <BarChart
            color="#005304"
            type="column"
            title=""
            subTitle=""
            series={invoiceSeries}
            tooltipSuffix={invoicesTranslation}
            xAxisTitle={monthsTranslation}
            yAxisTitle={amountTranslation}
            height={290}
            width={persistent === false ? 807 : 757}
            isLoading={chartsLoading}
          />
        </S.InvoiceChart>
        <S.WeightChart>
          <S.GridHeader>
            <S.BoxTitle>
              <S.Title>{t('common:netWeightTitle')}</S.Title>
            </S.BoxTitle>
          </S.GridHeader>
          <BarChart
            color="#158A16"
            type="column"
            title=""
            subTitle=""
            height={290}
            series={trackedTonsSeries}
            tooltipSuffix={t('common:tonne')}
            isLoading={chartsLoading}
            width={persistent === false ? 807 : 757}
          />
        </S.WeightChart>
      </S.GridChart>
    </S.GridContainer>
  )
}

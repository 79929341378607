import styled from 'styled-components'

export const CheckButton = styled.button`
  border-radius: 0.5rem;
  font-size: 1.4rem;
  font-weight: 600;
  /* min-width: 6rem; */
  transform-origin: center;
  transition: background-color 50ms, color 50ms, transform 50ms;
  padding: 0.6rem 2.2rem;
  will-change: background-color, color, transform;

  &.default {
    background-color: #f2f3f7;
    color: ${({ theme }) => theme.colors.primary};

    &:hover {
      background-color: ${({ theme }) => theme.colors.secondary.darken(0.6)};
      color: white;
    }
  }

  &.checked {
    background-color: ${({ theme }) =>
      theme.colors.secondary.darken(0.6)} !important;
    color: ${({ theme }) => theme.colors.white} !important;
    &:hover {
      background-color: ${({ theme }) => theme.colors.secondary.darken(0.6)};
    }
  }
`

import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  white-space: normal;
`

export const GridHeader = styled(Grid)`
  display: inline-flexbox;
  margin-bottom: 2.4rem;
`

export const GridTitle = styled(Grid)`
  display: block;
`

export const BoxTitle = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.8rem;
  font-weight: 600;
`

export const Line = styled.div`
  width: auto;
  height: 0.2rem;
  background-color: #f2f3f7;
`

export const GridTable = styled.div`
  margin-top: 2.5rem;
  max-height: 35rem;
  overflow-y: auto;

  ::-webkit-scrollbar {
    height: 1.2rem;
    width: 0.4rem;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray.luminance(0.5)};
    border-radius: 2rem;
  }
`

import { call, put } from 'redux-saga/effects'
import { toasts } from 'utils/toasts'

import { UserRoleService } from '../../services'

import * as actions from './UserRole.actions'

export function* fetchAll() {
  yield put(actions.setLoading({ loading: true }))

  const $userRole = new UserRoleService()
  const {
    success,
    status,
    data: userRole
  } = yield call($userRole.fetchAll.bind($userRole))

  if (success) return yield put(actions.setUserRole({ userRole }))
  if (status === 404) {
    return yield put(actions.setUserRole({ userRole: [] }))
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

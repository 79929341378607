import { useEffect, useState } from 'react'
import { omit } from 'lodash'
import { useDispatch } from 'react-redux'

import { PropertyTmpType } from 'types/PropertyTmp.types'
import { Button, Checkbox, Icon } from 'components'
import { TableNotFoundHint } from 'components/TableNotFoundHint'
import { MiniSpinner } from 'components/MiniSpinner'

import { ModalCompare } from '../../ModalCompare/ModalCompare'

import { propertyTmpActions } from 'store/PropertyTmp'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type TableAddProps = {
  propertiesTmp: PropertyTmpType[]
  loading: boolean
  setPropertiesTmp: (_val) => void
}

export function TableAdd({
  propertiesTmp,
  loading,
  setPropertiesTmp
}: TableAddProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [allCheckboxes, setAllCheckboxes] = useState(false)
  const [checkboxes, setCheckboxes] = useState([])
  const [propertyTmp, setPropertyTmp] = useState(null)
  const [propertySave, setPropertySave] = useState(null)
  const [propertyDiscard, setPropertyDiscard] = useState(null)
  const [propertiesTmpNew, setPropertiesNew] = useState([])
  const [propertiesTmpUpdate, setPropertiesUpdate] = useState([])

  useEffect(() => {
    if (propertyDiscard) {
      const checks = checkboxes.filter(f => f !== propertyDiscard)
      setCheckboxes(checks)

      dispatch(propertyTmpActions.deletePropertyTmp({ id: propertyDiscard }))

      const pTmpNew = propertiesTmpNew.filter(f => f.id !== propertyDiscard)
      const pTmpUpdate = propertiesTmpUpdate.filter(
        f => f.id !== propertyDiscard
      )
      setPropertiesNew(pTmpNew)
      setPropertiesUpdate(pTmpUpdate)

      setPropertyDiscard(null)
    }
  }, [
    dispatch,
    checkboxes,
    propertyDiscard,
    propertiesTmpNew,
    propertiesTmpUpdate
  ])

  useEffect(() => {
    if (propertySave) {
      const pTmpNew = propertiesTmpNew
      pTmpNew.unshift(omit(propertySave, 'property'))
      const pTmpUpdate = propertiesTmpUpdate.filter(
        f => f.id !== propertySave.id
      )
      setCheckboxes([...checkboxes, propertySave.id])
      setPropertiesNew(pTmpNew)
      setPropertiesUpdate(pTmpUpdate)
      setPropertySave(null)
    }
  }, [checkboxes, propertySave, propertiesTmpNew, propertiesTmpUpdate])

  useEffect(() => {
    if (propertiesTmp.length) {
      const pTmpNew = propertiesTmp.filter(f => !f.property)
      const pTmpUpdate = propertiesTmp.filter(f => f.property)
      setPropertiesNew(pTmpNew)
      setPropertiesUpdate(pTmpUpdate)
    }
  }, [propertiesTmp])

  useEffect(() => {
    if (propertiesTmpNew.length) {
      if (checkboxes.length === propertiesTmpNew.length) setAllCheckboxes(true)
      else setAllCheckboxes(false)
      setPropertiesTmp(checkboxes)
    } else {
      setAllCheckboxes(false)
    }
  }, [checkboxes, propertiesTmpNew, setPropertiesTmp])

  function cleanSelectedClients() {
    setCheckboxes([])
  }

  function changeAll() {
    setAllCheckboxes(!allCheckboxes)
    if (!allCheckboxes) {
      const newCheckboxes = new Set(propertiesTmpNew.map(({ id }) => id))
      setCheckboxes(Array.from(newCheckboxes))
    } else cleanSelectedClients()
  }

  function handleCheckbox(id: string) {
    return function handle(val) {
      if (val) setCheckboxes([...checkboxes, id])
      else setCheckboxes(Array.from(new Set(checkboxes.filter(e => e !== id))))
    }
  }

  function descriptionClientSelect() {
    return `${checkboxes.length} ${t('common:SelectedProperties')}`
  }

  function TBody({ propertyTmp }) {
    return (
      <S.Line id="values">
        <S.Column className={propertyTmp.property ? 'import' : ''}>
          <S.CheckboxLine>
            {propertyTmp.property ? (
              <S.AlertIcon fontSize="large" color="primary" />
            ) : (
              <Checkbox
                labelFor=""
                label=""
                value={propertyTmp.id}
                isChecked={
                  !!checkboxes.filter(f => f.includes(propertyTmp.id)).length
                }
                onCheck={handleCheckbox(propertyTmp.id)}
              />
            )}
            {propertyTmp.nameProperty}
          </S.CheckboxLine>
        </S.Column>
        <S.Column className={propertyTmp.property ? 'import' : ''}>
          {
            <Button
              minWidth={5}
              size="xsmall"
              variant={propertyTmp.property ? 'primary' : 'default'}
              onClick={() => setPropertyTmp(propertyTmp)}
            >
              Log
            </Button>
          }
        </S.Column>
        <S.Column className={propertyTmp.property ? 'import' : ''}>
          {propertyTmp.CAR}
        </S.Column>
        <S.Column className={propertyTmp.property ? 'import' : ''}>
          {propertyTmp.nameProducer}
        </S.Column>
        <S.Column className={propertyTmp.property ? 'import' : ''}>
          {propertyTmp.CPFCNPJ}
        </S.Column>
        <S.Column className={propertyTmp.property ? 'import' : ''}>
          {propertyTmp.IE}
        </S.Column>
      </S.Line>
    )
  }

  return (
    <S.Wrapper data-testid="TableAdd">
      {propertyTmp ? (
        <ModalCompare
          propertyTmp={propertyTmp}
          save={setPropertySave}
          discard={setPropertyDiscard}
          onExit={() => setPropertyTmp(null)}
        />
      ) : null}
      {propertiesTmpNew && checkboxes.length > 0 && (
        <S.CheckboxInfoGuide>
          <S.SelectedCheckboxInfo>
            <S.RemoveClientIcon
              title={t('common:clean')}
              onClick={cleanSelectedClients}
            >
              <Icon icon="input-exit" height={12} width={12} />
            </S.RemoveClientIcon>
            <span>{descriptionClientSelect()}</span>
          </S.SelectedCheckboxInfo>
        </S.CheckboxInfoGuide>
      )}
      <S.TableGroup>
        <S.ScrollWrapper>
          <S.Table id="table-fixed">
            <thead>
              <S.Line id="header">
                <S.Header>
                  <S.CheckboxLine>
                    <Checkbox
                      labelFor=""
                      label=""
                      value="all"
                      disabled={!propertiesTmpNew.length}
                      checked={allCheckboxes}
                      onCheck={changeAll}
                    />
                    {t('common:property')}
                  </S.CheckboxLine>
                </S.Header>
                <S.Header>{t('common:log')}</S.Header>
                <S.Header>{t('common:carNumber')}</S.Header>
                <S.Header>{t('common:productor')}</S.Header>
                <S.Header>{t('common:cpfCNPJ')}</S.Header>
                <S.Header>{t('tables:IE')}</S.Header>
              </S.Line>
            </thead>
            <tbody>
              {propertiesTmpUpdate.map((propertyTmp, key) => (
                <TBody propertyTmp={propertyTmp} key={key} />
              ))}
              {propertiesTmpNew.map((propertyTmp, key) => (
                <TBody propertyTmp={propertyTmp} key={key} />
              ))}
            </tbody>
          </S.Table>
        </S.ScrollWrapper>
      </S.TableGroup>
      {loading && <MiniSpinner />}
      {!loading && !propertiesTmpNew.length && !propertiesTmpUpdate.length && (
        <TableNotFoundHint />
      )}
    </S.Wrapper>
  )
}

import { AddButton, Button, InputSelect, Pagination } from 'components'
import { ColumnsProps, Table } from 'components/Table/Table'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { productLineActions } from 'store/ProductLine'
import { ModalDeleteProductLine } from '../ModalDeleteProductLine/ModalDeleteProductLine'
import { ProductLineDropdown } from './ProductLineDropdown'
import { partnerActions } from 'store/Partners'
import * as S from './styled'
import { all } from 'store/ProductLine/ProductLine.selector'
import { all as allOptionsPartners } from 'store/Partners/partners.selector'
import { useTranslation } from 'react-i18next'
import i18n from 'i18n'
import * as Yup from 'yup'

const columns: ColumnsProps[] = [
  {
    name: 'productLineName',
    align: 'left',
    title: i18n.t('productLine:productLine')
  },
  { name: 'partnerName', align: 'left', title: i18n.t('partner:partnerEco') }
]

const INITIAL_STATE = {
  id: '',
  partner_id: '',
  pagination: { totalItems: 10 },
  totalItems: 10,
  limit: 10,
  page: 0,
  column: 'name',
  sort: 'asc'
}

const ERRORS_INITIAL_STATE = {
  id: '',
  partner_id: ''
}

const FILTERS_INITIAL_STATE = {
  id: '',
  partner_id: null,
  limit: 10,
  page: 0
}

export function ProductLineList() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [_deleteProductLine, _setDeleteProductLine] = useState({
    id: null,
    productLineName: null
  })

  const { productLines, options } = useSelector(all)
  const { optionsPartner } = useSelector(allOptionsPartners)

  const [filter, setFilter] = useState({ ...INITIAL_STATE })
  const [errors, setErrors] = useState({ ...ERRORS_INITIAL_STATE })

  useEffect(() => {
    dispatch(partnerActions.getPartnerOptions())
  }, [dispatch])

  useEffect(() => {
    dispatch(productLineActions.getProductLineOptions())
  }, [dispatch])

  useEffect(() => {
    dispatch(productLineActions.fetchAll())
  }, [dispatch])

  function clearFilter() {
    const params = {
      ...INITIAL_STATE,
      partner: null,
      column: filter.column,
      sort: filter.sort
    }
    setFilter(params)
    setErrors({ ...INITIAL_STATE })
    dispatch(productLineActions.fetchAll({ params }))
  }

  async function onFilter() {
    try {
      const filterPayload = await Yup.object()
        .shape({
          id: Yup.string(),
          partner_id: Yup.string()
        })
        .validate(filter, {
          abortEarly: false,
          stripUnknown: true
        })

      dispatch(productLineActions.fetchAll(filterPayload))
    } catch (ex) {
      const pairs = ex.inner.map(({ path, message }) => [
        path,
        message.replace(`${path} `, '')
      ])
      setErrors(Object.fromEntries(pairs) as typeof FILTERS_INITIAL_STATE)
    }
  }

  function handleSelected(name: string) {
    return function handle({ value }) {
      setErrors({ ...errors, [name]: '' })
      setFilter({ ...filter, [name]: value })
    }
  }

  function actionsOnTable({ productLineName, id }) {
    return (
      <ProductLineDropdown
        id={id}
        setDeleteProductLine={() =>
          _setDeleteProductLine({ productLineName, id })
        }
      />
    )
  }

  function onExit() {
    _setDeleteProductLine({ id: null, productLineName: null })
  }

  function onRemove() {
    dispatch(
      productLineActions.deleteProductLine({ id: _deleteProductLine.id })
    )
    onExit()
  }

  function onFilterPaginate(params = {}) {
    dispatch(productLineActions.fetchAll({ params }))
  }

  useEffect(() => {
    if (productLines.items.length > 0 && Object.keys(productLines).length) {
      const itens = []
      productLines.items.forEach(p =>
        itens.push({ ...p, partnerName: p.partner.partnerName })
      )

      productLines.items = itens
    }
  })

  return (
    <S.Wrapper container>
      {_deleteProductLine.id ? (
        <ModalDeleteProductLine
          name={_deleteProductLine.productLineName}
          onExit={onExit}
          onRemove={onRemove}
        />
      ) : null}
      <S.ButtonAdd>
        <Link to="/linha-produto/novo">
          <AddButton />
        </Link>
      </S.ButtonAdd>
      <S.FullGrid item xs={12}>
        <S.GridHeader>
          <S.GridTitle item container xs={12}>
            <S.BoxTitle>{t('productLine:filterProductLine')}</S.BoxTitle>
            <S.BoxLine />
          </S.GridTitle>
        </S.GridHeader>
        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={6}>
            <InputSelect
              fullWidth
              label={t('productLine:productLine')}
              optionLabel="label"
              optionValue="value"
              options={options}
              error={Boolean(errors.id)}
              value={filter.id}
              onSelected={handleSelected('id')}
            />
          </S.GridInput>
          <S.GridInput item xs={12} md={6} sm={6}>
            <InputSelect
              fullWidth
              label={t('partner:partnerEco')}
              options={optionsPartner}
              optionLabel="label"
              optionValue="value"
              error={Boolean(errors.partner_id)}
              helperText={errors.partner_id}
              value={filter.partner_id}
              onSelected={handleSelected('partner_id')}
            />
          </S.GridInput>
          <S.GridButtons item xs={12} sm={12} md={12}>
            <Button variant="default" size="medium" onClick={clearFilter}>
              <p>{t('clean')}</p>
            </Button>
            <Button variant="primary" size="medium" onClick={onFilter}>
              <p>{t('filter')}</p>
            </Button>
          </S.GridButtons>
        </S.GridFilter>
        <S.GridTable>
          <Table
            rows={productLines.items}
            columns={columns}
            linkTableNotFound="/linha-produto/novo"
            actionTable={actionsOnTable}
          />
        </S.GridTable>
        {filter.pagination?.totalItems > 0 && (
          <Pagination
            filters={{ ...filter, limit: filter.limit, page: filter.page }}
            limit={filter.limit}
            page={filter.page}
            totalItems={filter.totalItems}
            setFilter={onFilterPaginate}
          />
        )}
      </S.FullGrid>
    </S.Wrapper>
  )
}

import styled from 'styled-components'

export const GridTable = styled.div`
  margin-top: 2.5rem;
  max-height: 35rem;
  overflow-y: auto;

  ::-webkit-scrollbar {
    height: 1.2rem;
    width: 0.4rem;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray.luminance(0.5)};
    border-radius: 2rem;
  }
`

export const TableWrapper = styled.div`
  padding-right: 20px;
`

import { call, put } from 'redux-saga/effects'
import { ProductLineService } from 'services'
import { toasts } from 'utils/toasts'

import * as actions from './ProductLine.actions'

export function* fetchAll(event) {
  yield put(actions.setLoading({ loading: true }))

  const $productLine = new ProductLineService()
  const {
    success,
    status,
    data: productLines
  } = yield call(
    $productLine.fetchAll.bind($productLine),
    event.payload.filters
  )

  yield put(actions.setLoading({ loading: false }))

  if (status === 404) {
    return yield put(
      actions.setProductLines({
        productLines: { items: [], pagination: { totalItems: 0 } }
      })
    )
  } else if (success) {
    return yield put(actions.setProductLines({ productLines }))
  } else toasts.generalFail()
}

export function* filterProductLines() {}

export function* fetchOne(action) {
  yield put(actions.setLoading({ loading: true }))

  const { id } = action.payload

  const $productLine = new ProductLineService()
  const productLine = yield call($productLine.fetchOne.bind($productLine), id)

  if (productLine.success) {
    return yield put(
      actions.setProductLine({
        productLine: productLine.data
      })
    )
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* create(action) {
  yield put(actions.setLoading({ loading: true }))

  const { payload } = action

  const $productLine = new ProductLineService()
  const productLine = yield call(
    $productLine.create.bind($productLine),
    payload
  )

  if (productLine.success) {
    toasts.created()
    return yield put(
      actions.setProductLineCreated({
        productLineCreate: productLine.data
      })
    )
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* edit(action) {
  yield put(actions.setLoading({ loading: true }))

  const { payload } = action

  const $productLine = new ProductLineService()
  const { success, data } = yield call(
    $productLine.edit.bind($productLine),
    payload
  )

  if (success) {
    toasts.edited()
    return yield put(actions.setProductLineEdit({ productLineEdit: data }))
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* deleteProductLine(action) {
  const id = action.payload

  const $productLine = new ProductLineService()
  const productLine = yield call(
    $productLine.deleteProductLine.bind($productLine),
    { id }
  )

  if (productLine.success) {
    toasts.deleted()
    return yield put(actions.fetchAll())
  } else if (productLine.data.code === 'PRODUCT_RELATED') {
    toasts.hasProduct()
  } else toasts.generalFail()
}

export function* getProductLineOptions() {
  const $productLine = new ProductLineService()
  const {
    success,
    status,
    data: options
  } = yield call($productLine.options.bind($productLine))

  if (success) return yield put(actions.setProductLineOptions({ options }))
  else if (status === 404) {
    return yield put(actions.setProductLineOptions({ options: [] }))
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

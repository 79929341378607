import { ColumnsProps } from 'components/Table/Table'
import i18n from 'i18n'

export const columnDownload = (): ColumnsProps[] => [
  { name: 'payload', title: i18n.t('tables:download'), fixed: true }
]

export const allColumns = (): ColumnsProps[] => [
  { name: 'lastTouchBy', title: i18n.t('tables:insertedBy') },
  { name: 'charge', title: i18n.t('tables:charge') },
  { name: 'author', title: i18n.t('tables:author') },
  { name: 'cnpjLocalStock', title: i18n.t('tables:CNPJ') },
  { name: 'dateDispatch', title: i18n.t('tables:shippingDate') },
  { name: 'dateSale', title: i18n.t('tables:saleDate') }
]

import { get } from 'lodash'
import { PropertyTmpType } from 'types/PropertyTmp.types'

import { types } from './PropertyTmp.actions'

type PropertyTmp = {
  propertiesTmp: {
    items: PropertyTmpType[]
    pagination: {
      totalItems: number
      limit: number
      page: number
    }
  }
  loading: boolean
}

const INITIAL_STATE = {
  propertiesTmp: {
    items: [],
    pagination: null
  },
  loading: false
}

function setState(state: PropertyTmp) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.SET_LOADING]: setState(state),
    [types.SET_PROPERTIES_TMP]: setState(state),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}

import { useEffect, useState } from 'react'

import { ClientType } from 'types/Client.types'
import { Checkbox, Icon } from 'components'
import { TableNotFoundHint } from 'components/TableNotFoundHint'
import { MiniSpinner } from 'components/MiniSpinner'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type ClientTableAddProps = {
  clients: ClientType[]
  loading: boolean
  setClients: (_val) => void
}

export function ClientTableAdd({
  clients,
  loading,
  setClients
}: ClientTableAddProps) {
  const { t } = useTranslation()
  const [allCheckboxes, setAllCheckboxes] = useState(false)
  const [checkboxes, setCheckboxes] = useState([])

  useEffect(() => {
    if (clients.length) {
      if (checkboxes.length === clients.length) setAllCheckboxes(true)
      else setAllCheckboxes(false)
      setClients(checkboxes)
    }
  }, [checkboxes, clients, setClients])

  function cleanSelectedClients() {
    setCheckboxes([])
  }

  function changeAll() {
    setAllCheckboxes(!allCheckboxes)
    if (!allCheckboxes) {
      const newCheckboxes = new Set(clients.map(({ id }) => id))
      setCheckboxes(Array.from(newCheckboxes))
    } else cleanSelectedClients()
  }

  function handleCheckbox(id: string) {
    return function handle(val) {
      if (val) setCheckboxes([...checkboxes, id])
      else setCheckboxes(Array.from(new Set(checkboxes.filter(e => e !== id))))
    }
  }

  function descriptionClientSelect() {
    return `${checkboxes.length} ${t('common:customerSelected')}`
  }

  function removeDuplicatedFilterItems(customRetailsFilters) {
    return JSON.stringify(
      Array.from(
        new Set(customRetailsFilters.map(item => item.customColumnFilter))
      )
    ).replaceAll('"', '')
  }

  function formatDescriptionFilter(customRetailsFilters) {
    return JSON.stringify(
      customRetailsFilters.map(item => item.customColumnValue)
    )
      .replace(/[[\\"\]]/g, '')
      .replaceAll(',', ', ')
  }

  return (
    <S.Wrapper data-testid="ClientTableAdd">
      {clients && checkboxes.length > 0 && (
        <S.CheckboxInfoGuide>
          <S.SelectedCheckboxInfo>
            <S.RemoveClientIcon
              title={t('common:clean')}
              onClick={cleanSelectedClients}
            >
              <Icon icon="input-exit" height={12} width={12} />
            </S.RemoveClientIcon>
            <span>{descriptionClientSelect()}</span>
          </S.SelectedCheckboxInfo>
        </S.CheckboxInfoGuide>
      )}
      <S.TableGroup>
        <S.ScrollWrapper>
          <S.Table id="table-fixed">
            <tbody>
              <S.Line id="header">
                <S.Header>
                  <S.CheckboxLine>
                    <Checkbox
                      labelFor=""
                      label=""
                      value="all"
                      disabled={!clients.length}
                      checked={allCheckboxes}
                      onCheck={changeAll}
                    />
                    {t('tables:socialReason')}
                  </S.CheckboxLine>
                </S.Header>
                <S.Header>{t('customFilters:identifier')}</S.Header>
                <S.Header>{t('customFilters:value')}</S.Header>
                <S.Header>{t('customFilters:filter')}</S.Header>
              </S.Line>
              {clients.map((each, key) => (
                <S.Line id="values" key={key}>
                  <S.Column>
                    <S.CheckboxLine>
                      <Checkbox
                        labelFor=""
                        label=""
                        value={each.id}
                        isChecked={
                          !!checkboxes.filter(f => f.includes(each.id)).length
                        }
                        onCheck={handleCheckbox(each.id)}
                      />
                      {each.name}
                    </S.CheckboxLine>
                  </S.Column>
                  <S.Column>{each.cnpj}</S.Column>
                  <S.Column>
                    {each.customRetailsFilters.length > 0 &&
                      formatDescriptionFilter(each.customRetailsFilters)}
                  </S.Column>
                  <S.Column>
                    {each.customRetailsFilters.length > 0 &&
                      removeDuplicatedFilterItems(each.customRetailsFilters)}
                  </S.Column>
                </S.Line>
              ))}
            </tbody>
          </S.Table>
        </S.ScrollWrapper>
      </S.TableGroup>
      {loading && <MiniSpinner />}
      {!loading && !clients.length && <TableNotFoundHint to="/cliente/novo" />}
    </S.Wrapper>
  )
}

import styled from 'styled-components'
interface ButtonProps {
  minWidth: number
}

export const Button = styled.button<ButtonProps>`
  margin: 0 2rem !important;
  border: none;
  outline: none;
  border-radius: 4rem;
  font-size: 1.4rem;
  font-weight: 500;
  transform-origin: center;
  transition: background-color 400ms, color 400ms, transform 400ms;
  will-change: background-color, color, transform;
  width: max-content;
  min-width: ${({ minWidth }) => minWidth}rem;
  padding: 0.1rem 1.2rem;
  &.confirm {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: white;
    &:hover {
      background-color: ${({ theme }) => theme.colors.secondary.darken()};
    }
  }
  &.send {
    background-color: ${({ theme }) => theme.colors.primary2};
    color: white;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary2.darken()};
    }
  }
`

import { INITIAL_STATE as INITIAL_STATE_FILTER } from 'constants/traceability.constants'
import { get } from 'lodash'

import { types } from './TableSIF.actions'

const INITIAL_STATE = {
  filterTableSIF: INITIAL_STATE_FILTER,
  tableSIF: {
    items: null,
    pagination: { limit: null, page: null }
  }
}

function setTableSIF(state) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.SET_TABLE_SIF]: setTableSIF({
      ...state,
      filterTableSIF: {
        ...state.filterTableSIF,
        limit: state.tableSIF.pagination.limit
      }
    }),
    [types.SET_FILTERS_TABLE_SIF]: setTableSIF({
      ...state,
      filterTableSIF: {
        ...state.filterTableSIF,
        limit: state.tableSIF.pagination.limit
      }
    }),
    [types.CLEAN_TABLE_SIF]: setTableSIF(INITIAL_STATE),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}

import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const GridGraphic = styled(Grid)`
  background: white; // pegar tema
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.8rem;

  box-shadow: 0px 11px 29px #00000021;
  width: 35.7rem;
`
export const Graphic = styled.div``
export const Header = styled.div<{
  isLoading: boolean
}>``
export const GraphicData = styled.div`
  position: relative;
  top: -5rem;
  left: -2rem;
  height: 28.5rem;
`
export const Title = styled.h1`
  font-size: 2.4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary};
  text-align: center;
  padding: 0 7rem 2rem 7rem;
`
export const Footer = styled.div`
  display: flex;
  padding-bottom: 2.4rem;
`
export const Description = styled.div`
  padding-left: 1.9rem;
`
export const Subtitle = styled.h3`
  color: ${({ theme }) => theme.colors.primary2};
  font-size: 1.8rem;
  padding-bottom: 0.8rem;
`
export const Text = styled.p`
  color: black;
  font-size: 1.4rem;
`
export const ViewMore = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.lightGray};
`
export const Button = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border: none;
  background: none;
  .icon-graphic {
    height: 2.9rem;
    width: 2.9rem;
    margin-bottom: -3px;
    margin-right: 1.5rem;
    &:hover {
      background: #00000006;
      border-radius: 50%;
    }
  }
`
export const Percentual = styled.div`
  position: absolute;
  top: 18rem;
  left: 15.5rem;
  z-index: 9999;
  h1 {
    font-size: 4rem;
    color: ${({ theme }) => theme.colors.primary2};
  }
`

import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  white-space: normal;
`

export const GridHeader = styled(Grid)`
  display: inline-flexbox;
  margin-bottom: 2.4rem;
`

export const GridTitle = styled(Grid)`
  display: block;
`

export const BoxTitle = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.8rem;
  font-weight: 600;
`

export const Line = styled.div`
  width: auto;
  height: 0.2rem;
  background-color: #f2f3f7;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.6rem;
  margin-bottom: 0.8rem;

  overflow-x: auto;
  width: 100%;
  max-height: 60rem;
  overflow-y: auto;

  ::-webkit-scrollbar {
    height: 0.4rem;
    width: 0.4rem;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray.luminance(0.5)};
    border-radius: 2rem;
  }
`
interface RowProps {
  index?: number
}

export const Header = styled.div`
  margin-top: 0.4rem;
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.lightGray.luminance(9)};
`

export const Row = styled.div<RowProps>`
  margin-top: 0.4rem;
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 0.6rem;
  background: ${({ theme, index }) =>
    index % 2 === 0
      ? theme.colors.lightGray.luminance(0.7)
      : theme.colors.lightGray.luminance(0.9)};
`

export const Title = styled.p`
  flex: 1;
  font-size: 1.8rem;
  font-weight: 600;
`

export const Field = styled.p`
  flex: 1;
  font-size: 1.6rem;
`
export const Value = styled.p`
  flex: 1;
  font-size: 1.6rem;

  &.import {
    font-style: italic;
    font-weight: 600;
  }
`

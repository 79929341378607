/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState, MouseEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IconButton } from '@material-ui/core'

import {
  Icon,
  ModalMapMarker,
  Pagination,
  Table,
  ClusterMaps,
  NewPagination
} from 'components'
import { columnsInvoice } from 'constants/propertiesInvoice.constants'

import { all } from 'store/TraceabilityTables/TraceabilityTables.selector'
import { all as allProperties } from 'store/Properties/Properties.selector'
import { INITIAL_STATE } from 'constants/traceability.constants'
import { PropertiesActions } from 'store/Properties'
import { all as allOrders } from 'store/Order/Order.selector'
import { calcOffsetForPagination } from 'utils/calcOffsetForPagination'

import { GridHeader } from '../GridHeader'
import { useTranslation } from 'react-i18next'

import * as S from './styled'
import { theme } from 'config'
import { THEMES_NAME } from 'config/theme.config'
import { ThumbDown, ThumbUp } from '@material-ui/icons'

export function PropertiesTable() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { filters, tableLoading } = useSelector(all)
  const { filterProperties } = useSelector(allProperties)
  const { ordersKeys } = useSelector(allOrders)

  const [filter, setFilter] = useState({ ...INITIAL_STATE })
  const [latLng, setLatLng] = useState(null)
  const [showPropertiesMap, setShowPropertiesMap] = useState(false)

  const { properties, propertiesLocation } = useSelector(allProperties)
  const { pagination } = properties
  const [isNewPage, setNewPage] = useState({ ...pagination })
  const fetch = useCallback(() => {
    setFilter(filters)
    if (!properties.items) {
      dispatch(PropertiesActions.getProperties(filters, ordersKeys))
    }
  }, [dispatch, filters, ordersKeys, properties.items])

  const applyIconForAnalysisField = () => {
    return properties.items?.map(item => ({
      ...item,
      analyze: item.analyze ? (
        <ThumbUp fontSize="large" style={{ color: '#00c853' }} />
      ) : (
        <ThumbDown fontSize="large" style={{ color: '#c62828' }} />
      )
    }))
  }

  useEffect(() => {
    fetch()
  }, [fetch])

  function viewInMap(property) {
    const point = {
      lat: parseFloat(property.proplat.replace(/,/, '.')),
      lng: parseFloat(property.proplng.replace(/,/, '.'))
    }
    setLatLng(point)
  }

  function onExitIndividualMap() {
    setLatLng(null)
  }

  function onExitPropertiesMap() {
    dispatch(PropertiesActions.cleanPropertiesLocation())
    setShowPropertiesMap(false)
  }
  async function handleChangePage(
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    const newFilter = {
      ...filterProperties,
      ...filters
    }
    newFilter.page = newPage
    dispatch(PropertiesActions.setFilters({ ...newFilter }))
    dispatch(PropertiesActions.getProperties(newFilter, ordersKeys))
  }
  function handleNewPage() {
    const newPage = isNewPage.page
    if (newPage > pagination.totalPages) {
      const newFilter = {
        ...filterProperties,
        ...filters
      }
      newFilter.page = pagination.totalPages
      dispatch(PropertiesActions.setFilters({ ...newFilter }))
      dispatch(PropertiesActions.getProperties(newFilter, ordersKeys))
      setNewPage({ ...pagination, page: newPage })
    } else {
      const newFilter = {
        ...filterProperties,
        ...filters
      }
      newFilter.page = newPage
      dispatch(PropertiesActions.setFilters({ ...newFilter }))
      dispatch(PropertiesActions.getProperties(newFilter, ordersKeys))
      setNewPage({ ...pagination, page: newPage })
    }
  }
  function handleInputPagination(name: string, value: number) {
    setNewPage({ ...pagination, [name]: value })
  }

  function handleChangeRowsPerPage(e) {
    const newFilter = {
      ...filterProperties,
      ...filters
    }
    newFilter.limit = e.target.value
    dispatch(PropertiesActions.setFilters({ ...newFilter }))
    dispatch(PropertiesActions.getProperties(newFilter, ordersKeys))
  }

  function onPaginateFilter({ limit, page }) {
    const newFilter = {
      ...filterProperties,
      ...filters
    }

    newFilter.page = calcOffsetForPagination(
      properties.pagination.limit,
      properties.pagination.page,
      limit,
      page
    )
    newFilter.limit = limit

    dispatch(PropertiesActions.setFilters({ ...newFilter }))
    dispatch(PropertiesActions.getProperties(newFilter, ordersKeys))
  }

  async function fetchPropertiesLocationsForMap() {
    dispatch(PropertiesActions.getPropertiesLocation(filters, ordersKeys))
    setShowPropertiesMap(true)
  }

  function actionsOnTable(each) {
    return (
      <IconButton
        id="action"
        color="inherit"
        aria-label="mapButton"
        onClick={() => viewInMap(each)}
        size="medium"
        edge="start"
      >
        <Icon
          icon={
            theme.appTheme === THEMES_NAME.swift ? 'map-pin-swift' : 'map-pin'
          }
          width={12}
          height={12}
          aria-label="action"
        />
      </IconButton>
    )
  }

  return (
    <>
      {latLng && (
        <ModalMapMarker latLng={latLng} onExit={onExitIndividualMap} />
      )}
      {showPropertiesMap && <ClusterMaps data={propertiesLocation} />}
      <S.FullGrid item xs={12}>
        <GridHeader
          title={t('common:propertyList')}
          mapButton={false}
          onClikMap={() => fetchPropertiesLocationsForMap()}
        />
        <S.GridTable>
          <Table
            columns={columnsInvoice()}
            rows={applyIconForAnalysisField() || []}
            sortColumn={{ column: filter.column, sort: filter.direction }}
            actionTable={actionsOnTable}
            actionColumnLabel={t('common:localization')}
            isLoading={tableLoading}
            isScroll={properties.items?.length > 10}
          />
          {properties.pagination?.totalItems > 0 && (
            <NewPagination
              count={pagination?.totalPages}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 25, 50, 100]}
              handleInput={handleInputPagination}
              handleNewPage={handleNewPage}
              page={pagination?.page}
              limit={pagination.limit}
            />
          )}
        </S.GridTable>
      </S.FullGrid>
    </>
  )
}

import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const Wrapper = styled(Grid)`
  display: flex;
  margin: 0;
  width: calc(100% +12px);
  flex-direction: row;
`

export const GridFull = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 5px 25px #0000000d;
  border-radius: 1.2rem;
  padding: 2.4rem;
  span {
    font-size: 1.4rem;
    color: #464a53;
    opacity: 1;
    font-weight: 800;
    padding: 0.3rem 0 0 0;
  }
`
export const GridButtons = styled(Grid)`
  align-self: center;
  text-align: end;
  margin: 3.2rem 0;

  button {
    margin: 0;
  }

  button + button {
    margin-left: 2.4rem;
  }
`

import { HTTP } from 'providers'
import { PropertyType } from 'types/Property.types'

export class PropertyService extends HTTP {
  private endpoint = '/properties'
  private config: Record<string, any> = {
    showLoading: true
  }

  public fetchAll({ params = {} } = {}) {
    return this.get({ endpoint: `${this.endpoint}/list`, params })
  }

  public create({ payload }: { payload: PropertyType }) {
    return this.post({
      endpoint: this.endpoint,
      payload,
      config: this.config
    })
  }

  public fetchOne(id) {
    return this.get({
      endpoint: `${this.endpoint}/${id}`
    })
  }

  public edit(property: PropertyType) {
    const { id } = property
    return this.put({
      endpoint: `${this.endpoint}/${id}`,
      payload: property,
      config: this.config
    })
  }

  public deleteProperty({ id }) {
    return this.delete({
      endpoint: `${this.endpoint}/${id}`
    })
  }

  public importProperties({ propertiesTmpIds }) {
    return this.post({
      endpoint: `${this.endpoint}/import`,
      payload: { propertiesTmpIds },
      config: this.config
    })
  }

  public addProducer({ producer, id }) {
    return this.put({
      endpoint: `${this.endpoint}/addProducer/${id}`,
      payload: { producers: producer },
      config: this.config
    })
  }

  public blockStatusProducer({ producer, id }) {
    return this.put({
      endpoint: `${this.endpoint}/changingLockState/${id}`,
      payload: { producers: producer },
      config: this.config
    })
  }

  public blockStatusAllProducer({ blockStatus, propertyId }) {
    return this.put({
      endpoint: `${this.endpoint}/changingLockAllState/${propertyId}`,
      payload: { ...blockStatus },
      config: this.config
    })
  }
}

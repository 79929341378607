import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const GridFields = styled(Grid)`
  display: flex;
  margin: 0px;
  width: calc(100% + 24px);
  flex-direction: row;
  margin-left: -6px;
`
export const GridInput = styled(Grid)``

import { IconButton } from '@material-ui/core'
import { Button } from 'components/Button'
import { Icon } from 'components/Icon'
import { Modal } from 'components/Modal'
import { useTranslation } from 'react-i18next'
import * as S from './styled'

export type ModalDeleteUserProps = {
  onExit?: () => void
  setDeleteUser?: () => void
}

export function ModalDeleteProfile({
  onExit,
  setDeleteUser
}: ModalDeleteUserProps) {
  const { t } = useTranslation()
  const button = (
    <Button variant="secondary" onClick={setDeleteUser} size="medium">
      <p>{t('common:delete')}</p>
    </Button>
  )

  const content = (
    <S.Wrapper>
      <S.DivTitle>
        <S.GridHeader>
          <S.GridTitle item container xs={12}>
            <IconButton
              id="action"
              aria-label="mapButton"
              onClick={() => {}}
              size="medium"
              edge="start"
              style={{ backgroundColor: '#F2F3F7', padding: '1.4rem' }}
            >
              <Icon
                icon="dropdown-delete"
                width={14}
                height={14}
                aria-label="action"
              />
            </IconButton>
            <S.BoxTitle>{t('common:deleteAccount')}</S.BoxTitle>
          </S.GridTitle>
        </S.GridHeader>
        <S.Line />
      </S.DivTitle>

      <S.GridFields container spacing={2}>
        <S.GridInput item sm={12}>
          <S.GridDescription>
            {t('common:deleteAccountDescriptionOne')}{' '}
            <span>{t('common:deleteAccountDescriptionTwo')}</span>
          </S.GridDescription>
        </S.GridInput>
      </S.GridFields>
    </S.Wrapper>
  )

  return (
    <Modal
      button={button}
      content={content}
      isActionsEnabled={true}
      width={60}
      onExit={onExit}
    />
  )
}

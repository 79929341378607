import { get } from 'lodash'

import { types } from './UserRole.actions'

type UserRole = {
  userRole: any
  loading: boolean
}

const INITIAL_STATE = {
  userRole: [],
  loading: false
}

function setState(state: UserRole) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.SET_LOADING]: setState(state),
    [types.SET_USER_ROLE]: setState(state),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}

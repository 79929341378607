import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const GridContainer = styled(Grid)`
  padding-top: 3.5rem;
  display: flex;
`

export const GridIconCard = styled(Grid)``
export const GridChart = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 4rem;
`
export const GridCard = styled(Grid)`
  border-radius: 0.8rem;
  box-shadow: 0px 11px 29px #00000021;
  margin-bottom: 40px;
`
export const GridCards = styled(Grid)`
  display: flex;
  flex-direction: column;
`
export const GridHeader = styled.div`
  padding-top: 2.4rem;
  padding-left: 2.4rem;
  padding-bottom: 3.1rem;
`
export const BoxTitle = styled.h2``
export const InvoiceChart = styled.div`
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0px 11px 29px #00000021;
  border-radius: 0.8rem;
`
export const WeightChart = styled.div`
  background-color: #fff;
  padding: 1.4rem;
  box-shadow: 0px 11px 29px #00000021;
  border-radius: 0.8rem;
`
export const Title = styled.div`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: 600;
  padding-left: 2.4rem;
`

import { TraceabilityFilter } from 'types/Traceability.types'

export const types = Object.freeze({
  GET_KPIS_PROPERTIES_DETAILS: '@propertiesDetails/getKpisPropertiesDetails',
  SET_KPIS_PROPERTIES_DETAILS: '@propertiesDetails/setKpisPropertiesDetails',
  CLEAN_KPIS_PROPERTIES_DETAILS:
    '@propertiesDetails/cleanKpisPropertiesDetails',
  GET_KPIS_PROPERTIES_SUMMARY: '@propertiesDetails/getKpisPropertiesSummary',
  GET_KPIS_PROPERTIES_EXPORT: '@propertiesDetails/getKpisPropertiesExport',
  SET_KPIS_PROPERTIES_SUMMARY: '@propertiesDetails/setKpisPropertiesSummary',
  CLEAN_KPIS_PROPERTIES_SUMMARY:
    '@propertiesDetails/cleanKpisPropertiesSummary',
  SET_SHOW_MODAL: '@propertiesDetails/setShowModal'
})

export function getKpisPropertiesDetails(
  filterProperties?: TraceabilityFilter
) {
  return {
    type: types.GET_KPIS_PROPERTIES_DETAILS,
    payload: { filterProperties }
  }
}

export function cleanPropertiesDetails() {
  return { type: types.CLEAN_KPIS_PROPERTIES_DETAILS }
}

export function setKpisPropertiesDetails({ kpiPropertiesDetails = [] } = {}) {
  return {
    type: types.SET_KPIS_PROPERTIES_DETAILS,
    payload: { kpiPropertiesDetails }
  }
}

export function getKpisPropertiesSummary(
  filterProperties?: TraceabilityFilter
) {
  return {
    type: types.GET_KPIS_PROPERTIES_SUMMARY,
    payload: { filterProperties }
  }
}

export function getKpisPropertiesExported(
  filterProperties?: TraceabilityFilter
) {
  return {
    type: types.GET_KPIS_PROPERTIES_EXPORT,
    payload: { filterProperties }
  }
}

export function cleanPropertiesSummary() {
  return { type: types.CLEAN_KPIS_PROPERTIES_SUMMARY }
}

export function setKpisPropertiesSummary({ kpiPropertiesSummary = [] } = {}) {
  return {
    type: types.SET_KPIS_PROPERTIES_SUMMARY,
    payload: { kpiPropertiesSummary }
  }
}

export function setShowModal(showKpiPropertiesDetailsModal: boolean) {
  return {
    type: types.SET_SHOW_MODAL,
    payload: { showKpiPropertiesDetailsModal }
  }
}

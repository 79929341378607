import { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { omit } from 'lodash'

import { Button, ContactForm, InputAutocomplete, Location } from 'components'

import { IndustryGroup } from './IndustryGroup/IndustryGroup'
import { IndustryPhotos } from './IndustryPhotos/IndustryPhotos'
import { IndustrySIF } from './IndustryPhotos/IndustrySIF'
import { BasicInformation } from './BasicInformation/BasicInformation'
import { Manager } from './Manager/Manager'
import { Technician } from './Technician/Technician'

import { YupValidator } from 'services/yupValidator.service'
import { IndustryFormSchema } from 'store/Industry/Industry.validator'

import { mapErrors } from 'utils/MapErrors'

import { CityActions } from 'store/City'
import { all } from 'store/Industry/Industry.selector'
import { all as allCity } from 'store/City/City.selector'

import { industryActions } from 'store/Industry'
import { useTranslation } from 'react-i18next'

import {
  CONTACT_INITIAL_STATE,
  ERRORS_INITIAL_STATE,
  INITIAL_STATE,
  QUALIFICATIONS as qualifications
} from 'constants/industry.constants'

import * as S from './styled'

interface ParamTypes {
  id: string
}

export type LatLngType = {
  lat: number
  lng: number
}

export function IndustryForm() {
  const { t } = useTranslation()
  const { id } = useParams<ParamTypes>()
  const [form, setForm] = useState({ ...INITIAL_STATE })
  const [errors, setErrors] = useState({ ...ERRORS_INITIAL_STATE })
  const [toUploadImages, setUploadImages] = useState<Record<string, File>>({})
  const [imgIndustry, setImgIndustry] = useState<File[]>([])
  const [deletedImgIndustry, setDeletedImgIndustry] = useState<string[]>([])

  const { industry, industryCreate, industryEdit } = useSelector(all)
  const { cities } = useSelector(allCity)

  const dispatch = useDispatch()
  const { goBack } = useHistory()

  useEffect(() => {
    if (id) {
      dispatch(industryActions.fetchOne(id))
    }
  }, [dispatch, id])

  useEffect(() => {
    if (industryCreate.id || industryEdit.id) {
      goBack()
      dispatch(industryActions.cleanIndustry())
    }
  }, [industryCreate, industryEdit, goBack, dispatch])

  useEffect(() => {
    if (form.state) {
      dispatch(CityActions.fetchByUf({ uf: form.state }))
    }
  }, [form.state, dispatch])

  useEffect(() => {
    if (Object.keys(industry).length) {
      const _form = {
        ...omit(industry, ['employees', 'photos'])
      } as typeof INITIAL_STATE

      const qualifications = []
      industry.qualifications.forEach(q =>
        qualifications.push({ qualificationsName: q })
      )

      _form.qualifications = qualifications

      industry.employees.forEach(employee => {
        _form[employee.role] = {
          bio: employee.employee.bio,
          doc: employee.employee.doc,
          name: employee.employee.name,
          photo: employee.employee.photo
        }
      })

      const _photos = []
      industry.photos.forEach(photo => {
        const { file, id } = photo as any
        _photos.push({ ...file, photoId: id })
      })
      _form.photos = _photos

      if (_form.lat === null) {
        _form.lat = ''
      }

      if (_form.lng === null) {
        _form.lng = ''
      }

      setErrors({
        ...ERRORS_INITIAL_STATE,
        contacts: _form.contacts.map(_contact => CONTACT_INITIAL_STATE)
      })

      setForm({ ..._form })
    }
  }, [industry])

  function handleSelectImages(e: ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) {
      return
    }

    const imgs = Array.from(e.target.files)

    const photos = []
    const images = []
    imgs.forEach((img, index) => {
      if (form.photos.length + (index + 1) <= 4) {
        images.push(img)
        photos.push({ filename: img.name, src: URL.createObjectURL(img) })
      }
    })

    setImgIndustry([...imgIndustry, ...images])
    setForm({ ...form, photos: [...form.photos, ...photos] })
  }

  function onLoadImage({ name, field }: { name: string; field?: string }) {
    return function handleSelectImage(e: ChangeEvent<HTMLInputElement>) {
      if (!e.target.files) return

      const [rawImg] = Array.from(e.target.files)
      const img = { filename: rawImg.name, src: URL.createObjectURL(rawImg) }

      if (field) {
        setUploadImages(images => ({ ...images, [name]: rawImg }))
        setForm({ ...form, [name]: { ...form[name], [field]: img } })
        return
      }

      setUploadImages(images => ({ ...images, [name]: rawImg }))
      setForm({ ...form, [name]: img })
      setErrors({ ...errors, [name]: '' })
    }
  }

  function onRemoveImage({ name, field }: { name: string; field?: string }) {
    if (field) {
      setUploadImages(images => ({ ...images, [field]: null }))
      setForm({ ...form, [name]: { ...form[name], [field]: null } })
    } else {
      setUploadImages(images => ({ ...images, [name]: null }))
      setForm({ ...form, [name]: null })
    }
  }

  function handleRemoveItemImage(index) {
    const { photos } = form
    const { id } = photos.find((_, i) => i === index)

    if (id) {
      setDeletedImgIndustry([...deletedImgIndustry, id])
    }

    if (photos.length === 1) {
      setImgIndustry([])
      setForm({ ...form, photos: [] })
    } else {
      const imgs = [...photos]
      imgs.splice(index, 1)

      setImgIndustry([...imgs])
      setForm({ ...form, photos: [...imgs] })
    }
  }

  function handleInput(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setForm({ ...form, [name]: value })
  }

  function handleInputObject(value: string, name: string, field: string) {
    setErrors({ ...errors, [name]: { ...errors[name], [field]: '' } })
    setForm({ ...form, [name]: { ...form[name], [field]: value } })
  }

  function onFillContacts(key, val, index) {
    const updated = [...form.contacts]

    updated[index] = {
      ...updated[index],
      [key]: val
    }

    setForm({ ...form, contacts: updated })
  }

  function handleSelected(name: string) {
    return function handle(value) {
      setErrors({ ...errors, [name]: '' })
      if (name === 'state') {
        setForm({ ...form, [name]: value.value, city: '' })
      } else if (name === 'city') {
        setForm({ ...form, [name]: value.title })
      } else {
        setForm({ ...form, [name]: value.value })
      }
    }
  }

  async function submit() {
    setErrors({
      ...ERRORS_INITIAL_STATE,
      contacts: form.contacts.map(_contact => CONTACT_INITIAL_STATE)
    })

    const _form = {
      ...form,
      lat: form.lat || null,
      lng: form.lng || null
    }

    const [isValid, validationError] = await new YupValidator(
      IndustryFormSchema()
    ).validate(_form)

    if (!isValid) {
      return setErrors(validationError as typeof ERRORS_INITIAL_STATE)
    }

    const payload = {
      ..._form,
      qualifications: _form.qualifications.map(
        ({ qualificationsName }) => qualificationsName
      )
    }

    dispatch(
      id
        ? industryActions.editIndustry(
            payload,
            toUploadImages,
            imgIndustry,
            deletedImgIndustry
          )
        : industryActions.createIndustry(payload, toUploadImages, imgIndustry)
    )
  }

  function onAddItem() {
    const contacts =
      errors?.contacts?.length > 0
        ? errors?.contacts
        : mapErrors(errors, 'contacts')

    setErrors({
      ...errors,
      contacts: [...contacts, { ...CONTACT_INITIAL_STATE }]
    })

    setForm({
      ...form,
      contacts: [...form.contacts, { ...CONTACT_INITIAL_STATE }]
    })
  }

  function onRemoveItem(index) {
    const item = form.contacts

    const contacts =
      errors?.contacts?.length > 0
        ? errors?.contacts
        : mapErrors(errors, 'contacts')
    const itemError = contacts

    if (item.length === 1) {
      setForm({ ...form, contacts: [] })
      setErrors({ ...errors, contacts: [] })
      return
    }

    const updated = [...item]
    const updatedErrors = [...itemError]
    updated.splice(index, 1)
    updatedErrors.splice(index, 1)
    setForm({ ...form, contacts: updated })
    setErrors({ ...errors, contacts: updatedErrors })
  }

  function onExit() {
    goBack()
    dispatch(industryActions.cleanIndustry())
  }

  function handleAutocomplete(field: string, value: any) {
    setErrors({ ...errors, [field]: '' })
    setForm({ ...form, [field]: value })
  }

  return (
    <S.Wrapper container>
      <S.FullGrid item xs={12}>
        <S.Fieldset>
          <S.GridHeader>
            <S.GridTitle item container xs={12}>
              <S.BoxTitle>{t('users:basicInformations')}</S.BoxTitle>
            </S.GridTitle>
          </S.GridHeader>

          <BasicInformation
            payload={form}
            errors={errors}
            handleInput={handleInput}
            handleSelected={handleSelected}
          />
        </S.Fieldset>

        <S.Fieldset>
          <S.GridHeader>
            <S.GridTitle item container xs={12}>
              <S.BoxTitle>{t('users:industryQualifications')}</S.BoxTitle>
            </S.GridTitle>
          </S.GridHeader>

          <S.GridFields container>
            <S.GridInput item sm={12} md={12}>
              <InputAutocomplete
                fullWidth
                error={Boolean(errors.qualifications)}
                helperText={errors.qualifications}
                label={t('users:selectQualifications')}
                options={qualifications}
                optionLabel="qualificationsName"
                defaultValue={form.qualifications}
                onSelected={val => handleAutocomplete('qualifications', val)}
              />
            </S.GridInput>
          </S.GridFields>
        </S.Fieldset>

        <S.Fieldset>
          <S.GridHeader>
            <S.GridTitle item container xs={12}>
              <S.BoxTitle>{t('common:localization')}</S.BoxTitle>
            </S.GridTitle>
          </S.GridHeader>

          <Location
            cities={cities}
            errors={errors}
            optionValueCity="title"
            payload={form}
            handleInput={handleInput}
            handleSelected={handleSelected}
          />
        </S.Fieldset>

        <S.Fieldset>
          <S.GridHeaderContact>
            <S.GridTitleContact item container xs={12}>
              <S.BoxTitleContact>{t('users:contactDetails')}</S.BoxTitleContact>
            </S.GridTitleContact>
          </S.GridHeaderContact>

          {form.contacts.map((contact, i) => (
            <ContactForm
              errors={errors}
              index={i}
              key={i}
              payload={contact}
              onFill={onFillContacts}
              removeButton={Boolean(form.contacts.length > 1)}
              onRemove={() => onRemoveItem(i)}
            />
          ))}

          <S.GridFields container spacing={2}>
            <S.GridButtonsAdd item sm={12}>
              <Button variant="primary" onClick={onAddItem}>
                <p>{t('users:addContact')}</p>
              </Button>
            </S.GridButtonsAdd>
          </S.GridFields>
        </S.Fieldset>

        <S.DividerHorizontal />

        {id && (
          <S.Fieldset>
            <S.GridHeader>
              <S.GridTitle item container xs={12}>
                <S.BoxTitle>{t('breadcrumb:industryRelatedGroup')}</S.BoxTitle>
              </S.GridTitle>
            </S.GridHeader>

            <IndustryGroup payload={form.groups} />
          </S.Fieldset>
        )}

        <S.Fieldset>
          <S.GridHeader>
            <S.GridTitle item container xs={12}>
              <S.BoxTitle>{t('common:responsibleTechnician')}</S.BoxTitle>
            </S.GridTitle>
          </S.GridHeader>

          <Technician
            payload={form}
            errors={errors}
            handleInput={handleInputObject}
            onLoadImage={onLoadImage({ name: 'technician', field: 'photo' })}
            onRemoveImage={() =>
              onRemoveImage({ name: 'technician', field: 'photo' })
            }
          />
        </S.Fieldset>

        <S.Fieldset>
          <S.GridHeader>
            <S.GridTitle item container xs={12}>
              <S.BoxTitle>{t('common:responsibleManager')}</S.BoxTitle>
            </S.GridTitle>
          </S.GridHeader>

          <Manager
            payload={form}
            errors={errors}
            handleInput={handleInputObject}
            onLoadImage={onLoadImage({ name: 'manager', field: 'photo' })}
            onRemoveImage={() =>
              onRemoveImage({ name: 'manager', field: 'photo' })
            }
          />
        </S.Fieldset>

        <S.Fieldset>
          <S.GridHeader container>
            <S.GridTitle item xs={8}>
              <S.BoxTitle>{t('profile:industryPhoto')}</S.BoxTitle>
            </S.GridTitle>
            <S.GridTitleSIF item xs={4}>
              <S.BoxTitle>{t('profile:SIFPhoto')}</S.BoxTitle>
            </S.GridTitleSIF>
          </S.GridHeader>

          <S.GridFieldsPhoto>
            <IndustryPhotos
              industryPhotos={form.photos}
              handleSelectImages={handleSelectImages}
              handleRemoveItemImage={handleRemoveItemImage}
            />

            <IndustrySIF
              error={errors.sifPhoto}
              industrySIFPhoto={form.sifPhoto}
              onRemoveImage={() => onRemoveImage({ name: 'sifPhoto' })}
              onLoadImage={onLoadImage({ name: 'sifPhoto' })}
            />
          </S.GridFieldsPhoto>
        </S.Fieldset>
      </S.FullGrid>
      <S.GridButtons item xs={12}>
        <Button variant="default" onClick={onExit} size="medium">
          <p>{t('common:cancel')}</p>
        </Button>
        <Button variant="alternative" onClick={submit} size="medium">
          <p>{t('common:save')}</p>
        </Button>
      </S.GridButtons>
    </S.Wrapper>
  )
}

import { MapContainer, TileLayer, Marker, LayersControl } from 'react-leaflet'
import L from 'leaflet'

import { Modal } from 'components/Modal'

import icon2x from '../../assets/map/images/marker-icon-2x.png'
import icon from '../../assets/map/images/marker-icon.png'

import * as S from './styled'
import { IconButton } from '@material-ui/core'
import { Icon } from 'components/Icon'
import { useTranslation } from 'react-i18next'
import { theme } from 'config'
import { THEMES_NAME } from 'config/theme.config'

const DefaultIcon = L.icon({
  iconRetinaUrl: icon2x,
  iconUrl: icon,
  iconAnchor: [10, 41],
  popupAnchor: [2, -40]
})

L.Marker.prototype.options.icon = DefaultIcon

export type ModalMapMarkerProps = {
  latLng: any
  onExit?: () => void
}

export function ModalMapMarker({ latLng, onExit }: ModalMapMarkerProps) {
  const { t } = useTranslation()
  const content = (
    <S.Wrapper>
      <S.DivTitle>
        <S.GridHeader>
          <S.GridTitle item container xs={12}>
            <IconButton
              id="action"
              aria-label="mapButton"
              onClick={() => {}}
              size="medium"
              edge="start"
              style={{ backgroundColor: '#F2F3F7' }}
            >
              <Icon
                icon={
                  theme.appTheme === THEMES_NAME.swift
                    ? 'map-pin-swift'
                    : 'map-pin'
                }
                width={16}
                height={16}
                aria-label="action"
              />
            </IconButton>
            <S.BoxTitle>{t('common:viewMap')}</S.BoxTitle>
          </S.GridTitle>
        </S.GridHeader>
      </S.DivTitle>

      <S.WrapperMap>
        <MapContainer
          style={{ width: '100%', height: '100%' }}
          zoomControl={true}
          center={latLng}
          zoom={14}
          preferCanvas
        >
          <LayersControl position="bottomright">
            <LayersControl.BaseLayer name="Mapa">
              <TileLayer
                maxZoom={18}
                url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_TOKEN_MAPBOX}`}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer checked name="Satélite">
              <TileLayer
                maxZoom={18}
                url={`https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_TOKEN_MAPBOX}`}
              />
            </LayersControl.BaseLayer>
          </LayersControl>

          <Marker position={latLng}></Marker>
        </MapContainer>
      </S.WrapperMap>
    </S.Wrapper>
  )

  return (
    <Modal
      content={content}
      isActionsEnabled={false}
      width={60}
      onExit={onExit}
    />
  )
}

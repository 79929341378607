import * as S from './styled'

type PropTypes = {
  label: string
  value: string
}

export function GeneralBlockInfo({ label, value }: PropTypes) {
  return (
    <S.GridInfoBlock>
      <S.GridInfoLabel>{label}: </S.GridInfoLabel>
      <S.GridInfoValue>{value}</S.GridInfoValue>
    </S.GridInfoBlock>
  )
}

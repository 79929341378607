import { HTTP } from 'providers'

import { TraceabilityFilter } from '../types/Traceability.types'

export class TableSIFService extends HTTP {
  private endpoint = '/tableSIF'

  public async getTableSIF(params: TraceabilityFilter) {
    return this.get({
      endpoint: this.endpoint,
      params
    })
  }
}

import { Input } from 'components/Input'
import { InputCpfCnpj } from 'components/InputCpfCnpj'
import { InputSelect } from 'components/InputSelect'
import { useTranslation } from 'react-i18next'
import i18n from 'i18n'

import {
  ERRORS_INITIAL_STATE,
  INITIAL_STATE
} from 'constants/industry.constants'

import * as S from './styled'

const status = () => [
  { label: i18n.t('common:active'), value: true },
  { label: i18n.t('common:inactive'), value: false }
]

const inspectionType = () => [
  { title: i18n.t('profile:federalInspectionSeal'), value: 'SIF' },
  { title: i18n.t('profile:stateInspectionSeal'), value: 'SIE' },
  { title: i18n.t('profile:municipalInspectionSeal'), value: 'SIM' }
]

type BasicInformationType = {
  errors: typeof ERRORS_INITIAL_STATE
  payload: typeof INITIAL_STATE
  handleInput: (_value, _name) => void
  handleSelected: (_name: string) => (_value: any) => void
}

export function BasicInformation({
  errors,
  payload,
  handleInput,
  handleSelected
}: BasicInformationType) {
  const { t } = useTranslation()
  return (
    <S.GridFields container spacing={2}>
      <S.GridInput item sm={12} md={4}>
        <Input
          fullWidth
          label={t('common:corporateNameOwnership')}
          value={payload.name}
          error={Boolean(errors.name)}
          helperText={errors.name}
          onInput={value => handleInput(value, 'name')}
        />
      </S.GridInput>
      <S.GridInput item sm={12} md={4}>
        <Input
          fullWidth
          label={t('common:fantasyName')}
          value={payload.fancyName}
          error={Boolean(errors.fancyName)}
          helperText={errors.fancyName}
          onInput={value => handleInput(value, 'fancyName')}
        />
      </S.GridInput>
      <S.GridInput item sm={12} md={4}>
        <InputSelect
          error={Boolean(errors.inspectionType)}
          fullWidth
          helperText={errors.inspectionType}
          label={t('common:typeInspection')}
          onSelected={handleSelected('inspectionType')}
          optionLabel="title"
          optionValue="value"
          options={inspectionType()}
          value={payload.inspectionType}
        />
      </S.GridInput>
      <S.GridInput item sm={12} md={4}>
        <Input
          fullWidth
          label={t('common:inspectionNumber')}
          value={payload.inspectionNum.replace(/\D/g, '')}
          error={Boolean(errors.inspectionNum)}
          helperText={errors.inspectionNum}
          onInput={value => handleInput(value, 'inspectionNum')}
        />
      </S.GridInput>
      <S.GridInput item sm={12} md={4}>
        <InputCpfCnpj
          fullWidth
          label={t('common:CNPJ')}
          value={payload.cnpj}
          error={Boolean(errors.cnpj)}
          helperText={errors.cnpj}
          type="cnpj"
          onInput={value => handleInput(value, 'cnpj')}
        />
      </S.GridInput>
      <S.GridInput item sm={12} md={4}>
        <Input
          fullWidth
          label={t('common:stateRegistration')}
          value={payload.ie}
          error={Boolean(errors.ie)}
          helperText={errors.ie}
          onInput={value => handleInput(value, 'ie')}
        />
      </S.GridInput>
      <S.GridInput item sm={12} md={4}>
        <InputSelect
          error={Boolean(errors.status)}
          fullWidth
          helperText={errors.status}
          label={t('common:status')}
          onSelected={handleSelected('status')}
          optionLabel="label"
          optionValue="value"
          options={status()}
          value={payload.status}
        />
      </S.GridInput>
    </S.GridFields>
  )
}

import { USER_ROLES, YEAR_OFFSET } from 'constants/user.constants'

/**
 * Retorna o ano(s) a ser mostrado nos filtros de acordo com o tipo de user
 * @param user objeto usuário
 * @returns array de anos permitidos
 */
export const getUserYearsForFilter = user => {
  const yearNow = new Date().getFullYear()
  const yearDiffLimit = yearNow - YEAR_OFFSET.base
  const offsetAdmin =
    yearDiffLimit > YEAR_OFFSET.admin ? YEAR_OFFSET.admin : yearDiffLimit

  return Array.from(
    {
      length:
        (user.tag === USER_ROLES.admin ? offsetAdmin : YEAR_OFFSET.viewer) + 1
    },
    (_, i) => {
      const valueAdmin = i + yearNow - offsetAdmin
      const valueView = i + yearNow - YEAR_OFFSET.viewer
      const isActive = false
      const labelAdmin = valueAdmin.toString()
      const labelView = valueView.toString()
      return { labelAdmin, labelView, valueAdmin, valueView, isActive }
    }
  )
}

/**
 * Retorna o ano inicial de acordo com o tipo de user
 * @param user objeto usuário
 * @returns ano
 */
export const getUserYearInit = user => {
  const yearNow = new Date().getFullYear()
  if (user.tag === USER_ROLES.admin) {
    const yearAdmin = yearNow - YEAR_OFFSET.admin
    return YEAR_OFFSET.base > yearAdmin ? YEAR_OFFSET.base : yearAdmin
  }
  return yearNow - YEAR_OFFSET.viewer
}

import styled from 'styled-components'

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 2.2rem;
  margin-top: 0.7rem;

  div > div {
    margin-right: 1.2rem;
  }

  svg > g > path {
    fill: ${({ theme }) => theme.colors.primary};
  }
  svg > path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`

export const Label = styled.p`
  color: ${({ theme }) => theme.colors.primary1};
  font-size: 1.4rem;
  text-align: center;
`

export const SuffixSeparator = styled(Label)`
  margin: 0 0.8rem;
`

export const Suffix = styled(Label)`
  &:not(:last-of-type) {
    align-items: center;
    display: flex;
    &::after {
      display: block;
      color: ${({ theme }) => theme.colors.primary1};
      content: '/';
      font-size: 1.4rem;
      text-align: center;
      margin: 0 0.4rem;
    }
  }
`

export const Divider = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.4rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  text-align: center;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
`

import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1rem;
  padding: 0.8rem;
`

export const Group = styled.div`
  margin: 0 -0.5rem;
  padding: 1rem 0;
  button {
    display: inline-block;
    margin: 0.5rem;
  }
`

export const Title = styled.p`
  font-size: 1.6rem;
  font-weight: 600;
  padding-top: 3rem;
  :first-child {
    padding-top: 0.8rem;
  }
`

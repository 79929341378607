import {
  ERRORS_CONTACT_STATE,
  INITIAL_CONTACT_STATE
} from './contact.constants'
import {
  ERRORS_PRODUCER_STATE,
  INITIAL_PRODUCER_STATE
} from './producer.constants'
import i18n from 'i18n'

export const FILTER_INITIAL_STATE = {
  name: '',
  cnpj: '',
  fancyName: '',
  ie: ''
}

export const INITIAL_STATE = {
  name: '',
  fancyName: '',
  description: '',
  CAR: '',
  INCRA: '',
  NIRF: '',
  CCIR: '',
  LARLAU: '',
  perimeterDocsOrigin: '',
  livestockExplorationCode: '',
  establishmentCode: '',
  state: '',
  city: '',
  address: '',
  cep: '',
  lat: '',
  lng: '',
  contacts: [{ ...INITIAL_CONTACT_STATE }],
  producers: [{ ...INITIAL_PRODUCER_STATE }]
}

export const ERRORS_INITIAL_STATE = {
  name: '',
  fancyName: '',
  description: '',
  CAR: '',
  INCRA: '',
  NIRF: '',
  CCIR: '',
  LARLAU: null,
  perimeterDocsOrigin: null,
  livestockExplorationCode: '',
  establishmentCode: '',
  state: '',
  city: '',
  address: '',
  cep: '',
  lat: '',
  lng: '',
  contacts: [{ ...ERRORS_CONTACT_STATE }],
  producers: [{ ...ERRORS_PRODUCER_STATE }]
}

export const PROPERTY_BLOCK_STATUS_GET = () => [
  { label: i18n.t('common:released'), value: 'L' },
  { label: i18n.t('common:blocked'), value: 'B' }
]

export const PROPERTY_BLOCK_STATUS = () => [
  { label: i18n.t('common:released'), value: false },
  { label: i18n.t('common:blocked'), value: true }
]

export const INITIAL_STATE_MODAL = {
  producers: [{ ...INITIAL_PRODUCER_STATE }]
}

export const ERRORS_INITIAL_STATE_MODAL = {
  producers: [{ ...ERRORS_PRODUCER_STATE }]
}

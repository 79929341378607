import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState
} from 'react'
import { cnpjMask } from '../../utils/CnpjMask'
import { cpfMask } from '../../utils/CpfMask'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type InputCpfCnpjProps = {
  disabled?: boolean
  error?: boolean
  fullWidth?: boolean
  helperText?: string
  label?: string
  value?: string
  type?: 'cpf' | 'cnpj'
  width?: number
  onKeyPress?: (_e: KeyboardEvent) => any
  onInput?: (_value: string) => void
}

export function InputCpfCnpj({
  disabled = false,
  error = false,
  fullWidth = false,
  helperText = '',
  label,
  value = '',
  type,
  width = 200,
  onKeyPress = () => {},
  onInput
}: InputCpfCnpjProps) {
  const { t } = useTranslation()
  const [valueMask, setValueMask] = useState('')

  const onMask = useCallback(
    (val: string) => {
      let mask = ''
      if (type === 'cpf') {
        mask = cpfMask(val)
      } else if (type === 'cnpj') {
        mask = cnpjMask(val)
      } else {
        mask = val.replace(/\D/g, '').length > 11 ? cnpjMask(val) : cpfMask(val)
      }
      setValueMask(mask)
      !!onInput && onInput(mask)
    },
    [onInput, type]
  )

  useEffect(() => {
    if (value !== valueMask) onMask(value)
  })

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const newValue = e.currentTarget.value
    if (value !== newValue) onMask(newValue)
  }

  function labelText() {
    if (label) {
      return label
    } else if (type) {
      return type === 'cpf' ? t('common:documentNumber') : t('common:CNPJ')
    } else {
      return t('common:cpfCNPJ')
    }
  }

  return (
    <S.InputCpfCnpj
      error={error}
      helperText={helperText}
      label={labelText()}
      disabled={disabled}
      fullWidth={fullWidth}
      onKeyPress={onKeyPress}
      onChange={onChange}
      style={{ width: !fullWidth ? width : '100%' }}
      value={valueMask}
    />
  )
}

/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { Button, ContactForm, InputAutocomplete, Location } from 'components'
import { CustomFilters } from 'components/CustomFilters'
import { CustomFields } from 'services'

import { ClientGroup } from './ClientGroup/ClientGroup'
import { BasicInformation } from './BasicInformation/BasicInformation'

import { YupValidator } from 'services/yupValidator.service'
import { ClientFormSchema } from 'store/Client/Client.validator'

import { mapErrors } from 'utils/MapErrors'

import { CityActions } from 'store/City'
import { all } from 'store/Client/Client.selector'
import { all as allCity } from 'store/City/City.selector'

import { clientActions } from 'store/Client'
import { useTranslation } from 'react-i18next'

import {
  ERRORS_INITIAL_STATE,
  INITIAL_STATE,
  QUALIFICATIONS as qualifications
} from 'constants/client.constants'

import { INITIAL_CONTACT_STATE } from 'constants/contact.constants'
import { INITIAL_CUSTOM_FILTERS_FROM_API_STATE } from 'constants/customFilters.constants'
import { CustomFilter } from 'types/customFilter.types'

import * as S from './styled'

interface ParamTypes {
  id: string
}

export type LatLngType = {
  lat: number
  lng: number
}

export function ClientForm() {
  const { t } = useTranslation()
  const { id } = useParams<ParamTypes>()
  const [form, setForm] = useState({
    ...INITIAL_STATE,
    customRetailsFilters: [
      {
        customColumnFilter: null,
        customColumnValue: '',
        retailCnpj: null,
        isDelete: false
      }
    ]
  })
  const [customFiltersRemoved, setCustomFiltersRemoved] = useState([])
  const [customFilters, setCustomFilters] = useState([
    INITIAL_CUSTOM_FILTERS_FROM_API_STATE
  ])
  const [errors, setErrors] = useState({ ...ERRORS_INITIAL_STATE })

  const { client, clientCreate, clientEdit } = useSelector(all)
  const { cities } = useSelector(allCity)

  const dispatch = useDispatch()
  const { goBack } = useHistory()

  useEffect(() => {
    if (id) {
      dispatch(clientActions.fetchOne(id))
    }
  }, [dispatch, id])

  useEffect(() => {
    const fetchCustomFilters = async () => {
      const { data } = await new CustomFields().fetchFilters()
      const customColumnFiltersFromApi = data as CustomFilter[]
      if (customColumnFiltersFromApi.length) {
        customColumnFiltersFromApi.unshift(
          INITIAL_CUSTOM_FILTERS_FROM_API_STATE
        )
        setCustomFilters(customColumnFiltersFromApi)
      }
    }
    fetchCustomFilters()
  }, [])

  useEffect(() => {
    if (clientCreate.id || clientEdit.id) {
      goBack()
      dispatch(clientActions.cleanClient())
    }
  }, [clientCreate, clientEdit, goBack, dispatch])

  useEffect(() => {
    if (form.state) {
      dispatch(CityActions.fetchByUf({ uf: form.state }))
    }
  }, [form.state, dispatch])

  useEffect(() => {
    if (Object.keys(client).length) {
      const _form = { ...client } as typeof INITIAL_STATE

      const qualifications = []
      client.qualifications.forEach(q =>
        qualifications.push({ qualificationsName: q })
      )

      _form.qualifications = qualifications

      if (_form.lat === null) {
        _form.lat = ''
      }

      if (_form.lng === null) {
        _form.lng = ''
      }

      setErrors({
        ...ERRORS_INITIAL_STATE,
        contacts: _form.contacts.map(_contact => INITIAL_CONTACT_STATE)
      })

      setForm({ ..._form })
    }
  }, [client])

  useEffect(() => {
    const { address, state, cep, city, ie } = form
    const shouldBeClearLocationFields =
      address !== '' || state !== '' || cep !== '' || city !== '' || ie !== ''

    if (form.documentType === 'internacional' && shouldBeClearLocationFields) {
      setForm({ ...form, address: '', state: '', cep: '', city: '', ie: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.address, form.state, form.cep, form.city, form.ie])

  async function validateForm(formParam) {
    const [isValid, validationError] = await new YupValidator(
      ClientFormSchema()
    ).validate(formParam)
    console.warn(validationError)
    if (!isValid) setErrors(validationError as typeof ERRORS_INITIAL_STATE)
    return isValid
  }

  function handleInput(value: string, name: string) {
    if (name === 'documentType') {
      setForm({ ...form, cnpj: '', documentType: value })
      return
    }
    setErrors({ ...errors, [name]: '' })
    setForm({ ...form, [name]: value })
  }

  function onRemoveCustomFilterItem(index: number) {
    const _customRetailsFilters = form.customRetailsFilters.map(i => i)
    const newCustomFiltersList = _customRetailsFilters.filter(
      (_, i) => i !== index
    )
    form.customRetailsFilters = []
    form.customRetailsFilters = newCustomFiltersList.map(filter => ({
      ...filter,
      isDelete: false
    }))
    setForm({ ...form })

    if (id) {
      const customFiltersListRemoved = _customRetailsFilters
        .filter((_, i) => i === index)
        .map(filter => ({ ...filter, isDelete: true }))
      setCustomFiltersRemoved(customFiltersListRemoved)
    }
  }

  function onSelectCustomFilter(index: number, customFilterColumnName: string) {
    if (customFilterColumnName === '') {
      form.customRetailsFilters[index].customColumnFilter = null
      form.customRetailsFilters[index].customColumnValue = ''
      form.customRetailsFilters[index].isDelete = true
      if (form.customRetailsFilters.length > 1) onRemoveCustomFilterItem(index)
    } else {
      form.customRetailsFilters[index].isDelete = false
      form.customRetailsFilters[index].customColumnFilter =
        customFilterColumnName
    }
    setForm({ ...form })
  }

  function onChangeCustomFilter(filterValue: string, index: number) {
    form.customRetailsFilters[index].customColumnValue =
      filterValue.toUpperCase()
    setForm({ ...form })
  }

  function onFillContacts(key, val, index) {
    const updated = [...form.contacts]

    updated[index] = {
      ...updated[index],
      [key]: val
    }

    setForm({ ...form, contacts: updated })
  }

  function handleSelected(name: string) {
    return function handle(value) {
      setErrors({ ...errors, [name]: '' })
      if (name === 'state') {
        setForm({ ...form, [name]: value.value, city: '' })
      } else if (name === 'city') {
        setForm({ ...form, [name]: value.title })
      } else {
        setForm({ ...form, [name]: value.value })
      }
    }
  }
  function addContactItem() {
    const contacts =
      errors?.contacts?.length > 0
        ? errors?.contacts
        : mapErrors(errors, 'contacts')

    setErrors({
      ...errors,
      contacts: [...contacts, { ...INITIAL_CONTACT_STATE }]
    })

    setForm({
      ...form,
      contacts: [...form.contacts, { ...INITIAL_CONTACT_STATE }]
    })
  }

  function checkIfHasCustomFilterWithValue() {
    return form.customRetailsFilters.every(
      item =>
        item.customColumnFilter &&
        item.customColumnFilter !== '' &&
        item.customColumnValue &&
        item.customColumnValue !== ''
    )
  }

  async function addCustomFilterItem() {
    if (checkIfHasCustomFilterWithValue()) {
      form.customRetailsFilters = form.customRetailsFilters.concat({
        customColumnFilter: null,
        customColumnValue: '',
        retailCnpj: null,
        isDelete: false
      })
      setForm({ ...form })
    }
  }

  function onRemoveContactItem(index) {
    const item = form.contacts

    const contacts =
      errors?.contacts?.length > 0
        ? errors?.contacts
        : mapErrors(errors, 'contacts')
    const itemError = contacts

    if (item.length === 1) {
      setForm({ ...form, contacts: [] })
      setErrors({ ...errors, contacts: [] })
      return
    }

    const updated = [...item]
    const updatedErrors = [...itemError]
    updated.splice(index, 1)
    updatedErrors.splice(index, 1)
    setForm({ ...form, contacts: updated })
    setErrors({ ...errors, contacts: updatedErrors })
  }

  function onExit() {
    goBack()
    dispatch(clientActions.cleanClient())
  }

  function handleAutocomplete(field: string, value: any) {
    setErrors({ ...errors, [field]: '' })
    setForm({ ...form, [field]: value })
  }

  async function submit() {
    setErrors({
      ...ERRORS_INITIAL_STATE,
      contacts: form.contacts.map(_contact => INITIAL_CONTACT_STATE)
    })

    const haveCustomFilter = form.customRetailsFilters.length > 0
    let customRetailsFilters = []

    if (form.customRetailsFilters.length > 1) {
      form.customRetailsFilters.forEach((filter, index) => {
        if (!filter.customColumnFilter && !filter.customColumnValue) {
          onRemoveCustomFilterItem(index)
        }
      })
    }

    if (haveCustomFilter || checkIfHasCustomFilterWithValue()) {
      customRetailsFilters = form.customRetailsFilters.map(filter => ({
        ...filter,
        retailCnpj: form.cnpj
      }))
    }

    const _form = {
      ...form,
      customRetailsFilters,
      lat: form.lat || null,
      lng: form.lng || null
    }

    const isValid = await validateForm(_form)
    if (!isValid) return

    const payload = { ..._form }

    const _qualifications = []
    payload.qualifications.forEach(q =>
      _qualifications.push(q.qualificationsName)
    )

    payload.qualifications = _qualifications
    if (customFiltersRemoved.length) {
      payload.customRetailsFilters =
        payload.customRetailsFilters.concat(customFiltersRemoved)
    }
    dispatch(
      id
        ? clientActions.editClient(payload)
        : clientActions.createClient(payload)
    )
  }

  return (
    <S.Wrapper container>
      <S.FullGrid item xs={12}>
        <S.Fieldset>
          <S.GridHeader>
            <S.GridTitle item container xs={12}>
              <S.BoxTitle>{t('users:basicInformations')}</S.BoxTitle>
            </S.GridTitle>
          </S.GridHeader>
          <BasicInformation
            payload={form}
            isEdit={!!id}
            errors={errors}
            handleInput={handleInput}
            handleSelected={handleSelected}
          />
        </S.Fieldset>

        <S.Fieldset>
          <S.GridHeaderContact>
            <S.GridTitleContact item container xs={12}>
              <S.BoxTitleContact>
                {t('customFilters:filters')}
              </S.BoxTitleContact>
            </S.GridTitleContact>
          </S.GridHeaderContact>

          {form.customRetailsFilters.map((filter, i) => (
            <CustomFilters
              errors={errors}
              index={i}
              payload={filter}
              key={i}
              filters={customFilters}
              onChange={onChangeCustomFilter}
              isRenderRemoveButton={form.customRetailsFilters.length > 1}
              onSelect={onSelectCustomFilter}
              onRemove={onRemoveCustomFilterItem}
            />
          ))}

          <S.GridFields container spacing={2}>
            <S.GridButtonsAdd item sm={12}>
              <Button
                variant={
                  checkIfHasCustomFilterWithValue() ? 'primary' : 'default'
                }
                onClick={addCustomFilterItem}
              >
                <p>{t('customFilters:addFilters')}</p>
              </Button>
            </S.GridButtonsAdd>
          </S.GridFields>
        </S.Fieldset>

        <S.Fieldset>
          <S.GridHeader>
            <S.GridTitle item container xs={12}>
              <S.BoxTitle>{t('users:customerQualifications')}</S.BoxTitle>
            </S.GridTitle>
          </S.GridHeader>

          <S.GridFields container>
            <S.GridInput item sm={12} md={12}>
              <InputAutocomplete
                defaultValue={form.qualifications}
                error={Boolean(errors.qualifications)}
                fullWidth
                helperText={errors.qualifications}
                label={t('users:selectQualifications')}
                onSelected={val => handleAutocomplete('qualifications', val)}
                optionLabel="qualificationsName"
                options={qualifications}
              />
            </S.GridInput>
          </S.GridFields>
        </S.Fieldset>

        <S.Fieldset>
          <S.GridHeader>
            <S.GridTitle item container xs={12}>
              <S.BoxTitle>{t('common:localization')}</S.BoxTitle>
            </S.GridTitle>
          </S.GridHeader>

          <Location
            cities={cities}
            errors={errors}
            optionValueCity="title"
            payload={form}
            handleInput={handleInput}
            handleSelected={handleSelected}
          />
        </S.Fieldset>

        <S.Fieldset>
          <S.GridHeaderContact>
            <S.GridTitleContact item container xs={12}>
              <S.BoxTitleContact>{t('users:contactDetails')}</S.BoxTitleContact>
            </S.GridTitleContact>
          </S.GridHeaderContact>

          {form.contacts.map((contact, i) => (
            <ContactForm
              errors={errors}
              index={i}
              key={i}
              payload={contact}
              onFill={onFillContacts}
              removeButton={Boolean(form.contacts.length > 1)}
              onRemove={() => onRemoveContactItem(i)}
            />
          ))}

          <S.GridFields container spacing={2}>
            <S.GridButtonsAdd item sm={12}>
              <Button variant="primary" onClick={addContactItem}>
                <p>{t('users:addContact')}</p>
              </Button>
            </S.GridButtonsAdd>
          </S.GridFields>
        </S.Fieldset>

        <S.DividerHorizontal />

        {id && (
          <S.Fieldset>
            <S.GridHeader>
              <S.GridTitle item container xs={12}>
                <S.BoxTitle>{t('users:CustomerRelatedGroup')}</S.BoxTitle>
              </S.GridTitle>
            </S.GridHeader>

            <ClientGroup payload={form.groups} />
          </S.Fieldset>
        )}
      </S.FullGrid>
      <S.GridButtons item xs={12}>
        <Button variant="default" onClick={onExit} size="medium">
          <p>{t('common:cancel')}</p>
        </Button>
        <Button variant="alternative" onClick={submit} size="medium">
          <p>{t('common:save')}</p>
        </Button>
      </S.GridButtons>
    </S.Wrapper>
  )
}

import { IconButton } from '@material-ui/core'

import { Dropdown } from 'components/Dropdown'
import { Icon } from 'components/Icon'
import { theme } from 'config'
import { THEMES_NAME } from 'config/theme.config'
import { useTranslation } from 'react-i18next'

import * as S from './styles'

type ClientGroupFilterDropDownProps = {
  setDeleteClient?: () => void
}

export const ClientGroupFilterDropDown = ({
  setDeleteClient
}: ClientGroupFilterDropDownProps) => {
  const { t } = useTranslation()
  return (
    <S.Wrapper>
      <Dropdown
        title={
          <IconButton
            id="action"
            color="inherit"
            aria-label="open drawer"
            onClick={() => {}}
            size="medium"
            edge="start"
          >
            <Icon
              icon={
                theme.appTheme === THEMES_NAME.swift
                  ? 'itens-interface-actions-swift'
                  : 'itens-interface-actions'
              }
              width={12}
              height={12}
              aria-label="action"
            />
          </IconButton>
        }
      >
        <S.Nav>
          <S.Link onClick={setDeleteClient}>
            <Icon icon="dropdown-delete" height={16} width={16} />
            <span>{t('common:oneCustomerRemove')}</span>
          </S.Link>
        </S.Nav>
      </Dropdown>
    </S.Wrapper>
  )
}

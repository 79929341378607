export const types = Object.freeze({
  DELETE_PROPERTY_TMP: '@property/deletePropertyTmp',
  FETCH_ALL: '@propertyTmp/fetchAll',
  SET_PROPERTIES_TMP: '@propertyTmp/setPropertiesTmp',
  SET_LOADING: '@propertyTmp/setLoading'
})

export function fetchAll(
  { params = {} }: { params?: Record<string, any> } = { params: {} }
) {
  return { type: types.FETCH_ALL, payload: { params } }
}

export function setProperties({ propertiesTmp = {} } = {}) {
  return { type: types.SET_PROPERTIES_TMP, payload: { propertiesTmp } }
}

export function setLoading({ loading = false } = {}) {
  return { type: types.SET_LOADING, payload: { loading } }
}

export function deletePropertyTmp({ id }) {
  return {
    type: types.DELETE_PROPERTY_TMP,
    payload: id
  }
}

import styled from 'styled-components'

export const ButtonAlign = styled.div`
  float: right;
  &.yes {
    border: 1px solid #abafb3;
  }
  &.not {
    border: none;
  }
`
export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  .icons-search {
    margin-bottom: -0.1rem;
  }
  .icon-arrow {
    margin-top: 0.6rem;
  }
`
export const ButtonText = styled.h1`
  font-size: 1.4rem;
  font-weight: 500;
  padding-right: 3.6rem;
  padding-left: 0.6rem;
`
export const Arrow = styled.div`
  align-items: center;
  justify-content: space-around;
  display: flex;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  &:hover {
    background: #f2f3f7;
  }
`

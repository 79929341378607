import { Link } from 'react-router-dom'

import { AvatarUI } from 'components/Avatar'
import { Dropdown } from 'components/Dropdown'
import { Icon } from 'components/Icon'
import { useTranslation } from 'react-i18next'

import * as S from './styles'

export type UserDropdownProps = {
  username: string
  imgUrl?: string
  setChangePassword?: () => void
}

export const UserDropdown = ({
  username,
  imgUrl,
  setChangePassword
}: UserDropdownProps) => {
  const { t } = useTranslation()
  return (
    <S.Wrapper>
      <Dropdown
        addRightSpace={true}
        title={
          <>
            <AvatarUI name={username} imgUrl={imgUrl} variant="small" />
            <S.Username>{username}</S.Username>
            <Icon icon="user-profine-arrow-down" height={16} width={16} />
          </>
        }
      >
        <S.Nav>
          <Link
            to={{
              pathname: '/perfil',
              state: { nameBreadcrumbs: t('profile:profileLabelPage') }
            }}
          >
            <S.Link>
              <Icon icon="dropdown-edit" height={16} width={16}></Icon>
              <span>{t('common:edit')}</span>
            </S.Link>
          </Link>
          <Link to={'#'} onClick={setChangePassword}>
            <S.Link>
              <Icon icon="dropdown-delete" height={16} width={16}></Icon>
              <span>{t('common:deleteAccount')}</span>
            </S.Link>
          </Link>
          <Link
            to={{
              pathname: '/login'
            }}
          >
            <S.Link>
              <Icon icon="logout" height={16} width={16}></Icon>
              <span>{t('common:logout')}</span>
            </S.Link>
          </Link>
        </S.Nav>
      </Dropdown>
    </S.Wrapper>
  )
}

import { call, put } from 'redux-saga/effects'

import { KpisService, TraceabilityService } from 'services'
import { spinnerActions } from 'store/Spinner'

import FilteringTraceability from 'utils/FilteringTraceability'
import { toasts } from 'utils/toasts'
import * as actions from './TraceabilityTables.actions'

export function* getTraceabilitySpecific(action) {
  yield put(spinnerActions.setVisibility(true))

  const filters = { ...action.payload }
  yield put(actions.setFilters({ filters }))

  const $traceability = new TraceabilityService()
  const traceability = yield call(
    $traceability.getPerSpecific.bind($traceability, {
      filterSpecific: { ...filters }
    })
  )

  if (traceability.success) {
    yield put(actions.setKpis({ kpis: traceability.data }))
  } else toasts.generalFail()

  yield put(spinnerActions.setVisibility(false))
}

export function* getKpisCards(action) {
  yield put(actions.setKpisCardsLoading(true))

  const _filters = FilteringTraceability(action.payload.filterOrders)

  const $kpis = new KpisService()

  const kpis = yield call($kpis.getKpisCards.bind($kpis), _filters)

  if (kpis.success) {
    yield put(actions.setKpis({ kpis: kpis.data }))
  }

  yield put(actions.setKpisCardsLoading(false))
}

export function* getKpisCharts(action) {
  yield put(actions.setKpisChartsLoading(true))
  const _filters = FilteringTraceability(action.payload.filterOrders)

  const $kpis = new KpisService()

  const kpis = yield call($kpis.getKpisCharts.bind($kpis), _filters)

  if (kpis.success) {
    yield put(actions.setCharts({ charts: kpis.data }))
  }

  yield put(actions.setKpisChartsLoading(false))
}

export function* setAccessGroupsFilter(action) {
  yield put(actions.setAccessGroups(action.payload.accessGroupsFilter))
}

export function* setIndustryGroupsFilter(action) {
  yield put(actions.setIndustryGroups(action.payload.industryGroupsFilter))
}

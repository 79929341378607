import { Icon } from 'components/Icon'
import { Picture } from 'components/Picture'

import * as S from './styled'

type IndustrySIFType = {
  error: string
  industrySIFPhoto: { src: string; filename: string }
  onLoadImage: (_key) => void
  onRemoveImage: (_key) => void
}

export function IndustrySIF({
  error,
  industrySIFPhoto,
  onLoadImage,
  onRemoveImage
}: IndustrySIFType) {
  return (
    <S.GridPhoto item sm={12} md={4}>
      <S.DividerVertical orientation="vertical" />
      <S.DivPhotoMessage>
        <S.CardPhoto>
          <Picture
            name="industrySIFPhoto"
            onLoadImage={onLoadImage}
            imgUrl={industrySIFPhoto ? industrySIFPhoto.src : ''}
            variant="square"
            onRemoveImage={onRemoveImage}
          />
        </S.CardPhoto>
        {error && (
          <S.AlertIcon>
            <Icon icon="itens-interface-alert" height={10} width={10} />
            <p>{error}</p>
          </S.AlertIcon>
        )}
      </S.DivPhotoMessage>
    </S.GridPhoto>
  )
}

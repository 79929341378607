import { Link } from 'react-router-dom'
import { IconButton } from '@material-ui/core'

import { Dropdown, Icon } from 'components'
import { useTranslation } from 'react-i18next'

import * as S from './styles'
import { THEMES_NAME } from 'config/theme.config'
import { theme } from 'config'

type PropertyDropdownProps = {
  id: string
  blockStatus: boolean
  setAddProducer: () => void
  setBlockProducer: () => void
  setBlockProperty: () => void
  setDeleteProperty: () => void
  setHistory: () => void
}
export const PropertyDropdown = ({
  id,
  blockStatus,
  setAddProducer,
  setBlockProducer,
  setBlockProperty,
  setDeleteProperty,
  setHistory
}: PropertyDropdownProps) => {
  const { t } = useTranslation()

  function dropdownIconButton() {
    return (
      <IconButton
        id="action"
        color="inherit"
        aria-label="open drawer"
        onClick={() => {}}
        size="medium"
        edge="start"
      >
        <Icon
          icon={
            theme.appTheme === THEMES_NAME.swift
              ? 'itens-interface-actions-swift'
              : 'itens-interface-actions'
          }
          width={12}
          height={12}
          aria-label="action"
        />
      </IconButton>
    )
  }

  return (
    <S.Wrapper>
      <Dropdown title={dropdownIconButton()}>
        <S.Nav>
          <Link to={`/propriedade/editar/${id}`}>
            <S.Link>
              <Icon icon="dropdown-edit" height={16} width={16}></Icon>
              <span>{t('common:edit')}</span>
            </S.Link>
          </Link>
          <S.Link onClick={setHistory}>
            <Icon icon="dropdown-log" height={16} width={16}></Icon>
            <span>{t('common:historic')}</span>
          </S.Link>
          <S.Link onClick={setAddProducer}>
            <Icon icon="dropdown-add-producer" height={16} width={16}></Icon>
            <span>{t('common:addProducer')}</span>
          </S.Link>
          <S.Link onClick={setBlockProducer}>
            <Icon
              icon={
                blockStatus ? 'dropdown-unblockstatus' : 'dropdown-blockstatus'
              }
              height={16}
              width={16}
            ></Icon>
            <span>
              {blockStatus ? t('common:unlock') : t('common:block')}
              <span> </span>
              {t('common:productor')}
            </span>
          </S.Link>
          <S.Link onClick={setBlockProperty}>
            <Icon
              icon="dropdown-blockstatus-property"
              height={16}
              width={16}
            ></Icon>
            <span>{t('toasts:changePropertyStatus')}</span>
          </S.Link>
          <S.Link onClick={setDeleteProperty}>
            <Icon icon="dropdown-delete" height={16} width={16}></Icon>
            <span>{t('common:delete')}</span>
          </S.Link>
        </S.Nav>
      </Dropdown>
    </S.Wrapper>
  )
}

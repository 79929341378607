import { AddButton, Button, Input, Pagination } from 'components'
import { ColumnsProps, Table } from 'components/Table/Table'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { partnerActions } from 'store/Partners'
import { all } from 'store/Partners/partners.selector'
import { submitOnEnter } from 'utils/submitOnEnter'
import { ModalDeletePartners } from '../ModalDeletePartners/ModalDeletePartners'
import { PartnersDropdown } from './PartnersDropdown'
import { useTranslation } from 'react-i18next'
import i18n from 'i18n'

import * as Yup from 'yup'

import * as S from './styled'

const INITIAL_STATE = {
  partnerName: '',
  pagination: { totalItems: 10 },
  totalItems: 10,
  limit: 10,
  page: 0,
  column: 'name',
  sort: 'asc'
}

const FILTERS_INITIAL_STATE = {
  partnerName: '',
  limit: 10,
  page: 0
}

const ERRORS_INITIAL_STATE = {
  partnerName: ''
}

const columns: ColumnsProps[] = [
  { name: 'partnerName', align: 'left', title: i18n.t('partner:partnerEco') }
]

export function PartnersList() {
  const { t } = useTranslation()
  const [filter, setFilter] = useState({ ...INITIAL_STATE })
  const [errors, setErrors] = useState({ ...ERRORS_INITIAL_STATE })

  const { partners } = useSelector(all)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(partnerActions.fetchAll())
  }, [dispatch])

  const [_deletePartners, _setDeletePartner] = useState({
    id: null,
    partnerName: null
  })

  function handleInput(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setFilter({ ...filter, [name]: value })
  }

  function clearFilter() {
    const params = {
      ...INITIAL_STATE,
      // limit: partners.pagination.limit,
      // page: partners.pagination.page,
      column: filter.column,
      sort: filter.sort
    }
    setFilter(params)
    setErrors({ ...INITIAL_STATE })
    dispatch(partnerActions.fetchAll({ params }))
  }

  function onSortColumn(column) {
    const { column: columnOld, sort } = filter
    const newSort =
      column !== columnOld ? 'asc' : sort === 'asc' ? 'desc' : 'asc'
    setFilter({ ...filter, column, sort: newSort })

    const params = { ...filter, column, sort: newSort }
    dispatch(partnerActions.fetchAll({ params }))
  }
  function actionsOnTable({ partnerName, id }) {
    return (
      <PartnersDropdown
        id={id}
        setDeletePartner={() => _setDeletePartner({ partnerName, id })}
      />
    )
  }

  async function onFilter() {
    try {
      const filterPayload = await Yup.object()
        .shape({
          partnerName: Yup.string()
        })
        .validate(filter, {
          abortEarly: false,
          stripUnknown: true
        })

      dispatch(partnerActions.fetchAll(filterPayload))
    } catch (ex) {
      const pairs = ex.inner.map(({ path, message }) => [
        path,
        message.replace(`${path} `, '')
      ])
      setErrors(Object.fromEntries(pairs) as typeof FILTERS_INITIAL_STATE)
    }
  }

  function onExit() {
    _setDeletePartner({ id: null, partnerName: null })
  }

  function onDelete(id) {
    return function () {
      dispatch(partnerActions.deletePartner({ id }))
      onExit()
    }
  }

  function onFilterPaginate(params = {}) {
    dispatch(partnerActions.fetchAll(params))
  }

  return (
    <S.Wrapper container>
      {_deletePartners.id ? (
        <ModalDeletePartners
          name={_deletePartners.partnerName}
          onExit={onExit}
          onRemove={onDelete(_deletePartners.id)}
        />
      ) : null}
      <S.ButtonAdd>
        <Link to="/parceiros-ecotrace/novo">
          <AddButton />
        </Link>
      </S.ButtonAdd>
      <S.FullGrid item xs={12}>
        <S.GridHeader>
          <S.GridTitle item container xs={12}>
            <S.BoxTitle>{t('partner:filterPartner')}</S.BoxTitle>
            <S.BoxLine />
          </S.GridTitle>
        </S.GridHeader>
        <S.GridFilter container>
          <S.GridInput item xs={12} sm={12} md={12}>
            <Input
              fullWidth
              label={t('partner:partnerEco')}
              error={Boolean(errors.partnerName)}
              helperText={errors.partnerName}
              value={filter.partnerName}
              onInput={value => handleInput(value, 'partnerName')}
              onKeyPress={submitOnEnter(onFilter)}
            ></Input>
          </S.GridInput>
          <S.GridButtons item xs={12} sm={12} md={12}>
            <Button variant="default" size="medium" onClick={clearFilter}>
              <p>{t('clean')}</p>
            </Button>
            <Button variant="primary" size="medium" onClick={onFilter}>
              <p>{t('filter')}</p>
            </Button>
          </S.GridButtons>
        </S.GridFilter>
        <S.GridTable>
          <Table
            columns={columns}
            linkTableNotFound="/parceiros-ecotrace/novo"
            rows={partners.items}
            onSortColumn={onSortColumn}
            actionTable={actionsOnTable}
            sortColumn={{ column: filter.column, sort: filter.sort }}
          />
          {filter.pagination?.totalItems > 0 && (
            <Pagination
              filters={{ ...filter, limit: filter.limit, page: filter.page }}
              limit={filter.limit}
              page={filter.page}
              totalItems={filter.totalItems}
              setFilter={onFilterPaginate}
            />
          )}
        </S.GridTable>
      </S.FullGrid>
    </S.Wrapper>
  )
}

import styled from 'styled-components'

export const Wrapper = styled.div`
  padding-left: 2.4rem;
  padding-right: 2.4rem;
`
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 0.4rem;
  padding-top: 3.3rem;
  padding-bottom: 2rem;
`
export const GridTitle = styled.div`
  display: flex;
  align-items: center;
`
export const BoxTitle = styled.h2`
  font-size: 1.8rem;
  padding-right: 4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary};
`
export const Link = styled.li`
  align-items: center;
  color: ${({ theme }) => theme.colors.gray};
  display: flex;
  cursor: pointer;

  > span {
    font-size: 1.6rem;
  }
`
export const BoxFit = styled.div`
  display: flex;
`
export const SubTitle = styled.p`
  color: #464a53;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 0 1.2rem;
`
export const Box = styled.div`
  display: flex;
  align-items: center;
  .iconFit {
    margin-bottom: -5px;
  }
`
export const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ButtonText = styled.span`
  margin-left: 12px;
`

import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CheckButton } from 'components/CheckButton'

import { getTraceabilityDateSpecific } from 'store/TraceabilityCharts/TraceabilityCharts.actions'
import { all } from 'store/TraceabilityCharts/TraceabilityCharts.selector'

import { debounce } from 'utils/operators'

import * as S from './styles'

const years = Array.from({ length: 3 }, (_, i) => {
  const value = i + 2019
  const isActive = false
  const label = value.toString()
  return { label, value, isActive }
})

const months = Array.from({ length: 12 }).map((_, i) => ({
  value: i + 1,
  isActive: false,
  label: new Date(new Date().getFullYear(), i, 1)
    .toLocaleString('pt-br', { month: 'short' })
    .replace('.', '')
}))

const days = Array.from({ length: 31 }, (_, i) => {
  const value = i + 1
  const isActive = false
  const label = value.toString().padStart(2, '0')
  return { label, value, isActive }
})

function fetch({ dispatch, filterSpecific, filterChart }) {
  return dispatch(getTraceabilityDateSpecific({ filterChart, filterSpecific }))
}
const debouncedFetch = debounce(1000, fetch)

export const HorizontalDateFilter = () => {
  const dispatch = useDispatch()
  const { filterChart, filterSpecific } = useSelector(all)

  const [_years, setYears] = useState(filterSpecific.years)
  const [_months, setMonths] = useState(filterSpecific.months)
  const [_days, setDays] = useState(filterSpecific.days)

  function toggleValues({ isActive, value, name, state, setState }) {
    const index = state.findIndex(each => each === value)
    const specific = { ...filterSpecific }

    if (isActive) {
      Object.assign(specific, { [name]: [...state, value] })
    } else {
      Object.assign(specific, { [name]: [...state.filter(f => f !== value)] })
    }

    if (!specific.years.length) {
      specific.years = years.map(year => year.value)
    }

    if (isActive) {
      setState($values => [...$values, value])
    } else {
      setState($values => {
        const entries = [...$values]
        entries.splice(index, 1)
        return entries
      })
    }

    debouncedFetch({ dispatch, filterSpecific: specific, filterChart })
  }

  return (
    <S.Wrapper data-testid="horizontalDateFilter">
      <S.Title>Ano</S.Title>
      <S.Group>
        {years.map(({ label, value }) => (
          <CheckButton
            data-testid="yearButton"
            defaultValue={!!filterSpecific.years.find(f => f === value)}
            onToggle={val =>
              toggleValues({
                isActive: val,
                value,
                name: 'years',
                state: _years,
                setState: setYears
              })
            }
            key={value}
          >
            {label}
          </CheckButton>
        ))}
      </S.Group>
      <S.Title>Mês</S.Title>
      <S.Group>
        {months.map(({ label, value }) => (
          <CheckButton
            defaultValue={!!filterSpecific.months.find(f => f === value)}
            onToggle={val =>
              toggleValues({
                isActive: val,
                value,
                name: 'months',
                state: _months,
                setState: setMonths
              })
            }
            key={value}
          >
            {label}
          </CheckButton>
        ))}
      </S.Group>
      <S.Title>Dia</S.Title>
      <S.Group>
        {days.map(({ label, value }) => (
          <CheckButton
            defaultValue={!!filterSpecific.days.find(f => f === value)}
            onToggle={val =>
              toggleValues({
                isActive: val,
                value,
                name: 'days',
                state: _days,
                setState: setDays
              })
            }
            key={value}
          >
            {label}
          </CheckButton>
        ))}
      </S.Group>
    </S.Wrapper>
  )
}

import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const Wrapper = styled(Grid)`
  margin: 0;
  margin-bottom: 2.4rem;
`

export const FullGrid = styled(Grid)`
  display: contents;
`

export const AvatarGrid = styled(Grid)`
  padding: 0 !important;
`

export const AvatarFlex = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1.2rem;
  box-shadow: 0px 5px 25px #0000000d;
  height: 100%;
  width: 100%;
`

export const GridAvatarPhoto = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 5.7rem;
  height: 16.8rem;
`

export const GridAvatarInfo = styled.div`
  align-items: center;
  margin-top: 2.4rem;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.darkGray};
  display: flex;
  flex-direction: column;
  .Name {
    padding-top: 1.4rem;
    font: italic normal normal 16px Source Sans Pro;
  }
  .Department {
    padding-top: 0.4rem;
    font: normal normal 600 16px Source Sans Pro;
  }
  .Separator {
    margin: 1.2rem 2.4rem;
    width: calc(100% - 4.8rem);
    height: 0.2rem;
    background-color: #f2f3f7;
  }
`

export const GridAvatarGroupDate = styled(Grid)`
  display: flex;
  width: 100%;
`

export const GridAvatarDate = styled(Grid)`
  display: grid;
  width: 50%;
  .createdAt {
    margin-left: 2.4rem;
  }
  .lastAccess {
    margin-right: 2.4rem;
  }
  div {
    display: grid;
    font-size: 1.4rem;
    margin-bottom: 1.4rem;
    margin-top: 1.4rem;
    .Date {
      color: ${({ theme }) => theme.colors.spanDate};
      margin: 1rem 0;
    }
  }
`

export const InfoGrid = styled(Grid)`
  margin-top: 2.4rem;
  padding: 0 !important;
  @media (min-width: 960px) {
    margin-top: 0;
  }
`

export const InfoFlex = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1.2rem;
  box-shadow: 0px 5px 25px #0000000d;
  height: 100%;
  margin-left: 0;
  padding: 1.4rem 2.4rem;
  width: auto;
  @media (min-width: 960px) {
    margin-left: 2rem;
  }
`

export const GridHeader = styled(Grid)`
  display: inline-flexbox;
  padding: 0.5rem 0 0.7rem 0;
`

export const GridTitle = styled(Grid)`
  display: block;
  padding: 0.5rem 0 1rem 0;
`

export const BoxTitle = styled.p`
  color: ${({ theme }) => theme.colors.labelGoBack};
  font-size: 1.4rem;
  font-weight: 600;
  padding-bottom: 1rem;
`

export const GroupsGrid = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1.2rem;
  box-shadow: 0px 5px 25px #0000000d;
  height: auto;
  margin-top: 2.4rem;
  padding-bottom: 1rem;
`

export const GroupsFlex = styled.div`
  margin-left: 0;
  padding: 1.4rem 2.4rem;
  width: auto;
`

export const GroupNotify = styled.div`
  margin-left: -3rem;
  padding: 1.4rem 2.4rem;
  width: auto;
  display: flex;
  flex-direction: row;
  .checkNotifier {
    margin-right: 0;
    margin-left: 3rem;
  }
`

export const GridButtons = styled(Grid)`
  align-self: center;
  text-align: end;
  margin: 3.2rem 0;

  button {
    margin: 0;
  }

  button + button {
    margin-left: 2.4rem;
  }
`

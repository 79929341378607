import { Button } from 'components/Button'
import { Icon } from 'components/Icon'
import { Modal } from 'components/Modal'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type ModalDeleteProps = {
  property: string
  producer: string
  onExit?: () => void
  onRemove?: () => void
}

export function ModalDelete({
  property,
  producer,
  onExit,
  onRemove
}: ModalDeleteProps) {
  const { t } = useTranslation()

  const button = (
    <Button variant="secondary" onClick={onRemove} size="medium">
      <p>{t('common:yes')}</p>
    </Button>
  )

  const content = (
    <S.Wrapper>
      <S.Icon>
        <Icon icon="dropdown-delete" width={16} height={16} />
      </S.Icon>
      <S.Title>{t('common:deleteProperty')}</S.Title>
      <S.Description>
        {t('modalDeleteConfirm:wantToDelete')} {t('common:productor')}
        <span> {producer}</span>
        {' ' + t('modalDeleteConfirm:ofTheProperty')}
        <span> {property}</span> ?
      </S.Description>
    </S.Wrapper>
  )

  return (
    <Modal
      button={button}
      content={content}
      isActionsEnabled={true}
      width={60}
      onExit={onExit}
    />
  )
}

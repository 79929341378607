import { call, put } from 'redux-saga/effects'
import { NutriTableService } from 'services/nutriTable.service'
import { toasts } from 'utils/toasts'
import * as actions from './nutriTable.action'

export function* fetchAll(action) {
  const { params } = action.payload

  yield put(actions.setLoading({ loading: true }))

  const $nutriTable = new NutriTableService()
  const {
    success,
    status,
    data: nutriTables
  } = yield call($nutriTable.fetchAll.bind($nutriTable), { params })

  yield put(actions.setLoading({ loading: false }))

  if (status === 404) {
    return yield put(
      actions.setNutriTables({
        nutriTables: { items: [], pagination: { totalItems: 0 } }
      })
    )
  } else if (success) {
    return yield put(actions.setNutriTables({ nutriTables }))
  } else toasts.generalFail()
}

export function* filterNutriTables() {
  //   yield put(actions.setLoading({ loading: true }))
  //   const $partner = new PartnerService()
  //   const { data, success } = yield $partner.searchByGroup(action.payload)
  //   if (success) yield put(actions.updateGroupIndustries({ ...data }))
  //   else {
  //     yield put(
  //       actions.updateGroupIndustries({
  //         companies: [],
  //         companiesPagination: { totalItems: 0 }
  //       })
  //     )
  //   }
  //   yield put(actions.setLoading({ loading: false }))
}

export function* fetchOne(action) {
  yield put(actions.setLoading({ loading: true }))

  const { id } = action.payload

  const $nutriTable = new NutriTableService()
  const nutriTable = yield call($nutriTable.fetchOne.bind($nutriTable), id)

  if (nutriTable.success) {
    return yield put(
      actions.setNutriTable({
        nutriTable: nutriTable.data
      })
    )
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* create(action) {
  yield put(actions.setLoading({ loading: true }))

  const { payload } = action

  const $nutriTable = new NutriTableService()
  const nutriTable = yield call($nutriTable.create.bind($nutriTable), payload)

  if (nutriTable.success) {
    toasts.created()
    return yield put(
      actions.setNutriTableCreated({
        nutriTableCreate: nutriTable.data
      })
    )
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* edit(action) {
  yield put(actions.setLoading({ loading: true }))

  const { payload } = action

  const $nutriTable = new NutriTableService()
  const { success, data } = yield call(
    $nutriTable.edit.bind($nutriTable),
    payload
  )

  if (success) {
    toasts.edited()
    return yield put(actions.setNutriTableEdit({ nutriTableEdit: data }))
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* deleteNutriTable(action) {
  const id = action.payload

  const $nutriTable = new NutriTableService()
  const nutriTable = yield call(
    $nutriTable.deleteNutriTable.bind($nutriTable),
    { id }
  )

  if (nutriTable.success) {
    toasts.deleted()
    return yield put(actions.fetchAll())
  } else toasts.generalFail()
}

export function* getNutriTableOptions() {
  const $nutriTable = new NutriTableService()
  const {
    success,
    status,
    data: options
  } = yield call($nutriTable.options.bind($nutriTable))

  if (success) return yield put(actions.setNutriTableOptions({ options }))
  else if (status === 404) {
    return yield put(actions.setNutriTableOptions({ options: [] }))
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

import { OrderHistoryType } from 'types/OrderHistory.types'

export type OrderHistoriesType = {
  orderHistories: OrderHistoryType[]
  success: boolean
}

export const INITIAL_STATE = {
  orderHistories: [
    {
      assetType: '',
      key: '',
      lastTouchBy: '',
      timestamp: '',
      author: '',
      charge: '',
      cnpjLocalStock: '',
      dateDispatch: '',
      dateSale: '',
      hashPartner: '',
      order: '',
      payload: ''
    }
  ],
  success: true
}

import { get } from 'lodash'
import {
  ProductLineOptionsType,
  ProductLineType
} from 'types/ProductLine.types'

import { types } from './ProductLine.actions'

type ProductLine = {
  productLines: {
    items: ProductLineType[]
    pagination: {
      totalItems: number
      limit: number
      page: number
    }
  }
  options?: ProductLineOptionsType[]
  productLineCreate?: ProductLineType
  productLineEdit?: ProductLineType
  productLine?: ProductLineType
  loading: boolean
}
const INITIAL_STATE = {
  productLines: {
    items: [],
    pagination: null
  },

  productLineCreate: {} as ProductLineType,
  productLineEdit: {} as ProductLineType,
  productLine: {} as ProductLineType,
  product: {} as ProductLineType,
  options: [],
  loading: false
}

function setState(state: ProductLine) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.CLEAN_PRODUCT_LINES]: setState(state),
    [types.SET_PRODUCT_LINE_CREATED]: setState(state),
    [types.SET_PRODUCT_LINE_EDIT]: setState(state),
    [types.SET_PRODUCT_LINE_OPTIONS]: setState(state),
    [types.SET_PRODUCT_LINES]: setState(state),
    [types.SET_PRODUCT_LINE]: setState(state),
    [types.SET_LOADING]: setState(state),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}

import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { TableNotFoundHint } from 'components/TableNotFoundHint'

import { ClientGroupType } from '../../../../types/ClientGroup.types'
import { ModalDeleteGroupClient } from '../../ModalDeleteGroupClient/ModalDeleteGroupClient'
import { ClientGroupDropdown } from './ClientGroupDropdown'

import { deleteClientGroup } from 'store/ClientGroup/ClientGroup.actions'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type ClientGroupTableProps = {
  rows: ClientGroupType[]
  isUserViewer: boolean
}

export function ClientGroupTable({
  rows,
  isUserViewer
}: ClientGroupTableProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [_deleteClientGroup, _setDeleteClientGroup] = useState(
    {} as ClientGroupType
  )

  function onExit() {
    _setDeleteClientGroup({} as ClientGroupType)
  }

  function onRemoveClientGroup() {
    dispatch(
      deleteClientGroup({
        id: _deleteClientGroup.id
      })
    )
    _setDeleteClientGroup({} as ClientGroupType)
  }

  return (
    <S.Wrapper data-testid="ClientGroupTable">
      {Object.keys(_deleteClientGroup).length > 0 ? (
        <ModalDeleteGroupClient
          groupName={_deleteClientGroup.name}
          retails={_deleteClientGroup.total}
          onExit={onExit}
          onRemove={onRemoveClientGroup}
        />
      ) : null}
      <S.Table>
        <tbody>
          <S.Line id="header">
            <S.Header>{t('tables:groupName')}</S.Header>
            <S.Header className="right">{t('common:customers')}</S.Header>
            {!isUserViewer && (
              <>
                <S.Header>{t('tables:adminUser')}</S.Header>
                <S.Header>{t('tables:viewType')}</S.Header>
                <S.Header>{t('common:actions')}</S.Header>
              </>
            )}
          </S.Line>
          {rows.map((each, key) => (
            <S.Line id="values" key={key}>
              <S.Column>{each.name}</S.Column>
              <S.Column className="right">
                {isUserViewer && (
                  <Link
                    title={String(each.total)}
                    to={{
                      pathname: `/grupo-cliente/gerenciar/${each.id}`,
                      state: { nameBreadcrumbs: `Grupo ${each.name}` }
                    }}
                  >
                    <S.Link>{each.total}</S.Link>
                  </Link>
                )}
                {!isUserViewer && each.total}
              </S.Column>
              {!isUserViewer && (
                <>
                  <S.Column>{each.adminEmail}</S.Column>
                  <S.Column>
                    {each.visible
                      ? t('clientGroup:released')
                      : t('clientGroup:notReleased')}
                  </S.Column>
                  <S.Column id="actions-column">
                    <ClientGroupDropdown
                      id={each.id}
                      clientGroupName={each.name}
                      setDeleteClientGroup={() => _setDeleteClientGroup(each)}
                    />
                  </S.Column>
                </>
              )}
            </S.Line>
          ))}
        </tbody>
      </S.Table>
      {!rows.length && (
        <TableNotFoundHint to={!isUserViewer ? '/grupo-cliente/novo' : ''} />
      )}
    </S.Wrapper>
  )
}

import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding-bottom: 0.05rem;
  display: flow-root;
  width: 100%;

  #table-scrolled {
    td,
    tr,
    th {
      cursor: text;
    }

    td,
    th {
      padding: 0.6rem 1.6rem;
    }

    td:last-child,
    th:last-child {
      border-right: 0px;
    }
  }
`
export const TableGroup = styled.div`
  display: flex;
  width: 100%;
`

export const ScrollWrapper = styled.div`
  cursor: pointer;
  display: grid;
  overflow-x: auto;
  width: 100%;

  ::-webkit-scrollbar {
    height: 0.4rem;
    width: 1.2rem;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray.luminance(0.5)};
    border-radius: 2rem;
  }
`

export const Table = styled.table`
  border-collapse: collapse;
  font-family: Arial, Helvetica, sans-serif;
  table-layout: auto;
  margin-bottom: auto;
`

export const Header = styled.th`
  border: 1px solid #ddd;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1.1rem 1.6rem;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  line-height: 1.6rem;

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }
`

export const CheckboxLine = styled.div`
  display: flex;
  align-items: center;
  > div {
    margin-right: 0.8rem;
  }
`

export const Line = styled.tr`
  height: 4rem;
  :nth-child(even) {
    background-color: ${({ theme }) => theme.colors.rowTable};
  }
`

export const Column = styled.td`
  border: 1px solid #ddd;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.4rem;
  padding: 1.1rem 1.6rem;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  line-height: 1.6rem;

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &#actions-column {
    padding: 0.6rem 0 0.6rem 0;
    div {
      margin: auto;
    }
    button {
      display: block;
      margin: auto;
    }
  }
`

export const CheckboxInfoGuide = styled.div`
  background-color: ${({ theme }) => theme.colors.rowTable};
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.4rem;
  font-weight: 600;
  height: 4rem;
  padding: 1.4rem 2rem;
`

export const SelectedCheckboxInfo = styled.div`
  display: flex;
`

export const RemoveClientIcon = styled.div`
  align-self: center;
  font-family: sans-serif;
  font-weight: 100;
  margin-left: 0.4rem;
  margin-right: 1.4rem;
  cursor: pointer;
`

import { Button, Icon, NewPagination, StatusBox } from 'components'
import { propertiesDetailsActions } from 'store/PropertiesDetails'
import { traceabilityTablesActions } from 'store/TraceabilityTables'
import { useSelector, useDispatch } from 'react-redux'
import * as S from './styled'
import { all as allProperties } from 'store/Properties/Properties.selector'
import { all } from 'store/TraceabilityTables/TraceabilityTables.selector'
import { useTranslation } from 'react-i18next'
import { Table } from './Tab'
import { useEffect, useState, MouseEvent } from 'react'
import { KpiPropertiesSummaryType } from 'types/KpiPropertiesSummary.types'
import { columns as Columns } from 'constants/properties.constants'
import { columns as ColumnsRetail } from 'constants/properties-retails.constants'
import { UserStorageService } from 'services'
import { USER_ROLES } from 'constants/user.constants'
import { ThumbDown, ThumbUp } from '@material-ui/icons'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { ModalSendEmail } from 'components/ModalSendMail'

type PropTypes = {
  kpiPropertiesSummary: {
    items: KpiPropertiesSummaryType[]
    pagination: any
  }
  openModal?: () => void
}

export function ProprietyTab({ kpiPropertiesSummary }: PropTypes) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [modalExport, setModalExport] = useState(false)
  const { filters, tableLoading } = useSelector(all)
  const { properties } = useSelector(allProperties)
  const { user } = UserStorageService.getUserData()
  const { pagination } = kpiPropertiesSummary
  const [isNewPage, setNewPage] = useState({ ...pagination })
  const columns =
    user.tag === USER_ROLES.admin ? [...Columns()] : [...ColumnsRetail()]

  useEffect(() => {
    dispatch(
      traceabilityTablesActions.getPropertiesLocation({
        ...filters,
        typeDate: null,
        years: filters.years || [new Date().getFullYear()]
      })
    )
  }, [dispatch, filters, properties])

  // Trecho comentado pois será utilizado o modal posteriormente
  // function actionsOnTable() {
  //   return (
  //     <S.Link onClick={openModal}>
  //       <Icon icon={'icon-fit'} width={16} height={16} aria-label="action" />
  //     </S.Link>
  //   )
  // }

  function onExport() {
    setModalExport(true)
    dispatch(propertiesDetailsActions.getKpisPropertiesExported(filters))
  }

  async function handleChangePage(
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    const limit = kpiPropertiesSummary.pagination.limit
    const newFilter = {
      ...filters
    }
    newFilter.page = newPage
    newFilter.limit = limit
    dispatch(propertiesDetailsActions.getKpisPropertiesSummary(newFilter))
  }
  function handleNewPage() {
    const limit = kpiPropertiesSummary.pagination.limit
    const newPage = isNewPage.page
    if (newPage > pagination.totalPages) {
      const newFilter = {
        ...filters
      }
      newFilter.page = pagination.totalPages
      newFilter.limit = limit
      dispatch(propertiesDetailsActions.getKpisPropertiesSummary(newFilter))
      setNewPage({ ...pagination, page: newPage })
    } else {
      const newFilter = {
        ...filters
      }
      newFilter.page = newPage
      newFilter.limit = limit
      dispatch(propertiesDetailsActions.getKpisPropertiesSummary(newFilter))
      setNewPage({ ...pagination, page: newPage })
    }
  }
  function handleInputPagination(name: string, value: number) {
    setNewPage({ ...pagination, [name]: value })
  }

  function handleChangeRowsPerPage(e) {
    const page = kpiPropertiesSummary.pagination.page
    const newFilter = {
      ...filters
    }
    newFilter.limit = e.target.value
    newFilter.page = page
    dispatch(propertiesDetailsActions.getKpisPropertiesSummary(newFilter))
  }
  function changeColumn({ column, row }) {
    if (column.name === 'status') {
      return (
        <StatusBox variant={row.status ? 'active' : 'inactive'}>
          {row.status ? t('common:yes') : t('common:no')}
        </StatusBox>
      )
    } else return row[column.name]
  }

  const applyIconForAnalysisField = () => {
    return kpiPropertiesSummary.items?.map(item => ({
      ...item,
      analyze:
        item.analyze || item.analyze === null ? (
          <ThumbUp fontSize="large" style={{ color: '#00c853' }} />
        ) : (
          <ThumbDown fontSize="large" style={{ color: '#c62828' }} />
        )
    }))
  }
  return (
    <S.Wrapper>
      <S.HeaderContainer>
        <S.GridTitle>
          <S.BoxTitle>{t('properties')}</S.BoxTitle>
          <S.BoxFit>
            <S.Box>
              <Icon icon="icon-fit" width={22} height={22} />
              <S.SubTitle>{t('fit')}</S.SubTitle>
            </S.Box>
            <S.Box>
              <Icon
                className="iconFit"
                icon="icon-not-fit"
                width={22}
                height={22}
              />
              <S.SubTitle>{t('notFit')}</S.SubTitle>
            </S.Box>
          </S.BoxFit>
        </S.GridTitle>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p
            style={{ fontSize: '1.4rem', color: 'gray', marginRight: '1.5rem' }}
          >
            {kpiPropertiesSummary.pagination?.totalItems} {t('common:register')}
          </p>
          <Button variant="secondary" size="medium" onClick={onExport}>
            <S.ButtonContent>
              <CloudDownloadIcon />
              <S.ButtonText>{t('common:export')}</S.ButtonText>
            </S.ButtonContent>
          </Button>
        </div>
      </S.HeaderContainer>
      <Table
        columns={columns}
        rows={applyIconForAnalysisField() || []}
        sortColumn={{ column: filters.column, sort: filters.direction }}
        isLoading={tableLoading}
        changeColumn={changeColumn}
        selectedAll
        isScroll={kpiPropertiesSummary.items?.length > 10}
      />
      {modalExport && (
        <ModalSendEmail
          onExit={() => setModalExport(false)}
          title={t('modalExportProperties:title')}
          description={t('modalExportProperties:message')}
        />
      )}
      {kpiPropertiesSummary.pagination.totalItems > 0 && (
        <NewPagination
          count={pagination.totalPages}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          handleInput={handleInputPagination}
          handleNewPage={handleNewPage}
          page={pagination.page}
          limit={pagination.limit}
        />
      )}
    </S.Wrapper>
  )
}

import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { LangDropdown } from 'components/LangDropdown'

import * as S from './styled'

type SigninProps = {
  children: ReactNode
  requestInfo?: boolean
}
const link = 'https://ecotrace.info/para-o-produtor-de-bovinos/'

export function Signin({ children }: SigninProps) {
  const appVersion = process.env.REACT_APP_VERSION
  const { t } = useTranslation()
  const year = new Date().getFullYear()
  const langList = ['pt-BR', 'en-US', 'es', 'ch']
  const defaultLang = langList.includes(window.navigator.language)
    ? window.navigator.language
    : 'en-US'

  const langLocalStorage = localStorage.getItem('i18nextLng')
    ? localStorage.getItem('i18nextLng')
    : defaultLang

  return (
    <S.GridContainer container>
      <S.SideBar item xs={12} sm={5} md={4}>
        <S.Row>
          <S.SideBarColumn>
            <S.SideBarTitle>{t('authPages:welcome')}</S.SideBarTitle>
            <S.SideBarDescription>
              {t('authPages:welcomeDescription')}
              <p>
                <a
                  style={{ color: 'white', textDecoration: 'underline' }}
                  href={link}
                  target="_blanck"
                >
                  {t('authPages:more')}
                </a>
              </p>
            </S.SideBarDescription>
          </S.SideBarColumn>
        </S.Row>
      </S.SideBar>

      <S.GridRight item xs={12} sm={7} md={8}>
        <S.Langdropdown>
          <LangDropdown userLang={langLocalStorage} />
        </S.Langdropdown>

        <S.Row>{children}</S.Row>
        <S.Footer>
          <S.Divider />
          <S.Copyright>
            © {year}{' '}
            <a href="https://ecotrace.info/" target="_blanc">
              &nbsp; By Ecotrace Solutions - version {appVersion}
            </a>
          </S.Copyright>
        </S.Footer>
      </S.GridRight>
    </S.GridContainer>
  )
}

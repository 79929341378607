import { Button, Icon } from 'components'
import { theme } from 'config'
import { THEMES_NAME } from 'config/theme.config'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export function RequestResetPwdForm({
  error,
  value,
  onInput,
  onSubmit
}: {
  error: string
  value: string
  onInput: (_x, _y) => void
  onSubmit: () => void
}) {
  const { t } = useTranslation()

  return (
    <>
      <S.Wrapper container>
        <S.FullGrid item xs={12}>
          <S.Description>
            {t('authPages:recoverPasswordDescription')}
          </S.Description>

          <S.FormContainer>
            <S.FormInput
              error={Boolean(error)}
              helperText={error}
              iconLeft={
                <Icon
                  icon={
                    theme.appTheme === THEMES_NAME.swift
                      ? 'envelope-swift'
                      : 'envelope'
                  }
                  height={16}
                  width={16}
                />
              }
              label={t('common:email')}
              onInput={value => onInput('email', value)}
              type="email"
              value={value}
            />
          </S.FormContainer>
        </S.FullGrid>
      </S.Wrapper>
      <Button variant="loggedOut" onClick={onSubmit}>
        {t('authPages:sendEmailRecoveryPass')}
      </Button>
    </>
  )
}

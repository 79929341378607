import styled from 'styled-components'

export const PropertyInfo = styled.div`
  display: flex;
  flex-direction: row;

  margin: 3rem 0;
`

export const InfoName = styled.div`
  margin-right: 1.5rem;
`

export const InfoCAR = styled.div`
  margin-left: 1.5rem;
`

export const InfoTitle = styled.p`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.darkGray};
  font-weight: 600;
`

export const InfoDescription = styled.p`
  margin-top: 0.5rem;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.gray};
`

import { Modal } from 'components/Modal'

import { IndustryGroupListTable } from './IndustryGroupListTable'
import { IndustryGroupType } from 'types/IndustryGroup.types'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type ModalIndustryGroupListProps = {
  groups: IndustryGroupType[]
  onExit?: () => void
}

export function ModalIndustryGroupList({
  groups,
  onExit
}: ModalIndustryGroupListProps) {
  const { t } = useTranslation()
  const content = (
    <S.Wrapper>
      <S.GridHeader>
        <S.GridTitle item container xs={12}>
          <S.BoxTitle>{t('common:industriesGroups')}</S.BoxTitle>
        </S.GridTitle>
      </S.GridHeader>

      <S.Line />

      <S.GridTable>
        {groups.length > 0 && <IndustryGroupListTable payload={groups} />}
      </S.GridTable>
    </S.Wrapper>
  )

  return (
    <Modal
      content={content}
      isActionsEnabled={false}
      width={70}
      onExit={onExit}
    />
  )
}

import { Error } from 'layouts'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export function PageNotFound() {
  const { t } = useTranslation()
  return (
    <Error>
      <S.Wrapper>
        <S.Logo />
        <S.Error>
          <S.Code>404</S.Code>
          <S.Message>{t('toasts:ContentNotFound')}</S.Message>
        </S.Error>
      </S.Wrapper>
    </Error>
  )
}

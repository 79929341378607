import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding-bottom: 0.05rem;
  display: flow-root;
  width: 100%;
`

export const Table = styled.table`
  border-collapse: collapse;
  table-layout: auto;
  margin-bottom: auto;
  min-width: 100%;
`

export const Header = styled.th`
  border: 1px solid #ddd;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1.1rem 1.6rem;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  line-height: 1.6rem;

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }
`

export const Line = styled.tr`
  height: 4rem;
  :nth-child(even) {
    background-color: ${({ theme }) => theme.colors.rowTable};
  }
`

export const Column = styled.td`
  border: 1px solid #ddd;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.4rem;
  padding: 1.1rem 1.6rem;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  line-height: 1.6rem;

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &#actions-column {
    padding: 0.6rem 0 0.6rem 0;
    width: 4.8rem;
    div {
      margin: auto;
    }
    button {
      display: block;
      margin: auto;
    }
  }
`

export const Link = styled.span`
  padding: 0.2rem 0.8rem;
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.colors.buttonSave};
  color: ${({ theme }) => theme.colors.white};
  &:hover {
    background-color: ${({ theme }) => theme.colors.buttonSave.darken()};
  }
`

export const INITIAL_PRODUCER_STATE = {
  CPFCNPJ: '',
  name: '',
  IE: '',
  free: false,
  blockStatus: false,
  reason: ''
}
export const ERRORS_PRODUCER_STATE = {
  CPFCNPJ: '',
  name: '',
  IE: '',
  free: false,
  blockStatus: null,
  reason: ''
}

export const PRODUCERS_FORM = [INITIAL_PRODUCER_STATE]

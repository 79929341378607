import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { Modal } from 'components/Modal'
import { TableAdd } from './TableAdd'

import { importProperties } from 'store/Property/Property.actions'
import { propertyTmpActions } from 'store/PropertyTmp'
import { all } from 'store/PropertyTmp/PropertyTmp.selector'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type ModalImportProps = {
  onExit?: () => void
}

export function ModalImport({ onExit }: ModalImportProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [propertiesTmpIds, setPropertiesTmpIds] = useState([])
  const { loading, propertiesTmp } = useSelector(all)

  function onAdd() {
    if (propertiesTmpIds.length) {
      dispatch(importProperties({ propertiesTmpIds }))
      onExit()
    }
  }

  function onCancel() {
    dispatch(propertyTmpActions.fetchAll())
    onExit()
  }

  const button = (
    <Button variant="secondary" onClick={onAdd} size="medium">
      <p>{t('common:add')}</p>
    </Button>
  )

  const content = (
    <S.Wrapper>
      <S.GridHeader>
        <S.GridTitle item container xs={12}>
          <S.BoxTitle>{t('common:importProperties')}</S.BoxTitle>
        </S.GridTitle>
      </S.GridHeader>

      <S.GridTable>
        <TableAdd
          propertiesTmp={propertiesTmp.items}
          loading={loading}
          setPropertiesTmp={setPropertiesTmpIds}
        />
      </S.GridTable>
    </S.Wrapper>
  )
  return (
    <Modal
      button={button}
      content={content}
      isActionsEnabled={true}
      width={120}
      onExit={onCancel}
    />
  )
}

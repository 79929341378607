import { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { all } from 'store/OrderHistory/OrderHistory.selector'
import { isVisible } from 'store/Spinner/Spinner.selector'
import { OrderHistoryActions } from 'store/OrderHistory'
import { Modal } from 'components/Modal'
import { ModalContent } from './ModalContent'

export type ModalProps = {
  hashPartner: string
  onExit?: () => void
}

export function ModalOrderHistory({ hashPartner, onExit }: ModalProps) {
  const dispatch = useDispatch()
  const { orderHistories } = useSelector(all)
  const spinnerIsVisible = useSelector(isVisible)

  const fetch = useCallback(() => {
    dispatch(OrderHistoryActions.getByHashPartner(hashPartner))
  }, [dispatch, hashPartner])

  useEffect(() => {
    fetch()
  }, [fetch])

  const onExitHandler = () => {
    dispatch(OrderHistoryActions.clearOrderHistory())
    onExit()
  }

  const content = (
    <ModalContent
      orderHistories={orderHistories}
      spinnerIsVisible={spinnerIsVisible}
    />
  )

  return (
    <Modal
      content={content}
      isActionsEnabled={false}
      width={140}
      onExit={onExitHandler}
    />
  )
}

import i18n from 'i18n'

export const labels = () => ({
  true: i18n.t('clientGroup:released'),
  false: i18n.t('clientGroup:notReleased')
})

export const statusBadgeClassnames = {
  true: 'active',
  false: 'inactive'
}

export const selectOptions = () => [
  { label: i18n.t('clientGroup:released'), value: true },
  { label: i18n.t('clientGroup:notReleased'), value: false }
]

import * as Yup from 'yup'

import { isValid as validateCPF } from 'cpf'
import { phoneValidator } from 'utils/phoneValidator'
import { USER_ROLES } from 'constants/user.constants'
import { viewMode } from 'config'
import i18n from 'i18n'

export const filterUserSchema = () => {
  return Yup.object().shape({
    company: Yup.string().label(i18n.t('common:industries')),
    CPF: Yup.lazy(data => {
      if (data) {
        return Yup.string()
          .test('cpf', i18n.t('inputErrors:invalidCPF'), value => {
            if (value) return validateCPF(value)
            return false
          })
          .label(i18n.t('common:documentNumber'))
      }
      return Yup.string()
    }),
    department: Yup.string().label(i18n.t('profile:department')),
    email: Yup.string().email().label(i18n.t('common:email')),
    name: Yup.string().label(i18n.t('common:name')),
    phone: Yup.lazy(data => {
      if (!data) return Yup.string()
      return phoneValidator().label(i18n.t('common:mobilePhone'))
    }),
    retail: Yup.string().label(i18n.t('common:customers'))
  })
}

export const LoginSchema = () => {
  return Yup.object().shape({
    email: Yup.string().required().email().label(i18n.t('common:email')),
    password: Yup.string()
      .required()
      .min(6)
      .max(32)
      .label(i18n.t('common:password'))
  })
}

export const RecoverySchema = () => {
  return Yup.object().shape({
    email: Yup.string().required().email().label(i18n.t('common:email'))
  })
}
export const ChangePasswordFormSchema = () => {
  return Yup.object().shape({
    confirmPassword: Yup.string()
      .required()
      .oneOf(
        [Yup.ref('password'), null],
        i18n.t('modalUpdatePassword:invalidNewPassword')
      )
      .label(i18n.t('modalUpdatePassword:confirmPassword')),
    currentPassword: Yup.string()
      .required()
      .min(6)
      .max(32)
      .label(i18n.t('modalUpdatePassword:currentPassword')),
    password: Yup.string()
      .required()
      .min(6)
      .max(32)
      .label(i18n.t('modalUpdatePassword:newPassword'))
  })
}

export const EditUserFormSchema = () => {
  return Yup.object().shape({
    CPF: Yup.string()
      .required()
      .test('cpf', i18n.t('inputErrors:invalidCPF'), value => {
        if (value) return validateCPF(value)
        return false
      })
      .label(i18n.t('common:documentNumber')),
    department: Yup.string().required().label(i18n.t('profile:department')),
    email: Yup.string().required().email().label(i18n.t('common:email')),
    emailRecovery: Yup.string()
      .notOneOf([Yup.ref('email')], i18n.t('inputErrors:invalidRecoveryEmail'))
      .notRequired()
      .email()
      .label(i18n.t('profile:emailRecovery')),
    firstName: Yup.string().required().label(i18n.t('profile:firstName')),
    lastName: Yup.string().required().label(i18n.t('profile:lastName')),
    confirmPassword: Yup.mixed().test(
      'confirmPassword',
      i18n.t('inputErrors:confirmPasswordEqual'),
      (value, props) => {
        if (props.parent.password) {
          const sc = Yup.string().notRequired().oneOf([props.parent.password])
          return sc.isValidSync(value)
        }
        return true
      }
    ),
    phone: phoneValidator().nullable(false).label(i18n.t('common:mobilePhone')),
    password: Yup.string().test(
      'password',
      i18n.t('inputErrors:passwordRequired'),
      value => {
        if (value) {
          const sc = Yup.string()
            .required()
            .min(6)
            .max(32)
            .label(i18n.t('common:password'))
          return sc.isValidSync(value)
        }
        return true
      }
    ),
    phoneRecovery: Yup.lazy(data => {
      if (!data) return Yup.string()
      return phoneValidator()
        .nullable(true)
        .notOneOf([Yup.ref('phone')], i18n.t('inputErrors:invalidCellPhone'))
        .label(i18n.t('common:mobilePhone'))
    }),
    roleId: Yup.string().required().label(i18n.t('common:userType')),
    companies: Yup.mixed()
      .test(
        'companies',
        i18n.t('inputErrors:selectErrorIndustry'),
        (value, props) => {
          if (
            viewMode.industry &&
            props.parent.roleTag === USER_ROLES.viewer &&
            !props.parent.companies.length
          ) {
            const sc = Yup.array().min(1).required()
            return sc.isValidSync(value)
          }
          return true
        }
      )
      .required(),
    retails: Yup.mixed()
      .test(
        'retails',
        i18n.t('inputErrors:selectErrorClient'),
        (value, props) => {
          if (
            viewMode.industry &&
            props.parent.roleTag === USER_ROLES.viewer &&
            !props.parent.retails.length
          ) {
            const sc = Yup.array().min(1).required()
            return sc.isValidSync(value)
          }
          return true
        }
      )
      .required()
  })
}

export const CreateUserFormSchema = () => {
  return Yup.object().shape({
    CPF: Yup.string()
      .required()
      .test('cpf', i18n.t('inputErrors:invalidCPF'), value => {
        if (value) return validateCPF(value)
        return false
      })
      .label(i18n.t('common:documentNumber')),
    department: Yup.string().required().label(i18n.t('profile:department')),
    email: Yup.string().required().email().label(i18n.t('common:email')),
    emailRecovery: Yup.string()
      .notOneOf([Yup.ref('email')], i18n.t('inputErrors:invalidRecoveryEmail'))
      .notRequired()
      .email()
      .label(i18n.t('profile:emailRecovery')),
    firstName: Yup.string().required().label(i18n.t('profile:firstName')),
    lastName: Yup.string().required().label(i18n.t('profile:lastName')),
    lang: Yup.string().required().label(i18n.t('profile:language')),
    phone: phoneValidator().nullable(false).label(i18n.t('common:mobilePhone')),
    phoneRecovery: Yup.lazy(data => {
      if (!data) return Yup.string()
      return phoneValidator()
        .nullable(true)
        .notOneOf([Yup.ref('phone')], i18n.t('inputErrors:invalidCellPhone'))
        .label(i18n.t('common:mobilePhone'))
    }),
    roleId: Yup.string().required().label(i18n.t('common:userType')),
    companies: Yup.mixed()
      .test(
        'companies',
        i18n.t('inputErrors:selectErrorIndustry'),
        (value, props) => {
          if (
            viewMode.industry &&
            props.parent.roleTag === USER_ROLES.viewer &&
            !props.parent.companies.length
          ) {
            const sc = Yup.array().min(1).required()
            return sc.isValidSync(value)
          }
          return true
        }
      )
      .required(),
    retails: Yup.mixed()
      .test(
        'retails',
        i18n.t('inputErrors:selectErrorClient'),
        (value, props) => {
          if (
            viewMode.industry &&
            props.parent.roleTag === USER_ROLES.viewer &&
            !props.parent.retails.length
          ) {
            const sc = Yup.array().min(1).required()
            return sc.isValidSync(value)
          }
          return true
        }
      )
      .required()
  })
}

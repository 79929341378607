import chroma from 'chroma-js'
import { useTranslation } from 'react-i18next'

const Translate = ({ text }: { text: string }) => {
  const { t } = useTranslation()

  return <>{t(text)}</>
}

export const standard = {
  colors: {
    background: chroma('#F4F8F7'),
    backgroundAutocomplete: chroma('#F7F9FD'),
    backgroundInactive: chroma('#F99746'),
    backgroundActive: chroma('#3FAB36'),
    backgroundMessage: chroma('#EBEBEB'),
    black: chroma('#000000'),
    borderActive: chroma('#128C49'),
    borderInactive: chroma('#F99746'),
    darkGray: chroma('#464A53'),
    error: chroma('#de4f4f'),
    focusForm: chroma('#408ae4'),
    footer: chroma('#e4e4e4'),
    gray: chroma('#707070'),
    hoverForm: chroma('#0f0f0f'),
    lightGray: chroma('#abafb3'),
    primary: chroma('#315ca7'),
    red: chroma('#DE4F4F'),
    rowTable: chroma('#F4F8F7'),
    secondary: chroma('#3fab36'),
    success: chroma('#3FAB36'),
    textActive: chroma('#0B592F'),
    textInactive: chroma('#944B0F'),
    warn: chroma('#F39C12'),
    white: chroma('#ffffff'),
    backgroundLoggedOut: chroma('#3fab36'),
    buttonLoggedOut: chroma('#3fab36'),
    loggetOutColor: chroma('#ffffff'),
    fillIcon: chroma('#3fab36'),
    buttonSave: chroma('#3fab36'),
    buttonMap: chroma('#3fab36'),
    labelGoBack: chroma('#315ca7'),
    spanDate: chroma('#3fab36'),
    backgroundPermission: chroma('#3fab36'),
    colorCardDark: chroma('#158a16'),
    colorCardLight: chroma('#72de62'),
    colorCardBlue: chroma('#158a16'),
    colorCardGray: chroma('#56c14a'),
    primary1: chroma('#315ca7'),
    primary2: chroma('#315ca7')
  },
  iconLargeNameSidebar: 'panel-logo-ecotrace',
  iconLargeSidebar: 100,
  iconSmallNameSidebar: 'panel-simbolo-ecotrace',
  iconSmallSidebar: 24,
  spacings: {
    xxxsmall: '0.4rem',
    xxsmall: '0.8rem',
    xsmall: '1.6rem',
    small: '2.4rem',
    medium: '3.2rem',
    large: '4.0rem',
    xlarge: '4.8rem',
    xxlarge: '5.6rem'
  },
  title: 'Trace beef',
  transition: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out'
  },
  traceabilityPanel: <Translate text="cards:traceabilityPanel" />
}

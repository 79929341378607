import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const Translate = ({ text }: { text: string }) => {
  const { t } = useTranslation()

  return <>{t(text)}</>
}

export const toasts = {
  addProducer() {
    return toast.success(<Translate text="common:addProducer" />, {
      autoClose: 5 * 1000
    })
  },
  addGroupSuccess() {
    return toast.success(<Translate text="toasts:addGroupSuccess" />, {
      autoClose: 5 * 1000
    })
  },
  blockStatusProducer() {
    return toast.success(<Translate text="toasts:blockStatusProducer" />, {
      autoClose: 5 * 1000
    })
  },
  citiesFail() {
    return toast.error(<Translate text="toasts:citiesFail" />, {
      autoClose: 5 * 1000
    })
  },
  created() {
    return toast.success(<Translate text="toasts:created" />, {
      autoClose: 5 * 1000
    })
  },
  deleted() {
    return toast.success(<Translate text="toasts:deleted" />, {
      autoClose: 5 * 1000
    })
  },
  discarded() {
    return toast.success(<Translate text="toasts:discarded" />, {
      autoClose: 5 * 1000
    })
  },
  duplicated() {
    return toast.warn(<Translate text="toasts:duplicated" />, {
      autoClose: 5 * 1000
    })
  },
  duplicatedCPFCNPJIE() {
    return toast.warn(<Translate text="toasts:duplicatedCPFCNPJIE" />, {
      autoClose: 5 * 1000
    })
  },
  duplicatedRetailWithCustomFilters() {
    return toast.warn(
      <Translate text="toasts:duplicatedRetailWithCustomFilters" />,
      {
        autoClose: 5 * 1000
      }
    )
  },
  duplicatedCNPJ() {
    return toast.warn(<Translate text="toasts:duplicatedCNPJ" />, {
      autoClose: 5 * 1000
    })
  },
  duplicatedCPF() {
    return toast.warn(<Translate text="toasts:duplicatedCPF" />, {
      autoClose: 5 * 1000
    })
  },
  duplicatedEmail() {
    return toast.warn(<Translate text="toasts:duplicatedEmail" />, {
      autoClose: 5 * 1000
    })
  },
  duplicatedPhone() {
    return toast.warn(<Translate text="toasts:duplicatedPhone" />, {
      autoClose: 5 * 1000
    })
  },
  hasProductLine() {
    return toast.warn(<Translate text="toasts:hasProductLine" />, {
      autoClose: 5 * 1000
    })
  },
  hasProduct() {
    return toast.warn(<Translate text="toasts:hasProduct" />, {
      autoClose: 5 * 1000
    })
  },
  edited() {
    return toast.success(<Translate text="toasts:edited" />, {
      autoClose: 5 * 1000
    })
  },
  generalFail({ code = 'UNKNOWN_ERROR' } = {}) {
    return toast.error(<Translate text="toasts:generalFail" />, {
      autoClose: 5 * 1000,
      toastId: code
    })
  },
  import() {
    return toast.success(<Translate text="toasts:import" />, {
      autoClose: 5 * 1000
    })
  },
  updatePwd() {
    return toast.success(<Translate text="toasts:updatePwd" />, {
      autoClose: 5 * 1000
    })
  },
  updatePwdError() {
    return toast.error(<Translate text="toasts:updatePwdError" />, {
      autoClose: 5 * 1000
    })
  },
  updatePwdOldError() {
    return toast.error(<Translate text="toasts:updatePwdOldError" />, {
      autoClose: 5 * 1000
    })
  },
  sendEmailPwd() {
    return toast.success(<Translate text="toasts:sendEmailSuccess" />, {
      autoClose: 5 * 1000
    })
  },
  sendEmailPwdError() {
    return toast.error(<Translate text="toasts:sendEmailError" />, {
      autoClose: 5 * 1000
    })
  },
  blockchainUnavailable() {
    return toast.warn(<Translate text="toasts:blockchainUnavailable" />, {
      autoClose: 5 * 1000
    })
  }
}

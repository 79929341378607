import { Button } from 'components/Button'
import { Icon } from 'components/Icon'
import { Modal } from 'components/Modal'

import { cnpjMask } from 'utils/CnpjMask'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type ModalDeleteIndustryProps = {
  cnpj: string
  onExit?: () => void
  onRemove?: () => void
}

export function ModalDeleteIndustry({
  cnpj,
  onExit,
  onRemove
}: ModalDeleteIndustryProps) {
  const { t } = useTranslation()
  const button = (
    <Button variant="secondary" onClick={onRemove} size="medium">
      <p>{t('common:yes')}</p>
    </Button>
  )

  const content = (
    <S.Wrapper>
      <S.Icon>
        <Icon icon="dropdown-delete" width={16} height={16} />
      </S.Icon>
      <S.Title>{t('breadcrumb:deleteIndustry')}</S.Title>
      <S.Description>
        {t('modalDeleteConfirm:wantToDelete')}
        <span>
          {t('common:CNPJ')} {cnpjMask(cnpj)}
        </span>
        ?
      </S.Description>
    </S.Wrapper>
  )

  return (
    <Modal
      button={button}
      content={content}
      isActionsEnabled={true}
      width={60}
      onExit={onExit}
    />
  )
}

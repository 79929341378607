export const INITIAL_CUSTOM_FILTERS_FROM_API_STATE = {
  id: '',
  customColumnFilter: ''
}

export const ERRORS_CUSTOM_FILTERS_FROM_API_STATE = {
  id: '',
  customColumnFilter: ''
}

export const CUSTOM_FILTERS_FORM = [INITIAL_CUSTOM_FILTERS_FROM_API_STATE]

import { IconButton } from '@material-ui/core'
import { AvatarUI } from 'components/Avatar'
import { Icon } from 'components/Icon'

import defaultImage from '../../assets/images/default-image.png'
import { useTranslation } from 'react-i18next'

import * as S from './styled'
import { theme } from 'config'
import { THEMES_NAME } from 'config/theme.config'

export type PictureProps = {
  imgUrl: string
  name: string
  variant?: 'circle' | 'square'
  onLoadImage?: (_name) => void
  onRemoveImage?: (_name) => void
}

export function Picture({
  imgUrl,
  name,
  variant = 'circle',
  onLoadImage,
  onRemoveImage
}: PictureProps) {
  const { t } = useTranslation()
  return (
    <>
      {imgUrl && (
        <S.Remove>
          <S.RemoveFile>
            <IconButton
              style={{ backgroundColor: '#ffffff' }}
              aria-label="delete picture"
              component="span"
              onClick={onRemoveImage}
            >
              <Icon icon="delete-image" height={16} width={16} fill="#de4f4f" />
            </IconButton>
          </S.RemoveFile>
        </S.Remove>
      )}

      <S.Camera>
        <S.InputFile
          accept="image/*"
          id={name}
          type="file"
          onChange={onLoadImage}
        />

        <S.LabelFile className={`${variant}`} htmlFor={name}>
          <IconButton
            style={
              theme.appTheme === THEMES_NAME.swift
                ? { backgroundColor: '#FFC90C' }
                : { backgroundColor: '#315CA7' }
            }
            aria-label="upload picture"
            component="span"
          >
            <Icon icon="photo" height={16} width={16} fill="#fff" />
          </IconButton>
        </S.LabelFile>
      </S.Camera>
      {variant === 'circle' && (
        <AvatarUI
          variant="medium"
          name={name}
          color="#d9d9d9"
          imgUrl={imgUrl}
        />
      )}
      {variant === 'square' && (
        <S.WraperImage>
          <img src={imgUrl || defaultImage} />
        </S.WraperImage>
      )}
      <S.LabelPhoto>{t('profile:changeAvatar')}</S.LabelPhoto>
    </>
  )
}

import { KpiPropertiesDetailsType } from 'types/KpiPropertiesDetails.types'
import { Icon, Pagination } from 'components'
import { propertiesDetailsActions } from 'store/PropertiesDetails'
import { all as allFilter } from 'store/TraceabilityTables/TraceabilityTables.selector'
import { useSelector, useDispatch } from 'react-redux'
import * as S from './styled'
import { useTranslation } from 'react-i18next'

type PropTypes = {
  kpiPropertiesDetails: {
    items: KpiPropertiesDetailsType[]
    pagination: any
  }
}

export function PropertiesDetailsGrid({ kpiPropertiesDetails }: PropTypes) {
  const { t } = useTranslation()
  const { filters } = useSelector(allFilter)
  const dispatch = useDispatch()

  function onPaginateFilter({ limit, page }) {
    const newFilter = {
      ...filters
    }

    newFilter.page = page
    newFilter.limit = limit

    dispatch(propertiesDetailsActions.getKpisPropertiesDetails(newFilter))
  }

  return (
    <>
      <S.Date>
        <p>
          {t('slaughterDate')}: {filters.slaughtersif}
        </p>
      </S.Date>
      <S.GridTable>
        <S.TableWrapper>
          <S.Table id="table-fixed">
            <tbody>
              <S.Line id="header">
                <S.Header>SIF Abate</S.Header>
                <S.Header>Responsável Monitoramento</S.Header>
                <S.Header>CNPJ</S.Header>
                <S.Header>Site</S.Header>
                <S.Header>Status</S.Header>
              </S.Line>

              <S.Line>
                <S.Column>{filters.processedSIF}</S.Column>
                <S.Column>Agrotools Gestão e Monitoramento</S.Column>
                <S.Column>08.808.179/0002-09</S.Column>
                <S.Column>
                  <a
                    style={{ color: '#464A53' }}
                    href="https://agrotools.com.br/"
                    target="_blanck"
                  >
                    www.agrotools.com.br
                  </a>
                </S.Column>
                <S.Column>
                  <Icon icon="icon-fit" width={18} height={18} />
                </S.Column>
              </S.Line>
            </tbody>
          </S.Table>

          {kpiPropertiesDetails.pagination?.totalItems > 0 && (
            <Pagination
              filters={filters}
              limit={kpiPropertiesDetails.pagination.limit}
              page={kpiPropertiesDetails.pagination.page}
              totalItems={kpiPropertiesDetails.pagination.totalItems}
              setFilter={onPaginateFilter}
            />
          )}
        </S.TableWrapper>
      </S.GridTable>
    </>
  )
}

import { Divider, Grid } from '@material-ui/core'

import styled from 'styled-components'

export const Wrapper = styled(Grid)`
  display: flex;
  margin: 0;
  width: calc(100% + 24px);
  flex-direction: row;
`
export const GridFields = styled(Grid)`
  display: flex;
  margin: -9px;
  width: calc(100% + 24px);
  flex-direction: row;
`
export const GridInput = styled(Grid)``

export const GridPhoto = styled(Grid)`
  display: flex;
  margin: 0 0.6rem;
  width: calc(100% + 24px);
  flex-direction: row;
`

export const DividerVertical = styled(Divider)`
  background-color: #f2f3f7;
`

export const CardPhoto = styled.div`
  background-color: #f7f9fd;
  border-radius: 0.8rem;
  width: 100%;
  margin: 0 1.6rem;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0.8rem;
  height: 27.8rem;
`
export const StyleAutoComplete8 = styled(Grid)`
  margin-left: -5px;
  margin-right: -6px;
`

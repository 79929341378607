import { HTTP } from 'providers'

export class PropertyProducersService extends HTTP {
  private endpoint = '/propertyProducer'

  public fetchAll({ params = {} } = {}) {
    return this.get({ endpoint: this.endpoint, params })
  }

  public fetchOne(id) {
    return this.get({
      endpoint: `${this.endpoint}/${id}`
    })
  }

  public fetchByPropertyId(propertyId) {
    return this.get({
      endpoint: `${this.endpoint}/property/${propertyId}`
    })
  }

  public deleteProperty({ id }) {
    return this.delete({
      endpoint: `${this.endpoint}/${id}`
    })
  }
}

import { useSelector } from 'react-redux'
import { all } from 'store/TraceabilityTables/TraceabilityTables.selector'

import { BarChart } from 'components'
import * as S from './styled'
import { useTranslation } from 'react-i18next'
import { theme } from 'config'
import { THEMES_NAME } from 'config/theme.config'

export function InvoicesAndPropertiesCharts() {
  const { t } = useTranslation()
  const monthsTranslation = t('common:months')
  const amountTranslation = t('common:amount')
  const invoicesTranslation = t('common:invoices')

  const { invoicesByMonth, propertiesByMonth, chartsLoading } = useSelector(all)
  const invoiceSeries = {
    name: monthsTranslation,
    data: invoicesByMonth
  }

  const propertySeries = {
    name: monthsTranslation,
    data: propertiesByMonth
  }

  return (
    <S.Wrapper item xs={12} md={5} container>
      <S.GridHorizontalDate item xs={12}>
        <BarChart
          color={theme.appTheme === THEMES_NAME.swift ? '#EF5123' : '#2f73cc'}
          type="column"
          title={invoicesTranslation}
          subTitle=""
          series={invoiceSeries}
          tooltipSuffix={invoicesTranslation}
          xAxisTitle={monthsTranslation}
          yAxisTitle={amountTranslation}
          height={275}
          isLoading={chartsLoading}
        />
      </S.GridHorizontalDate>

      <S.GridHorizontalDate item xs={12}>
        <BarChart
          color={theme.appTheme === THEMES_NAME.swift ? '#FD9272' : '#add9fd'}
          type="column"
          title={t('dashboard:slaughterEvolution')}
          subTitle=""
          series={propertySeries}
          tooltipSuffix={t('common:properties')}
          xAxisTitle={monthsTranslation}
          yAxisTitle={amountTranslation}
          height={275}
          isLoading={chartsLoading}
        />
      </S.GridHorizontalDate>
    </S.Wrapper>
  )
}

import aditionalInformation from './ch/aditionalInformation.json'
import authPages from './ch/authPages.json'
import breadcrumb from './ch/breadcrumb.json'
import cards from './ch/cards.json'
import clientGroup from './ch/clientGroup.json'
import common from './ch/common.json'
import configurationInputErrors from './ch/configurationInputErrors.json'
import customFilters from './ch/customFilters.json'
import dashboard from './ch/dashboard.json'
import inputErrors from './ch/inputErrors.json'
import modalBlockchainHistory from './ch/modalBlockchainHistory.json'
import modalDeleteConfirm from './ch/modalDeleteConfirm.json'
import modalUpdatePassword from './ch/modalUpdatePassword.json'
import pagination from './ch/pagination.json'
import profile from './ch/profile.json'
import tables from './ch/tables.json'
import toasts from './ch/toasts.json'
import users from './ch/users.json'
import nutriTable from './ch/nutriTable.json'
import partner from './ch/partner.json'
import product from './ch/product.json'
import productLine from './ch/productLine.json'
import termOfUse from './ch/termOfUse.json'
import months from './ch/months.json'
import modalExportProperties from './ch/modalExportProperties.json'
import modalExportOrders from './ch/modalExportOrders.json'
import modalExportManageClientGroup from './ch/modalExportManageClientGroup.json'

export default {
  aditionalInformation,
  authPages,
  breadcrumb,
  cards,
  clientGroup,
  common,
  configurationInputErrors,
  customFilters,
  dashboard,
  inputErrors,
  modalBlockchainHistory,
  modalDeleteConfirm,
  modalUpdatePassword,
  pagination,
  profile,
  tables,
  toasts,
  users,
  nutriTable,
  partner,
  product,
  productLine,
  termOfUse,
  months,
  modalExportProperties,
  modalExportOrders,
  modalExportManageClientGroup,
  filter: {
    slaughter: 'Filtrar abate'
  }
}

/* eslint-disable no-debugger */
import { omit } from 'lodash'
import { call, put } from 'redux-saga/effects'

import { PropertiesService, KpisService } from 'services'
import FilteringTraceability from 'utils/FilteringTraceability'

import * as actions from './Properties.actions'
import * as actionsKpis from '../TraceabilityTables/TraceabilityTables.actions'
import { spinnerActions } from 'store/Spinner'

export function* getProperties(action) {
  yield put(actionsKpis.setTableLoading(true))
  const _filters = FilteringTraceability(action.payload.filterProperties)

  const $order = new PropertiesService()

  const properties = yield call($order.getProperties.bind($order), {
    ..._filters,
    orderskeys: action.payload.ordersKeys.toString()
  })

  if (properties.success) {
    properties.data.items = properties.data.items.map(item => ({
      ...item,
      analyze: true
    }))
    yield put(
      actions.setProperties({
        properties: omit(properties.data, ['kpis', 'charts'])
      })
    )
  } else {
    yield put(
      actions.setProperties({
        properties: { items: [], pagination: { totalItems: 0 } }
      })
    )
    if (properties.status !== 404) {
      yield put(actionsKpis.cleanKpis())
    }
  }

  yield put(actionsKpis.setTableLoading(false))
}

export function* getPropertiesLocation(action) {
  const _filters = FilteringTraceability({
    ...action.payload.filterProperties,
    typeDate: ''
  })

  const getLocationFilter = action.payload.ordersKeys
    ? {
        ..._filters,
        orderskeys: action.payload.ordersKeys.toString()
      }
    : action.payload.filterProperties

  yield put(spinnerActions.setVisibility(true))

  const $properties = new PropertiesService()
  const $kpis = new KpisService()

  const $service = action.payload.ordersKeys ? $properties : $kpis

  const properties = yield call(
    $service.getPropertiesLocation.bind($service),
    getLocationFilter
  )

  if (properties.success) {
    yield put(
      actions.setPropertiesLocation({ propertiesLocation: properties.data })
    )
  }

  yield put(spinnerActions.setVisibility(false))
}

/* eslint-disable react-hooks/exhaustive-deps */
import { viewMode } from 'config'
import {
  INITIAL_STATE,
  INITIAL_STATE_ERRORS
} from 'constants/traceability.constants'
import { USER_ROLES } from 'constants/user.constants'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UserService, UserStorageService } from 'services'
import { CityActions } from 'store/City'
import { traceabilityTablesActions } from 'store/TraceabilityTables'
import { all as allTraceability } from 'store/TraceabilityTables/TraceabilityTables.selector'
import { toasts } from 'utils/toasts'
import * as Yup from 'yup'
import { InputGeneralFilters } from './../InputGeneralFilters'

export function ManagementGeneralFilters({ isPropertyPage = false }) {
  const { filters, accessGroupsFilter, industryGroupsFilter } =
    useSelector(allTraceability)
  const trackable = 'true'

  const [filter, setFilter] = useState({ ...filters, trackable })
  const [errors, setErrors] = useState({ ...INITIAL_STATE_ERRORS })
  const dispatch = useDispatch()
  const { user } = UserStorageService.getUserData()
  const INITIAL_YEARS_STATE = filters.years.length
    ? filters.years
    : [new Date().getFullYear()]
  // eslint-disable-next-line no-unused-vars
  const [yearsState, setYearsState] = useState(INITIAL_YEARS_STATE)
  const schema = Yup.object().shape({
    barcode: Yup.string(),
    batch: Yup.string(),
    begin: Yup.string()
      .nullable(true)
      .test('begin', 'Data inválida', val => {
        if (val === 'Invalid Date') return false
        return true
      })
      .test('begin', 'Informe a data', (val, props) => {
        if (props.parent.typeDate && !val) return false
        return true
      })
      .default(undefined),
    boningoutinspectionnum: Yup.string(),
    boningoutindustrycnpj: Yup.string(),
    car: Yup.string(),
    cargo: Yup.string(),
    destinationcnpj: Yup.string(),
    end: Yup.string()
      .nullable(true)
      .test('end', 'Data inválida', val => {
        if (val === 'Invalid Date') return false
        return true
      })
      .test('end', 'Informe a data', (val, props) => {
        if (props.parent.typeDate && !val) return false
        return true
      })
      .default(undefined),
    ie: Yup.string(),
    invoice: Yup.string(),
    fazCode: Yup.string(),
    order: Yup.string(),
    producerdoc: Yup.string(),
    propname: Yup.string(),
    producername: Yup.string(),
    slaughtersif: Yup.string(),
    typeDate: Yup.string()
      .nullable(true)
      .test('typeDate', 'Informe o tipo de data', (val, props) => {
        if (props.parent.begin && !val) return false
        if (props.parent.end && !val) return false
        return true
      })
      .default(undefined),
    column: Yup.string(),
    limit: Yup.number().default(10),
    direction: Yup.string(),
    page: Yup.number(),
    trackable: Yup.string(),
    productionType: Yup.string(),
    invoiceType: Yup.string(),
    processedSIF: Yup.string(),
    stockcnpj: Yup.string(),
    years: Yup.array(),
    months: Yup.array(),
    accessGroupsFilter: Yup.array(),
    industryGroupsFilter: Yup.array(),
    state: Yup.string().nullable(),
    city: Yup.string().nullable(),
    analyze: Yup.string().nullable()
  })

  useEffect(() => {
    const fetch = async () => {
      const loggedInUser = UserStorageService.getUserData()
      const { data, success } = await new UserService().fetchOne(
        loggedInUser.id
      )
      const _data = viewMode.industry
        ? {
            ...data,
            clientGroups: data.groups,
            industryGroups: data.groupsIndustry
          }
        : data
      if (!success) toasts.generalFail()
      if (_data.groups.length) {
        const accessClientGroupsFilter = Object.values(_data.groups)
          .map((group: any) => ({
            accessGroupName: group.name,
            id: group.id,
            type: group.type
          }))
          .filter(group => group.type === 'retail')
        if (user.tag === USER_ROLES.viewer) {
          filter.accessGroupsFilter = accessClientGroupsFilter
          setFilter({ ...filter })
        }
        dispatch(
          traceabilityTablesActions.setAccessGroups(accessClientGroupsFilter)
        )
      }
      if (_data.groupsIndustry.length) {
        const industryGroupsFilter = Object.values(_data.groupsIndustry).map(
          (groupIndustry: any) => ({
            industryGroupName: groupIndustry.name,
            id: groupIndustry.id,
            type: groupIndustry.type
          })
        )
        if (user.tag === USER_ROLES.viewer) {
          filter.industryGroupsFilter = industryGroupsFilter
          setFilter({ ...filter })
        }
        dispatch(
          traceabilityTablesActions.setIndustryGroups(industryGroupsFilter)
        )
      }
    }

    if (!filter.accessGroupsFilter.length || !filter.industryGroupsFilter) {
      fetch()
    }
  }, [filter.accessGroupsFilter, filter.industryGroupsFilter])

  useEffect(() => {
    if (filter.state) dispatch(CityActions.fetchByUf({ uf: filter.state }))
  }, [filter.state])
  useEffect(() => {
    if (!isEqual(filters.years, yearsState)) {
      const { begin, end } = setBeginEndDate()
      setFilter({
        ...filters,
        begin,
        end,
        trackable,
        years: yearsState
      })
      dispatch(
        traceabilityTablesActions.setFilters({
          ...filters,
          begin,
          end,
          years: yearsState
        })
      )
    }
  }, [yearsState])

  function setBeginEndDate() {
    const begin = new Date(yearsState[0], 0, 1)
    const atualYear = new Date().getFullYear()
    const end = yearsState.includes(atualYear)
      ? new Date()
      : new Date(yearsState[yearsState.length - 1], 11, 31, 23, 59, 59)
    return { begin, end }
  }
  function handleInputData(value: string | Date, name: string) {
    setErrors({ ...errors, [name]: '' })
    setFilter({ ...filter, [name]: value })
  }

  function handleSelect(value: any, name: string) {
    setErrors({ ...errors, [name]: '' })
    if (value.value) setFilter({ ...filter, [name]: value.value })
    else setFilter({ ...filter, [name]: value })
  }

  async function onSubmit() {
    try {
      const params = await schema.validate(
        { ...filter, limit: filter.limit || 10 },
        {
          abortEarly: false,
          stripUnknown: true
        }
      )
      dispatch(traceabilityTablesActions.setFilters(params))
    } catch (ex) {
      const pairs = ex.inner.map(({ path, message }) => [
        path,
        message.replace(`${path} `, '')
      ])
      setErrors(Object.fromEntries(pairs) as typeof INITIAL_STATE_ERRORS)
    }
  }

  function clearFilter() {
    const params = {
      ...INITIAL_STATE,
      accessGroupsFilter:
        user.tag === USER_ROLES.admin ? [] : accessGroupsFilter,
      begin: new Date(new Date().getFullYear(), 0, 1),
      end: new Date(),
      limit: filter.limit,
      page: filter.page,
      column: filter.column,
      direction: filter.direction
    }
    setFilter(params)
    setErrors({ ...INITIAL_STATE_ERRORS })
    dispatch(traceabilityTablesActions.setFilters(params))
  }

  return (
    <>
      <InputGeneralFilters
        filter={filter}
        isPropertyPage={isPropertyPage}
        accessGroupsFilter={accessGroupsFilter}
        industryGroupsFilter={industryGroupsFilter}
        errors={errors}
        handleInputData={handleInputData}
        handleSelect={handleSelect}
        onSubmit={onSubmit}
        clearFilter={clearFilter}
      />
    </>
  )
}

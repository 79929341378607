import styled from 'styled-components'
export const DropContent = styled.div.attrs({ className: 'dropzone' })`
  border: 1px dashed #707070;
  cursor: pointer;
  transition: height:  0.2s ease;
  border-radius: 2rem;
  width: 55.2rem;
  height: 14.8rem;
  button {
    margin-top: 5rem
  }
  p{
    font-size: 1.6rem;
    color: #ABAFB3;
  }
`
export const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ButtonText = styled.span`
  margin-right: 12px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1.6rem;
  white-space: normal;
  text-align: center;
  span {
    position: absolute;
    left: 2.7rem;
    bottom: 12.5rem;
    font-size: 1.2rem;
  }
`
export const Icon = styled.div`
  background-color: #f2f3f7;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.4rem;
`

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.8rem;
  font-weight: 600;
  height: 2.3rem;
  margin-bottom: 1.6rem;
`

export const Description = styled.p`
  span {
    color: #464a53;
  }
`

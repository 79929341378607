import { HTTP } from 'providers'
import { ProductLineType } from 'types/ProductLine.types'

export class ProductLineService extends HTTP {
  private endpoint = '/product-line'

  public fetchAll(params: any) {
    return this.get({
      endpoint: this.endpoint,
      params: { id: params.id, partner: params.partner_id }
    })
  }

  public fetchOne(id) {
    return this.get({
      endpoint: `${this.endpoint}/${id}`
    })
  }

  public async create(productLine: ProductLineType) {
    return this.post({
      endpoint: this.endpoint,
      payload: productLine
    })
  }

  public async edit(productLine: ProductLineType) {
    const { id } = productLine
    return this.put({
      endpoint: `${this.endpoint}/${id}`,
      payload: productLine
    })
  }

  public async deleteProductLine({ id }) {
    return this.delete({
      endpoint: `${this.endpoint}/${id}`
    })
  }

  public async options() {
    return this.get({ endpoint: `${this.endpoint}/options` })
  }
}

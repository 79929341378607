import { CityType } from 'types/City.types'

import { get } from 'lodash'

import { types } from './City.actions'

type Property = {
  cities: CityType[]
}

const INITIAL_STATE = {
  cities: []
}

function setState(state: Property) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.SET_CITIES]: setState(state),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}

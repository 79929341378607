import { MouseEventHandler } from 'react'
import { SubmitButton } from './SubmitButton'
import { CancelButton } from './CancelButton'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

type PropTypes = {
  onSubmit: MouseEventHandler
  onExit: MouseEventHandler
  type?: 'form' | 'filter' | 'search'
}

export function FormActionRow({ onExit, onSubmit, type = 'form' }: PropTypes) {
  const { t } = useTranslation()
  return (
    <S.Container item xs={12}>
      <CancelButton
        title={type === 'form' ? t('common:cancel') : t('common:clean')}
        onExit={onExit}
      />
      <SubmitButton
        title={type === 'form' ? t('common:save') : t('common:filter')}
        variant={type === 'form' ? 'primary' : 'secondary'}
        onSubmit={onSubmit}
      />
    </S.Container>
  )
}

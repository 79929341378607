import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  white-space: normal;
`

export const GridHeader = styled(Grid)`
  display: inline-flexbox;
  margin-bottom: 2.4rem;
`

export const GridTitle = styled(Grid)`
  display: block;
`

export const BoxTitle = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.8rem;
  font-weight: 600;
`

export const Line = styled.div`
  width: auto;
  height: 0.2rem;
  background-color: #f2f3f7;
`

export const PropertyInfo = styled.div`
  display: flex;
  flex-direction: row;

  margin: 3rem 0;
`

export const InfoName = styled.div`
  margin-right: 1.5rem;
`

export const InfoCAR = styled.div`
  margin-left: 1.5rem;
`

export const InfoTitle = styled.p`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.darkGray};
  font-weight: 600;
`

export const InfoDescription = styled.p`
  margin-top: 0.5rem;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.gray};
`

export const GridTable = styled.div`
  margin-top: 2.5rem;
  max-height: 35rem;
  overflow-y: auto;
`

export const GridFields = styled(Grid)`
  display: flex;
  margin: -16px;
  width: calc(100% + 24px);
  flex-direction: row;
`

export const GridInput = styled(Grid)``

import { Button } from 'components/Button'
import { Icon } from 'components/Icon'
import { Modal } from 'components/Modal'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type ModalConfirmProps = {
  property: string
  producer: string
  onExit?: () => void
  onDiscard?: () => void
}

export function ModalConfirm({
  property,
  producer,
  onExit,
  onDiscard
}: ModalConfirmProps) {
  const { t } = useTranslation()
  const button = (
    <Button variant="secondary" onClick={onDiscard} size="medium">
      <p>{t('common:confirm')}</p>
    </Button>
  )

  const content = (
    <S.Wrapper>
      <S.Icon>
        <Icon icon="dropdown-delete" width={16} height={16} />
      </S.Icon>
      <S.Title>{t('modalDeleteConfirm:discardImport')}</S.Title>
      <S.Description>
        {t('modalDeleteConfirm:discardProducerImport') + ' '}
        <span> {producer} </span>
        {t('modalDeleteConfirm:ofTheProperty')}
        <span> {property}</span> ?
      </S.Description>
    </S.Wrapper>
  )

  return (
    <Modal
      button={button}
      content={content}
      isActionsEnabled={true}
      width={60}
      onExit={onExit}
    />
  )
}

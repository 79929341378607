import CircularProgress from '@material-ui/core/CircularProgress'
import * as S from './styled'

interface ILoading {
  loadingColorWhite?: boolean
}
export function Loading({ loadingColorWhite = false }: ILoading) {
  const changeLoadColor = loadingColorWhite === true ? 'white' : ''

  return (
    <S.Wrapper>
      <CircularProgress style={{ color: changeLoadColor }} />
    </S.Wrapper>
  )
}

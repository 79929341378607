import { HTTP } from 'providers'

export class PropertyTmpService extends HTTP {
  private endpoint = '/reference'

  public fetchAll({ params = {} } = {}) {
    return this.get({ endpoint: this.endpoint, params })
  }

  public deletePropertyTmp({ id }) {
    return this.delete({
      endpoint: `${this.endpoint}/${id}`
    })
  }
}

import { Base } from 'layouts'
import { Tabs } from '.'
import { GeneralFilters } from 'components/GeneralFilters'

export function Invoices() {
  return (
    <Base>
      <GeneralFilters />

      <Tabs />
    </Base>
  )
}

import { HTTP } from 'providers'
import { PartnerType } from 'types/Partner.types'

export class PartnerService extends HTTP {
  private endpoint = '/partner'

  public fetchAll(params = {}) {
    return this.get({ endpoint: this.endpoint, params })
  }

  public fetchOne(id) {
    return this.get({
      endpoint: `${this.endpoint}/${id}`
    })
  }

  public async create(partner: PartnerType) {
    return this.post({
      endpoint: this.endpoint,
      payload: partner
    })
  }

  public async edit(partner: PartnerType) {
    const { id } = partner
    return this.put({
      endpoint: `${this.endpoint}/${id}`,
      payload: partner
    })
  }

  public async deletePartner({ id }) {
    return this.delete({
      endpoint: `${this.endpoint}/${id}`
    })
  }

  public async options() {
    return this.get({ endpoint: `${this.endpoint}/options` })
  }
}

import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { phoneMask } from '../../utils/PhoneMask'

import * as S from './styled'

export type InputPhoneProps = {
  error?: boolean
  fullWidth?: boolean
  helperText?: string
  id?: string
  label?: string
  name?: string
  value?: string
  width?: number
  onInput?: (_value: string) => void
}

export function InputPhone({
  error = false,
  fullWidth = false,
  helperText = '',
  label,
  value = '',
  width = 200,
  onInput
}: InputPhoneProps) {
  const [valueMask, setValueMask] = useState('')

  const onMask = useCallback(
    (val: string) => {
      const mask = phoneMask(val)

      setValueMask(mask)
      !!onInput && onInput(mask)
    },
    [onInput]
  )

  useEffect(() => {
    if (value !== valueMask) onMask(value)
  })

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const newValue = e.currentTarget.value
    if (value !== newValue) onMask(newValue)
  }

  return (
    <S.InputPhone
      error={error}
      helperText={helperText}
      label={label}
      fullWidth={fullWidth}
      onChange={onChange}
      style={{ width: !fullWidth ? width : '100%' }}
      value={valueMask}
    />
  )
}

import * as S from './styled'
import { useTranslation } from 'react-i18next'

export function TableNotFoundHint({
  to,
  onClick
}: {
  to?: string
  onClick?: (_value: unknown) => void
}) {
  const { t } = useTranslation()

  return (
    <S.Hint>
      {t('toasts:dataNotFound')}
      {Boolean(to) && <S.Link to={to}>{t('toasts:wantToAdd')}</S.Link>}
      {Boolean(onClick) && (
        <S.Link to={'#'} onClick={onClick}>
          {t('toasts:wantToAdd')}
        </S.Link>
      )}
    </S.Hint>
  )
}

import { omit } from 'lodash'
import { call, put } from 'redux-saga/effects'

import { FoodSafetyService } from 'services'
import FilteringTraceability from 'utils/FilteringTraceability'

import * as actions from './FoodSafety.actions'
import * as actionsKpis from '../TraceabilityTables/TraceabilityTables.actions'

export function* getFoodSafety(action) {
  yield put(actionsKpis.setTableLoading(true))
  const _filters = FilteringTraceability(action.payload.filterFoodSafety)

  const $service = new FoodSafetyService()

  const foodSafety = yield call($service.getFoodSafety.bind($service), {
    ..._filters,
    orderskeys: action.payload.ordersKeys.toString()
  })

  if (foodSafety.success) {
    yield put(
      actions.setFoodSafety({
        foodSafety: omit(foodSafety.data, ['kpis', 'charts'])
      })
    )
    yield put(actionsKpis.setKpis({ kpis: foodSafety.data.kpis }))
    yield put(actionsKpis.setCharts({ charts: foodSafety.data.charts }))
  } else {
    yield put(
      actions.setFoodSafety({
        foodSafety: { items: [], pagination: { totalItems: 0 } }
      })
    )
    if (foodSafety.status !== 404) {
      yield put(actionsKpis.cleanKpis())
    }
  }

  yield put(actionsKpis.setTableLoading(false))
}

import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Button, Icon, Input } from 'components'

export const SideBarCorner = styled.div`
  align-self: center;
  margin-left: -9.35rem;
  margin-top: -15rem;
  .chevron {
    color: white;
    display: block;
    font-size: 4.8rem;
    margin-left: 9rem;
    margin-top: -16rem;
    transform: translateY(-110%);
  }
`
export const LogoSwift = styled.img.attrs({
  alt: 'Logo Friboi',
  src: '/images/loggo_swift.svg'
})`
  height: auto;
  object-fit: contain;
  object-position: center;
  margin: 0 0 3rem 0;
  width: 17rem;
`

export const LogoEco = styled.img.attrs({
  alt: 'Lupa com uma folha com Ecotrace escrito ao lado',
  src: '/images/logo.png'
})`
  height: auto;
  object-fit: contain;
  object-position: center;
  margin: 0 0 3rem 0;
  width: 17rem;
`

export const LogoFriboi = styled.img.attrs({
  alt: 'Logo Friboi',
  src: '/images/logo_friboi.png'
})`
  height: auto;
  object-fit: contain;
  object-position: center;
  margin: 0 0 3rem 0;
  width: 23rem;
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 3rem;
  font-weight: 600;
  line-height: 3.6rem;
  margin-bottom: 3rem;
  text-align: center;
`

export const LoginContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`

export const FormInput = styled(Input)`
  width: 42rem;

  .MuiInputLabel-root {
    transform: translate(3.2rem, 1.7rem) scale(1);
  }

  .MuiOutlinedInput-input {
    padding: 1.6rem 0;
  }
`

export const ForgetPassword = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.6rem;
  text-decoration: underline;
  width: 42rem;
  margin-top: -1rem;
`

export const FormButton = styled(Button)`
  margin-top: 5rem;
`

export const HelpDiv = styled.div`
  display: inline;
`

export const HelpText = styled.span`
  color: ${({ theme }) => theme.colors.black};
  display: inline;
  font-size: 1.6rem;
  margin-right: 0.5rem;
`

export const GoToHelpDesk = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.6rem;
  text-decoration: underline;
`

export const InputError = styled.span`
  color: ${({ theme }) => theme.colors.red};
  display: flex;
  flex-direction: row;
  font-size: 1.1rem;
  height: 3rem;
  width: 42rem;
`

interface InputErrorIconProps {
  opacity: string
}
export const InputErrorIcon = styled(Icon)<InputErrorIconProps>`
  margin-top: -2rem;
  opacity: ${({ opacity }) => (opacity ? 1 : 0)};
`

export const InputErrorText = styled.p`
  color: ${({ theme }) => theme.colors.red};
  margin-left: 1rem;
`

export const FooterLogo = styled.div`
  position: absolute;
  bottom: 4.2rem;
  right: 3.4rem;
`

export const LogoEcoFooter = styled.img.attrs({
  alt: 'Lupa com uma folha com Ecotrace escrito ao lado',
  src: '/images/logo.png'
})`
  height: auto;
  object-fit: contain;
  object-position: center;
  width: 8rem;
`

import { StatusBox, Table } from 'components'
import { Modal } from 'components/Modal'
import { ColumnsProps } from 'components/Table/Table'
import i18n from 'i18n'

import * as S from './styled'

export type ModalLogsListProps = {
  logs: any
  onExit?: () => void
}

const columns = (): ColumnsProps[] => [
  {
    align: 'center',
    fixed: true,
    name: 'blockStatus',
    title: i18n.t('common:situation')
  },
  { name: 'data', fixed: true, title: i18n.t('common:date') },
  { name: 'action', fixed: true, title: i18n.t('common:action') },
  { name: 'propertyName', fixed: true, title: i18n.t('common:property') },
  { name: 'CAR', title: i18n.t('common:CAR') },
  { name: 'fancyName', title: i18n.t('tables:fantasyName') },
  { name: 'name', title: i18n.t('common:productor') },
  { name: 'CPFCNPJ', title: i18n.t('common:cpfCNPJ') },
  { name: 'IE', title: i18n.t('tables:IE') },
  { name: 'INCRA', title: i18n.t('common:INCRA') },
  { name: 'NIRF', title: i18n.t('common:NIRF') },
  { name: 'CCIR', title: i18n.t('common:CCIR') },
  { name: 'LARLAU', title: i18n.t('common:LARLAU') },
  {
    align: 'right',
    name: 'establishmentCode',
    title: i18n.t('common:establishmentCode')
  },
  {
    name: 'perimeterDocsOrigin',
    title: i18n.t('aditionalInformation:docsOriginPerimeter')
  },
  { name: 'description', title: i18n.t('common:description') },
  { align: 'right', name: 'lat', title: i18n.t('common:latitude') },
  { align: 'right', name: 'lng', title: i18n.t('common:longitude') },
  { name: 'reason', title: i18n.t('common:reasonBlocking') },
  { name: 'address', title: i18n.t('common:address') },
  { align: 'right', name: 'cep', title: i18n.t('common:CEP') },
  { name: 'city', title: i18n.t('common:city') },
  { name: 'user', title: i18n.t('common:user') }
]

export function ModalLogsList({ logs, onExit }: ModalLogsListProps) {
  function changeColumn({ column, row }) {
    if (column.name === 'blockStatus') {
      return (
        <StatusBox
          variant={row.blockStatus ? 'inactive' : 'active'}
          minWidth={9}
        >
          {row.blockStatus
            ? i18n.t('common:blocked')
            : i18n.t('common:released')}
        </StatusBox>
      )
    } else return row[column.name]
  }

  const content = (
    <S.Wrapper>
      <S.GridHeader>
        <S.GridTitle item container xs={12}>
          <S.BoxTitle>{i18n.t('common:historic')}</S.BoxTitle>
        </S.GridTitle>
      </S.GridHeader>

      <S.Line />

      <S.GridTable>
        {logs.length > 0 && (
          <Table rows={logs} columns={columns()} changeColumn={changeColumn} />
        )}
      </S.GridTable>
    </S.Wrapper>
  )

  return (
    <Modal
      content={content}
      isActionsEnabled={false}
      width={140}
      onExit={onExit}
    />
  )
}

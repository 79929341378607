import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`

export const GridFields = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  margin: -16px;
  min-height: 9.3rem;
  width: calc(100% + 24px);
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 0;
`

export const GridInput = styled(Grid)``

export const Remove = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  margin-right: 0.8rem;
  margin-bottom: 0.4rem;
  height: 2.8rem;
`

export const Removed = styled.label`
  display: flex;
  align-items: center;

  p {
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.error};
    cursor: pointer;
  }
`

export const EqualSignal = styled.h2`
  align-items: center;
  margin: 0 -0.4rem;
  padding-bottom: 2.1rem;
  display: flex;
`

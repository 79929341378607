import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import {
  AddButton,
  CSVImport,
  Input,
  InputAutoCompleteProduct,
  InputSelect,
  StatusBox,
  Table,
  Pagination
} from 'components'
import { Button } from 'components/Button'
import { ColumnsProps } from 'components/Table/Table'
import { ProductDropdown } from './ProductDropdown'
import { all } from 'store/Product/Product.selector'
import { productActions } from 'store/Product'

import {
  ERROR_INITIAL_STATE,
  INITIAL_STATE,
  productLineName,
  productStatus,
  COUNTRY as country
} from 'constants/product.constants'

import * as S from './styled'
import { ModalDeleteProduct } from '../ModalDeleteProducts/ModalDeleteProduct'
import { productLineActions } from 'store/ProductLine'
import { all as allOptionsProductLine } from 'store/ProductLine/ProductLine.selector'
import { useTranslation } from 'react-i18next'
import i18n from 'i18n'

const columns: ColumnsProps[] = [
  { align: 'left', fixed: true, name: 'status', title: i18n.t('status') },
  { align: 'left', name: 'codeProduct', title: i18n.t('product:productCode') },
  { align: 'left', name: 'description', title: i18n.t('description') },
  {
    align: 'left',
    name: 'productLineName',
    title: i18n.t('productLine:productLine')
  },
  { align: 'left', name: 'ean', title: i18n.t('product:ean') },
  { align: 'left', name: 'language', title: i18n.t('product:language') }
]

const schema = Yup.object().shape({
  codeProduct: Yup.string(),
  description: Yup.string()
})

export function ProductList() {
  const { t } = useTranslation()
  const [filter, setFilter] = useState({ ...INITIAL_STATE })
  const [errors, setErrors] = useState({ ...ERROR_INITIAL_STATE })
  const { options } = useSelector(allOptionsProductLine)
  const { products } = useSelector(all)

  const dispatch = useDispatch()
  const [_deleteProduct, _setDeleteProduct] = useState({
    codeProduct: null,
    id: null
  })

  useEffect(() => {
    dispatch(productLineActions.getProductLineOptions())
  }, [filter.productLine, dispatch])

  useEffect(() => {
    dispatch(productActions.fetchAll())
  }, [dispatch])

  function changeColumn({ column, row }) {
    if (column.name === 'status') {
      return (
        <StatusBox variant={row.status ? 'active' : 'inactive'}>
          {row.status ? t('active') : t('inactive')}
        </StatusBox>
      )
    } else return row[column.name]
  }

  function handleInput(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setFilter({ ...filter, [name]: value })
  }

  function clearFilter() {
    const params = {
      ...INITIAL_STATE,
      column: filter.column,
      sort: filter.sort
    }
    setFilter(params)
    setErrors({ ...ERROR_INITIAL_STATE })
    dispatch(productActions.fetchAll({ params }))
  }

  async function onFilter() {
    try {
      const params = await schema.validate(filter, {
        abortEarly: false,
        stripUnknown: true
      })
      dispatch(productActions.fetchAll({ params }))
    } catch (ex) {
      const pairs = ex.inner.map(({ path, message }) => [
        path,
        message.replace(`${path} `, '')
      ])
      setErrors(Object.fromEntries(pairs) as typeof ERROR_INITIAL_STATE)
    }
  }

  function actionsOnTable({ codeProduct, id }) {
    return (
      <ProductDropdown
        id={id}
        setDeleteProduct={() => _setDeleteProduct({ codeProduct, id })}
      />
    )
  }

  function handleAutocomplete(field: string, value: any) {
    setErrors({ ...errors, [field]: '' })
    setFilter({ ...filter, [field]: value })
  }

  function onSortColumn(column) {
    const { column: columnOld, sort } = filter
    const newSort =
      column !== columnOld ? 'asc' : sort === 'asc' ? 'desc' : 'asc'
    setFilter({ ...filter, column, sort: newSort })

    const params = { ...filter, column, sort: newSort }
    dispatch(productActions.fetchAll({ params }))
  }

  function onExit() {
    _setDeleteProduct({ id: null, codeProduct: null })
  }

  function onDelete(id) {
    return function () {
      dispatch(productActions.deleteProduct({ id }))
      onExit()
    }
  }

  function onFilterPaginate(params = {}) {
    dispatch(productActions.fetchAll(params))
  }

  return (
    <S.Wrapper container>
      {_deleteProduct.id ? (
        <ModalDeleteProduct
          name={_deleteProduct.codeProduct}
          onExit={onExit}
          onRemove={onDelete(_deleteProduct.id)}
        />
      ) : null}
      <S.ButtonAdd>
        <Link to="/produto/novo">
          <AddButton />
        </Link>
      </S.ButtonAdd>

      <S.ButtonImport>
        <CSVImport></CSVImport>
      </S.ButtonImport>

      <S.FullGrid item xs={12}>
        <S.GridHeader>
          <S.GridTitle item container xs={12}>
            <S.BoxTitle>{t('product:filterProduct')}</S.BoxTitle>
            <S.BoxLine />
          </S.GridTitle>
        </S.GridHeader>
        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              fullWidth
              error={Boolean(errors.codeProduct)}
              helperText={errors.codeProduct}
              label={t('product:productCode')}
              value={filter.codeProduct}
              onInput={value => handleInput(value, 'codeProduct')}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              fullWidth
              error={Boolean(errors.description)}
              helperText={errors.description}
              label={t('description')}
              value={filter.description}
              onInput={value => handleInput(value, 'description')}
            />
          </S.GridInput>

          <S.GridInput item xs={12} sm={6} md={3}>
            <InputSelect
              disabled={productLineName.length < 1}
              fullWidth
              optionLabel="label"
              optionValue="value"
              onSelected={({ value }) => handleInput(value, 'productLine')}
              error={Boolean(errors.productLine)}
              helperText={errors.productLine}
              label={t('productLine:productLine')}
              options={options}
              value={filter.productLine}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              fullWidth
              error={Boolean(errors.ean)}
              helperText={errors.ean}
              label={t('product:ean')}
              value={filter.ean}
              onInput={value => handleInput(value, 'ean')}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <InputSelect
              fullWidth
              optionLabel="label"
              optionValue="value"
              onSelected={({ value }) => handleInput(value, 'status')}
              error={Boolean(errors.status)}
              helperText={errors.status}
              label={t('status')}
              options={productStatus}
              value={filter.status}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <InputAutoCompleteProduct
              defaultValue={filter.country}
              error={Boolean(errors.country)}
              fullWidth
              helperText={errors.country}
              label={t('product:country')}
              options={country}
              optionLabel="countryName"
              onSelected={val => handleAutocomplete('country', val)}
            />
          </S.GridInput>
          <S.GridButtons item xs={12} sm={6} md={12}>
            <Button variant="default" size="medium" onClick={clearFilter}>
              <p>{t('clean')}</p>
            </Button>
            <Button variant="primary" size="medium" onClick={onFilter}>
              <p>{t('filter')}</p>
            </Button>
          </S.GridButtons>
        </S.GridFilter>
        <S.GridTable>
          <Table
            changeColumn={changeColumn}
            columns={columns}
            linkTableNotFound="/produto/novo"
            rows={products.items}
            sortColumn={{ column: filter.column, sort: filter.sort }}
            actionTable={actionsOnTable}
            onSortColumn={onSortColumn}
          />
        </S.GridTable>
        {filter.pagination?.totalItems > 0 && (
          <Pagination
            filters={{ ...filter, limit: filter.limit, page: filter.page }}
            limit={filter.limit}
            page={filter.page}
            totalItems={filter.totalItems}
            setFilter={onFilterPaginate}
          />
        )}
      </S.FullGrid>
    </S.Wrapper>
  )
}

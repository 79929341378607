import { IndustryGroupType } from 'types/IndustryGroup.types'

export const types = Object.freeze({
  CLEAN_INDUSTRY_GROUPS: '@industryGroup/cleanIndustryGroups',
  CREATE_INDUSTRY_GROUP: '@industryGroup/createIndustryGroup',
  DELETE_INDUSTRY_GROUP: '@industryGroup/deleteIndustryGroup',
  EDIT_INDUSTRY_GROUP: '@industryGroup/editIndustryGroup',
  FETCH_ALL: '@industryGroup/fetchAll',
  FETCH_ONE: '@industryGroup/fetchOne',
  FILTER_GROUP_INDUSTRIES: '@industryGroup/filterGroupIndustries',
  GET_INDUSTRY_GROUP_OPTIONS: '@industryGroup/getIndustryGroupOptions',
  GET_UNRELATED_INDUSTRIES: '@industryGroup/getUnrelatedIndustries',
  LINK_INDUSTRY_WITH_GROUP: '@industryGroup/linkIndustryWithGroup',
  REMOVE_DELETED_INDUSTRIES: '@industryGroup/removeDeletedIndustries',
  SET_INDUSTRY_GROUP_CREATED: '@industryGroup/setIndustryGroupCreated',
  SET_INDUSTRY_GROUP_EDIT: '@industryGroup/setIndustryGroupEdit',
  SET_INDUSTRY_GROUP_OPTIONS: '@industryGroup/setIndustryGroupsOptions',
  SET_INDUSTRY_GROUPS: '@industryGroup/setIndustryGroups',
  SET_LOADING: '@industryGroup/setLoading',
  SET_MANAGE_INDUSTRY_GROUP: '@industryGroup/setManageIndustryGroup',
  SET_UNRELATED_INDUSTRIES: '@industryGroup/setUnrelatedIndustries',
  UNLINK_WITH_GROUP: '@industryGroup/unlinkWithIndustry',
  UPDATE_GROUP_INDUSTRIES: '@industryGroup/updateGroupIndustries'
})

export function setLoading({ loading = false } = {}) {
  return { type: types.SET_LOADING, payload: { loading } }
}

export function fetchAll(filters = {}) {
  return { type: types.FETCH_ALL, payload: { filters } }
}

export function setIndustryGroups({ industryGroups = {} } = {}) {
  return {
    type: types.SET_INDUSTRY_GROUPS,
    payload: { industryGroups, loading: false }
  }
}

export function createIndustryGroup(industryGroup: IndustryGroupType) {
  return {
    type: types.CREATE_INDUSTRY_GROUP,
    payload: industryGroup
  }
}

export function editIndustryGroup(industryGroup: IndustryGroupType) {
  return {
    type: types.EDIT_INDUSTRY_GROUP,
    payload: industryGroup
  }
}

export function setIndustryGroupCreated({ industryGroupCreate = [] } = {}) {
  return {
    type: types.SET_INDUSTRY_GROUP_CREATED,
    payload: { industryGroupCreate, loading: false }
  }
}

export function setIndustryGroupEdit({ industryGroupEdit = [] } = {}) {
  return {
    type: types.SET_INDUSTRY_GROUP_EDIT,
    payload: { industryGroupEdit, loading: false }
  }
}

export function fetchOne(id: string) {
  return { type: types.FETCH_ONE, payload: { id } }
}

export function setManageIndustryGroup({ manageIndustryGroup = [] } = {}) {
  return {
    type: types.SET_MANAGE_INDUSTRY_GROUP,
    payload: { manageIndustryGroup, loading: false }
  }
}

export function deleteIndustryGroup({ id }) {
  return {
    type: types.DELETE_INDUSTRY_GROUP,
    payload: id
  }
}

export function filterGroupIndustries({ groupId, params }) {
  return { type: types.FILTER_GROUP_INDUSTRIES, payload: { groupId, params } }
}

export function removeDeletedIndustries({ ids }) {
  return { type: types.REMOVE_DELETED_INDUSTRIES, payload: { ids } }
}

export function updateGroupIndustries({ companies, companiesPagination }) {
  return {
    type: types.UPDATE_GROUP_INDUSTRIES,
    payload: { companies, companiesPagination }
  }
}

export function linkWithGroup({ id, industryIds }: Record<string, any>) {
  return {
    type: types.LINK_INDUSTRY_WITH_GROUP,
    payload: { id, companyIds: industryIds }
  }
}

export function unlinkWithGroup({ id, companyIds }) {
  return { type: types.UNLINK_WITH_GROUP, payload: { id, companyIds } }
}

export function getUnrelatedIndustries({ id, params = {} }) {
  return { type: types.GET_UNRELATED_INDUSTRIES, payload: { id, params } }
}

export function setUnrelatedIndustries({ unrelatedIndustries = {} } = {}) {
  return {
    type: types.SET_UNRELATED_INDUSTRIES,
    payload: { unrelatedIndustries }
  }
}

export function cleanIndustryGroup() {
  return {
    type: types.CLEAN_INDUSTRY_GROUPS,
    payload: {
      industryGroupCreate: {},
      industryGroupEdit: {},
      manageIndustryGroup: {}
    }
  }
}

export function getIdustryGroupOptions() {
  return { type: types.GET_INDUSTRY_GROUP_OPTIONS }
}

export function setIndustryGroupOptions({ options = [] } = {}) {
  return {
    type: types.SET_INDUSTRY_GROUP_OPTIONS,
    payload: { options }
  }
}

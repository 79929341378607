import styled, { keyframes } from 'styled-components'

const fade = keyframes`
  from {
    opacity: 0.7;
  }

  to {
    opacity: 0.15;
  }
`

export const BarChartLoadingContainer = styled.div`
  width: 100%;
  height: 275px;
  display: flex;
  justify-content: center;
  align-content: center;
`

export const LoadingTextContainer = styled.div<{ isLoading?: boolean }>`
  padding-top: 75px;
  animation: 1.5s ${fade} alternate infinite;
`

export const LoadingText = styled.span`
  font-size: 2rem;
  font-weight: 900;
  text-align: center;
  height: auto;
  width: 100%;
`

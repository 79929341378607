import { omit } from 'lodash'
import { call, put } from 'redux-saga/effects'

import { BoxService } from 'services'
import FilteringTraceability from 'utils/FilteringTraceability'

import * as actions from './Box.actions'
import * as actionsKpis from '../TraceabilityTables/TraceabilityTables.actions'

export function* getBoxes(action) {
  yield put(actionsKpis.setTableLoading(true))
  const _filters = FilteringTraceability(action.payload.filterBoxes)

  const $box = new BoxService()

  const boxes = yield call($box.getBoxes.bind($box), {
    ..._filters,
    orderskeys: action.payload.ordersKeys.toString()
  })

  if (boxes.success) {
    yield put(actions.setBoxes({ boxes: omit(boxes.data, ['kpis', 'charts']) }))
  } else {
    yield put(
      actions.setBoxes({
        boxes: { items: [], pagination: { totalItems: 0 } }
      })
    )
    if (boxes.status !== 404) {
      yield put(actionsKpis.cleanKpis())
    }
  }

  yield put(actionsKpis.setTableLoading(false))
}

import { THEMES } from 'themes'
import { theme } from 'config'
import { useTranslation } from 'react-i18next'
import { THEMES_NAME } from 'config/theme.config'

const Translate = ({ text }: { text: string }) => {
  const { t } = useTranslation()

  return <>{t(text)}</>
}

export default [
  {
    icon: 'panel-track',
    id: 'inicio',
    labelMenu: <Translate text="breadcrumb:home" />,
    labelPage: <Translate text="breadcrumb:home" />,
    link: '/',
    sidebar: true,
    viewer: true
  },
  {
    icon: 'panel-track',
    id: 'invoice',
    labelMenu: <Translate text="breadcrumb:invoices" />,
    labelPage: <Translate text="breadcrumb:invoices" />,
    link: '/notas-fiscais',
    sidebar: false,
    viewer: true
  },
  {
    icon: 'panel-track',
    id: 'sifProduction',
    labelMenu: <Translate text="breadcrumb:sifProduction" />,
    labelPage: <Translate text="breadcrumb:sifProduction" />,
    link: '/sif-producao',
    sidebar: false,
    viewer: true
  },
  {
    icon: 'panel-track',
    id: 'netWeight',
    labelMenu: <Translate text="breadcrumb:netWeight" />,
    labelPage: <Translate text="breadcrumb:netWeight" />,
    link: '/peso-liquido',
    sidebar: false,
    viewer: true
  },
  {
    icon: 'panel-track',
    id: 'conformity',
    labelMenu: <Translate text="breadcrumb:conformitys" />,
    labelPage: <Translate text="breadcrumb:conformitys" />,
    link: '/conformidade',
    sidebar: false,
    viewer: true
  },
  {
    icon: 'panel-track',
    id: 'propertiesNew',
    labelMenu: <Translate text="breadcrumb:properties" />,
    labelPage: <Translate text="breadcrumb:properties" />,
    link: '/properties',
    sidebar: false,
    viewer: true
  },
  {
    icon: 'panel-general',
    id: 'painelGeral',
    labelMenu: <Translate text="breadcrumb:generalPanel" />,
    labelPage: <Translate text="breadcrumb:generalPanel" />,
    link: '/geral',
    sidebar: true,
    viewer: true
  },
  {
    icon: 'breadcrumbs-clients',
    id: 'client',
    labelPage: <Translate text="common:customers" />,
    link: '/cliente',
    sidebar: false,
    viewIndustry: true,
    viewRetail: false,
    viewer: false
  },
  {
    icon: 'breadcrumbs-clients',
    id: 'clientRegister',
    labelPage: <Translate text="breadcrumb:customerRegistration" />,
    link: '/cliente/novo',
    sidebar: false,
    viewIndustry: true,
    viewRetail: false,
    viewer: false
  },
  {
    icon: 'breadcrumbs-clients',
    id: 'clientEdit',
    labelPage: <Translate text="breadcrumb:customerEdit" />,
    link: '/cliente/editar/:id',
    sidebar: false,
    viewIndustry: true,
    viewRetail: false,
    viewer: false
  },
  {
    icon: 'breadcrumbs-type-add',
    id: 'clientGroup',
    labelPage: <Translate text="cards:customersGroups" />,
    link: '/grupo-cliente',
    sidebar: false,
    viewIndustry: true,
    viewRetail: false,
    viewer: true
  },
  {
    icon: 'breadcrumbs-type-add',
    id: 'clientGroupRegister',
    labelPage: <Translate text="breadcrumb:newGroup" />,
    link: '/grupo-cliente/novo',
    sidebar: false,
    viewIndustry: true,
    viewRetail: false,
    viewer: false
  },
  {
    icon: 'breadcrumbs-type-add',
    id: 'clientGroupEdit',
    labelPage: <Translate text="breadcrumb:editGroup" />,
    link: '/grupo-cliente/editar/:id',
    sidebar: false,
    viewIndustry: true,
    viewRetail: false,
    viewer: false
  },
  {
    icon: 'breadcrumbs-type-add',
    id: 'clientGroupManage',
    labelPage: <Translate text="breadcrumb:manageGroup" />,
    link: '/grupo-cliente/gerenciar/:id',
    sidebar: false,
    viewIndustry: true,
    viewRetail: false,
    viewer: true
  },
  {
    icon: 'panel-track',
    id: 'painelRastreabilidade',
    labelMenu: THEMES[theme.appTheme].traceabilityPanel,
    labelPage: THEMES[theme.appTheme].traceabilityPanel,
    link: '/rastreabilidade',
    sidebar: false,
    viewer: false
  },
  {
    icon: 'breadcrumbs-industry',
    id: 'indutry',
    labelPage: <Translate text="common:industries" />,
    link: '/industria',
    sidebar: false,
    viewIndustry: true,
    viewRetail: false,
    viewer: false
  },
  {
    icon: 'breadcrumbs-industry',
    id: 'indutryRegister',
    labelPage: <Translate text="breadcrumb:industryRegistration" />,
    link: '/industria/novo',
    sidebar: false,
    viewIndustry: true,
    viewRetail: false,
    viewer: false
  },
  {
    icon: 'breadcrumbs-industry',
    id: 'indutryEdit',
    labelPage: <Translate text="breadcrumb:editIndustry" />,
    link: '/industria/editar/:id',
    sidebar: false,
    viewIndustry: true,
    viewRetail: false,
    viewer: false
  },
  {
    icon: 'breadcrumbs-industry',
    id: 'indutryGroup',
    labelPage: <Translate text="cards:industryGroups" />,
    link: '/grupo-industria',
    sidebar: false,
    viewIndustry: true,
    viewRetail: false,
    viewer: false
  },
  {
    icon: 'breadcrumbs-industry',
    id: 'indutryGroupRegister',
    labelPage: <Translate text="breadcrumb:newGroup" />,
    link: '/grupo-industria/novo',
    sidebar: false,
    viewIndustry: true,
    viewRetail: false,
    viewer: false
  },
  {
    icon: 'breadcrumbs-industry',
    id: 'indutryGroupEdit',
    labelPage: <Translate text="breadcrumb:editGroup" />,
    link: '/grupo-industria/editar/:id',
    sidebar: false,
    viewIndustry: true,
    viewRetail: false,
    viewer: false
  },
  {
    icon: 'breadcrumbs-industry',
    id: 'indutryGroupEdit',
    labelPage: <Translate text="breadcrumb:manageGroup" />,
    link: '/grupo-industria/gerenciar/:id',
    sidebar: false,
    viewIndustry: true,
    viewRetail: false,
    viewer: false
  },
  {
    icon: 'breadcrumbs-user',
    id: 'profile',
    labelPage: <Translate text="breadcrumb:users" />,
    link: '/usuario',
    sidebar: false,
    viewer: false
  },
  {
    icon: 'breadcrumbs-type-property',
    id: 'property',
    labelPage: <Translate text="common:properties" />,
    link: '/propriedade',
    sidebar: false,
    viewIndustry: false,
    viewRetail: true,
    viewer: false
  },
  {
    icon: 'breadcrumbs-type-property',
    id: 'propertyRegister',
    labelPage: <Translate text="breadcrumb:propertyRegistration" />,
    link: '/propriedade/novo',
    sidebar: false,
    viewIndustry: false,
    viewRetail: true,
    viewer: false
  },
  {
    icon: 'breadcrumbs-type-property',
    id: 'propertyEdit',
    labelPage: <Translate text="breadcrumb:editProperty" />,
    link: '/propriedade/editar/:id',
    sidebar: false,
    viewIndustry: false,
    viewRetail: true,
    viewer: false
  },
  {
    icon: 'breadcrumbs-user',
    id: 'user',
    labelPage: <Translate text="breadcrumb:perfil" />,
    link: '/perfil',
    sidebar: false,
    viewer: true
  },
  {
    icon: 'breadcrumbs-user',
    id: 'userRegister',
    labelPage: <Translate text="breadcrumb:userRegistration" />,
    link: '/usuario/novo',
    sidebar: false,
    viewer: false
  },
  {
    icon: 'breadcrumbs-user',
    id: 'userEdit',
    labelPage: <Translate text="breadcrumb:userEdit" />,
    link: '/usuario/editar/:id',
    sidebar: false,
    viewer: false
  },
  {
    icon: 'panel-doubt',
    id: 'helpDesk',
    labelMenu: <Translate text="breadcrumb:helpDesk" />,
    labelPage: '',
    link: '/ajuda',
    sidebar: true,
    viewer: true
  },
  {
    icon: 'breadcrumbs-product',
    id: 'generalProducts',
    labelPage: 'Painel de Produtos',
    link: '/painel-produtos',
    sidebar: false,
    viewer: true
  },
  {
    icon:
      theme.appTheme === THEMES_NAME.swift
        ? 'card-partner-eco-swift'
        : 'card-partner-eco',
    id: 'partnersEcotrace',
    labelPage: 'Parceiros Ecotrace',
    link: '/parceiros-ecotrace',
    sidebar: false,
    viewer: true
  },
  {
    icon: 'breadcrumbs-product',
    id: 'product',
    labelPage: 'Produtos',
    link: '/produto',
    sidebar: false,
    viewer: true
  },
  {
    icon: 'breadcrumbs-product',
    id: 'newProduct',
    labelPage: 'Cadastrar Produto',
    link: '/produto/novo',
    sidebar: false,
    viewer: true
  },
  {
    icon: 'breadcrumbs-product',
    id: 'productLine',
    labelPage: 'Linha do Produto',
    link: '/linha-produto',
    sidebar: false,
    viewer: true
  },
  {
    icon: 'breadcrumbs-product',
    id: 'newProductLine',
    labelPage: 'Cadastro Linha do Produto',
    link: '/linha-produto/novo',
    sidebar: false,
    viewer: true
  },
  {
    icon:
      theme.appTheme === THEMES_NAME.swift
        ? 'card-partner-eco-swift'
        : 'card-partner-eco',
    id: 'newPartnersEcotrace',
    labelPage: 'Cadastro Parceiros Ecotrace',
    link: '/parceiros-ecotrace/novo',
    sidebar: false,
    viewer: true
  },
  {
    icon:
      theme.appTheme === THEMES_NAME.swift
        ? 'table-nutritional-small-swift'
        : 'table-nutritional-small',
    id: 'tableNutritional',
    labelPage: 'Tabela Nutricional Padrão',
    link: '/tabela-nutricional-padrao',
    sidebar: false,
    viewer: true
  },
  {
    icon:
      theme.appTheme === THEMES_NAME.swift
        ? 'card-partner-eco-swift'
        : 'card-partner-eco',
    id: 'partnersEdit',
    labelPage: 'Editar Parceiros Ecotrace',
    link: '/parceiros-ecotrace/editar/:id',
    sidebar: false,
    viewer: false
  },
  {
    icon: 'breadcrumbs-product',
    id: 'productLineEdit',
    labelPage: 'Editar linha do Produto',
    link: '/linha-produto/editar/:id',
    sidebar: false,
    viewer: true
  },
  {
    icon: 'breadcrumbs-product',
    id: 'productEdit',
    labelPage: 'Cadastrar Produto',
    link: '/produto/editar/:id',
    sidebar: false,
    viewer: true
  }
]

import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const Wrapper = styled(Grid)`
  display: flex;
  margin: 0;
  width: calc(100% + 24px);
  flex-direction: row;
`

export const GridFields = styled(Grid)`
  display: flex;
  margin: 0;
  width: calc(100% + 24px);
  flex-direction: row;
`

export const GridPhoto = styled(Grid)`
  display: flex;
  margin: 0;
  flex-direction: row;
`

export const CardPhoto = styled.div`
  border-radius: 0.8rem;
  width: 100%;
  margin: 0 1.6rem;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 0.8rem;
`

export const GridHeader = styled(Grid)`
  display: inline-flexbox;
  padding: 0 0 2.4rem 0.7rem;
`

export const GridTitle = styled(Grid)`
  display: block;
  padding: 1rem 0 0 0;
`

export const BoxTitle = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.4rem;
  font-weight: 600;
  padding: 0.7rem 0 0 0.3rem;
`

export const GridInput = styled(Grid)``

export const GridInput95 = styled(Grid)`
  padding-right: 27px;
`

import { call, put } from 'redux-saga/effects'

import { TableSIFService } from 'services'
import FilteringTraceability from 'utils/FilteringTraceability'

import * as actions from './TableSIF.actions'
import * as actionsKpis from '../TraceabilityTables/TraceabilityTables.actions'

export function* getTableSIF(action) {
  yield put(actionsKpis.setTableLoading(true))

  const _filters = FilteringTraceability(action.payload.filterTableSIF)

  const $tableSIF = new TableSIFService()

  const tableSIF = yield call($tableSIF.getTableSIF.bind($tableSIF), _filters)

  if (tableSIF.success) {
    yield put(actions.setTableSIF({ tableSIF: tableSIF.data }))
  } else {
    yield put(
      actions.setTableSIF({
        tableSIF: { items: [], pagination: { totalItems: 0 } }
      })
    )
    if (tableSIF.status !== 404) {
      yield put(actionsKpis.cleanKpis())
    }
  }

  yield put(actionsKpis.setTableLoading(false))
}

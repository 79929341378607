import { useState } from 'react'
import { Icon, Input } from 'components'
import { IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { InputSelect } from 'components/InputSelect'
import { CustomFilter, CustomFilterForm } from 'types/customFilter.types'
import * as S from './styled'

export type CustomFilterFormProps = {
  errors: any
  index: number
  filters: CustomFilter[]
  onSelect: (_index: number, _customFilterColumnName: string) => void
  payload?: CustomFilterForm
  isRenderRemoveButton: boolean
  onChange: (_filterValue: string, _index: number) => void
  onRemove: (_index: number) => void
}

export function CustomFilters({
  index,
  errors,
  payload,
  onSelect,
  filters,
  isRenderRemoveButton,
  onChange,
  onRemove
}: CustomFilterFormProps) {
  const { t } = useTranslation()
  const [_filters, _setFilters] = useState<CustomFilter>()

  function err(name: string) {
    const { customRetailsFilters } = errors

    if (
      customRetailsFilters &&
      customRetailsFilters[index] &&
      customRetailsFilters[index][name]
    ) {
      return customRetailsFilters[index][name]
    } else {
      return errors[`customRetailsFilters[${index}].${name}`]
    }
  }

  return (
    <S.Wrapper>
      <S.GridFields container spacing={2}>
        <S.GridInput item sm={12} md={4}>
          <InputSelect
            fullWidth
            error={Boolean(err('customColumnFilter'))}
            helperText={err('customColumnFilter')}
            label={t('customFilters:customColumnFilter')}
            optionLabel="customColumnFilter"
            optionValue="customColumnFilter"
            value={payload.customColumnFilter}
            options={filters}
            onSelected={(value: any) =>
              onSelect(index, value.customColumnFilter)
            }
          />
        </S.GridInput>
        <S.EqualSignal>=</S.EqualSignal>
        <S.GridInput item sm={12} md={4}>
          <Input
            fullWidth
            disabled={!payload.customColumnFilter}
            helperText={err('customColumnValue')}
            value={payload.customColumnValue}
            label={t('customFilters:value')}
            error={Boolean(err('customColumnValue'))}
            onInput={value => onChange(value, index)}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <S.Remove>
            {isRenderRemoveButton && (
              <S.Removed onClick={() => onRemove(index)}>
                <p>{t('common:delete')}</p>
                <IconButton
                  style={{ backgroundColor: '#ffffff' }}
                  aria-label="delete picture"
                  component="span"
                >
                  <Icon
                    icon="delete-image"
                    height={16}
                    width={16}
                    fill="#de4f4f"
                  />
                </IconButton>
              </S.Removed>
            )}
          </S.Remove>
        </S.GridInput>
      </S.GridFields>
    </S.Wrapper>
  )
}

import { call, put } from 'redux-saga/effects'
import { PartnerService } from 'services/partner.service'
import { toasts } from 'utils/toasts'
import * as actions from './partners.action'

export function* fetchAll(event) {
  yield put(actions.setLoading({ loading: true }))

  const $partner = new PartnerService()
  const {
    success,
    status,
    data: partners
  } = yield call($partner.fetchAll.bind($partner), event.payload.filters)

  yield put(actions.setLoading({ loading: false }))

  if (status === 404) {
    return yield put(
      actions.setPartners({
        partners: { items: [], pagination: { totalItems: 0 } }
      })
    )
  } else if (success) {
    return yield put(actions.setPartners({ partners }))
  } else toasts.generalFail()
}

export function* filterPartners() {
  //   yield put(actions.setLoading({ loading: true }))
  //   const $partner = new PartnerService()
  //   const { data, success } = yield $partner.searchByGroup(action.payload)
  //   if (success) yield put(actions.updateGroupIndustries({ ...data }))
  //   else {
  //     yield put(
  //       actions.updateGroupIndustries({
  //         companies: [],
  //         companiesPagination: { totalItems: 0 }
  //       })
  //     )
  //   }
  //   yield put(actions.setLoading({ loading: false }))
}

export function* fetchOne(action) {
  yield put(actions.setLoading({ loading: true }))

  const { id } = action.payload

  const $partner = new PartnerService()
  const partner = yield call($partner.fetchOne.bind($partner), id)

  if (partner.success) {
    return yield put(
      actions.setPartner({
        partner: partner.data
      })
    )
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* create(action) {
  yield put(actions.setLoading({ loading: true }))

  const { payload } = action

  const $partner = new PartnerService()
  const partner = yield call($partner.create.bind($partner), payload)

  if (partner.success) {
    toasts.created()
    return yield put(
      actions.setPartnerCreated({
        partnerCreate: partner.data
      })
    )
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* edit(action) {
  yield put(actions.setLoading({ loading: true }))

  const { payload } = action

  const $partner = new PartnerService()
  const { success, data } = yield call($partner.edit.bind($partner), payload)

  if (success) {
    toasts.edited()
    return yield put(actions.setPartnerEdit({ partnerEdit: data }))
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* deletePartner(action) {
  const id = action.payload

  const $partner = new PartnerService()
  const partner = yield call($partner.deletePartner.bind($partner), { id })

  if (partner.success) {
    toasts.deleted()
    return yield put(actions.fetchAll())
  } else if (partner.data.code === 'PRODUCT_LINE_RELATED') {
    toasts.hasProductLine()
  } else toasts.generalFail()
}

export function* getPartnerOptions() {
  const $partner = new PartnerService()
  const {
    success,
    status,
    data: optionsPartner
  } = yield call($partner.options.bind($partner))

  if (success) return yield put(actions.setPartnerOptions({ optionsPartner }))
  else if (status === 404) {
    return yield put(actions.setPartnerOptions({ optionsPartner: [] }))
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

import { AddButton } from 'components/AddButton'
import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { InputSelect } from 'components/InputSelect'
import { IndustryTable } from './IndustryTable'
import { Pagination } from 'components/Pagination'

import { all } from 'store/Industry/Industry.selector'
import { industryActions } from 'store/Industry'
import { all as industryGroupSelector } from 'store/IndustryGroup/IndustryGroup.selector'

import { submitOnEnter } from 'utils/submitOnEnter'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

const INITIAL_STATE = {
  cnpj: '',
  fancyName: '',
  group: '',
  ie: '',
  inspectionNum: '',
  limit: 10,
  name: '',
  page: 0
}

const schema = Yup.object().shape({
  name: Yup.string(),
  fancyName: Yup.string(),
  cnpj: Yup.string(),
  inspectionNum: Yup.string(),
  ie: Yup.string(),
  group: Yup.string()
})

export function IndustryList() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { industries } = useSelector(all)
  const { options: industryGroupOptions } = useSelector(industryGroupSelector)

  useEffect(() => {
    dispatch(industryActions.fetchAll())
  }, [dispatch])

  const [errors, setErrors] = useState({ ...INITIAL_STATE })
  const [filter, setFilter] = useState({ ...INITIAL_STATE })

  function handleInput(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setFilter({ ...filter, [name]: value })
  }

  async function onFilter() {
    try {
      const params = await schema.validate(filter, {
        abortEarly: false,
        stripUnknown: true
      })
      dispatch(industryActions.fetchAll({ params }))
    } catch (ex) {
      const pairs = ex.inner.map(({ path, message }) => [
        path,
        message.replace(`${path} `, '')
      ])
      setErrors(Object.fromEntries(pairs) as typeof INITIAL_STATE)
    }
  }

  function onFilterPaginate(params = {}) {
    dispatch(industryActions.fetchAll({ params }))
  }

  function clearFilter() {
    const params = {
      ...INITIAL_STATE,
      limit: industries.pagination.limit,
      page: industries.pagination.page
    }
    setFilter(params)
    setErrors({ ...INITIAL_STATE })
    dispatch(industryActions.fetchAll({ params }))
  }

  return (
    <S.Wrapper container>
      <S.ButtonAdd>
        <Link to="/industria/novo">
          <AddButton />
        </Link>
      </S.ButtonAdd>

      <S.FullGrid item xs={12}>
        <S.GridHeader>
          <S.GridTitle item container xs={12}>
            <S.BoxTitle>{t('common:filterIndustries')}</S.BoxTitle>
            <S.BoxLine />
          </S.GridTitle>
        </S.GridHeader>

        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              fullWidth
              error={Boolean(errors.name)}
              helperText={errors.name}
              label={t('common:socialReason')}
              onInput={value => handleInput(value, 'name')}
              onKeyPress={submitOnEnter(onFilter)}
              value={filter.name}
            />
          </S.GridInput>

          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              error={Boolean(errors.fancyName)}
              fullWidth
              helperText={errors.fancyName}
              label={t('common:fantasyName')}
              onInput={value => handleInput(value, 'fancyName')}
              onKeyPress={submitOnEnter(onFilter)}
              value={filter.fancyName}
            />
          </S.GridInput>

          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              error={Boolean(errors.cnpj)}
              fullWidth
              helperText={errors.cnpj}
              label={t('common:CNPJ')}
              onInput={value => handleInput(value.replace(/[ ]/g, ''), 'cnpj')}
              onKeyPress={submitOnEnter(onFilter)}
              type="cnpj"
              value={filter.cnpj}
            />
          </S.GridInput>

          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              error={Boolean(errors.inspectionNum)}
              fullWidth
              helperText={errors.inspectionNum}
              label={t('common:inspectionNumber')}
              onInput={value => handleInput(value, 'inspectionNum')}
              onKeyPress={submitOnEnter(onFilter)}
              value={filter.inspectionNum}
            />
          </S.GridInput>
        </S.GridFilter>

        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              error={Boolean(errors.ie)}
              fullWidth
              helperText={errors.ie}
              label={t('common:stateRegistration')}
              onInput={value => handleInput(value, 'ie')}
              onKeyPress={submitOnEnter(onFilter)}
              value={filter.ie}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <InputSelect
              disabled={industryGroupOptions.length < 1}
              error={Boolean(errors.group)}
              fullWidth
              helperText={errors.group}
              label={t('common:group')}
              onSelected={({ value }) => handleInput(value, 'group')}
              optionLabel="label"
              options={industryGroupOptions}
              optionValue="value"
              value={filter.group}
            />
          </S.GridInput>
          <S.GridButtons item xs={12} sm={12} md={6}>
            <Button variant="default" onClick={clearFilter} size="medium">
              <p>{t('common:clean')}</p>
            </Button>
            <Button variant="primary" onClick={onFilter} size="medium">
              <p>{t('common:search')}</p>
            </Button>
          </S.GridButtons>
        </S.GridFilter>
        <S.GridTable>
          <IndustryTable rows={industries.items} />
          {industries.pagination?.totalItems > 0 && (
            <Pagination
              filters={{
                ...filter,
                limit: industries.pagination.limit,
                page: industries.pagination.page
              }}
              limit={industries.pagination.limit}
              page={industries.pagination.page}
              totalItems={industries.pagination.totalItems}
              setFilter={onFilterPaginate}
            />
          )}
        </S.GridTable>
      </S.FullGrid>
    </S.Wrapper>
  )
}

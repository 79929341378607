import { HTTP } from 'providers'
import { IndustryType } from 'types/Industry.types'

export class IndustryService extends HTTP {
  private endpoint = '/company'
  private config: Record<string, any> = {
    showLoading: true
  }

  public fetchAll({ params = {} } = {}) {
    return this.get({ endpoint: this.endpoint, params })
  }

  public searchByGroup({ groupId, params }) {
    return this.get({
      endpoint: `${this.endpoint}/by-group/${groupId}`,
      params
    })
  }

  public removeMany({ ids }: { ids: string[] }) {
    return this.delete({ endpoint: `${this.endpoint}/many`, data: { ids } })
  }

  public create({ payload }: { payload: IndustryType }) {
    return this.post({
      endpoint: this.endpoint,
      payload,
      config: this.config
    })
  }

  public fetchOne(id) {
    return this.get({
      endpoint: `${this.endpoint}/${id}`
    })
  }

  public fetchUnrelatedWithGroup({ groupId, params = {} }) {
    return this.get({
      endpoint: `${this.endpoint}/unrelated-with-group/${groupId}`,
      params
    })
  }

  public edit(industry: IndustryType) {
    const { id } = industry
    return this.put({
      endpoint: `${this.endpoint}/${id}`,
      payload: industry,
      config: this.config
    })
  }

  public deleteIndustry({ id }) {
    return this.delete({
      endpoint: `${this.endpoint}/${id}`
    })
  }

  public uploadSifPhoto({ id, multipartForm }) {
    return this.post({
      endpoint: `${this.endpoint}/upload-sif-photo/${id}?`,
      payload: multipartForm,
      config: this.config
    })
  }

  public uploadEmployeePhoto({ id, multipartForm, name }) {
    return this.post({
      endpoint: `${this.endpoint}/upload-employee-photo/${id}/${name}?`,
      payload: multipartForm,
      config: this.config
    })
  }

  public uploadCompanyPhoto({ id, multipartForm }) {
    return this.post({
      endpoint: `${this.endpoint}/upload-company-photo/${id}`,
      payload: multipartForm,
      config: this.config
    })
  }

  public removeCompanyPhoto({ id, del }) {
    return this.delete({
      endpoint: `${this.endpoint}/remove-company-photo/${del}/${id}`
    })
  }
}

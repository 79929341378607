export const INITIAL_NUTRITIONAL_STATE = {
  standard_cut: '',
  portion_packaging: '',
  homemade_measure: '',
  energeticValue: '',
  energeticValue_value: '',
  energeticValue_diary: '',
  totalCarbohydrates: '',
  totalCarbohydrates_value: '',
  totalCarbohydrates_diary: '',
  totalSugars: '',
  totalSugars_value: '',
  totalSugars_diary: '',
  addedSugars: '',
  addedSugars_value: '',
  addedSugars_diary: '',
  proteins: '',
  proteins_value: '',
  proteins_diary: '',
  totalFat: '',
  totalFat_value: '',
  totalFat_diary: '',
  saturatedFat: '',
  saturatedFat_value: '',
  saturatedFat_diary: '',
  transFats: '',
  transFats_value: '',
  transFats_diary: '',
  dietaryFiber: '',
  dietaryFiber_value: '',
  dietaryFiber_diary: '',
  sodium: '',
  sodium_value: '',
  sodium_diary: '',
  isTemplate: true
}

export const ERRORS_INITIAL_NUTRITIONAL_STATE = {
  standard_cut: '',
  portion_packaging: '',
  homemade_measure: '',
  energeticValue: '',
  energeticValue_value: '',
  energeticValue_diary: '',
  totalCarbohydrates: '',
  totalCarbohydrates_value: '',
  totalCarbohydrates_diary: '',
  totalSugars: '',
  totalSugars_value: '',
  totalSugars_diary: '',
  addedSugars: '',
  addedSugars_value: '',
  addedSugars_diary: '',
  proteins: '',
  proteins_value: '',
  proteins_diary: '',
  totalFat: '',
  totalFat_value: '',
  totalFat_diary: '',
  saturatedFat: '',
  saturatedFat_value: '',
  saturatedFat_diary: '',
  transFats: '',
  transFats_value: '',
  transFats_diary: '',
  dietaryFiber: '',
  dietaryFiber_value: '',
  dietaryFiber_diary: '',
  sodium: '',
  sodium_value: '',
  sodium_diary: ''
}

export const NUTRITIONAL_FORM = [INITIAL_NUTRITIONAL_STATE]

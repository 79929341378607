import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

import { Table } from 'components/Table'
import { ClientDropdown } from './ClientDropdown'
import { AddButton } from 'components/AddButton'
import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { InputSelect } from 'components/InputSelect'
import { Pagination } from 'components/Pagination'
import { ColumnsProps } from 'components/Table/Table'
import { ModalDeleteClient } from '../ModalDeleteClient/ModalDeleteClient'
import { StatusBox } from 'components/StatusBox'

import { all } from 'store/Client/Client.selector'
import { clientActions } from 'store/Client'
import { all as clientGroupSelector } from 'store/ClientGroup/ClientGroup.selector'

import { submitOnEnter } from 'utils/submitOnEnter'
import { useTranslation } from 'react-i18next'
import i18n from 'i18n'

import * as S from './styled'

const INITIAL_STATE = {
  cnpj: '',
  fancyName: '',
  group: '',
  ie: '',
  inspectionNum: '',
  limit: 10,
  name: '',
  page: 0,
  column: 'name',
  sort: 'asc'
}

const columns = (): ColumnsProps[] => [
  {
    align: 'center',
    fixed: true,
    name: 'status',
    title: i18n.t('common:status')
  },
  {
    name: 'name',
    title: i18n.t('tables:socialReason')
  },
  {
    name: 'fancyName',
    title: i18n.t('tables:fantasyName')
  },
  {
    name: 'inspectionType',
    title: i18n.t('tables:inspectionType')
  },
  {
    align: 'right',
    name: 'inspectionNum',
    title: i18n.t('tables:inspectionNumber')
  },
  {
    align: 'right',
    name: 'cnpj',
    title: i18n.t('common:identificationCode')
  },
  {
    align: 'right',
    name: 'ie',
    title: i18n.t('tables:IE')
  },
  {
    align: 'right',
    name: 'total',
    title: i18n.t('common:groups')
  },
  {
    name: 'city',
    title: i18n.t('common:city')
  },
  {
    name: 'state',
    title: i18n.t('common:state')
  }
]

function changeColumn({ column, row }) {
  if (column.name === 'status') {
    return (
      <StatusBox variant={row.status ? 'active' : 'inactive'}>
        {row.status ? i18n.t('common:active') : i18n.t('common:inactive')}
      </StatusBox>
    )
  } else return row[column.name]
}

const schema = Yup.object().shape({
  name: Yup.string(),
  fancyName: Yup.string(),
  cnpj: Yup.string(),
  inspectionNum: Yup.string(),
  ie: Yup.string(),
  group: Yup.string()
})

export function ClientList() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [errors, setErrors] = useState({ ...INITIAL_STATE })
  const [filter, setFilter] = useState({ ...INITIAL_STATE })
  const [_deleteClient, _setDeleteClient] = useState({
    cnpj: null,
    id: null
  })

  const { clients } = useSelector(all)
  const { options: clientGroupOptions } = useSelector(clientGroupSelector)

  useEffect(() => {
    dispatch(clientActions.fetchAll())
  }, [dispatch])

  function handleInput(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setFilter({ ...filter, [name]: value })
  }

  async function onFilter() {
    try {
      const params = await schema.validate(filter, {
        abortEarly: false,
        stripUnknown: true
      })
      dispatch(clientActions.fetchAll({ params }))
    } catch (ex) {
      const pairs = ex.inner.map(({ path, message }) => [
        path,
        message.replace(`${path} `, '')
      ])
      setErrors(Object.fromEntries(pairs) as typeof INITIAL_STATE)
    }
  }

  function onFilterPaginate(params = {}) {
    dispatch(clientActions.fetchAll({ params }))
  }

  function clearFilter() {
    const params = {
      ...INITIAL_STATE,
      limit: clients.pagination.limit,
      page: clients.pagination.page,
      column: filter.column,
      sort: filter.sort
    }
    setFilter(params)
    setErrors({ ...INITIAL_STATE })
    dispatch(clientActions.fetchAll({ params }))
  }

  function onSortColumn(column) {
    const { column: columnOld, sort } = filter
    const newSort =
      column !== columnOld ? 'asc' : sort === 'asc' ? 'desc' : 'asc'
    setFilter({ ...filter, column, sort: newSort })

    const params = { ...filter, column, sort: newSort }
    dispatch(clientActions.fetchAll({ params }))
  }

  function actionsOnTable({ cnpj, id }) {
    return (
      <ClientDropdown
        id={id}
        setDeleteClient={() => _setDeleteClient({ cnpj, id })}
      />
    )
  }

  function onExit() {
    _setDeleteClient({ cnpj: null, id: null })
  }

  function onDelete(id) {
    return function () {
      dispatch(clientActions.deleteClient({ id }))
      onExit()
    }
  }

  return (
    <S.Wrapper container>
      {_deleteClient.id ? (
        <ModalDeleteClient
          cnpj={_deleteClient.cnpj}
          onExit={onExit}
          onRemove={onDelete(_deleteClient.id)}
        />
      ) : null}
      <S.ButtonAdd>
        <Link to="/cliente/novo">
          <AddButton />
        </Link>
      </S.ButtonAdd>
      <S.FullGrid item xs={12}>
        <S.GridHeader>
          <S.GridTitle item container xs={12}>
            <S.BoxTitle>{i18n.t('common:filterCustomers')}</S.BoxTitle>
            <S.BoxLine />
          </S.GridTitle>
        </S.GridHeader>
        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              fullWidth
              error={Boolean(errors.name)}
              helperText={errors.name}
              label={i18n.t('common:socialReason')}
              value={filter.name}
              onInput={value => handleInput(value, 'name')}
              onKeyPress={submitOnEnter(onFilter)}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              error={Boolean(errors.fancyName)}
              fullWidth
              helperText={errors.fancyName}
              label={i18n.t('common:fantasyName')}
              value={filter.fancyName}
              onKeyPress={submitOnEnter(onFilter)}
              onInput={value => handleInput(value, 'fancyName')}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              error={Boolean(errors.cnpj)}
              fullWidth
              helperText={errors.cnpj}
              label={i18n.t('common:identificationCode')}
              value={filter.cnpj}
              type="cnpj"
              onInput={value => handleInput(value.replace(/[ ]/g, ''), 'cnpj')}
              onKeyPress={submitOnEnter(onFilter)}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              error={Boolean(errors.ie)}
              fullWidth
              helperText={errors.ie}
              label={i18n.t('common:stateRegistration')}
              value={filter.ie}
              onInput={value => handleInput(value, 'ie')}
              onKeyPress={submitOnEnter(onFilter)}
            />
          </S.GridInput>
        </S.GridFilter>
        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={3}>
            <InputSelect
              disabled={clientGroupOptions.length < 1}
              error={Boolean(errors.group)}
              fullWidth
              helperText={errors.group}
              label={i18n.t('common:group')}
              onSelected={({ value }) => handleInput(value, 'group')}
              optionLabel="label"
              options={clientGroupOptions}
              optionValue="value"
              value={filter.group}
            />
          </S.GridInput>
          <S.GridButtons item xs={12} sm={12} md={9}>
            <Button variant="default" onClick={clearFilter} size="medium">
              <p>{t('common:clean')}</p>
            </Button>
            <Button variant="primary" onClick={onFilter} size="medium">
              <p>{t('common:search')}</p>
            </Button>
          </S.GridButtons>
        </S.GridFilter>
        <S.GridTable>
          <Table
            columns={columns()}
            linkTableNotFound="/cliente/novo"
            rows={clients.items}
            sortColumn={{ column: filter.column, sort: filter.sort }}
            actionTable={actionsOnTable}
            onSortColumn={onSortColumn}
            changeColumn={changeColumn}
          />
          {clients.pagination?.totalItems > 0 && (
            <Pagination
              filters={{
                ...filter,
                limit: clients.pagination.limit,
                page: clients.pagination.page
              }}
              limit={clients.pagination.limit}
              page={clients.pagination.page}
              totalItems={clients.pagination.totalItems}
              setFilter={onFilterPaginate}
            />
          )}
        </S.GridTable>
      </S.FullGrid>
    </S.Wrapper>
  )
}

// import { NutriTableFormSchema } from 'store/NutriTable/nutriTable.validator'
import * as Yup from 'yup'

export const ProductFormSchema = Yup.object().shape({
  nutriTable: Yup.object(),
  codeProduct: Yup.string().required(),
  description: Yup.string().required(),
  ean: Yup.string().required(),
  productLine: Yup.string().nullable(true).required(),
  status: Yup.boolean().nullable(true).required(),
  country: Yup.array().min(1)
})

export type ProductFormYup = Yup.InferType<typeof ProductFormSchema>

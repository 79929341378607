import { get } from 'lodash'
import { TraceabilityType } from 'types/Traceability.types'
import { INITIAL_STATE as INITIAL_STATE_FILTER } from 'constants/traceability.constants'

import { types } from './TraceabilityCharts.actions'

const INITIAL_STATE = {
  invoiceMonthChartData: [],
  sifVolumetryChartData: [],
  sankeyChart: [],
  filters: INITIAL_STATE_FILTER,
  filterChart: { firstColumn: 0, secondColumn: 0, thirdColumn: 0 },
  filterPeriod: { dtFinal: null, dtStart: null },
  filterSpecific: {
    days: [],
    months: [new Date().getMonth(), new Date().getMonth() + 1],
    years: [new Date().getFullYear()]
  },
  nodesChart: [],
  totalCustomer: null,
  totalNotTrackedOrders: null,
  totalOrders: null,
  totalProducers: null,
  totalProperties: null,
  totalSecondaryBoxes: null,
  totalRetails: null,
  totalTrackedOrders: null,
  trackedTon: null
}

function setState(state: TraceabilityType) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

function setInitialState(state: TraceabilityType) {
  return function _handle() {
    return { ...state, ...INITIAL_STATE }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.SET_TRACEABILITY]: setState(state),
    [types.SET_FILTERS_CHART]: setState(state),
    [types.SET_INITIAL_FILTERS]: setInitialState(state),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}

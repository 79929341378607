import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import * as Yup from 'yup'
import i18n from 'i18n'

import { Button, Input, InputSelect, StatusBox, Table } from 'components'
import { Modal } from 'components/Modal'
import { ColumnsProps } from 'components/Table/Table'

import { YupValidator } from 'services/yupValidator.service'

import { propertyActions } from 'store/Property'
import { all } from 'store/Property/Property.selector'

import { PROPERTY_BLOCK_STATUS } from 'constants/property.constants'

import * as S from './styled'

const INITIAL_STATE = {
  blockStatus: null,
  reason: ''
}
const ERRORS_INITIAL_STATE = {
  blockStatus: null,
  reason: ''
}

const schema = () => {
  return Yup.object().shape({
    blockStatus: Yup.boolean()
      .nullable(true)
      .required()
      .label(i18n.t('common:situation')),
    reason: Yup.mixed().test(
      'reason',
      i18n.t('inputErrors:reasonRequiredToBlock'),
      (value, props) => {
        if (props.parent.blockStatus === true) {
          const sc = Yup.string().required().oneOf([props.parent.reason])
          return sc.isValidSync(value)
        }
        return true
      }
    )
  })
}

const columns = (): ColumnsProps[] => [
  {
    align: 'center',
    fixed: true,
    name: 'blockStatus',
    title: i18n.t('common:situation')
  },
  {
    name: 'producerName',
    title: i18n.t('common:productor')
  },
  {
    name: 'CPFCNPJ',
    title: i18n.t('common:cpfCNPJ')
  },
  {
    name: 'IE',
    title: i18n.t('common:IE')
  }
]

type Property = {
  CAR: string
  propertyId: string
  propertyName: string
}

export type ModalLogsListProps = {
  property: Property
  onExit?: () => void
}

export function ModalBlockProperty({ property, onExit }: ModalLogsListProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [form, setForm] = useState({ ...INITIAL_STATE })
  const [errors, setErrors] = useState({ ...ERRORS_INITIAL_STATE })

  const { blockStatusAllProducer, propertiesProducers } = useSelector(all)

  useEffect(() => {
    if (property.propertyId) {
      dispatch(propertyActions.fetchByPropertyId(property.propertyId))
    }
  }, [dispatch, property])

  useEffect(() => {
    if (blockStatusAllProducer.id) {
      dispatch(propertyActions.cleanProperty())
      dispatch(propertyActions.fetchAll())
      onExit()
    }
  }, [blockStatusAllProducer, dispatch, onExit])

  function handleInput(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setForm({ ...form, [name]: value })
  }

  function handleSelected(name: string) {
    return function handle(value) {
      setErrors({ ...errors, [name]: '' })
      setForm({ ...form, [name]: value.value })
    }
  }

  function changeColumn({ column, row }) {
    if (column.name === 'blockStatus') {
      return (
        <StatusBox
          variant={row.blockStatus ? 'inactive' : 'active'}
          minWidth={9}
        >
          {row.blockStatus ? t('common:blocked') : t('common:released')}
        </StatusBox>
      )
    } else return row[column.name]
  }

  async function onSubmit() {
    setErrors({
      ...ERRORS_INITIAL_STATE
    })
    const [isValid, validationError] = await new YupValidator(
      schema()
    ).validate(form)
    if (!isValid) {
      return setErrors(validationError as typeof ERRORS_INITIAL_STATE)
    }

    const _form = { ...form }
    if (_form.blockStatus === false) {
      _form.reason = ''
    }

    dispatch(propertyActions.blockStatusAllProducer(_form, property.propertyId))
  }

  const button = (
    <Button variant="secondary" onClick={onSubmit} size="medium">
      <p>{t('common:save')}</p>
    </Button>
  )

  const content = (
    <S.Wrapper>
      <S.GridHeader>
        <S.GridTitle item container xs={12}>
          <S.BoxTitle>{t('toasts:changePropertyStatus')}</S.BoxTitle>
        </S.GridTitle>
      </S.GridHeader>

      <S.Line />

      <S.PropertyInfo>
        <S.InfoName>
          <S.InfoTitle>{t('common:property')}</S.InfoTitle>
          <S.InfoDescription>{property.propertyName}</S.InfoDescription>
        </S.InfoName>

        <S.InfoCAR>
          <S.InfoTitle>{t('common:CAR')}</S.InfoTitle>
          <S.InfoDescription>{property.CAR}</S.InfoDescription>
        </S.InfoCAR>
      </S.PropertyInfo>

      <S.GridFields container spacing={2}>
        <S.GridInput item sm={12} md={4}>
          <InputSelect
            error={Boolean(errors.blockStatus)}
            fullWidth
            helperText={errors.blockStatus}
            label={t('aditionalInformation:situationOwnerProducer')}
            onSelected={handleSelected('blockStatus')}
            optionLabel="label"
            optionValue="value"
            options={PROPERTY_BLOCK_STATUS()}
            value={form.blockStatus}
          />
        </S.GridInput>

        {form.blockStatus && (
          <S.GridInput item sm={12} md={8}>
            <Input
              fullWidth
              label={t('common:blockingReason')}
              value={form.reason}
              error={Boolean(errors.reason)}
              helperText={errors.reason}
              onInput={value => handleInput(value, 'reason')}
            />
          </S.GridInput>
        )}
      </S.GridFields>

      <S.GridTable>
        {propertiesProducers.items.length > 0 && (
          <Table
            rows={propertiesProducers.items}
            columns={columns()}
            changeColumn={changeColumn}
          />
        )}
      </S.GridTable>
    </S.Wrapper>
  )

  return (
    <Modal
      button={button}
      content={content}
      isActionsEnabled={true}
      width={120}
      onExit={onExit}
    />
  )
}

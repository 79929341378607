import { Button } from 'components/Button'
import { Icon } from 'components/Icon'
import { Modal } from 'components/Modal'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type ModalDeleteUserProps = {
  name: string
  onExit?: () => void
  onRemove?: () => void
}

export function ModalDeleteUser({
  name,
  onExit,
  onRemove
}: ModalDeleteUserProps) {
  const { t } = useTranslation()
  const button = (
    <Button variant="alternative" onClick={onRemove} size="medium">
      <p>{t('common:yes')}</p>
    </Button>
  )

  const content = (
    <S.Wrapper>
      <S.Icon>
        <Icon icon="dropdown-delete" width={16} height={16} />
      </S.Icon>
      <S.Title>{t('breadcrumb:deleteUser')}</S.Title>
      <S.Description>
        {t('modalDeleteConfirm:wantToDelete')}
        {t('common:user')} <span>{name}</span> ?
      </S.Description>
    </S.Wrapper>
  )

  return (
    <Modal
      button={button}
      content={content}
      isActionsEnabled={true}
      width={60}
      onExit={onExit}
    />
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, MouseEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Table } from './Table'
import { NewPagination } from 'components'
import { all } from 'store/TraceabilityTables/TraceabilityTables.selector'
import { all as allTableSIF } from 'store/TableSIF/TableSIF.selector'

import { columns } from 'constants/box.constants'
import { INITIAL_STATE } from 'constants/traceability.constants'
import * as S from './styled'
import { TableSIFActions } from 'store/TableSIF'

export function AnalisysSIFTable() {
  const dispatch = useDispatch()

  const [filter] = useState({ ...INITIAL_STATE, limit: 50 })
  const { filters, tableLoading } = useSelector(all)
  const { tableSIF } = useSelector(allTableSIF)
  const { pagination } = tableSIF
  const [isNewPage, setNewPage] = useState({ ...pagination })
  useEffect(() => {
    if (filters.begin && filters.end && !tableLoading) {
      dispatch(
        TableSIFActions.getTableSIF({
          ...filters,
          limit: filter.limit
        })
      )
    }
  }, [dispatch, filters])

  async function handleChangePage(
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    const limit = tableSIF.pagination.limit
    const newFilter = {
      ...filters
    }
    newFilter.page = newPage
    newFilter.limit = limit
    dispatch(TableSIFActions.getTableSIF(newFilter))
  }
  function handleNewPage() {
    const limit = tableSIF.pagination.limit
    const newPage = isNewPage.page
    if (newPage > pagination.totalPages) {
      const newFilter = {
        ...filters
      }
      newFilter.page = pagination.totalPages
      newFilter.limit = limit
      dispatch(TableSIFActions.getTableSIF(newFilter))
      setNewPage({ ...pagination, page: newPage })
    } else {
      const newFilter = {
        ...filters
      }
      newFilter.page = newPage
      newFilter.limit = limit
      dispatch(TableSIFActions.getTableSIF(newFilter))
      setNewPage({ ...pagination, page: newPage })
    }
  }
  function handleInputPagination(name: string, value: number) {
    setNewPage({ ...pagination, [name]: value })
  }

  function handleChangeRowsPerPage(e) {
    const page = pagination.page
    const newFilter = {
      ...filters
    }
    newFilter.limit = e.target.value
    newFilter.page = page
    dispatch(TableSIFActions.getTableSIF(newFilter))
  }
  return (
    <S.FullGrid item xs={12}>
      <S.GridTable>
        <Table
          columns={columns()}
          rows={tableSIF.items || []}
          sortColumn={{ column: filter.column, sort: filter.direction }}
          isLoading={tableLoading}
          isScroll={tableSIF.items?.length > 10}
        />
        {tableSIF.pagination?.totalItems && (
          <NewPagination
            count={pagination?.totalPages}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 50, 100]}
            handleInput={handleInputPagination}
            handleNewPage={handleNewPage}
            page={pagination?.page}
            limit={pagination.limit}
          />
        )}
      </S.GridTable>
    </S.FullGrid>
  )
}

import { TraceabilityFilter } from 'types/Traceability.types'
export const types = Object.freeze({
  GET_PROPERTIES: '@properties/getProperties',
  SET_FILTERS: '@properties/setFilters',
  SET_PROPERTIES_LIST: '@properties/setPropertiesList',
  CLEAN_PROPERTIES_LIST: '@properties/cleanPropertiesLits',
  GET_PROPERTIES_LOCATION: '@properties/getPropertiesLocation',
  SET_PROPERTIES_LOCATION: '@properties/setPropertiesLocation',
  CLEAN_PROPERTIES_LOCATION: '@properties/cleanPropertiesLocation'
})

export function getProperties(
  filterProperties?: TraceabilityFilter,
  ordersKeys?: string[]
) {
  return {
    type: types.GET_PROPERTIES,
    payload: { filterProperties, ordersKeys }
  }
}

export function cleanProperties() {
  return { type: types.CLEAN_PROPERTIES_LIST }
}

export function setFilters(filterProperties: TraceabilityFilter) {
  return { type: types.SET_FILTERS, payload: { filterProperties } }
}

export function setProperties({ properties = {} } = {}) {
  return { type: types.SET_PROPERTIES_LIST, payload: { properties } }
}

export function getPropertiesLocation(
  filterProperties?: TraceabilityFilter,
  ordersKeys?: string[]
) {
  return {
    type: types.GET_PROPERTIES_LOCATION,
    payload: { filterProperties, ordersKeys }
  }
}

export function setPropertiesLocation({ propertiesLocation = [{}] }) {
  return {
    type: types.SET_PROPERTIES_LOCATION,
    payload: { propertiesLocation }
  }
}

export function cleanPropertiesLocation() {
  return { type: types.CLEAN_PROPERTIES_LOCATION }
}

import { call, put } from 'redux-saga/effects'
import { OrderHistoryService } from 'services/orderHistory.service'
import { spinnerActions } from '../Spinner'
import { toasts } from 'utils/toasts'
import * as actions from './OrderHistory.actions'

export function* getByHashPartner(action) {
  yield put(spinnerActions.setVisibility(true))
  const { hashPartner } = action.payload
  const $orderHistory = new OrderHistoryService()
  const orderHistories = yield call(
    $orderHistory.getByHashPartner.bind($orderHistory),
    { hashPartner }
  )
  if (orderHistories.success) {
    yield put(
      actions.setOrderHistory({
        orderHistories: orderHistories.data,
        success: true
      })
    )
  } else {
    yield put(
      actions.setOrderHistory({
        orderHistories: [],
        success: false
      })
    )
    toasts.blockchainUnavailable()
  }
  yield put(spinnerActions.setVisibility(false))
}

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ClientGroupFilter } from './ClientGroupFilter'
import { ClientGroupInfo } from './ClientGroupInfo'
import { clientGroupActions } from 'store/ClientGroup'
import { ModalAddClient } from './ModalAddClient/ModalAddClient'

import { AddButton } from 'components'
import { UserStorageService } from 'services'

import * as S from './styled'

interface ParamTypes {
  id: string
}

export function ManageClientGroup() {
  const { id } = useParams<ParamTypes>()
  const { user } = UserStorageService.getUserData()

  const [modalAdd, setModalAdd] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clientGroupActions.fetchOne(id))
  }, [dispatch, id])

  function onExit() {
    setModalAdd(false)
  }

  return (
    <S.Wrapper container spacing={3}>
      {modalAdd && <ModalAddClient id={id} onExit={onExit} />}
      {!UserStorageService.isViewer(user) && (
        <>
          <S.ButtonAdd onClick={() => setModalAdd(true)}>
            <AddButton />
          </S.ButtonAdd>
          <S.GridContainer item xs={12} md={4} lg={3}>
            <ClientGroupInfo />
          </S.GridContainer>
        </>
      )}
      <S.GridContainer
        item
        xs={12}
        md={UserStorageService.isViewer(user) ? 12 : 8}
        lg={UserStorageService.isViewer(user) ? 12 : 9}
      >
        <ClientGroupFilter onNewClick={() => setModalAdd(true)} />
      </S.GridContainer>
    </S.Wrapper>
  )
}

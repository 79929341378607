import { Icon } from 'components'
import { theme } from 'config'
import { THEMES_NAME } from 'config/theme.config'
import * as S from './styled'

export type SimpleCardProps = {
  clickable?: boolean
  description: number
  title: string
  isLoading?: boolean
  variant?: 'darker' | 'dark' | 'medium' | 'light'
}

export function SimpleCard({
  description,
  title,
  variant = 'darker',
  isLoading = false,
  clickable = false
}: SimpleCardProps) {
  return (
    <>
      {theme.appTheme === THEMES_NAME.swift ? (
        <S.SimpleCardSwift
          className={variant}
          isLoading={isLoading}
          clickable={clickable}
        >
          <S.Title>
            {title}{' '}
            {clickable ? (
              <>
                <Icon
                  className={'icon-style'}
                  icon="white-search"
                  height={45}
                  width={45}
                  fill={'#FFF'}
                ></Icon>
              </>
            ) : (
              ''
            )}
          </S.Title>
          {isLoading ? (
            <S.Loading>. . .</S.Loading>
          ) : (
            <S.Description>
              {new Intl.NumberFormat('pt-BR', {
                maximumFractionDigits: 2
              }).format(description)}
            </S.Description>
          )}
        </S.SimpleCardSwift>
      ) : (
        <S.SimpleCard
          className={variant}
          isLoading={isLoading}
          clickable={clickable}
        >
          <S.Title>
            {title}{' '}
            {clickable ? (
              <>
                <Icon
                  className={'icon-style'}
                  icon="white-search"
                  height={45}
                  width={45}
                  fill={'#FFF'}
                ></Icon>
              </>
            ) : (
              ''
            )}
          </S.Title>
          {isLoading ? (
            <S.Loading>. . .</S.Loading>
          ) : (
            <S.Description>
              {new Intl.NumberFormat('pt-BR', {
                maximumFractionDigits: 2
              }).format(description)}
            </S.Description>
          )}
        </S.SimpleCard>
      )}
    </>
  )
}

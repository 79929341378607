import { OrderHistoryType } from 'types/OrderHistory.types'
import { GeneralBlockInfo } from '../GeneralBlockInfo'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

type PropTypes = {
  orderHistory: OrderHistoryType
}

export function GeneralBlock({ orderHistory }: PropTypes) {
  const { t } = useTranslation()
  return (
    <S.Gridinfo item container xs={12}>
      <GeneralBlockInfo label={t('common:order')} value={orderHistory.order} />
      <GeneralBlockInfo
        label={t('common:type')}
        value={orderHistory.assetType}
      />
      <GeneralBlockInfo label={t('common:key')} value={orderHistory.key} />
      <GeneralBlockInfo
        label={t('common:hash')}
        value={orderHistory.hashPartner}
      />
    </S.Gridinfo>
  )
}

import { ReactNode } from 'react'
import clsx from 'clsx'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'

import * as S from './styled'

import { MediaMatch } from 'components/MediaMatch'
import { UserDropdown } from 'components/UserDropdown'
import { LangDropdown } from 'components/LangDropdown'

export type TopbarProps = {
  username?: string | null
  imgUrl?: string | null
  loading?: boolean
  button?: ReactNode
  setChangePassword?: () => void
}

const useStyles = makeStyles(() => ({
  toolbar: {
    backgroundColor: '#ffffff',
    paddingLeft: 24
  }
}))

export function Topbar({
  username,
  imgUrl,
  loading,
  button,
  setChangePassword
}: TopbarProps) {
  const classes = useStyles()
  const langList = ['pt-BR', 'en-US', 'es', 'ch']

  const defaultLang = langList.includes(window.navigator.language)
    ? window.navigator.language
    : 'en-US'

  const langLocalStorage = localStorage.getItem('i18nextLng')
    ? localStorage.getItem('i18nextLng')
    : defaultLang

  return (
    <Toolbar data-testid="topbar" className={clsx(classes.toolbar)}>
      {button}

      {!loading && (
        <>
          <S.MenuGroup>
            <S.Langdropdown>
              <LangDropdown userLang={langLocalStorage} />
            </S.Langdropdown>
            <MediaMatch data-testid="dropdown" greaterThan="medium">
              <UserDropdown
                username={username}
                imgUrl={imgUrl}
                setChangePassword={setChangePassword}
              />
            </MediaMatch>
          </S.MenuGroup>
        </>
      )}
    </Toolbar>
  )
}

import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styled'
type FormPwdProps = {
  children: ReactNode
}
const link = 'https://friboi.com.br/sustentabilidade/garantia-de-origem'
export function FormPwd({ children }: FormPwdProps) {
  const { t } = useTranslation()
  const year = new Date().getFullYear()
  return (
    <S.GridContainer container>
      <S.SideBar item xs={12} sm={5} md={4}>
        <S.Row>
          <S.SideBarColumn>
            <S.SideBarTitle>{t('authPages:welcome')}</S.SideBarTitle>
            <S.SideBarDescription>
              {t('authPages:welcomeDescription')}
              <p>
                <a
                  style={{ color: 'white', textDecoration: 'underline' }}
                  href={link}
                  target="_blanck"
                >
                  {t('authPages:more')}
                </a>
              </p>
            </S.SideBarDescription>
          </S.SideBarColumn>
        </S.Row>
      </S.SideBar>

      <S.GridRight item xs={12} sm={7} md={8}>
        <S.Row>{children}</S.Row>
        <S.Footer>
          <S.Divider />
          <S.Copyright>
            © {year}{' '}
            <a href="https://ecotrace.info/" target="_blanc">
              &nbsp; By Ecotrace Solutions
            </a>
          </S.Copyright>
        </S.Footer>
      </S.GridRight>
    </S.GridContainer>
  )
}

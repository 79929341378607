import faker from 'faker'
import { createServer, JSONAPISerializer } from 'miragejs'

import factories from './factories'
import models from './models'

export function makeServer({
  baseURL = 'http://localhost:5000',
  environment = 'development'
} = {}) {
  faker.setLocale('pt_BR')
  console.log(`Mirage Server on: ${baseURL}`)

  const server = createServer({
    environment,
    factories,
    models,

    serializers: JSONAPISerializer,

    seeds(server) {
      server.createList('propertyTmp', 5)
      server.createList('property', 3)
    },

    routes() {
      this.namespace = 'api'

      this.passthrough('http://localhost:5000/api/company')
      this.passthrough('http://localhost:5000/api/company/*')
      this.passthrough('http://localhost:5000/api/company-group')
      this.passthrough('http://localhost:5000/api/company-group/*')

      this.passthrough('http://localhost:5000/api/order')
      this.passthrough('http://localhost:5000/api/order/*')

      this.passthrough('http://localhost:5000/api/orderHistory')

      this.passthrough('http://localhost:5000/api/packing')
      this.passthrough('http://localhost:5000/api/packing/*')

      this.passthrough('http://localhost:5000/api/properties')
      this.passthrough('http://localhost:5000/api/properties/*')

      this.passthrough('http://localhost:5000/api/pwd-recovery')
      this.passthrough('http://localhost:5000/api/pwd-recovery/*')

      this.passthrough('http://localhost:5000/api/rdc24')
      this.passthrough('http://localhost:5000/api/rdc24/*')

      this.passthrough('http://localhost:5000/api/retail')
      this.passthrough('http://localhost:5000/api/retail/*')
      this.passthrough(
        'http://localhost:5000/api/retail/unrelated-with-group/*'
      )
      this.passthrough('http://localhost:5000/api/retail-group')
      this.passthrough('http://localhost:5000/api/retail-group/*')

      this.passthrough('http://localhost:5000/api/sign-in')
      this.passthrough('http://localhost:5000/api/sign-in/*')

      this.passthrough('http://localhost:5000/api/sign-up')
      this.passthrough('http://localhost:5000/api/sign-up/*')

      this.passthrough('http://localhost:5000/api/traceability')
      this.passthrough('http://localhost:5000/api/traceability/*')

      this.passthrough('http://localhost:5000/api/user-role')
      this.passthrough('http://localhost:5000/api/user-role/*')

      this.passthrough('http://localhost:5000/api/user')
      this.passthrough('http://localhost:5000/api/user/*')

      this.passthrough('http://localhost:3000/*')
      this.passthrough('https://b2b-tsi.ecotrace.technology/*')
      this.passthrough('https://geojson-cities-states-br.s3.amazonaws.com/*')

      this.passthrough('http://localhost:5001/api/property')
      this.passthrough('http://localhost:5001/api/property/*')
    }
  })

  return server
}

export const types = Object.freeze({
  FETCH_BY_UF: '@city/fetchByUF',
  SET_CITIES: '@city/setCities'
})

export function fetchByUf(params: { uf: string }) {
  return {
    type: types.FETCH_BY_UF,
    payload: { params }
  }
}

export function setCities({ cities = [] } = {}) {
  return { type: types.SET_CITIES, payload: { cities } }
}

import { call, put } from 'redux-saga/effects'
import { ProductService } from 'services'
import { toasts } from 'utils/toasts'

import * as actions from './Product.actions'

export function* fetchAll(action) {
  const { params } = action.payload

  yield put(actions.setLoading({ loading: true }))
  // yield put(actions.setProduct({ product: {} }))

  const $product = new ProductService()
  const {
    success,
    status,
    data: products
  } = yield call($product.fetchAll.bind($product), { params })

  if (status === 404) {
    return yield put(
      actions.setProducts({
        products: { items: [], pagination: { totalItems: 0 } }
      })
    )
  } else if (success) yield put(actions.setProducts({ products }))
  else toasts.generalFail()
}

export function* filterProducts() {}

export function* fetchOne(action) {
  yield put(actions.setLoading({ loading: true }))

  const { id } = action.payload

  const $product = new ProductService()
  const product = yield call($product.fetchOne.bind($product), id)

  if (product.success) {
    return yield put(
      actions.setProduct({
        product: product.data
      })
    )
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* createback(action) {
  yield put(actions.setLoading({ loading: true }))

  const { payload } = action

  const $product = new ProductService()
  const product = yield call($product.create.bind($product), payload)

  if (product.success) {
    toasts.created()
    return yield put(
      actions.setProductCreated({
        productCreate: product.data
      })
    )
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* create(action) {
  const { payload } = action
  const { toUploadImages } = payload

  yield put(actions.setLoading({ loading: true }))

  const $product = new ProductService()
  const product = yield call($product.create.bind($product), payload.product)

  const { id } = product.data

  if (product.success) {
    if (id && toUploadImages.photo) {
      const multipartForm = new FormData()
      multipartForm.set('file', toUploadImages.photo)

      yield call($product.uploadProductPhoto.bind($product), {
        id,
        multipartForm
      })
    }

    toasts.created()
    return yield put(
      actions.setProductCreated({
        productCreate: product.data
      })
    )
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* edit(action) {
  yield put(actions.setLoading({ loading: true }))

  const { payload } = action

  const $product = new ProductService()
  const { success, data } = yield call(
    $product.edit.bind($product),
    payload.product
  )

  if (success) {
    toasts.edited()
    return yield put(actions.setProductEdit({ productEdit: data }))
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* deleteProduct(action) {
  const id = action.payload

  const $product = new ProductService()
  const product = yield call($product.deleteProduct.bind($product), { id })

  if (product.success) {
    toasts.deleted()
    return yield put(actions.fetchAll())
  } else toasts.generalFail()
}

import * as S from './styled'

export function SvgSignin() {
  return (
    <>
      <S.Svg
        xmlns="http://www.w3.org/2000/svg"
        width="138.11"
        height="395.5"
        viewBox="0 0 138.11 395.5"
        className="decoration"
      >
        <path
          className="decorator_form"
          d="M138.11,212.143c0-56.625-22.787-78.15-36.5-107.314C92.441,85.312,93.41,0,93.41,0H0V395.5H93.41s1.552-61.884,8.206-76.042C115.324,290.293,138.11,268.769,138.11,212.143Z"
        />
      </S.Svg>
      <p className="chevron">›</p>
    </>
  )
}

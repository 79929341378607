import { ColumnsProps } from 'components/Table/Table'
import i18n from 'i18n'

export const columnTrackable = (): ColumnsProps[] => [
  {
    align: 'right',
    fixed: true,
    name: 'percentStatus',
    title: i18n.t('common:traceability')
  }
]

export const allColumns = (): ColumnsProps[] => [
  {
    align: 'right',
    fixed: true,
    name: 'invoice',
    title: i18n.t('tables:invoice')
  },
  {
    align: 'right',
    fixed: true,
    name: 'invoicedate',
    title: i18n.t('tables:invoiceDate')
  },
  {
    align: 'right',
    fixed: true,
    name: 'order',
    title: i18n.t('tables:orderNumber')
  },
  {
    align: 'right',
    fixed: true,
    name: 'orderdate',
    title: i18n.t('tables:orderDate')
  },
  {
    align: 'right',
    name: 'stockcnpj',
    title: i18n.t('tables:shipperCNPJ')
  },
  {
    align: 'right',
    name: 'docnamelocalstock',
    title: i18n.t('tables:shipperCorporateName')
  },
  {
    align: 'right',
    name: 'cargo',
    title: i18n.t('tables:charge')
  },
  {
    align: 'right',
    name: 'shipmentdate',
    title: i18n.t('tables:loadingDate')
  },
  {
    align: 'right',
    name: 'qtt',
    title: i18n.t('tables:totalOrderQty')
  },
  {
    name: 'invoicetype',
    title: i18n.t('tables:nfNature')
  },
  {
    align: 'right',
    name: 'destinationcnpj',
    title: i18n.t('common:destinationId')
  },
  {
    name: 'destinationname',
    title: i18n.t('tables:corporatePurposeDestiny')
  },
  {
    name: 'destinationcity',
    title: i18n.t('tables:destinationCity')
  },
  {
    name: 'destinationstate',
    title: i18n.t('tables:stateOfDestionation')
  },
  {
    align: 'right',
    name: 'shippingcnpj',
    title: i18n.t('tables:carrierCNPJ')
  },
  {
    name: 'shippingcompany',
    title: i18n.t('tables:carrierCorporateName')
  },
  {
    name: 'truckplate',
    title: i18n.t('tables:vehiclePlate')
  },
  {
    name: 'driver',
    title: i18n.t('common:driver')
  }
]

import { ColumnsProps } from 'components/Table/Table'
import i18n from 'i18n'

export const columns = (): ColumnsProps[] => [
  {
    align: 'right',
    fixed: true,
    name: 'fazCode',
    title: i18n.t('tables:code')
  },
  {
    align: 'left',
    fixed: true,
    name: 'fazName',
    title: i18n.t('tables:corporateNameOwnership')
  },
  {
    align: 'left',
    name: 'producerName',
    title: i18n.t('tables:producerName')
  },
  {
    align: 'right',
    name: 'producerDoc',
    title: i18n.t('tables:producerDocument')
  },
  {
    align: 'right',
    name: 'fazCarNumber',
    title: i18n.t('tables:carNumber')
  },
  {
    align: 'left',
    name: 'city',
    title: i18n.t('common:city')
  },
  {
    align: 'left',
    name: 'state',
    title: i18n.t('common:uf')
  },
  {
    align: 'right',
    name: 'qtyInvoice',
    title: i18n.t('tables:quantityOrders')
  },
  // {
  //   align: 'right',
  //   name: 'qtyAbate',
  //   title: i18n.t('tables:quantitySlaughter')
  // },
  // {
  //   align: 'right',
  //   name: 'qtySIF',
  //   title: i18n.t('tables:quantitySIF')
  // },
  // {
  //   align: 'right',
  //   name: 'qtyKg',
  //   title: i18n.t('tables:quantityKl')
  // },
  {
    align: 'left',
    fixed: false,
    name: 'analyze',
    title: i18n.t('common:analyze')
  }
]

import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { TableNotFoundHint } from 'components/TableNotFoundHint'

import { IndustryGroupType } from '../../../../types/IndustryGroup.types'
import { ModalDeleteGroupIndustry } from '../../ModalDeleteGroupIndustry/ModalDeleteGroupIndustry'
import { IndustryGroupDropdown } from './IndustryGroupDropdown'

import { deleteIndustryGroup } from 'store/IndustryGroup/IndustryGroup.actions'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type IndustryGroupTableProps = {
  rows: IndustryGroupType[]
}

export function IndustryGroupTable({ rows }: IndustryGroupTableProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [_deleteIndustryGroup, _setDeleteIndustryGroup] = useState(
    {} as IndustryGroupType
  )

  function onExit() {
    _setDeleteIndustryGroup({} as IndustryGroupType)
  }

  function onRemoveIndustryGroup() {
    dispatch(
      deleteIndustryGroup({
        id: _deleteIndustryGroup.id
      })
    )
    _setDeleteIndustryGroup({} as IndustryGroupType)
  }

  return (
    <S.Wrapper data-testid="IndustryGroupTable">
      {Object.keys(_deleteIndustryGroup).length ? (
        <ModalDeleteGroupIndustry
          groupName={_deleteIndustryGroup.name}
          companies={_deleteIndustryGroup.total}
          onExit={onExit}
          onRemove={onRemoveIndustryGroup}
        />
      ) : null}
      <S.Table>
        <tbody>
          <S.Line id="header">
            <S.Header>{t('tables:groupName')}</S.Header>
            <S.Header className="right">{t('common:industries')}</S.Header>
            <S.Header>{t('tables:adminUser')}</S.Header>
            <S.Header>{t('tables:viewType')}</S.Header>
            <S.Header>{t('common:actions')}</S.Header>
          </S.Line>
          {rows.map(each => (
            <S.Line id="values" key={each.id}>
              <S.Column>{each.name}</S.Column>
              <S.Column className="right">{each.total}</S.Column>
              <S.Column>{each.adminEmail}</S.Column>
              <S.Column>
                {each.visible
                  ? t('clientGroup:released')
                  : t('clientGroup:notReleased')}
              </S.Column>
              <S.Column id="actions-column">
                <IndustryGroupDropdown
                  id={each.id}
                  industryGroupName={each.name}
                  setDeleteIndustryGroup={() => _setDeleteIndustryGroup(each)}
                />
              </S.Column>
            </S.Line>
          ))}
        </tbody>
      </S.Table>
      {!rows.length && <TableNotFoundHint to="/grupo-industria/novo" />}
    </S.Wrapper>
  )
}

import { call, put } from 'redux-saga/effects'

import { toasts } from 'utils/toasts'

import { CityService } from 'services'

import * as actions from './City.actions'

export function* fetchByUF(action) {
  const { params } = action.payload

  yield put(actions.setCities({ cities: [] }))

  const $city = new CityService()
  const {
    success,
    status,
    data: cities
  } = yield call($city.fetchByUF.bind($city), params)

  if (status === 404) {
    return yield put(
      actions.setCities({
        cities: []
      })
    )
  } else if (success) {
    return yield put(actions.setCities({ cities }))
  } else toasts.citiesFail()
}

import { useEffect, useState } from 'react'
import { Checkbox } from 'components/Checkbox'
import { Icon } from 'components/Icon'

import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'

import * as S from './styled'

export type InputAutocompleteProps = {
  defaultValue?: any
  error?: boolean
  fullWidth?: boolean
  helperText?: string
  padding?: number
  label?: string
  options: unknown[]
  optionLabel: string
  width?: number
  onSelected?: (_value: any) => any
  clearFilter?: () => void
  disableRemoveButton?: boolean
  allowRemoveAllItems?: boolean
}

export function InputAutocomplete({
  defaultValue = [],
  error = false,
  fullWidth = false,
  helperText = '',
  label = '',
  options,
  padding,
  optionLabel,
  width = 200,
  onSelected,
  clearFilter,
  disableRemoveButton = false,
  allowRemoveAllItems = true
}: InputAutocompleteProps) {
  const [value, setValue] = useState<any>(defaultValue)

  function handleChange(
    newValue,
    componentName: 'remove-option' | 'clear' | 'select-option'
  ) {
    const executeHandle = () => {
      const setObj = new Set()
      const result = newValue.reduce((acc, item) => {
        if (!setObj.has(item[optionLabel])) {
          setObj.add(item[optionLabel])
          acc.push(item)
        }
        return acc
      }, [])
      setValue(result)
      !!onSelected && onSelected(result)
    }

    if (allowRemoveAllItems) executeHandle()
    if (
      (disableRemoveButton && newValue.length) ||
      (componentName === 'remove-option' && newValue.length) ||
      (!disableRemoveButton &&
        componentName === 'select-option' &&
        newValue.length)
    ) {
      executeHandle()
    }
  }

  function handleCheckDelete(val) {
    const newValue = value.filter(each => each[optionLabel] !== val)
    if ((newValue.length && !disableRemoveButton) || allowRemoveAllItems) {
      setValue(newValue)
      !!onSelected && onSelected(newValue)
    }
  }

  useEffect(() => {
    if (defaultValue) {
      const newValue = defaultValue.map(each => {
        const formated = { ...each }
        return formated
      })
      setValue(newValue)
    }
  }, [defaultValue, optionLabel])

  return (
    <S.InputAutocomplete
      multiple
      padding={padding}
      options={options}
      disableCloseOnSelect
      getOptionLabel={option => option[optionLabel]}
      onChange={(_event: any, newValue: unknown, nameComponent) =>
        handleChange(newValue, nameComponent)
      }
      value={value}
      getOptionSelected={(option, value) =>
        option[optionLabel] === value[optionLabel]
      }
      popupIcon={<Icon icon="input-chevron-down" width={14} height={14} />}
      closeIcon={
        <Icon icon="input-exit" width={12} height={12} onClick={clearFilter} />
      }
      renderOption={option => (
        <>
          <Checkbox
            label=""
            labelFor=""
            style={{ marginRight: 8 }}
            value={option[optionLabel]}
            isChecked={
              value && !!value.find(f => f[optionLabel] === option[optionLabel])
            }
          />
          {option[optionLabel]}
        </>
      )}
      style={{ width: !fullWidth ? width : '100%' }}
      renderInput={params => (
        <>
          <TextField
            {...params}
            variant="outlined"
            label={label}
            error={error}
            helperText={helperText}
          />
          {error && helperText && (
            <S.AlertIcon>
              <Icon icon="itens-interface-alert" height={10} width={10} />
            </S.AlertIcon>
          )}
        </>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            variant="outlined"
            label={option[optionLabel]}
            deleteIcon={
              disableRemoveButton ? (
                <S.EmptyRemoveButton />
              ) : (
                <Icon icon="input-exit" width={12} height={12} />
              )
            }
            {...getTagProps({ index })}
            onClick={() => handleCheckDelete(option[optionLabel])}
          />
        ))
      }
    />
  )
}

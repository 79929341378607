import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Input } from 'components/Input'
import { InputCpfCnpj } from 'components/InputCpfCnpj'
import { InputSelect } from 'components/InputSelect'
import { Button } from 'components/Button'

import { selectOptions as visibilityOptions } from 'constants/industryGroup.constants'

import { YupValidator } from 'services/yupValidator.service'

import { IndustryGroupFormSchema } from 'store/IndustryGroup/IndustryGroup.validator'
import { industryGroupActions } from 'store/IndustryGroup'
import { all } from 'store/IndustryGroup/IndustryGroup.selector'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

interface ParamTypes {
  id: string
}

const INITIAL_STATE = {
  form: {
    name: '',
    adminName: '',
    adminEmail: '',
    cnpj: '',
    visible: null
  },
  errors: {
    name: '',
    adminName: '',
    adminEmail: '',
    cnpj: '',
    visible: ''
  }
}

export function IndustryGroupForm() {
  const { t } = useTranslation()
  const { id } = useParams<ParamTypes>()
  const [form, setForm] = useState({ ...INITIAL_STATE.form })
  const [errors, setErrors] = useState({ ...INITIAL_STATE.errors })

  const { manageIndustryGroup, industryGroupCreate, industryGroupEdit } =
    useSelector(all)

  const dispatch = useDispatch()
  const { goBack } = useHistory()

  useEffect(() => {
    if (id && Object.keys(manageIndustryGroup).length === 0) {
      dispatch(industryGroupActions.fetchOne(id))
    }
  }, [dispatch, id, manageIndustryGroup])

  useEffect(() => {
    if (industryGroupCreate.id || industryGroupEdit.id) {
      goBack()
      dispatch(industryGroupActions.cleanIndustryGroup())
    }
  }, [industryGroupCreate, industryGroupEdit, goBack, dispatch])

  useEffect(() => {
    if (id) {
      setForm({
        name: manageIndustryGroup.name,
        adminName: manageIndustryGroup.adminName,
        adminEmail: manageIndustryGroup.adminEmail,
        cnpj: manageIndustryGroup.cnpj,
        visible: Boolean(manageIndustryGroup.visible)
      })
    }
  }, [manageIndustryGroup, id])

  function handleInput(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setForm({ ...form, [name]: value })
  }

  function handleSelected(name: string) {
    return function handle({ value }) {
      setErrors({ ...errors, [name]: '' })
      setForm({ ...form, [name]: value })
    }
  }

  async function submit() {
    setErrors({ ...INITIAL_STATE.errors })

    const validator = new YupValidator(IndustryGroupFormSchema())
    const [isValid, validationError] = await validator.validate(form)
    if (!isValid) {
      return setErrors(validationError as typeof INITIAL_STATE.errors)
    }

    dispatch(
      id
        ? industryGroupActions.editIndustryGroup({ ...form, id })
        : industryGroupActions.createIndustryGroup(form)
    )
  }

  function onExit() {
    goBack()
    dispatch(industryGroupActions.cleanIndustryGroup())
  }

  return (
    <S.Wrapper container>
      <S.FullGrid item xs={12}>
        <div style={{ marginBottom: 16 }}>
          <S.GridHeader>
            <S.GridTitle item container xs={12}>
              <S.BoxTitle>{t('users:basicInformations')}</S.BoxTitle>
            </S.GridTitle>
          </S.GridHeader>
          <S.GridFilter container spacing={2}>
            <S.GridInput item sm={12} md={6}>
              <Input
                fullWidth
                label={t('clientGroup:industryGroupName')}
                value={form.name}
                error={!!errors.name}
                helperText={errors.name}
                onInput={value => handleInput(value, 'name')}
              />
            </S.GridInput>
            <S.GridInput item sm={12} md={6}>
              <Input
                fullWidth
                label={t('clientGroup:adminName')}
                value={form.adminName}
                error={!!errors.adminName}
                helperText={errors.adminName}
                onInput={value => handleInput(value, 'adminName')}
              />
            </S.GridInput>
            <S.GridInput item sm={12} md={6}>
              <Input
                fullWidth
                label={t('profile:adminEmail')}
                value={form.adminEmail}
                error={!!errors.adminEmail}
                helperText={errors.adminEmail}
                onInput={value => handleInput(value, 'adminEmail')}
              />
            </S.GridInput>
            <S.GridInput item sm={12} md={6}>
              <InputCpfCnpj
                fullWidth
                value={form.cnpj}
                error={!!errors.cnpj}
                helperText={errors.cnpj}
                type="cnpj"
                onInput={value => handleInput(value, 'cnpj')}
              />
            </S.GridInput>
          </S.GridFilter>
        </div>
        <div style={{ marginBottom: 16 }}>
          <S.GridHeader>
            <S.GridTitle item container xs={12}>
              <S.BoxTitle>{t('clientGroup:viewType')}</S.BoxTitle>
            </S.GridTitle>
          </S.GridHeader>
          <S.GridFilter container spacing={2}>
            <S.GridInput item sm={12} md={6}>
              <InputSelect
                error={Boolean(errors.visible)}
                fullWidth
                helperText={errors.visible}
                label={t('clientGroup:view')}
                onSelected={handleSelected('visible')}
                optionLabel="label"
                optionValue="value"
                options={visibilityOptions()}
                value={form.visible}
              />
            </S.GridInput>
          </S.GridFilter>
        </div>
      </S.FullGrid>
      <S.GridButtons item xs={12}>
        <Button variant="default" onClick={onExit} size="medium">
          <p>{t('common:cancel')}</p>
        </Button>
        <Button variant="alternative" onClick={submit} size="medium">
          <p>{t('common:save')}</p>
        </Button>
      </S.GridButtons>
    </S.Wrapper>
  )
}

import styled from 'styled-components'

export const Wrapper = styled.div`
  align-items: center;
  display: inline-flex;
  margin-top: 0.7rem;
  margin-bottom: 1.7rem;
  cursor: pointer;
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.labelGoBack};
  font-size: 1.2rem;
  margin-left: 0.8rem;
  cursor: pointer;
`

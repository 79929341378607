import { get } from 'lodash'
import { ProductType } from 'types/Product.types'

import { types } from './Product.actions'

type Product = {
  products: {
    items: ProductType[]
    pagination: {
      totalItems: number
      limit: number
      page: number
    }
  }
  productCreate?: ProductType
  productEdit?: ProductType
  product?: ProductType
  loading: boolean
}
const INITIAL_STATE = {
  products: {
    items: [],
    pagination: null
  },
  productCreate: {} as ProductType,
  productEdit: {} as ProductType,
  product: {} as ProductType,
  loading: false
}

function setState(state: Product) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.CLEAN_PRODUCTS]: setState(state),
    [types.SET_PRODUCT_CREATED]: setState(state),
    [types.SET_PRODUCT_EDIT]: setState(state),
    [types.SET_PRODUCTS]: setState(state),
    [types.SET_PRODUCT]: setState(state),
    [types.SET_LOADING]: setState(state),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}

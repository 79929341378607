import { get } from 'lodash'
import { ClientType } from 'types/Client.types'

import { types } from './Client.actions'

type Client = {
  clients: {
    items: ClientType[]
    pagination: {
      totalItems: number
      limit: number
      page: number
    }
  }
  clientCreate?: ClientType
  clientEdit?: ClientType
  client?: ClientType
  loading: boolean
}

const INITIAL_STATE = {
  clients: {
    items: [],
    pagination: null
  },
  clientCreate: {} as ClientType,
  clientEdit: {} as ClientType,
  client: {} as ClientType,
  loading: false
}

function setState(state: Client) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.CLEAN_CLIENT]: setState(state),
    [types.SET_CLIENT]: setState(state),
    [types.SET_CLIENTS]: setState(state),
    [types.SET_CLIENT_CREATED]: setState(state),
    [types.SET_CLIENT_EDIT]: setState(state),
    [types.SET_LOADING]: setState(state),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}

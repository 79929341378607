import styled from 'styled-components'

import MaterialTab from '@material-ui/core/Tab'
import MaterialTabList from '@material-ui/lab/TabList'
import MaterialTabPanel from '@material-ui/lab/TabPanel'
import { Grid } from '@material-ui/core'

export const Wrapper = styled.div`
  border-radius: 1rem;
  flex-grow: 1;
  margin: 0 auto;
`

export const Panel = styled(MaterialTabPanel)`
  background-color: white;
  box-shadow: 0px 5px 25px #0000000d;
  border-radius: 0 1rem 1rem 1rem;
  padding: 1rem 1.6rem;
`

export const TabList = styled(MaterialTabList)`
  && {
    background-color: transparent;
    min-height: 0;

    .MuiTabs-flexContainer {
      background-color: transparent;
    }

    .MuiTabs-indicator {
      display: none;
    }
  }
`

export const Tab = styled(MaterialTab)`
  && {
    background: transparent
      linear-gradient(180deg, #fff 0%, #fff 85%, #f2f3f7 100%) 0% 0% no-repeat
      padding-box;
    color: ${({ theme }) => theme.colors.darkGray};
    padding: 0.8rem 2.4rem;
    min-height: 0;

    &:first-of-type {
      border-radius: 0.8rem 0 0 0;
    }

    &:last-of-type {
      border-radius: 0 0.8rem 0 0;
    }

    .MuiTab-wrapper {
      font-size: 1.2rem;
      text-transform: none;
    }

    &.Mui-selected {
      background: ${({ theme }) => theme.colors.primary};
      color: white;
    }
  }
`
export const GridButtons = styled(Grid)`
  align-self: center;
  text-align: end;
  button + button {
    margin-left: 0.8rem;
  }
`

export const GridFilter = styled(Grid)``

export const GridInput = styled(Grid)``

import { Button } from 'components/Button'
import { Icon } from 'components/Icon'
import { Modal } from 'components/Modal'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type ModalDeleteGroupIndustryProps = {
  groupName: string
  companies: number
  onExit?: () => void
  onRemove?: () => void
}

export function ModalDeleteGroupIndustry({
  groupName,
  companies,
  onExit,
  onRemove
}: ModalDeleteGroupIndustryProps) {
  const { t } = useTranslation()
  const button = (
    <Button variant="secondary" onClick={onRemove} size="medium">
      <p>{t('common:yes')}</p>
    </Button>
  )

  const content = (
    <S.Wrapper>
      <S.Icon>
        <Icon icon="dropdown-delete" width={16} height={16} />
      </S.Icon>
      <S.Title>{t('breadcrumb:deleteGroup')}</S.Title>
      <S.Description>
        {t('modalDeleteConfirm:wantToDelete')} {t('common:group')}
        <span> {groupName}</span> ?
        {companies > 1 && (
          <>
            <br />
            {t('modalDeleteConfirm:thisGroupHas')}
            {companies}
            {t('modalDeleteConfirm:industriesRelatedToIt')}
          </>
        )}
      </S.Description>
    </S.Wrapper>
  )

  return (
    <Modal
      button={button}
      content={content}
      isActionsEnabled={true}
      width={60}
      onExit={onExit}
    />
  )
}

import styled from 'styled-components'
export const Pagination = styled.div`
  margin-top: 1.6rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding: 1.1rem 0;
  .css-1ilbeq7 {
    border: none;
    margin: 0 0.2rem;
    background-color: #ffffff;
    border-radius: 0.4rem;
    color: #464a53;
    font-size: 1.1rem;
    font-weight: 600;
    font-family: inherit;
    &:hover {
      background-color: #315ca7;
      color: #ffffff;
    }
  }
  .css-1ilbeq7.Mui-selected {
    background-color: #315ca7;
  }
  .Mui-selected {
    max-width: 25px;
    max-height: 25px;
    background-color: #315ca7 !important;
    color: #ffffff;
    &:hover {
      background-color: #315ca7 !important;
      color: #ffffff;
    }
  }
  .css-rktzwn-MuiButtonBase-root-MuiPaginationItem-root {
    border: none;
    margin: 0 0.2rem;
    background-color: #ffffff;
    border-radius: 0.4rem;
    color: #464a53;
    font-size: 1.1rem !important;
    font-weight: 600 !important;
    font-family: inherit !important;
    &:hover {
      background-color: #315ca7;
      color: #ffffff;
    }
    max-width: 25px;
    max-height: 25px;
  }
  .css-rktzwn-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #315ca7;
    color: #ffffff;
    &:hover {
      background-color: #315ca7;
      color: #ffffff;
    }
  }

  .css-rktzwn-MuiButtonBase-root-MuiPaginationItem-root {
    border: none;
    margin: 0 0.2rem;
    background-color: #ffffff;
    border-radius: 0.4rem;
    color: #464a53;
    font-size: 1.1rem;
    font-weight: 600;
    font-family: inherit;
    &:hover {
      background-color: #315ca7;
      color: #ffffff;
    }
  }
  .css-rktzwn-MuiButtonBase-root-MuiPaginationItem-root {
    max-width: 25px;
    max-height: 25px;
  }
`
export const LinesPerPages = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -0.3rem;
`
export const Label = styled.p`
  color: #464a53;
  font-size: 1.2rem;
  padding-right: 1.5rem;
  font-weight: 600;
`
export const Select = styled.select`
  border: none;
  background-color: #ffffff;
  border-radius: 0.4rem;
  margin-right: 1.9rem;
`

export const Option = styled.option``

export const Input = styled.div`
  margin-top: -0.6rem;
  .MuiInputBase-root {
    padding-left: 1rem;
    color: #464a53;
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0;
  }
  .MuiFormControl-root {
    width: 79px;
    height: 20px;
    .MuiInputLabel-root {
      font-size: 0.9rem;
      font-family: inherit;
      font-weight: 600;
    }
  }
  .MuiOutlinedInput-input {
    padding: 0.4rem 0;
  }
`

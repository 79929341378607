import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IconButton, InputAdornment } from '@material-ui/core'
import { useDispatch } from 'react-redux'

import { theme } from 'config'
import { THEMES_NAME } from 'config/theme.config'
import { clearReduxStateAction } from 'store/ClearReduxState'

import { Icon, Signin, SvgSignin } from 'components'
import { AuthService, UserStorageService, UserService } from 'services'

import { YupValidator } from 'services/yupValidator.service'
import { LoginSchema } from 'store/User/User.validator'
import { traceabilityTablesActions } from 'store/TraceabilityTables'
import { traceabilityChartsActions } from 'store/TraceabilityCharts'

import { INITIAL_STATE as INITIAL_STATE_FILTER } from 'constants/traceability.constants'

import { useTranslation } from 'react-i18next'
import * as S from './styled'
import jwt from 'jwt-decode'
import { toast } from 'react-toastify'
import { submitOnEnter } from 'utils/submitOnEnter'

interface UserResponse {
  success: boolean
  data: {
    lang?: string
    token?: string
    avatar?: string
    message?: string
    profile?: string
    code?: string
  }
}

interface userToken {
  id: string
  email: string
  firstName: string
  iat: number
  exp: number
}

export const INITIAL_STATE = {
  email: '',
  password: ''
}

export function Login() {
  const { t, i18n } = useTranslation()
  const [validationErrorRequest, setValidationErrorRequest] = useState('')
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({ ...INITIAL_STATE })
  const [errors, setErrors] = useState({ ...INITIAL_STATE })
  const [passwordVisible, setPasswordVisible] = useState('password')
  const [lang, setLang] = useState('')
  const auth = new AuthService()
  const dispatch = useDispatch()
  const history = useHistory()
  function changePasswordView() {
    setPasswordVisible(oldValue => (oldValue === 'text' ? 'password' : 'text'))
  }

  useEffect(() => {
    UserStorageService.signOut()
    dispatch(clearReduxStateAction.clearRedux())
    dispatch(traceabilityTablesActions.setFilters({ ...INITIAL_STATE_FILTER }))
    dispatch(traceabilityChartsActions.setInitialFilters())
  }, [loading, dispatch])

  async function handleLogin() {
    setLoading(true)
    setErrors({ ...INITIAL_STATE })
    setValidationErrorRequest('')

    const [isValid, validationError] = await new YupValidator(
      LoginSchema()
    ).validate(form)

    if (!isValid) {
      setLoading(false)
      return setErrors(validationError as typeof INITIAL_STATE)
    }

    const { success, data }: UserResponse = await auth.login({
      ...form
    })

    if (data.code === 'NOT_FOUND') {
      toast.error(t('toasts:dataNotFound'))
    } else if (success) {
      const userData = jwt(data.token) as userToken
      const lang = localStorage.getItem('i18nextLng')
      UserStorageService.signIn(JSON.stringify({ ...data, lang }))
      const { token } = JSON.parse(UserStorageService.getToken() || '{}')
      if (token) {
        const $user = new UserService()
        await $user.updateProfile(userData.id, { lang })
      }
      history.push('/')
    } else {
      setValidationErrorRequest(i18n.t('inputErrors:invalidData'))
    }
    setLoading(false)
  }

  function shouldInvoke() {
    if (!loading) return handleLogin()
  }

  function handleInput(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setForm({ ...form, [name]: value })
  }

  function GetThemeMode() {
    const THEMES_NAME = {
      jbsFriboi: (
        <>
          <S.LogoFriboi />
          <S.Title>{t('authPages:ecoB2BFrigol')}</S.Title>
          <p
            style={{
              color: '#3A599C',
              fontSize: '2.5rem',
              fontWeight: 'bold',
              lineHeight: '0.1rem',
              marginBottom: '1.4rem'
            }}
          >
            {t('authPages:b2b')}
          </p>
        </>
      ),
      swift: (
        <>
          <S.LogoSwift />
          <S.Title>{t('authPages:ecoB2BRetailSafe')}</S.Title>
        </>
      ),
      standard: (
        <>
          <S.LogoEco />
          <S.Title>{t('authPages:ecoB2BEco')}</S.Title>
        </>
      )
    }

    return THEMES_NAME[theme.appTheme]
  }

  useEffect(() => {
    const lang = i18n.language
    if (lang === 'ch') {
      setLang('zh-cn')
    } else if (lang === 'es') {
      setLang('es-ar')
    } else if (lang === 'en-US') {
      setLang('en-us')
    } else {
      setLang('pt-br')
    }
  }, [i18n.language])
  return (
    <Signin>
      <S.SideBarCorner>
        <SvgSignin />
      </S.SideBarCorner>
      <S.LoginContainer>
        <GetThemeMode />
        <S.FormInput
          error={Boolean(errors.email)}
          helperText={errors.email}
          iconLeft={
            <Icon
              icon={
                theme.appTheme === THEMES_NAME.swift
                  ? 'envelope-swift'
                  : 'envelope'
              }
              height={16}
              width={16}
            />
          }
          label={t('common:email')}
          onInput={value => handleInput(value, 'email')}
          type="email"
          value={form.email}
        />

        <S.FormInput
          error={Boolean(errors.password)}
          helperText={errors.password}
          iconLeft={
            <Icon
              icon={
                theme.appTheme === THEMES_NAME.swift
                  ? 'input-padlock-swift'
                  : 'input-padlock'
              }
              height={16}
              width={16}
            />
          }
          iconRight={
            <InputAdornment position="start">
              <IconButton onClick={changePasswordView}>
                {theme.appTheme === THEMES_NAME.swift ? (
                  <Icon
                    icon={
                      passwordVisible === 'text'
                        ? 'input-visible-swift'
                        : 'input-visible-not-swift'
                    }
                    height={16}
                    width={16}
                  />
                ) : (
                  <Icon
                    icon={
                      passwordVisible === 'text'
                        ? 'input-visible'
                        : 'input-visible-not'
                    }
                    height={16}
                    width={16}
                  />
                )}
              </IconButton>
            </InputAdornment>
          }
          label={t('common:password')}
          onInput={value => handleInput(value, 'password')}
          type={passwordVisible}
          value={form.password}
          onKeyPress={submitOnEnter(shouldInvoke)}
        />

        <S.InputError>
          <S.InputErrorIcon
            icon="itens-interface-alert"
            width={12}
            opacity={validationErrorRequest}
          />
          <S.InputErrorText>{validationErrorRequest}</S.InputErrorText>
        </S.InputError>

        <S.ForgetPassword to="/esqueci-minha-senha">
          {t('authPages:forgotPassword')}
        </S.ForgetPassword>

        <S.FormButton size="large" variant="loggedOut" onClick={shouldInvoke}>
          {t('authPages:loginButton')}
        </S.FormButton>

        <S.HelpDiv>
          <S.HelpText>{t('authPages:dontHaveAccessYet')}</S.HelpText>
          <S.GoToHelpDesk
            to={{
              pathname: `${
                theme.appTheme === THEMES_NAME.jbsFriboi
                  ? `https://suporte-ecotrace-jbs.zendesk.com/hc/${lang}/categories/7115458829339-Q-A-D%C3%BAvidas-frequentes`
                  : `https://suporte-ecotrace.zendesk.com/hc/${lang}/categories/4520372469019-Q-A-D%C3%BAvidas-frequentes`
              }`
            }}
            target="_blank"
          >
            {t('authPages:learnHowToAccess')}
          </S.GoToHelpDesk>
        </S.HelpDiv>

        {theme.appTheme === THEMES_NAME.jbsFriboi ||
          (THEMES_NAME.swift && (
            <S.FooterLogo>
              <S.LogoEcoFooter />
            </S.FooterLogo>
          ))}
      </S.LoginContainer>
    </Signin>
  )
}

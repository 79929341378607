import { HTTP } from 'providers'
import { UserType, ChangePasswordType } from 'types/User.types'

export class UserService extends HTTP {
  private endpoint = '/user'
  private config: Record<string, any> = {
    showLoading: true
  }

  public changePassword({ payload }: { payload: ChangePasswordType }) {
    return this.patch({
      endpoint: `${this.endpoint}/update-passwd`,
      payload,
      config: this.config
    })
  }

  public create({ payload }: { payload: UserType }) {
    return this.post({
      endpoint: this.endpoint,
      payload,
      config: this.config
    })
  }

  public deleteUser({ id }) {
    return this.delete({ endpoint: `${this.endpoint}/${id}` })
  }

  public edit(user: UserType) {
    const { id } = user
    return this.put({
      endpoint: `${this.endpoint}/${id}`,
      payload: user,
      config: this.config
    })
  }

  public fetchAll({ params = {} } = {}) {
    return this.get({ endpoint: this.endpoint, params })
  }

  public fetchOne(id) {
    return this.get({ endpoint: `${this.endpoint}/${id}` })
  }

  public fetchFilterOptions() {
    return this.get({ endpoint: `${this.endpoint}/filter-options` })
  }

  public requestPwdReset(email: string) {
    return this.post({
      endpoint: '/pwd-recovery',
      payload: { email },
      config: this.config
    })
  }

  public update(id: string, payload: Record<string, any>) {
    return this.put({
      endpoint: `${this.endpoint}/${id}`,
      payload,
      config: this.config
    })
  }

  public updateProfile(id: string, payload: Record<string, any>) {
    return this.patch({
      endpoint: `${this.endpoint}/update-profile/${id}`,
      payload,
      config: this.config
    })
  }

  public uploadAvatar(id: string, file: File) {
    const multipartForm = new FormData()
    multipartForm.set('file', file)
    return this.post({
      endpoint: `${this.endpoint}/upload-avatar/${id}`,
      payload: multipartForm,
      config: this.config
    })
  }

  public resetPwd(payload: {
    confirmPassword: string
    email: string
    password: string
    token: string
  }) {
    return this.patch({
      endpoint: '/pwd-recovery',
      payload,
      config: this.config
    })
  }

  public newPassword(payload: {
    confirmPassword: string
    email: string
    password: string
    token: string
  }) {
    return this.patch({
      endpoint: `${this.endpoint}/new-pwd`,
      payload,
      config: this.config
    })
  }

  public sendEmailNewPwd(payload: {
    id: string
    email: string
    token: string
    lastSignIn: Date
  }) {
    return this.patch({
      endpoint: `${this.endpoint}/send-email-new-pwd`,
      payload,
      config: this.config
    })
  }

  public confirmRegister(payload: {
    password: string
    confirmPassword: string
    termOfUse: boolean
    typeNotification: string
    token: string
    email: string
  }) {
    return this.patch({
      endpoint: '/confirm-registration',
      payload,
      config: this.config
    })
  }
}

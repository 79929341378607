import { HTTP } from 'providers'
import { ClientGroupType } from 'types/ClientGroup.types'

export class ClientGroupService extends HTTP {
  private endpoint = '/retail-group'
  private config: Record<string, any> = {
    showLoading: true
  }

  public fetchAll(params = {}) {
    return this.get({ endpoint: this.endpoint, params })
  }

  public fetchOne(id) {
    return this.get({
      endpoint: `${this.endpoint}/${id}`
    })
  }

  public create(clientGroup: ClientGroupType) {
    return this.post({
      endpoint: this.endpoint,
      payload: clientGroup,
      config: this.config
    })
  }

  public edit(clientGroup: ClientGroupType) {
    const { id } = clientGroup
    return this.put({
      endpoint: `${this.endpoint}/${id}`,
      payload: clientGroup,
      config: this.config
    })
  }

  public deleteClientFromGroup({ clientGroupId, clientIds }) {
    return this.put({
      endpoint: `${this.endpoint}/deleteClientGroupId/${clientGroupId}`,
      payload: { clientIds },
      config: this.config
    })
  }

  public linkWithGroup({ id, clientIds }) {
    return this.patch({
      endpoint: `${this.endpoint}/link-with-retails/${id}`,
      payload: { retailIds: clientIds },
      config: this.config
    })
  }

  public unlinkWithGroup({ id, clientIds }) {
    return this.patch({
      endpoint: `${this.endpoint}/unlink-with-retails/${id}`,
      payload: { retailIds: clientIds },
      config: this.config
    })
  }

  public deleteClientGroup({ id }) {
    return this.delete({
      endpoint: `${this.endpoint}/${id}`
    })
  }

  public async options() {
    return this.get({ endpoint: `${this.endpoint}/options` })
  }
}

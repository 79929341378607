export const downloadFile = (data: any, fileName: string) => {
  const date = new Date().toISOString().split('T')[0]
  const name = fileName || `file-export-${date}.xlsx`
  const blob = new Blob([data])
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = name
  link.click()
  link.remove()
}

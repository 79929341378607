import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import PublishSharpIcon from '@material-ui/icons/PublishSharp'
import i18n from 'i18n'

import {
  AddButton,
  Button,
  Input,
  InputCpfCnpj,
  InputSelect,
  Pagination,
  StatusBox,
  Table
} from 'components'
import { PropertyDropdown } from './PropertyDropdown'
import { ColumnsProps } from 'components/Table/Table'

import { ModalAddProducer } from './ModalAddProducer/ModalAddProducer'
import { ModalDelete } from './ModalDelete/ModalDelete'
import { ModalImport } from './ModalImport/ModalImport'
import { ModalLogsList } from './ModalLogsList/ModalLogsList'
import { ModalBlockProducer } from './ModalBlockProducer/ModalBlockProducer'
import { ModalBlockProperty } from './ModalBlockProperty/ModalBlockProperty'

import { all as allProperty } from 'store/Property/Property.selector'
import { all as allPropertyTmp } from 'store/PropertyTmp/PropertyTmp.selector'
import { propertyActions } from 'store/Property'
import { propertyTmpActions } from 'store/PropertyTmp'

import { submitOnEnter } from 'utils/submitOnEnter'

import { PROPERTY_BLOCK_STATUS_GET } from 'constants/property.constants'

import { cpfCnpj } from 'utils/cpfCnpjValidator'

import * as S from './styled'
import { STATES } from 'constants/location.constants'

const INITIAL_STATE = {
  blockStatus: '',
  CAR: '',
  CPFCNPJ: '',
  column: 'CAR',
  limit: 10,
  name: '',
  page: 0,
  producer: '',
  direction: 'asc',
  state: ''
}

const columns = (): ColumnsProps[] => [
  {
    align: 'center',
    fixed: true,
    name: 'blockStatus',
    title: i18n.t('common:situation')
  },
  { fixed: true, name: 'propertyName', title: i18n.t('common:properties') },
  { align: 'right', name: 'CAR', title: i18n.t('common:carNumber') },
  { name: 'producerName', title: i18n.t('common:productor') },
  { name: 'CPFCNPJ', title: i18n.t('common:cpfCNPJ') },
  { name: 'IE', title: i18n.t('tables:IE') },
  { name: 'state', title: i18n.t('common:state') }
]

const schema = Yup.object().shape({
  blockStatus: Yup.string(),
  CAR: Yup.string(),
  CPFCNPJ: Yup.lazy(data => {
    if (data) {
      return cpfCnpj.verify().required().label('CPF / CNPJ')
    }
    return Yup.string()
  }),
  name: Yup.string(),
  producer: Yup.string(),
  state: Yup.string(),
  column: Yup.string(),
  limit: Yup.number(),
  direction: Yup.string(),
  page: Yup.number()
})

export function List() {
  const dispatch = useDispatch()

  const [errors, setErrors] = useState({ ...INITIAL_STATE })
  const [filter, setFilter] = useState({ ...INITIAL_STATE })
  const [_deleteProperty, _setDeleteProperty] = useState({
    propertyName: null,
    producerName: null,
    id: null
  })
  const [history, setHistory] = useState(null)
  const [modalImport, setModalImport] = useState(false)
  const [addProducer, setAddProducer] = useState(null)
  const [blockProducer, setBlockProducer] = useState(null)
  const [blockProperty, setBlockProperty] = useState(null)

  const { logsProperties, properties, propertiesImport } =
    useSelector(allProperty)
  const { propertiesTmp } = useSelector(allPropertyTmp)

  useEffect(() => {
    dispatch(propertyActions.fetchAll())
    dispatch(propertyTmpActions.fetchAll())
    if (propertiesImport.length) {
      dispatch(propertyActions.cleanProperty())
    }
  }, [dispatch, propertiesImport])

  useEffect(() => {
    dispatch(propertyTmpActions.fetchAll())
  }, [dispatch, properties])

  useEffect(() => {
    if (history) {
      const params = {
        propertyId: history.propertyId,
        producerId: history.producerId
      }
      dispatch(propertyActions.fetchAllLogs({ params }))
    }
  }, [dispatch, history])

  function changeColumn({ column, row }) {
    if (column.name === 'blockStatus') {
      return (
        <StatusBox
          variant={row.blockStatus ? 'inactive' : 'active'}
          minWidth={9}
        >
          {row.blockStatus
            ? i18n.t('common:blocked')
            : i18n.t('common:released')}
        </StatusBox>
      )
    } else return row[column.name]
  }

  function handleInput(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setFilter({ ...filter, [name]: value })
  }

  async function onFilter() {
    try {
      const params = await schema.validate(filter, {
        abortEarly: false,
        stripUnknown: true
      })
      dispatch(propertyActions.fetchAll({ params }))
    } catch (ex) {
      const pairs = ex.inner.map(({ path, message }) => [
        path,
        message.replace(`${path} `, '')
      ])
      setErrors(Object.fromEntries(pairs) as typeof INITIAL_STATE)
    }
  }

  function onFilterPaginate(params = {}) {
    dispatch(propertyActions.fetchAll({ params }))
  }

  function clearFilter() {
    const params = {
      ...INITIAL_STATE,
      limit: properties.pagination.limit,
      page: properties.pagination.page,
      column: filter.column,
      direction: filter.direction
    }
    setFilter(params)
    setErrors({ ...INITIAL_STATE })
    dispatch(propertyActions.fetchAll({ params }))
  }

  function _onSortColumn(column) {
    const { column: columnOld, direction } = filter
    const newSort =
      column !== columnOld ? 'asc' : direction === 'asc' ? 'desc' : 'asc'
    setFilter({ ...filter, column, direction: newSort })

    const params = { ...filter, column, direction: newSort }
    dispatch(propertyActions.fetchAll({ params }))
  }

  function actionsOnTable({
    blockStatus,
    CAR,
    CPFCNPJ,
    IE,
    id,
    producerId,
    producerName,
    propertyId,
    propertyName,
    reason
  }) {
    return (
      <PropertyDropdown
        id={id}
        blockStatus={blockStatus}
        setAddProducer={() =>
          setAddProducer({ propertyName, CAR, id: propertyId })
        }
        setBlockProducer={() =>
          setBlockProducer({
            blockStatus,
            CAR,
            CPFCNPJ,
            id: propertyId,
            IE,
            producerId,
            producerName,
            propertyName,
            reason
          })
        }
        setBlockProperty={() =>
          setBlockProperty({
            CAR,
            propertyId,
            propertyName
          })
        }
        setDeleteProperty={() =>
          _setDeleteProperty({ propertyName, producerName, id })
        }
        setHistory={() => setHistory({ propertyId, producerId })}
      />
    )
  }

  function onExitModalDelete() {
    _setDeleteProperty({ propertyName: null, producerName: null, id: null })
  }

  function onDelete(id) {
    return function () {
      dispatch(propertyActions.deleteProperty({ id }))
      onExitModalDelete()
    }
  }

  return (
    <S.Wrapper container>
      {addProducer ? (
        <ModalAddProducer
          property={addProducer}
          onExit={() => setAddProducer(null)}
        />
      ) : null}
      {blockProducer ? (
        <ModalBlockProducer
          property={blockProducer}
          onExit={() => setBlockProducer(null)}
        />
      ) : null}
      {blockProperty ? (
        <ModalBlockProperty
          property={blockProperty}
          onExit={() => setBlockProperty(null)}
        />
      ) : null}
      {modalImport && <ModalImport onExit={() => setModalImport(false)} />}
      {_deleteProperty.id ? (
        <ModalDelete
          property={_deleteProperty.propertyName}
          producer={_deleteProperty.producerName}
          onExit={onExitModalDelete}
          onRemove={onDelete(_deleteProperty.id)}
        />
      ) : null}
      {history ? (
        <ModalLogsList
          logs={logsProperties.items}
          onExit={() => setHistory(null)}
        />
      ) : null}
      <S.ButtonImport>
        {propertiesTmp.items.length ? (
          <S.Notification>
            <p>{propertiesTmp.items.length}</p>
          </S.Notification>
        ) : null}
        <Button
          variant={propertiesTmp.items.length ? 'secondary' : 'default'}
          onClick={() => {
            if (propertiesTmp.items.length) {
              setModalImport(true)
            }
          }}
          size="medium"
        >
          <S.Import>
            <p>{i18n.t('common:importProperties')}</p>
            <PublishSharpIcon style={{ marginLeft: 8, fontSize: 18 }} />
          </S.Import>
        </Button>
      </S.ButtonImport>

      <S.ButtonAdd>
        <Link to="/propriedade/novo">
          <AddButton />
        </Link>
      </S.ButtonAdd>
      <S.FullGrid item xs={12}>
        <S.GridHeader>
          <S.GridTitle item container xs={12}>
            <S.BoxTitle>{i18n.t('common:filterProperties')}</S.BoxTitle>
            <S.BoxLine />
          </S.GridTitle>
        </S.GridHeader>
        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={3}>
            <InputSelect
              error={Boolean(errors.blockStatus)}
              fullWidth
              helperText={errors.blockStatus}
              label={i18n.t('common:situation')}
              onSelected={({ value }) => handleInput(value, 'blockStatus')}
              optionLabel="label"
              options={PROPERTY_BLOCK_STATUS_GET()}
              optionValue="value"
              value={filter.blockStatus}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              fullWidth
              error={Boolean(errors.name)}
              helperText={errors.name}
              label={i18n.t('common:propertyName')}
              value={filter.name}
              onInput={value => handleInput(value, 'name')}
              onKeyPress={submitOnEnter(onFilter)}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              error={Boolean(errors.CAR)}
              fullWidth
              helperText={errors.CAR}
              label={i18n.t('common:CAR')}
              value={filter.CAR}
              onKeyPress={submitOnEnter(onFilter)}
              onInput={value => handleInput(value, 'CAR')}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              error={Boolean(errors.producer)}
              fullWidth
              helperText={errors.producer}
              label={i18n.t('common:producerName')}
              value={filter.producer}
              onKeyPress={submitOnEnter(onFilter)}
              onInput={value => handleInput(value, 'producer')}
            />
          </S.GridInput>
        </S.GridFilter>
        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={3}>
            <InputCpfCnpj
              error={Boolean(errors.CPFCNPJ)}
              fullWidth
              helperText={errors.CPFCNPJ}
              label={i18n.t('common:producerDocument')}
              value={filter.CPFCNPJ}
              onInput={value => handleInput(value, 'CPFCNPJ')}
              onKeyPress={submitOnEnter(onFilter)}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <InputSelect
              error={Boolean(errors.state)}
              fullWidth
              helperText={errors.state}
              label={i18n.t('common:state')}
              onSelected={({ value }) => handleInput(value, 'state')}
              optionLabel="title"
              options={STATES}
              optionValue="value"
              value={filter.state}
            />
          </S.GridInput>
          <S.GridButtons item xs={12} sm={12} md={6}>
            <Button variant="default" onClick={clearFilter} size="medium">
              <p>{i18n.t('common:clean')}</p>
            </Button>
            <Button variant="primary" onClick={onFilter} size="medium">
              <p>{i18n.t('common:search')}</p>
            </Button>
          </S.GridButtons>
        </S.GridFilter>
        <S.GridTable>
          <Table
            columns={columns()}
            linkTableNotFound="/propriedade/novo"
            rows={properties.items}
            sortColumn={{ column: filter.column, sort: filter.direction }}
            actionTable={actionsOnTable}
            changeColumn={changeColumn}
          />
          {properties.pagination?.totalItems > 0 && (
            <Pagination
              filters={{
                ...filter,
                limit: properties.pagination.limit,
                page: properties.pagination.page
              }}
              limit={properties.pagination.limit}
              page={properties.pagination.page}
              totalItems={properties.pagination.totalItems}
              setFilter={onFilterPaginate}
            />
          )}
        </S.GridTable>
      </S.FullGrid>
    </S.Wrapper>
  )
}

import styled from 'styled-components'

interface ButtonProps {
  minWidth: number
}

export const Button = styled.button<ButtonProps>`
  outline: none;
  border-radius: 4rem;
  border: none;
  font-size: 1.4rem;
  font-weight: 600;
  transform-origin: center;
  transition: background-color 400ms, color 400ms, transform 400ms;
  will-change: background-color, color, transform;
  margin: 0.8rem;
  width: max-content;
  min-width: ${({ minWidth }) => minWidth}rem;

  &.primary {
    background-color: ${({ theme }) => theme.colors.primary2};
    color: white;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary.darken()};
    }
  }

  &.secondary {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: white;
    &:hover {
      background-color: ${({ theme }) => theme.colors.secondary.darken()};
    }
  }

  &.default {
    background-color: ${({ theme }) => theme.colors.gray};
    color: white;
    &:hover {
      background-color: ${({ theme }) => theme.colors.gray.darken()};
    }
  }

  &.filter {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.gray};
    font-weight: 500;
    border-radius: 0.8rem;
    margin: 0 !important;
  }

  &.loggedOut {
    background-color: ${({ theme }) => theme.colors.buttonLoggedOut};
    color: white;
    &:hover {
      background-color: ${({ theme }) => theme.colors.buttonLoggedOut.darken()};
    }
  }

  &.warn {
    background-color: ${({ theme }) => theme.colors.warn};
    color: white;
    &:hover {
      background-color: ${({ theme }) => theme.colors.warn.darken()};
    }
  }
  &.fixed {
    background-color: ${({ theme }) => theme.colors.warn};
    color: white;
  }

  &:active {
    transform: scale(0.88);
  }
  &.yes {
    border: 1px solid #abafb3;
  }
  &.not {
    border: none;
  }

  &.large {
    padding: 1.4rem 10rem;
    font-size: 1.6rem;
  }

  &.medium {
    padding: 1.1rem 3.2rem;
  }
  &.sizeFiler {
    padding: 1.1rem 1.2rem 1.1rem 3.2rem;
  }

  &.small {
    padding: 0.7rem 2.8rem;
  }

  &.xsmall {
    box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.background.darken()};
    font-weight: 400;
    padding: 0.1rem 1.1rem;
  }

  &.alternative {
    background-color: ${({ theme }) => theme.colors.buttonSave};
    color: white;
    &:hover {
      background-color: ${({ theme }) => theme.colors.buttonSave.darken()};
    }
  }

  &.map {
    background-color: ${({ theme }) => theme.colors.buttonMap};
    color: white;
    &:hover {
      background-color: ${({ theme }) => theme.colors.buttonMap.darken()};
    }
  }

  &.lessBorder {
    margin: 0;
    padding: 1.1rem 1.6rem 1.1rem 2.4rem;
    border-radius: 0.8rem;
    background-color: transparent;
    border: 1px solid #abafb3;
    color: ${({ theme }) => theme.colors.darkGray};
    &:active,
    :hover {
      background-color: white !important;
      color: ${({ theme }) => theme.colors.darkGray} !important;
    }
  }

  &.permission {
    background-color: ${({ theme }) => theme.colors.backgroundPermission};
    color: white;
    &:hover {
      background-color: ${({ theme }) =>
        theme.colors.backgroundPermission.darken()};
    }
  }
`

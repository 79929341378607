import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { ModalDeleteIndustry } from 'components/Industry/ModalDeleteIndustry/ModalDeleteIndustry'
import { StatusBox } from 'components/StatusBox'
import { TableNotFoundHint } from 'components/TableNotFoundHint'

import { deleteIndustry } from 'store/Industry/Industry.actions'

import { IndustryType } from 'types/Industry.types'

import { IndustryDropdown } from './IndustryDropdown'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type IndustryTableProps = {
  rows: IndustryType[]
}

export function IndustryTable({ rows }: IndustryTableProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [_deleteIndustry, _setDeleteIndustry] = useState({
    cnpj: null,
    id: null
  })

  function onExit() {
    _setDeleteIndustry({ cnpj: null, id: null })
  }

  function onDelete(id) {
    return function () {
      dispatch(deleteIndustry({ id }))
      onExit()
    }
  }

  return (
    <S.Wrapper data-testid="IndustryTable">
      {_deleteIndustry.id ? (
        <ModalDeleteIndustry
          cnpj={_deleteIndustry.cnpj}
          onExit={onExit}
          onRemove={onDelete(_deleteIndustry.id)}
        />
      ) : null}
      <S.Teste>
        <S.Table id="table-fixed">
          <tbody>
            <S.Line id="header">
              <S.Header>{t('common:status')}</S.Header>
            </S.Line>
            {rows.map(({ id, status }) => (
              <S.Line id="values" key={id}>
                <S.Column className="center">
                  <StatusBox variant={status ? 'active' : 'inactive'}>
                    {status ? t('common:active') : t('common:inactive')}
                  </StatusBox>
                </S.Column>
              </S.Line>
            ))}
          </tbody>
        </S.Table>
        <S.ScrollWrapper>
          <S.Table id="table-scrolled">
            <tbody>
              <S.Line id="header">
                <S.Header>{t('tables:socialReason')}</S.Header>
                <S.Header>{t('tables:fantasyName')}</S.Header>
                <S.Header>{t('tables:inspectionType')}</S.Header>
                <S.Header className="right">
                  {t('tables:inspectionNumber')}
                </S.Header>
                <S.Header className="right">{t('tables:CNPJ')}</S.Header>
                <S.Header className="right">{t('tables:IE')}</S.Header>
                <S.Header className="right">{t('common:groups')}</S.Header>
                <S.Header className="right">{t('common:latitude')}</S.Header>
                <S.Header className="right">{t('common:longitude')}</S.Header>
              </S.Line>
              {rows.map(each => (
                <S.Line id="values" key={each.id}>
                  <S.Column>{each.name}</S.Column>
                  <S.Column>{each.fancyName}</S.Column>
                  <S.Column>{each.inspectionType}</S.Column>
                  <S.Column className="right">{each.inspectionNum}</S.Column>
                  <S.Column className="right">{each.cnpj}</S.Column>
                  <S.Column className="right">{each.ie}</S.Column>
                  <S.Column className="right">{each.total}</S.Column>
                  <S.Column className="right">{each.lat || '-'}</S.Column>
                  <S.Column className="right">{each.lng || '-'}</S.Column>
                </S.Line>
              ))}
            </tbody>
          </S.Table>
        </S.ScrollWrapper>
        <S.Table id="table-fixed">
          <tbody>
            <S.Line id="header">
              <S.Header>{t('common:actions')}</S.Header>
            </S.Line>
            {Boolean(rows && rows.length) &&
              rows.map(({ cnpj, id }) => (
                <S.Line key={id}>
                  <S.Column id="actions-column">
                    <IndustryDropdown
                      id={id}
                      setDeleteIndustry={() => _setDeleteIndustry({ cnpj, id })}
                    />
                  </S.Column>
                </S.Line>
              ))}
          </tbody>
        </S.Table>
      </S.Teste>
      {!rows.length && <TableNotFoundHint to="/industria/novo" />}
    </S.Wrapper>
  )
}

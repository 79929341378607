import { get } from 'lodash'

import { types } from './Version.actions'

type Version = { versionApi: string }

const INITIAL_STATE = { versionApi: '' }

function setState(state: Version) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}
export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.SET]: setState(state),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}

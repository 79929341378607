import { IconButton } from '@material-ui/core'
import { Link } from 'react-router-dom'

import { Dropdown } from '../../../Dropdown'
import { Icon } from '../../../Icon'
import { useTranslation } from 'react-i18next'

import * as S from './styles'
import { THEMES_NAME } from 'config/theme.config'
import { theme } from 'config'

type UserDropdownProps = {
  id: string
  setDeleteUser: () => void
}

export const UserDropdown = ({ id, setDeleteUser }: UserDropdownProps) => {
  const { t } = useTranslation()
  return (
    <S.Wrapper>
      <Dropdown
        title={
          <IconButton
            id="action"
            color="inherit"
            aria-label="open drawer"
            onClick={() => {}}
            size="medium"
            edge="start"
          >
            <Icon
              icon={
                theme.appTheme === THEMES_NAME.swift
                  ? 'itens-interface-actions-swift'
                  : 'itens-interface-actions'
              }
              width={12}
              height={12}
              aria-label="action"
            />
          </IconButton>
        }
      >
        <S.Nav>
          <Link to={`/usuario/editar/${id}`}>
            <S.Link>
              <Icon icon="dropdown-edit" height={16} width={16}></Icon>
              <span>{t('common:edit')}</span>
            </S.Link>
          </Link>

          {/* <Link to={`/usuario/editar/${id}`}>
            <S.Link>
              <Icon icon="modal-exit" height={16} width={16}></Icon>
              <span>Desativar</span>
            </S.Link>
          </Link> */}
          <S.Link onClick={setDeleteUser}>
            <Icon icon="dropdown-delete" height={16} width={16}></Icon>
            <span>{t('common:delete')}</span>
          </S.Link>
        </S.Nav>
      </Dropdown>
    </S.Wrapper>
  )
}

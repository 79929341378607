import { call, put } from 'redux-saga/effects'

import { IndustryGroupService, IndustryService } from 'services'
import { spinnerActions } from 'store/Spinner'
import { toasts } from 'utils/toasts'

import * as actions from './IndustryGroup.actions'

export function* fetchAll(event) {
  yield put(actions.setLoading({ loading: true }))

  const $industryGroup = new IndustryGroupService()
  const {
    success,
    status,
    data: industryGroups
  } = yield call(
    $industryGroup.fetchAll.bind($industryGroup),
    event.payload.filters
  )

  yield put(actions.setLoading({ loading: false }))

  if (status === 404) {
    return yield put(
      actions.setIndustryGroups({
        industryGroups: { items: [], pagination: { totalItems: 0 } }
      })
    )
  } else if (success) {
    return yield put(actions.setIndustryGroups({ industryGroups }))
  } else toasts.generalFail()
}

export function* fetchUnrelatedWithGroup(action) {
  yield put(actions.setLoading({ loading: true }))

  const $industry = new IndustryService()
  const fetch = $industry.fetchUnrelatedWithGroup.bind($industry)

  const { id, params } = action.payload
  const {
    success,
    status,
    data: unrelatedIndustries
  } = yield call(fetch, { groupId: id, params })

  yield put(actions.setLoading({ loading: false }))

  if (status === 404) {
    return yield put(
      actions.setUnrelatedIndustries({
        unrelatedIndustries: { items: [], pagination: { totalItems: 0 } }
      })
    )
  } else if (success) {
    return yield put(actions.setUnrelatedIndustries({ unrelatedIndustries }))
  } else toasts.generalFail()
}

export function* fetchOne(action) {
  yield put(actions.setLoading({ loading: true }))

  const { id } = action.payload

  const $industryGroup = new IndustryGroupService()
  const industryGroup = yield call(
    $industryGroup.fetchOne.bind($industryGroup),
    id
  )

  if (industryGroup.success) {
    return yield put(
      actions.setManageIndustryGroup({
        manageIndustryGroup: industryGroup.data
      })
    )
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* filterGroupCompanies(action) {
  yield put(actions.setLoading({ loading: true }))
  const $industry = new IndustryService()
  const { data, success } = yield $industry.searchByGroup(action.payload)

  if (success) yield put(actions.updateGroupIndustries({ ...data }))
  else {
    yield put(
      actions.updateGroupIndustries({
        companies: [],
        companiesPagination: { totalItems: 0 }
      })
    )
  }

  yield put(actions.setLoading({ loading: false }))
}

export function* create(action) {
  yield put(actions.setLoading({ loading: true }))

  const { payload } = action

  const $industryGroup = new IndustryGroupService()
  const industryGroup = yield call(
    $industryGroup.create.bind($industryGroup),
    payload
  )

  if (industryGroup.success) {
    toasts.created()
    return yield put(
      actions.setIndustryGroupCreated({
        industryGroupCreate: industryGroup.data
      })
    )
  } else if (industryGroup.data.code === 'DUPLICATED') {
    toasts.duplicatedCNPJ()
    return yield put(actions.setLoading({ loading: false }))
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* edit(action) {
  yield put(actions.setLoading({ loading: true }))

  const { payload } = action

  const $industryGroup = new IndustryGroupService()
  const { success, data } = yield call(
    $industryGroup.edit.bind($industryGroup),
    payload
  )

  if (success) {
    toasts.edited()
    return yield put(actions.setIndustryGroupEdit({ industryGroupEdit: data }))
  } else if (data.code === 'DUPLICATED') {
    toasts.duplicatedCNPJ()
    return yield put(actions.setLoading({ loading: false }))
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* linkIndustryWithGroup(action) {
  yield put(actions.setLoading({ loading: true }))
  yield put(spinnerActions.setVisibility(true))

  const $industryGroup = new IndustryGroupService()
  const linkWithCompanies = $industryGroup.linkWithGroup.bind($industryGroup)

  const { id, companyIds } = action.payload
  const { success, data } = yield call(linkWithCompanies, { id, companyIds })

  if (success) {
    yield put(actions.setManageIndustryGroup({ manageIndustryGroup: data }))
  } else toasts.generalFail()

  yield put(spinnerActions.setVisibility(false))
  yield put(actions.setLoading({ loading: false }))
}

export function* unlinkIndustryWithGroup(action) {
  yield put(actions.setLoading({ loading: true }))
  yield put(spinnerActions.setVisibility(true))

  const $industryGroup = new IndustryGroupService()
  const unlinkWithGroup = $industryGroup.unlinkWithGroup.bind($industryGroup)

  const { success, data } = yield call(unlinkWithGroup, action.payload)

  if (success) {
    yield put(actions.setManageIndustryGroup({ manageIndustryGroup: data }))
  } else toasts.generalFail()

  yield put(spinnerActions.setVisibility(false))
  yield put(actions.setLoading({ loading: false }))
}

export function* deleteIndustryGroup(action) {
  const id = action.payload

  const $industryGroup = new IndustryGroupService()
  const industryGroup = yield call(
    $industryGroup.deleteIndustryGroup.bind($industryGroup),
    { id }
  )

  if (industryGroup.success) {
    toasts.deleted()
    return yield put(actions.fetchAll())
  } else toasts.generalFail()
}

export function* getIndustryGroupOptions() {
  const $industryGroup = new IndustryGroupService()
  const {
    success,
    status,
    data: options
  } = yield call($industryGroup.options.bind($industryGroup))

  if (success) return yield put(actions.setIndustryGroupOptions({ options }))
  else if (status === 404) {
    return yield put(actions.setIndustryGroupOptions({ options: [] }))
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

import { call, put } from 'redux-saga/effects'

import { toasts } from 'utils/toasts'

import {
  PropertyService,
  PropertyLogService,
  PropertyProducersService
} from 'services'

import * as actions from './Property.actions'

export function* fetchAll(action) {
  const { params } = action.payload

  yield put(actions.setProperty({ property: [] }))

  const $property = new PropertyProducersService()
  const {
    success,
    status,
    data: properties
  } = yield call($property.fetchAll.bind($property), { params })

  if (status === 404) {
    return yield put(
      actions.setProperties({
        properties: { items: [], pagination: { totalItems: 0 } }
      })
    )
  } else if (success) {
    return yield put(actions.setProperties({ properties }))
  } else toasts.generalFail()
}

export function* create(action) {
  const { payload } = action

  yield put(actions.setLoading({ loading: true }))

  const $property = new PropertyService()
  const property = yield call($property.create.bind($property), { payload })

  if (property.success) {
    toasts.created()
    return yield put(
      actions.setPropertyCreated({
        propertyCreate: property.data
      })
    )
  } else if (property.data.code === 'DUPLICATED') {
    toasts.duplicatedCPFCNPJIE()
    return yield put(actions.setLoading({ loading: false }))
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* fetchOne(action) {
  const { id } = action.payload

  yield put(actions.setLoading({ loading: true }))

  const $property = new PropertyProducersService()
  const property = yield call($property.fetchOne.bind($property), id)

  if (property.success) {
    return yield put(
      actions.setProperty({
        property: property.data
      })
    )
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* edit(action) {
  const { payload } = action

  yield put(actions.setLoading({ loading: true }))

  const $property = new PropertyService()
  const { success, data } = yield call($property.edit.bind($property), payload)

  if (success) {
    toasts.edited()
    return yield put(actions.setPropertyEdit({ propertyEdit: data }))
  } else if (data.code === 'DUPLICATED') {
    toasts.duplicated()
    return yield put(actions.setLoading({ loading: false }))
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* deleteProperty(action) {
  const id = action.payload

  const $property = new PropertyProducersService()
  const property = yield call($property.deleteProperty.bind($property), {
    id
  })

  if (property.success) {
    toasts.deleted()
    return yield put(actions.fetchAll())
  } else toasts.generalFail()
}

export function* fetchAllLogs(action) {
  const { params } = action.payload

  yield put(actions.setLoading({ loading: true }))

  const $logsProperty = new PropertyLogService()
  const {
    success,
    status,
    data: logsProperties
  } = yield call($logsProperty.fetchAllLogs.bind($logsProperty), { params })

  yield put(actions.setLoading({ loading: false }))

  if (status === 404) {
    return yield put(
      actions.setPropertiesLogs({
        logsProperties: { items: [], pagination: { totalItems: 0 } }
      })
    )
  } else if (success) {
    return yield put(actions.setPropertiesLogs({ logsProperties }))
  } else toasts.generalFail()
}

export function* importProperties(action) {
  yield put(actions.setLoading({ loading: true }))

  const $property = new PropertyService()
  const importProperties = $property.importProperties.bind($property)

  const { success, data: propertiesImport } = yield call(
    importProperties,
    action.payload
  )

  if (success) {
    toasts.import()
    return yield put(
      actions.setPropertiesImport({
        propertiesImport
      })
    )
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* addProducer(action) {
  const { payload } = action

  yield put(actions.setLoading({ loading: true }))

  const $property = new PropertyService()
  const { success, data } = yield call(
    $property.addProducer.bind($property),
    payload
  )

  if (success) {
    toasts.addProducer()
    return yield put(actions.setAddProducer({ addProducer: data }))
  } else if (data.code === 'DUPLICATED') {
    toasts.duplicated()
    return yield put(actions.setLoading({ loading: false }))
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* blockStatusProducer(action) {
  const { payload } = action

  yield put(actions.setLoading({ loading: true }))

  const $property = new PropertyService()
  const { success, data } = yield call(
    $property.blockStatusProducer.bind($property),
    payload
  )

  if (success) {
    toasts.blockStatusProducer()
    return yield put(
      actions.setBlockStatusProducer({ blockStatusProducer: data })
    )
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* fetchByPropertyId(action) {
  const { propertyId } = action.payload

  yield put(actions.setLoading({ loading: true }))

  const $property = new PropertyProducersService()
  const property = yield call(
    $property.fetchByPropertyId.bind($property),
    propertyId
  )

  if (property.success) {
    return yield put(
      actions.setPropertiesProducers({
        propertiesProducers: property.data
      })
    )
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* blockStatusAllProducer(action) {
  const { payload } = action

  yield put(actions.setLoading({ loading: true }))

  const $property = new PropertyService()
  const { success, data } = yield call(
    $property.blockStatusAllProducer.bind($property),
    payload
  )

  if (success) {
    toasts.blockStatusProducer()
    return yield put(
      actions.setBlockStatusAllProducer({ blockStatusAllProducer: data })
    )
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export function SuccessResetMessage() {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <S.Wrapper container>
      <S.FullGrid item xs={12}>
        <S.TitleMargin />

        <S.DescriptionBox item sm={12}>
          <S.DescriptionText>{t('authPages:updPassword')}</S.DescriptionText>
        </S.DescriptionBox>

        <S.GoBackLoginButton
          variant="secondary"
          onClick={() => history.push('/login')}
        >
          {t('authPages:backToLogin')}
        </S.GoBackLoginButton>
      </S.FullGrid>
    </S.Wrapper>
  )
}

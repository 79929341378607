import { TraceabilityFilter } from 'types/Traceability.types'

export const types = Object.freeze({
  CLEAN_KPIS: '@traceabilityTables/cleanKpis',
  SET_KPIS: '@traceabilityTables/setKpis',
  SET_CHARTS: '@traceabilityTables/setCharts',
  SET_FILTERS: '@traceabilityTables/setFilters',
  GET_KPIS_CARDS: '@traceabilityTables/getKpisCards',
  GET_KPIS_CHARTS: '@traceabilityTables/getCharts',
  SET_CARDS_LOADING: '@traceabilityTables/setCardsLoading',
  SET_CHARTS_LOADING: '@traceabilityTables/setChartsLoading',
  SET_TABLE_LOADING: '@traceabilityTables/setTableLoading',
  GET_PROPERTIES_LOCATION: '@traceabilityTables/getPropertiesLocation',
  SET_ACCESS_GROUPS_FILTER: '@traceabilityTables/setAccessGroupsFilter',
  SET_INDUSTRY_GROUPS_FILTER: '@traceabilityTables/setIndustryGroupsFilter'
})

export function setKpis({ kpis = {} } = {}) {
  return { type: types.SET_KPIS, payload: { ...kpis } }
}

export function setKpisCardsLoading(state: boolean) {
  return { type: types.SET_CARDS_LOADING, payload: { cardsLoading: state } }
}

export function getKpisCards(filterOrders?: TraceabilityFilter) {
  return { type: types.GET_KPIS_CARDS, payload: { filterOrders } }
}

export function setCharts({ charts = [] } = {}) {
  return { type: types.SET_CHARTS, payload: { ...charts } }
}

export function setKpisChartsLoading(state: boolean) {
  return { type: types.SET_CHARTS_LOADING, payload: { chartsLoading: state } }
}

export function getKpisCharts(filterOrders?: TraceabilityFilter) {
  return { type: types.GET_KPIS_CHARTS, payload: { filterOrders } }
}

export function setFilters(filters = {}) {
  return { type: types.SET_FILTERS, payload: { filters } }
}

export function setAccessGroups(accessGroupsFilter = []) {
  return {
    type: types.SET_ACCESS_GROUPS_FILTER,
    payload: { accessGroupsFilter }
  }
}
export function setIndustryGroups(industryGroupsFilter = []) {
  return {
    type: types.SET_INDUSTRY_GROUPS_FILTER,
    payload: { industryGroupsFilter }
  }
}

export function setTableLoading(state: boolean) {
  return { type: types.SET_TABLE_LOADING, payload: { tableLoading: state } }
}

export function cleanKpis() {
  return {
    type: types.CLEAN_KPIS,
    payload: {
      volumeDelivered: null,
      properties: null,
      invoices: null,
      fazCode: null,
      trackedTons: null,
      suitableProperties: null,
      sifProduction: null
    }
  }
}

export function getPropertiesLocation(filterProperties?: TraceabilityFilter) {
  return {
    type: types.GET_PROPERTIES_LOCATION,
    payload: { filterProperties }
  }
}

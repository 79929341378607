import { call, put } from 'redux-saga/effects'

import { toasts } from 'utils/toasts'

import { PropertyTmpService } from 'services'

import * as actions from './PropertyTmp.actions'

export function* fetchAll(action) {
  const { params } = action.payload

  yield put(actions.setLoading({ loading: true }))

  const $propertyTmp = new PropertyTmpService()
  const {
    success,
    status,
    data: propertiesTmp
  } = yield call($propertyTmp.fetchAll.bind($propertyTmp), { params })

  yield put(actions.setLoading({ loading: false }))

  if (status === 404) {
    return yield put(
      actions.setProperties({
        propertiesTmp: { items: [], pagination: { totalItems: 0 } }
      })
    )
  } else if (success) {
    return yield put(actions.setProperties({ propertiesTmp }))
  } else toasts.generalFail()
}

export function* deletePropertyTmp(action) {
  const id = action.payload

  const $propertyTmp = new PropertyTmpService()
  const propertyTmp = yield call(
    $propertyTmp.deletePropertyTmp.bind($propertyTmp),
    {
      id
    }
  )

  if (propertyTmp.success) {
    toasts.discarded()
  } else toasts.generalFail()
}

import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { InputCpfCnpj } from 'components/InputCpfCnpj'

import { useTranslation } from 'react-i18next'
import * as S from './styled'

export type BasicInformationProps = {
  form?: any
  sendEmail?: () => void
}

export function BasicInformation({ form, sendEmail }: BasicInformationProps) {
  const { t } = useTranslation()

  return (
    <S.GridFields container spacing={2}>
      <S.GridInput item sm={12} md={6}>
        <Input
          fullWidth
          disabled
          label={t('profile:firstName')}
          value={form.firstName}
        />
      </S.GridInput>
      <S.GridInput item sm={12} md={6}>
        <Input
          fullWidth
          disabled
          label={t('profile:lastName')}
          value={form.lastName}
        />
      </S.GridInput>
      <S.GridInput item sm={12} md={6}>
        <Input
          fullWidth
          disabled
          label={t('common:email')}
          value={form.email}
        />
      </S.GridInput>
      <S.GridInput item sm={12} md={6}>
        <InputCpfCnpj
          fullWidth
          disabled
          label={t('profile:cpfNumber')}
          value={form.CPF}
          type="cpf"
        />
      </S.GridInput>

      <S.GridInput item sm={12} md={6}>
        <Input
          fullWidth
          disabled
          label={t('profile:department')}
          value={form.department}
        />
      </S.GridInput>
      <S.GridInput item sm={12} md={6}>
        <Input
          fullWidth
          disabled
          label={t('common:userType')}
          value={form.role}
        />
      </S.GridInput>
      <S.Button>
        <Button onClick={sendEmail} size="medium">
          {t('common:changePwd')}
        </Button>
      </S.Button>
    </S.GridFields>
  )
}

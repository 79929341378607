import { useSelector } from 'react-redux'
import * as S from './styles'
import { versionApi } from 'store/Version/Version.selector'
export const Footer = () => {
  const year = new Date().getFullYear()
  const appVersion = process.env.REACT_APP_VERSION
  const appVersionApi = useSelector(versionApi)
  return (
    <S.Wrapper data-testid="footer">
      <S.Title>
        © {year}{' '}
        <a href="https://ecotrace.info/" target="_blanc">
          By Ecotrace Solutions | v{appVersion}-{appVersionApi.versionApi}
        </a>
      </S.Title>
    </S.Wrapper>
  )
}

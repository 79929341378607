import { Icon } from 'components/Icon'
import { Loading } from 'components/Loading'
import { Link } from 'react-router-dom'
import * as S from './styled'

export type CardProps = {
  quantity: string | number
  redirect?: string
  description: string
  service: string
  variant: 'green' | 'lightGreen' | 'boldGreen' | 'darkGreen'
  cursor?: 'pointer' | 'default'
  minWidth?: number
  className?: string
  isLoading: boolean
  icon: string
}

export function NewCard({
  quantity,
  redirect,
  description,
  service,
  variant,
  minWidth = 1,
  className,
  isLoading = false,
  icon,
  cursor
}: CardProps) {
  return (
    <Link to={redirect} style={{ cursor: `${cursor} ` }}>
      <S.Card isLoading={isLoading}>
        <S.Quantity
          minWidth={minWidth}
          className={`${variant}  ${className}`}
          textLength={String(quantity).length}
        >
          {isLoading ? <Loading loadingColorWhite /> : quantity}
        </S.Quantity>
        <S.Section>
          <S.Service>
            <S.Title>{service}</S.Title>
            <S.Description>{description}</S.Description>
          </S.Service>
          {cursor === 'pointer' && (
            <S.Icon>
              <Icon icon={icon} height={46} width={16}></Icon>
            </S.Icon>
          )}
        </S.Section>
      </S.Card>
    </Link>
  )
}

import styled from 'styled-components'
export const Remove = styled.div`
  position: absolute;
`

export const RemoveFile = styled.label`
  position: relative;
  z-index: 150;
  top: -6rem;
  left: 5.2rem;
`

export const Camera = styled.div`
  position: absolute;
`

export const InputFile = styled.input`
  display: none;
`

export const LabelFile = styled.label`
  position: relative;
  z-index: 9;

  &.circle {
    bottom: -4.2rem;
  }

  &.square {
    bottom: -3.1rem;
  }
`
export const WraperImage = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;

  img {
    width: 60%;
    height: 50%;
  }
`

export const LabelPhoto = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.primary};
  height: 1.5rem;
  margin-top: 1.6rem;
`

export const GroupInfo = styled.div`
  text-align: start;
  position: relative;
  top: 4rem;
  left: 0.2rem;
  width: 100%;
  p {
    font-size: 1rem;
  }
`

/* eslint-disable no-template-curly-in-string */
import { setLocale } from 'yup'
import i18n from 'i18n'

export const translation = () => {
  return {
    array: {
      max: i18n.t('inputErrors:maxLenght'),
      min: i18n.t('inputErrors:minLenght')
    },
    date: {
      max: i18n.t('inputErrors:maxDate'),
      min: i18n.t('inputErrors:minDate')
    },
    mixed: {
      default: i18n.t('inputErrors:isInvalid'),
      notOneOf: i18n.t('inputErrors:notBeEqual'),
      oneOf: i18n.t('inputErrors:mustEqual'),
      required: i18n.t('inputErrors:isRequired')
    },
    number: {
      integer: i18n.t('inputErrors:beInteger'),
      lessThan: i18n.t('inputErrors:beLessThan'),
      max: i18n.t('inputErrors:beAtMost'),
      min: i18n.t('inputErrors:beAtLeast'),
      moreThan: i18n.t('inputErrors:beGreaterThan'),
      negative: i18n.t('inputErrors:beNegative'),
      notEqual: i18n.t('inputErrors:notBeEqualTo'),
      positive: i18n.t('inputErrors:bePositive')
    },
    string: {
      email: i18n.t('inputErrors:isInvalid'),
      length: i18n.t('inputErrors:mustContainExactly'),
      lowercase: i18n.t('inputErrors:beUppercase'),
      max: i18n.t('inputErrors:containAtMost'),
      min: i18n.t('inputErrors:containAtLeast'),
      trim: i18n.t('inputErrors:notContainWhitespace'),
      uppercase: i18n.t('inputErrors:beLowercase'),
      url: i18n.t('inputErrors:notContainValidURL')
    }
  }
}

setLocale(translation())

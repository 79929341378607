import { call, put } from 'redux-saga/effects'

import { toasts } from 'utils/toasts'

import { ClientGroupService, ClientService } from 'services'

import * as actions from './ClientGroup.actions'
import { spinnerActions } from 'store/Spinner'

export function* fetchAll(event) {
  yield put(actions.setLoading({ loading: true }))

  const $clientGroup = new ClientGroupService()
  const {
    success,
    status,
    data: clientGroups
  } = yield call(
    $clientGroup.fetchAll.bind($clientGroup),
    event.payload.filters
  )

  yield put(actions.setLoading({ loading: false }))

  if (status === 404) {
    return yield put(
      actions.setClientGroups({
        clientGroups: { items: [], pagination: { totalItems: 0 } }
      })
    )
  } else if (success) {
    return yield put(actions.setClientGroups({ clientGroups }))
  } else toasts.generalFail()
}

export function* fetchOne(action) {
  yield put(actions.setLoading({ loading: true }))

  const { id } = action.payload

  const $clientGroup = new ClientGroupService()
  const clientGroup = yield call($clientGroup.fetchOne.bind($clientGroup, id))

  if (clientGroup.success) {
    return yield put(
      actions.setManageClientGroup({
        manageClientGroup: clientGroup.data
      })
    )
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* filterGroupClients(action) {
  yield put(actions.setLoading({ loading: true }))
  const $client = new ClientService()
  const { data, success } = yield $client.searchByGroup(action.payload)

  if (success) yield put(actions.updateGroupClients({ ...data }))
  else {
    yield put(
      actions.updateGroupClients({
        retails: [],
        retailsPagination: { totalItems: 0 }
      })
    )
  }

  yield put(actions.setLoading({ loading: false }))
}

export function* create(action) {
  yield put(actions.setLoading({ loading: true }))

  const { payload } = action

  const $clientGroup = new ClientGroupService()
  const clientGroup = yield call(
    $clientGroup.create.bind($clientGroup),
    payload
  )

  if (clientGroup.success) {
    toasts.created()
    return yield put(
      actions.setClientGroupCreated({
        clientGroupCreate: clientGroup.data
      })
    )
  } else if (clientGroup.data.code === 'DUPLICATED') {
    toasts.duplicatedCNPJ()
    return yield put(actions.setLoading({ loading: false }))
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* edit(action) {
  yield put(actions.setLoading({ loading: true }))

  const { payload } = action

  const $clientGroup = new ClientGroupService()
  const { success, data } = yield call(
    $clientGroup.edit.bind($clientGroup),
    payload
  )

  if (success) {
    toasts.edited()
    return yield put(
      actions.setClientGroupEdit({
        clientGroupEdit: data
      })
    )
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* deleteClientFromGroup(action) {
  const { payload } = action

  const $client = new ClientService()
  const { success } = yield call($client.removeMany.bind($client), payload)

  if (success) {
    toasts.deleted()
    return yield put(actions.removeDeletedClients(payload))
  } else toasts.generalFail()
}

export function* linkWithClient(action) {
  yield put(actions.setLoading({ loading: true }))
  yield put(spinnerActions.setVisibility(true))

  const $clientGroup = new ClientGroupService()
  const linkWithGroup = $clientGroup.linkWithGroup.bind($clientGroup)

  const { success, data } = yield call(linkWithGroup, action.payload)

  if (success) {
    yield put(actions.setManageClientGroup({ manageClientGroup: data }))
  } else toasts.generalFail()

  yield put(spinnerActions.setVisibility(false))
  yield put(actions.setLoading({ loading: false }))
}

export function* unlinkWithClient(action) {
  yield put(actions.setLoading({ loading: true }))
  yield put(spinnerActions.setVisibility(true))

  const $clientGroup = new ClientGroupService()
  const unlinkWithGroup = $clientGroup.unlinkWithGroup.bind($clientGroup)

  const { success, data } = yield call(unlinkWithGroup, action.payload)

  if (success) {
    yield put(actions.setManageClientGroup({ manageClientGroup: data }))
  } else toasts.generalFail()

  yield put(spinnerActions.setVisibility(false))
  yield put(actions.setLoading({ loading: false }))
}

export function* getUnrelatedClients(action) {
  yield put(actions.setLoading({ loading: true }))

  const $client = new ClientService()
  const fetch = $client.fetchUnrelatedWithGroup.bind($client)

  const {
    success,
    status,
    data: unrelatedClients
  } = yield call(fetch, {
    groupId: action.payload.id,
    params: action.payload.params
  })

  yield put(actions.setLoading({ loading: false }))

  if (status === 404) {
    return yield put(
      actions.setUnrelatedClients({
        unrelatedClients: { items: [], pagination: { totalItems: 0 } }
      })
    )
  } else if (success) {
    return yield put(actions.setUnrelatedClients({ unrelatedClients }))
  } else toasts.generalFail()
}

export function* deleteClientGroup(action) {
  const id = action.payload

  const $clientGroup = new ClientGroupService()
  const clientGroup = yield call(
    $clientGroup.deleteClientGroup.bind($clientGroup),
    { id }
  )

  if (clientGroup.success) {
    toasts.deleted()
    return yield put(actions.fetchAll())
  } else toasts.generalFail()
}

export function* getClientGroupOptions() {
  const $clientGroup = new ClientGroupService()
  const {
    success,
    status,
    data: options
  } = yield call($clientGroup.options.bind($clientGroup))

  if (success) return yield put(actions.setClientGroupOptions({ options }))
  else if (status === 404) {
    return yield put(actions.setClientGroupOptions({ options: [] }))
  } else toasts.generalFail()

  yield put(actions.setLoading({ loading: false }))
}

export function* getClientGroupExported(action) {
  const $client = new ClientService()

  yield call($client.exportByGroup.bind($client), action.payload)
}

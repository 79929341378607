import { HeaderBlock, Button } from 'components'
import MapIcon from '@material-ui/icons/Map'
import { useTranslation } from 'react-i18next'
import * as S from './styled'

export type Props = {
  // data?: Object
  title: string
  mapButton?: boolean
  onClikMap?(): any
}

export function GridHeader({
  // data,
  title,
  mapButton = false,
  onClikMap = () => {}
}) {
  const { t } = useTranslation()

  return (
    <S.HeaderGrid>
      <HeaderBlock title={title} />
      <div>
        {mapButton && (
          <Button variant="map" size="medium" onClick={onClikMap}>
            <S.ButtonContent>
              <MapIcon />
              <S.ButtonText>{t('common:viewMap')}</S.ButtonText>
            </S.ButtonContent>
          </Button>
        )}
        {/* <CSVExport data={data} /> */}
      </div>
    </S.HeaderGrid>
  )
}

import { useDispatch, useSelector } from 'react-redux'
import { SimpleCard } from 'components/SimpleCard'

import { all } from 'store/TraceabilityTables/TraceabilityTables.selector'

import * as S from './styled'
import { propertiesDetailsActions } from 'store/PropertiesDetails'
import { useTranslation } from 'react-i18next'

export function Cards() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { volumeDelivered, properties, invoices, trackedTons, cardsLoading } =
    useSelector(all) // pegar esse valor

  function setShowModal() {
    dispatch(propertiesDetailsActions.setShowModal(true))
  }

  return (
    <S.Wrapper item xs={12} md={2} container>
      <S.GridSimpleCard item xs={12}>
        <SimpleCard
          title={t('common:invoices')}
          description={invoices}
          isLoading={cardsLoading}
        />
      </S.GridSimpleCard>

      <S.GridSimpleCard item xs={12}>
        <SimpleCard
          title={t('common:tonsProduced')}
          description={trackedTons}
          isLoading={cardsLoading}
          variant="dark"
        />
      </S.GridSimpleCard>

      <S.GridSimpleCard item xs={12} onClick={setShowModal}>
        <SimpleCard
          clickable
          title="qualqer coisa"
          description={properties}
          isLoading={cardsLoading}
          variant="medium"
        />
      </S.GridSimpleCard>

      <S.GridSimpleCard item xs={12}>
        <SimpleCard
          title={t('common:volumesDelivered')}
          description={volumeDelivered}
          isLoading={cardsLoading}
          variant="light"
        />
      </S.GridSimpleCard>
    </S.Wrapper>
  )
}

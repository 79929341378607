import CssBaseline from '@material-ui/core/CssBaseline'
import { Provider as ReduxProvider } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { theme } from 'config'
import { THEMES } from 'themes'
import { store } from 'store'
import { GlobalStyle } from 'styles/global.styles'

import { ToastContainer } from './components/ToastContainer'
import { Spinner } from './components/Spinner'
import { Router } from './router'

import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

function App() {
  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={THEMES[theme.appTheme]}>
        <I18nextProvider i18n={i18n}>
          <GlobalStyle />
          <CssBaseline />
          <Router />
          <Spinner />
          <ToastContainer />
        </I18nextProvider>
      </ThemeProvider>
    </ReduxProvider>
  )
}

export default App

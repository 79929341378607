import { ProductType } from 'types/Product.types'

export const types = Object.freeze({
  CLEAN_PRODUCTS: '@product/cleanProducts',
  CREATE_PRODUCT: '@product/createProduct',
  DELETE_PRODUCT: '@product/deleteProduct',
  EDIT_PRODUCT: '@product/editProduct',
  FETCH_ALL: '@product/fetchAll',
  FETCH_ONE: '@product/fetchOne',
  FILTER_PRODUCTS: '@product/filterProducts',
  SET_PRODUCT_CREATED: '@product/setProductCreated',
  SET_PRODUCT_EDIT: '@product/setProductEdit',
  SET_PRODUCTS: '@product/setProducts',
  SET_PRODUCT: '@product/setProduct',
  SET_LOADING: '@product/setLoading'
})

export function setLoading({ loading = false } = {}) {
  return { type: types.SET_LOADING, payload: { loading } }
}

export function fetchOne(id: string) {
  return {
    type: types.FETCH_ONE,
    payload: { id }
  }
}

export function filterProducts({ params }) {
  return { type: types.FILTER_PRODUCTS, payload: { params } }
}

export function fetchAll(
  { params = {} }: { params?: Record<string, any> } = { params: {} }
) {
  return { type: types.FETCH_ALL, payload: { params } }
}

export function cleanProducts() {
  return {
    type: types.CLEAN_PRODUCTS,
    payload: {
      editProduct: {},
      createProduct: {},
      product: {}
    }
  }
}

export function setProducts({ products = {} } = {}) {
  return { type: types.SET_PRODUCTS, payload: { products } }
}

export function setProduct({ product = {} } = {}) {
  return {
    type: types.SET_PRODUCT,
    payload: { product, loading: false }
  }
}

export function createProduct(
  product: ProductType,
  toUploadImages: Record<string, File>
) {
  return {
    type: types.CREATE_PRODUCT,
    payload: { product, toUploadImages }
  }
}

export function deleteProduct({ id }) {
  return {
    type: types.DELETE_PRODUCT,
    payload: id
  }
}

export function editProduct(
  product: ProductType,
  toUploadImages: Record<string, File>
) {
  return {
    type: types.EDIT_PRODUCT,
    payload: { product, toUploadImages }
  }
}

export function setProductCreated({ productCreate = [] } = {}) {
  return {
    type: types.SET_PRODUCT_CREATED,
    payload: { productCreate, loading: false }
  }
}

export function setProductEdit({ productEdit = [] } = {}) {
  return {
    type: types.SET_PRODUCT_EDIT,
    payload: { productEdit, loading: false }
  }
}
